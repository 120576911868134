import { Parameter, ValueType } from "@hypertune/sdk/src/shared";
import { Expression } from "@hypertune/sdk/dist/dist/shared/types";
import { Variable, VariableMap } from "./types";
import { getVariableFieldPath } from "./fieldPath";

export default function getNewVariables(
  parameters: Parameter[],
  parameterValueTypes: ValueType[],
  parentArguments?: (Expression | null)[],
  parentPath?: string
): VariableMap {
  if (parameters.length !== parameterValueTypes.length) {
    return {};
  }

  return Object.fromEntries(
    parameters.map((parameter, index) => {
      const variable: Variable = {
        path: parentPath
          ? getVariableFieldPath(parentPath, parameter.name)
          : undefined,
        id: parameter.id,
        name: parameter.name,
        valueType: parameterValueTypes[index],
        value: parentArguments?.[index],
      };
      return [variable.id, variable];
    })
  );
}
