import { NavigateOptions } from "react-router-dom";
import useSearchParamsState from "../../../app/useSearchParamsState";

export const showSetupModalParamName = "setup";

export default function useShowSetupModal(): [
  showSetupModal: boolean,
  setShowSetupModal: (
    newShowSetupModal: boolean,
    options?: NavigateOptions
  ) => void,
] {
  const [showSetupModal, setShowSetupModal] = useSearchParamsState<boolean>(
    showSetupModalParamName,
    false
  );

  return [showSetupModal, setShowSetupModal];
}
