import { rootObjectTypeName } from "@hypertune/shared-internal";
import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setObjectAddFieldModalState } from "../projectSlice";
import Tooltip from "../../../components/tooltips/Tooltip";
import PlusButton from "../../../components/buttons/PlusButton";
import { useHypertune } from "../../../generated/hypertune.react";

export default function NewFlagButton({
  defaultFieldPath,
}: {
  defaultFieldPath: string[];
}): React.ReactElement | null {
  const content = useHypertune().content();
  const showNewFlagModal = useShowNewFlagModal(defaultFieldPath);

  if (!showNewFlagModal) {
    return null;
  }

  return (
    <Tooltip id="logic" step={1} allSteps={content.logic().tooltips()}>
      <PlusButton onClick={showNewFlagModal} />
    </Tooltip>
  );
}

export function useShowNewFlagModal(
  defaultFieldPath: string[]
): null | (() => void) {
  const dispatch = useAppDispatch();
  const expression = useAppSelector(
    (state) => state.project.draftCommit?.expression
  );

  if (!expression) {
    return null;
  }
  return () =>
    dispatch(
      setObjectAddFieldModalState({
        objectTypeName: rootObjectTypeName,
        fieldPosition: "first",
        entity: {
          name: "flag",
          parentFieldPath: defaultFieldPath.join(fieldPathSeparator),
        },
      })
    );
}
