import { Expression } from "@hypertune/sdk/src/shared/types";

const argsSuffix = "args";
export const argsObjectSuffix = `_${argsSuffix}`;

type FieldArgumentsObjectTypeNameParts = {
  parentObjectTypeName: string;
  fieldName: string;
};

export function getFieldArgumentsObjectTypeName(
  parts: FieldArgumentsObjectTypeNameParts
): string {
  return `${parts.parentObjectTypeName}_${parts.fieldName}_${argsSuffix}`;
}

export function getFieldArgumentsObjectTypeNameParts(
  fieldArgumentsObjectTypeName: string
): FieldArgumentsObjectTypeNameParts | null {
  const parts = fieldArgumentsObjectTypeName.split("_");
  if (parts.length < 3 || parts[parts.length - 1] !== argsSuffix) {
    return null;
  }
  return {
    parentObjectTypeName: parts[0],
    fieldName: parts.slice(1, parts.length - 1).join("_"),
  };
}

export function isFieldArgumentsObjectVariableExpression(
  expression: Expression | null
): boolean {
  return (
    !!expression &&
    expression.type === "VariableExpression" &&
    expression.valueType.type === "ObjectValueType" &&
    !!getFieldArgumentsObjectTypeNameParts(expression.valueType.objectTypeName)
  );
}
