import { Expression } from "@hypertune/sdk/src/shared/types";
import Variable from "../../components/icons/Variable";
import TypeIcon from "../../components/icons/TypeIcon";
import Block from "../../components/icons/Block";

export default function getExpressionOptionIcon(
  expression: Expression
): React.ReactNode {
  return expression.type === "ApplicationExpression" ||
    expression.type === "FunctionExpression" ||
    expression.type === "VariableExpression" ? (
    <Variable />
  ) : expression.type === "GetFieldExpression" ? (
    <TypeIcon type="input" size="small" />
  ) : (
    <Block />
  );
}
