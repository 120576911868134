import { useCallback, useState } from "react";
import { asError } from "@hypertune/sdk/src/shared";
import { useHypertune } from "../../generated/hypertune.react";
import getErrorMessage from "../../lib/query/getErrorMessage";

const weakPasswordErrorCode = "auth/weak-password";
const userNotFoundErrorCode = "auth/user-not-found";
const invalidPasswordErrorCode = "auth/wrong-password";
const emailAlreadyExistsErrorCode = "auth/email-already-in-use";

export default function useFirebaseErrorMessage(): {
  errorMessage: string;
  setError: (caller: string, error: any) => void;
  resetError: () => void;
} {
  const content = useHypertune().content().login();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const setError = useCallback(
    (caller: string, rawError: any) => {
      const error = asError(rawError);

      if ("code" in error && typeof error.code === "string") {
        switch (error.code) {
          case weakPasswordErrorCode:
            setErrorMessage(
              error.message
                .replaceAll("Firebase: ", "")
                .replaceAll(` (${error.code})`, "")
            );
            break;
          case userNotFoundErrorCode:
          case invalidPasswordErrorCode:
            setErrorMessage(
              content.invalidCredentialsError({ fallback: error.code })
            );
            break;
          case emailAlreadyExistsErrorCode:
            setErrorMessage(
              content.emailAlreadyExistsError({ fallback: error.code })
            );
            break;
          default:
            setErrorMessage(content.unexpectedError({ fallback: error.code }));
            console.error(`${caller} unexpected firebase error:`, error);
        }
        return;
      }

      setErrorMessage(content.unexpectedError({ fallback: error.message }));
      console.error(`${caller} unexpected error:`, {
        message: getErrorMessage(error),
        error,
      });
    },
    [content]
  );
  const resetError = useCallback(() => setErrorMessage(""), []);

  return {
    errorMessage,
    setError,
    resetError,
  };
}
