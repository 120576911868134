import {
  ListExpression,
  ListValueType,
  uniqueId,
} from "@hypertune/sdk/src/shared";

export default function getListExpression(
  valueType: ListValueType
): ListExpression {
  const expression: ListExpression = {
    id: uniqueId(),
    type: "ListExpression",
    valueType,
    items: [],
  };
  return expression;
}
