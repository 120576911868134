import { mapExpressionWithResult, Expression } from "@hypertune/sdk/src/shared";

// Expensive
export default function usesVariables(
  expression: Expression | null,
  variableIds: { [variableId: string]: boolean }
): boolean {
  const result = mapExpressionWithResult<boolean>(
    (expr) => {
      if (
        expr &&
        expr.type === "VariableExpression" &&
        variableIds[expr.variableId]
      ) {
        return {
          newExpression: expr,
          mapResult: true,
        };
      }
      return {
        newExpression: expr,
        mapResult: false,
      };
    },
    (...results: boolean[]) => results.some((b) => b),
    expression
  );
  return result.mapResult;
}
