import { Expression } from "@hypertune/sdk/src/shared";

export default function getListExpressionItemsAndSetItemsFunction(
  expression: Expression | null
): {
  items: (Expression | null)[] | null;
  setItems: (items: (Expression | null)[]) => Expression | null;
} {
  switch (expression?.type) {
    case "ListExpression": {
      return {
        items: expression.items,
        setItems: (items) => {
          return {
            ...expression,
            items,
          };
        },
      };
    }
    case "FunctionExpression": {
      const expressionItems = getListExpressionItemsAndSetItemsFunction(
        expression.body
      );
      return {
        items: expressionItems.items,
        setItems: (newItems) => {
          return {
            ...expression,
            body: expressionItems.setItems(newItems),
          };
        },
      };
    }
    case "ApplicationExpression": {
      const expressionItems = getListExpressionItemsAndSetItemsFunction(
        expression.function
      );
      return {
        items: expressionItems.items,
        setItems: (newItems) => {
          return {
            ...expression,
            function: expressionItems.setItems(newItems),
          };
        },
      };
    }
    default: {
      return {
        items: null,
        setItems: () => expression,
      };
    }
  }
}
