import { greyHex } from "../../lib/constants";

export default function Separator({
  style,
}: {
  style?: React.CSSProperties;
}): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="18"
      viewBox="0 0 8 18"
      fill="none"
      style={style}
    >
      <path
        d="M7 1L1 17"
        stroke={greyHex}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
