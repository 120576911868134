export default function NameError<T extends { name: string }>({
  noun,
  idMap,
  currentName,
  newName,
}: {
  noun: string;
  idMap: { [id: string]: T };
  currentName?: string;
  newName: string;
}): string | null {
  if (currentName && currentName === newName) {
    return null;
  }
  return Object.values(idMap).some((tObject) => tObject.name === newName)
    ? `${noun} with this name already exists`
    : null;
}
