import { ComparisonOperator } from "@hypertune/sdk/src/shared";

export default function getOperatorLabel(operator: ComparisonOperator): string {
  switch (operator) {
    case "==":
      return "equals";

    case "!=":
      return "does not equal";

    case "<":
      return "is less than";

    case "<=":
      return "is less than or equal to";

    case ">":
      return "is greater than";

    case ">=":
      return "is greater than or equal to";

    case "AND":
      return "AND";

    case "OR":
      return "OR";

    case "in":
      return "is one of";

    case "notIn":
      return "is not one of";

    case "startsWith":
      return "starts with";

    case "notStartsWith":
      return "does not start with";

    case "endsWith":
      return "ends with";

    case "notEndsWith":
      return "does not end with";

    case "contains":
      return "contains";

    case "notContains":
      return "does not contain";

    case "matches":
      return "matches regex";

    case "notMatches":
      return "does not match regex";

    default: {
      const neverOperator: never = operator;
      throw new Error(`Unexpected operator: ${neverOperator}`);
    }
  }
}
