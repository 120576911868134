import Button from "../../../components/buttons/Button";
import { draftCommitId } from "../../../lib/constants";

export default function GoToDraftButton({
  setSelectedCommitId,
}: {
  setSelectedCommitId: (newCommitId: string) => void;
}): React.ReactElement | null {
  return (
    <Button
      intent="neutral"
      weight="elevated"
      size="large"
      onClick={() => setSelectedCommitId(draftCommitId)}
      text="Go to draft"
    />
  );
}
