import parser from "ua-parser-js";

/* Hypertune Schema

input UserAgent {
  browser: Browser!
  device: Device!
  engine: Engine!
  os: OS!
  cpu: CPU!
}

input Browser {
  name: String! # e.g. Chrome, Edge, Firefox, Safari
  version: String! # dynamic
}

input Device {
  type: String! # e.g. mobile, wearable, tablet
  vendor: String! # e.g. Apple, Google, HP, HTC, Huawei, Microsoft, Motorola, Nokia, Samsung
  model: String! # dynamic
}

input Engine {
  name: String! # e.g. Amaya, EdgeHTML, Gecko
  version: String! # dynamic
}

input OS {
  name: String! # e.g. Android, BlackBerry, Chromium OS, iOS, Mac OS, Windows
  version: String! # dynamic
}

input CPU {
  architecture: String! # e.g. 68k, amd64, arm, arm64
}

*/

type UserAgent = {
  browser: Browser;
  device: Device;
  engine: Engine;
  os: OS;
  cpu: CPU;
};

type Browser = {
  name: string; // E.g. Chrome, Safari
  version: string;
};

type Device = {
  type: string; // E.g. mobile, wearable, tablet
  vendor: string; // E.g. Apple, Google, Samsung
  model: string;
};

type Engine = {
  name: string; // E.g. Amaya, Blink
  version: string;
};

type OS = {
  name: string; // E.g. iOS, BlackBerry, Android
  version: string;
};

type CPU = {
  architecture: string; // E.g. 68k, amd64
};

export default function getUserAgent(userAgentString: string): UserAgent {
  const userAgent = parser(userAgentString || "none");

  return {
    browser: {
      name: userAgent.browser.name || "",
      version: userAgent.browser.version || "",
    },
    device: {
      type: userAgent.device.type || "",
      vendor: userAgent.device.vendor || "",
      model: userAgent.device.model || "",
    },
    engine: {
      name: userAgent.engine.name || "",
      version: userAgent.engine.version || "",
    },
    os: {
      name: userAgent.os.name || "",
      version: userAgent.os.version || "",
    },
    cpu: {
      architecture: userAgent.cpu.architecture || "",
    },
  };
}
