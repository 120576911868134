import { Expression, ListExpression } from "@hypertune/sdk/src/shared";

export default function isEmbeddedListExpression(
  parentExpression: Expression | null,
  expression: ListExpression
): boolean {
  return (
    !!parentExpression &&
    parentExpression.type === "StringConcatExpression" &&
    parentExpression.strings === expression
  );
}
