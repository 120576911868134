import { DiscreteDimension, Split, SplitMap } from "@hypertune/sdk/src/shared";

// eslint-disable-next-line import/prefer-default-export
export function deleteSplitDiscreteDimensionArm(
  splitMap: SplitMap,
  split: Split,
  dimension: DiscreteDimension,
  armId: string
): SplitMap {
  const { [armId]: deletedArm, ...newArms } = dimension.arms;

  return {
    ...splitMap,
    [split.id]: {
      ...split,
      dimensions: {
        ...split.dimensions,
        [dimension.id]: {
          ...dimension,
          arms: Object.fromEntries(
            Object.entries(newArms).map(([, arm]) =>
              arm.index < deletedArm.index
                ? [arm.id, arm]
                : [
                    arm.id,
                    {
                      ...arm,
                      index: arm.index - 1,
                    },
                  ]
            )
          ),
        },
      },
    },
  };
}
