import React from "react";
import { useLocation } from "react-router-dom";
import {
  CreditCard,
  Desktop,
  GearSix,
  PaperPlaneTilt,
  User,
} from "@phosphor-icons/react";
import TopBar from "../../components/TopBar";
import Sidebar from "../../components/Sidebar";

import { iconColor, iconWeight } from "../../components/icons/icons";
import { useHasVercelConnectionError } from "./vercel/hooks";
import CenteredContainer from "../../components/container/CenteredContainer";
import { useBusinessesQuery } from "../../generated/graphql";
import { canEditBusiness } from "../../lib/query/rolePermissions";

export default function BusinessPage({
  compact,
  children,
  containerChildrenClassName,
}: {
  compact?: boolean;
  containerChildrenClassName?: string;
  children: React.ReactNode;
}): React.ReactElement {
  const location = useLocation();
  const { data } = useBusinessesQuery();
  const canEdit = canEditBusiness(data?.primaryBusiness?.role);
  const isProjectSelected = location.pathname === "/";
  const isTeamSelected = location.pathname === "/team";
  const isTeamsSelected = location.pathname === "/teams";
  const isSettingsSelected = location.pathname.startsWith("/settings");
  const isPlansSelected = location.pathname === "/plans";
  const isBillingSelected = location.pathname === "/billing";

  const hasVercelError = useHasVercelConnectionError();

  return (
    <div className="flex h-screen w-full flex-col">
      <TopBar showBusinessSelector={!compact} disableLogoLink={!!compact} />
      <div className="flex h-full flex-row overflow-hidden text-base">
        {compact ? null : (
          <Sidebar
            sidebarId="business"
            sections={[
              {
                items: [
                  {
                    title: "Projects",
                    icon: (
                      <Desktop
                        color={iconColor(isProjectSelected)}
                        weight={iconWeight(isProjectSelected)}
                      />
                    ),
                    isSelected: isProjectSelected,
                    link: { href: "/" },
                  },
                  ...(data?.primaryBusiness?.business.type === "Personal"
                    ? [
                        {
                          title: "Your teams",
                          icon: (
                            <User
                              color={iconColor(isTeamsSelected)}
                              weight={iconWeight(isTeamsSelected)}
                            />
                          ),
                          isSelected: isTeamsSelected,
                          link: { href: "/teams" },
                        },
                      ]
                    : [
                        {
                          title: "Team",
                          icon: (
                            <User
                              color={iconColor(isTeamSelected)}
                              weight={iconWeight(isTeamSelected)}
                            />
                          ),
                          isSelected: isTeamSelected,
                          link: { href: "/team" },
                        },
                      ]),
                  {
                    title:
                      data?.primaryBusiness?.business.type === "Personal"
                        ? "Account settings"
                        : "Settings",
                    icon: (
                      <GearSix
                        color={iconColor(isSettingsSelected)}
                        weight={iconWeight(isSettingsSelected)}
                      />
                    ),
                    isSelected: isSettingsSelected,
                    hasError: hasVercelError,
                    link: { href: "/settings" },
                  },
                  ...(data
                    ? [
                        {
                          title: "Plans",
                          icon: (
                            <PaperPlaneTilt
                              color={iconColor(isPlansSelected)}
                              weight={iconWeight(isPlansSelected)}
                            />
                          ),
                          isSelected: isPlansSelected,
                          link: { href: "/plans" },
                        },
                      ]
                    : []),
                  ...(canEdit &&
                  data &&
                  data.primaryBusiness?.business.billingDataJson &&
                  JSON.parse(data.primaryBusiness.business.billingDataJson)
                    .paymentMethod
                    ? [
                        {
                          title: "Billing",
                          icon: (
                            <CreditCard
                              color={iconColor(isBillingSelected)}
                              weight={iconWeight(isBillingSelected)}
                            />
                          ),
                          isSelected: isBillingSelected,
                          link: { href: "/billing" },
                        },
                      ]
                    : []),
                ],
              },
            ]}
          />
        )}
        <CenteredContainer childrenClassName={containerChildrenClassName}>
          {children}
        </CenteredContainer>
      </div>
    </div>
  );
}
