import { intentSuccessHex, whiteHex } from "../../lib/constants";

export default function Event({
  size = 16,
}: {
  size?: number;
}): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="6" fill={intentSuccessHex} />
      <path
        d="M7.44284 12.2142L6.0436 5.21802C6.02669 5.13346 6.11757 5.06858 6.19205 5.11203L11.8091 8.38864C11.8834 8.43198 11.8719 8.54269 11.7903 8.56989L9.0393 9.4869C9.01414 9.49529 8.99334 9.51333 8.98148 9.53705L7.63034 12.2393C7.58776 12.3245 7.46151 12.3076 7.44284 12.2142Z"
        fill={whiteHex}
        stroke={whiteHex}
      />
    </svg>
  );
}
