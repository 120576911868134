import {
  ApplicationExpression,
  FunctionExpression,
} from "@hypertune/sdk/src/shared";

export default function getApplicationFunctionExpression(
  expression: ApplicationExpression
): FunctionExpression | null {
  const functionExpression = expression.function;
  if (
    !functionExpression ||
    functionExpression.type !== "FunctionExpression" ||
    expression.arguments.length !==
      functionExpression.valueType.parameterValueTypes.length ||
    expression.arguments.length !== functionExpression.parameters.length
  ) {
    return null;
  }
  return functionExpression;
}
