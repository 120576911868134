import React from "react";

export default function BorderedIcon({
  className,
  size = 26,
  icon,
  isSelected,
}: {
  className?: string;
  size?: number;
  icon: React.ReactNode;
  isSelected?: boolean;
}): React.ReactElement | null {
  return (
    <div
      className={`flex items-center justify-center rounded-md border bg-white leading-normal 
                  ${isSelected ? "border-intent-primary" : "border-bd-darker"}
                  ${className || ""}`}
      style={{
        height: size,
        width: size,
      }}
    >
      {icon}
    </div>
  );
}
