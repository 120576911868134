import { Check, X } from "@phosphor-icons/react";
import { intentDangerHex, intentPrimaryHex } from "../lib/constants";

export default function StructuredErrorMessage({
  checks,
}: {
  checks: { ok: boolean; message: string }[];
}): React.ReactElement | null {
  return (
    <div className="flex flex-col gap-2">
      {checks.map((check) => (
        <div className="flex flex-row items-center gap-2">
          {check.ok ? (
            <Check
              color={intentPrimaryHex}
              weight="bold"
              size={12}
              className="flex-shrink-0"
            />
          ) : (
            <X
              color={intentDangerHex}
              weight="bold"
              size={12}
              className="flex-shrink-0"
            />
          )}
          <p
            className={`leading-[16px] ${
              check.ok ? "text-intent-primary" : "text-intent-danger"
            }`}
          >
            {check.message}
          </p>
        </div>
      ))}
    </div>
  );
}
