import { normal } from "../../../lib/constants";

export default function NoOpExpressionControl({
  optionsButton,
}: {
  optionsButton: React.ReactNode;
}): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div>No Op</div>
      {optionsButton ? (
        <>
          <div style={{ flexGrow: 1, minWidth: normal }} />
          {optionsButton}
        </>
      ) : null}
    </div>
  );
}
