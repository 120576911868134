/* eslint-disable max-params */
import {
  Query,
  Selection,
  ObjectValueWithVariables,
  ValueWithVariables,
  ValueType,
  VariableDefinitions,
  FieldQuery,
  InlineFragment,
  FragmentDefinitions,
} from "@hypertune/sdk/src/shared/types";
import {
  isPartialObjectKey,
  isQueryVariableKey,
} from "@hypertune/sdk/src/shared/constants";
import { getInlineFragment } from "@hypertune/sdk/src/shared";

export type GetQueryCodeConfig = {
  includeComments: boolean;
  includeArguments: boolean;
  inlineSharedFragments: boolean;
};

export default function getQueryCodeFromQuery({
  query,
  ...config
}: {
  query: Query<ObjectValueWithVariables> | null;
} & GetQueryCodeConfig): string {
  if (
    query === null ||
    query.fieldQuery === null ||
    Object.keys(query.fieldQuery).length === 0
  ) {
    return "query Full{root{*}}";
  }

  return `${
    config.includeComments
      ? `# To set up the SDK, follow the quickstart:
# https://docs.hypertune.com/quickstart

`
      : ""
  }query ${query.name ?? "FullQuery"}${
    Object.keys(query.variableDefinitions).length === 0
      ? ""
      : `(${Object.entries(query.variableDefinitions)
          .map(
            ([variableName, definition]) =>
              // TODO: default values
              `$${variableName}: ${getValueTypeCode(definition.valueType)}`
          )
          .join(", ")})`
  }${getQueryCodeFromFieldQueryWithPadding(query.fragmentDefinitions, query.fieldQuery, query.variableDefinitions, config, 2)}
${
  !config.inlineSharedFragments
    ? `\n${Object.entries(query.fragmentDefinitions)
        .map(
          ([objectTypeName, fragment]) =>
            `fragment ${objectTypeName} on ${objectTypeName} {\n${getQueryCodeFromSelection(query.fragmentDefinitions, fragment.selection, query.variableDefinitions, config, 2)}}\n`
        )
        .join("\n")}`
    : ""
}`;
}

function getQueryCodeFromFieldQueryWithPadding(
  fragmentDefinitions: FragmentDefinitions<ObjectValueWithVariables>,
  query: FieldQuery<ObjectValueWithVariables> | null,
  variableDefinitions: VariableDefinitions,
  config: GetQueryCodeConfig,
  padOffset: number
): string {
  if (!query || Object.keys(query).length === 0) {
    return "";
  }
  const fragmentValues = Object.entries(query);

  if (fragmentValues.length > 1) {
    // We only have more than one fragment for union types.
    return ` {\n${fragmentValues
      .map(([objectTypeName, fragment]) => {
        return `${pad(padOffset)}... on ${objectTypeName} {\n${
          !config.inlineSharedFragments && fragment.type === "FragmentSpread"
            ? `${pad(padOffset + 2)}...${fragment.fragmentName}\n`
            : getQueryCodeFromSelection(
                fragmentDefinitions,
                getInlineFragment(fragmentDefinitions, fragment).selection,
                variableDefinitions,
                config,
                padOffset + 2
              )
        }${pad(padOffset)}}`;
      })
      .join("\n")}\n${pad(padOffset - 2)}}`;
  }
  const [, fragment] = fragmentValues[0];
  return ` {
${
  !config.inlineSharedFragments && fragment.type === "FragmentSpread"
    ? `${pad(padOffset)}...${fragment.fragmentName}\n`
    : getQueryCodeFromSelection(
        fragmentDefinitions,
        getInlineFragment(fragmentDefinitions, fragment).selection,
        variableDefinitions,
        config,
        padOffset
      )
}${pad(padOffset - 2)}}`;
}

function getQueryCodeFromSelection(
  fragmentDefinitions: {
    [fragmentName: string]: InlineFragment<ObjectValueWithVariables>;
  },
  selection: Selection<ObjectValueWithVariables>,
  variableDefinitions: VariableDefinitions,
  config: GetQueryCodeConfig,
  padOffset: number
): string {
  return `${Object.entries(selection)
    .map(([fieldName, field]) => {
      return `${pad(padOffset)}${fieldName}${
        config.includeArguments
          ? getQueryCodeArgs(field.fieldArguments)
          : config.includeComments
            ? getQueryCodeArgsPrefix(field.fieldArguments, padOffset)
            : ""
      }${getQueryCodeFromFieldQueryWithPadding(
        fragmentDefinitions,
        field.fieldQuery,
        variableDefinitions,
        config,
        padOffset + 2
      )}`;
    })
    .join("\n")}\n`;
}

function getQueryCodeArgsPrefix(
  fieldArguments: ObjectValueWithVariables | null,
  padOffset: number
): string {
  const args = getQueryCodeArgs(fieldArguments);
  if (!args) {
    return "";
  }

  return `
${pad(padOffset)}# Try uncommenting the line below and passing different args
${pad(padOffset)}# ${args}
${pad(padOffset - 1)}`;
}

function getQueryCodeArgs(
  fieldArguments: ObjectValueWithVariables | null
): string {
  if (
    !fieldArguments ||
    Object.keys(fieldArguments).filter((key) => key !== isPartialObjectKey)
      .length === 0
  ) {
    return "";
  }
  return `(${Object.entries(fieldArguments)
    .filter(([key]) => key !== isPartialObjectKey)
    .map(([objectFieldName, value]) => {
      return `${objectFieldName}: ${stringifyQuery(value, " ")}`;
    })
    .join(", ")})`;
}

function pad(n: number): string {
  return " ".repeat(n);
}

function stringifyQuery(value: ValueWithVariables, padding: string): string {
  if (Array.isArray(value)) {
    return `[${padding}${value
      .map((arrayValue) => stringifyQuery(arrayValue, padding))
      .join(`,${padding}`)}${padding}]`;
  }
  if (value instanceof Object) {
    if (
      isQueryVariableKey in value &&
      value[isQueryVariableKey] &&
      "name" in value &&
      typeof value.name === "string"
    ) {
      return `$${value.name}`;
    }
    return `{${padding}${Object.entries(value)
      .map(([fieldName, objectValue]) => {
        return `${fieldName}:${padding}${stringifyQuery(objectValue, padding)}`;
      })
      .join(`,${padding}`)}${padding}}`;
  }
  // Value is a basic type so we can just use JSON conversion.
  return JSON.stringify(value);
}

function getValueTypeCode(valueType: ValueType): string {
  switch (valueType.type) {
    case "BooleanValueType":
      return "Boolean!";
    case "StringValueType":
      return "String!";
    case "IntValueType":
      return "Int!";
    case "FloatValueType":
      return "Float!";
    case "EnumValueType":
      return `${valueType.enumTypeName}!`;
    case "ObjectValueType":
      return `${valueType.objectTypeName}!`;
    case "ListValueType":
      return `[${getValueTypeCode(valueType.itemValueType)}]!`;
    default:
      throw new Error(`unexpected value type: ${valueType}`);
  }
}
