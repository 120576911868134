import { SealCheck, SealWarning } from "@phosphor-icons/react";
import Button from "../../../../components/buttons/Button";
import { intentSuccessHex } from "../../../../lib/constants";

const defaultDeprecationReason = "No longer supported.";

export default function StatusChangeButton({
  deprecationReason,
  setDeprecationReason,
}: {
  deprecationReason: string | undefined;
  setDeprecationReason: (reason: string | undefined) => void;
}): React.ReactElement | null {
  if (deprecationReason === undefined) {
    return (
      <Button
        icon={<SealWarning weight="regular" />}
        text="Deprecate"
        intent="neutral"
        weight="elevated"
        onClick={() => setDeprecationReason(defaultDeprecationReason)}
      />
    );
  }

  return (
    <Button
      icon={<SealCheck weight="regular" color={intentSuccessHex} />}
      text="Reactivate"
      intent="success"
      weight="elevated"
      onClick={() => setDeprecationReason(undefined)}
    />
  );
}
