import { CheckCircle } from "@phosphor-icons/react";
import { intentSuccessHex } from "../../lib/constants";

export default function SuccessCircle({
  size,
  style,
}: {
  size?: number;
  style?: React.CSSProperties;
}): React.ReactElement {
  return (
    <CheckCircle
      size={size || 14.5}
      color={intentSuccessHex}
      weight="fill"
      style={style}
    />
  );
}
