import { NavigateOptions, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import { useProjectSelectedState } from "./projectHooks";

export const urlPathSeparator = ">";
export const selectedFieldPathParamKey = "selected_field_path";

export function useLogicSelectedFieldPath(
  defaultFieldPath?: string
): string | null {
  const [searchParams] = useSearchParams();
  const rawSelectedField = searchParams.get(selectedFieldPathParamKey);
  return rawSelectedField
    ? rawSelectedField.replaceAll(urlPathSeparator, fieldPathSeparator)
    : (defaultFieldPath ?? null);
}

export function useLogicSetSelectedFieldPath({
  setLogicView,
}: {
  setLogicView?: boolean;
} = {}): (selectedFieldPath: string, options?: NavigateOptions) => void {
  const { setSelected } = useProjectSelectedState();

  const setSelectedField = useCallback(
    (selectedFieldPath: string, options?: NavigateOptions) => {
      setSelected(
        {
          ...(setLogicView ? { view: "logic" } : {}),
          searchParams: new URLSearchParams({
            [selectedFieldPathParamKey]: selectedFieldPath.replaceAll(
              fieldPathSeparator,
              urlPathSeparator
            ),
          }),
        },
        options
      );
    },
    [setLogicView, setSelected]
  );

  return setSelectedField;
}
