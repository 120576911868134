import { useCallback } from "react";
import { ModalContent } from "../generated/hypertune";
import Modal, { ModalButtonLayout } from "./Modal";
import { Intent } from "./intent";
import { Weight } from "./buttons/Button";

export default function ModalWithContent({
  content,
  children,
  closeWeight,
  variables,
  onClose,
  closeLoading,
  onSave,
  saveLoading,
  saveWeight,
  disableAwayClickClose,
  buttonLayout = "stretch",
}: {
  content: ModalContent;
  children?: React.ReactNode;
  closeWeight?: Weight;
  variables?: { [variableName: string]: string };
  onClose?: (options?: { isAction?: boolean }) => void;
  closeLoading?: boolean;
  onSave?: () => void;
  saveLoading?: boolean;
  saveWeight?: Weight;
  disableAwayClickClose?: boolean;
  buttonLayout?: ModalButtonLayout;
}): React.ReactElement | null {
  const formatText = useCallback(
    (text: string): string => {
      if (!variables) {
        return text;
      }
      let result = text;
      Object.entries(variables).forEach(([name, value]) => {
        result = result.replaceAll(`{${name}}`, value);
      });
      return result;
    },
    [variables]
  );

  return (
    <div className="font-inter text-base">
      <Modal
        title={formatText(content.title)}
        closeText={content.closeText}
        closeWeight={closeWeight}
        saveText={formatText(content.buttonText)}
        saveIntent={content.intent.toLowerCase() as Intent}
        saveWeight={saveWeight}
        onClose={onClose}
        closeLoading={closeLoading}
        onSave={onSave}
        saveLoading={saveLoading}
        disableAwayClickClose={disableAwayClickClose}
        buttonLayout={buttonLayout}
      >
        <p
          className={`w-[374px] whitespace-pre-wrap text-tx-default ${
            !onSave || children ? "mb-4" : ""
          }`}
        >
          {formatText(content.message)}
        </p>
        {children}
      </Modal>
    </div>
  );
}
