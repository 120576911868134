import {
  ApplicationExpression,
  Expression,
  RoundNumberExpression,
  StringifyNumberExpression,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import createApplication from "../../../lib/expression/createApplication";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import isReadOnly from "../../../lib/expression/isReadOnly";
import { liftPermissionsDeniedErrorMessage } from "../../../lib/constants";

export default function WrapperExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: RoundNumberExpression | StringifyNumberExpression;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  return (
    <ExpressionControl
      context={context}
      variables={variables}
      setVariableName={setVariableName}
      valueTypeConstraint={{ type: "FloatValueTypeConstraint" }}
      expression={expression.number}
      setExpression={(newExpression: Expression | null): void =>
        setExpression({
          ...expression,
          number: newExpression,
        })
      }
      lift={(child): void => {
        if (isReadOnly(context)) {
          // eslint-disable-next-line no-alert
          alert(liftPermissionsDeniedErrorMessage);
          return;
        }
        function replaceArgument(
          variable: VariableExpression | ApplicationExpression
        ): RoundNumberExpression | StringifyNumberExpression {
          const newExpression:
            | RoundNumberExpression
            | StringifyNumberExpression = {
            ...expression,
            number: child.replaceArgument(variable),
          };
          return newExpression;
        }
        const applicationExpression = createApplication({
          variables,
          rawArgument: child.argument,
          replacedVariableIdToNewVariable:
            child.replacedVariableIdToNewVariable,
          valueType: expression.valueType,
          replaceArgument,
          newVariableName: child.newVariableName,
          setExpressionEditorSelectedItem: (newSelectedItem) =>
            context.setExpressionEditorState({
              ...context.expressionEditorState,
              selectedItem: newSelectedItem,
            }),
        });
        setExpression(applicationExpression);
      }}
      parentExpression={expression}
      setParentExpression={setExpression}
      includeExpressionOption={includeExpressionOption}
    />
  );
}
