import { MagnifyingGlass } from "@phosphor-icons/react";
import { useDebouncedCallback } from "use-debounce";
import { useEffect } from "react";
import { lighterGreyHex } from "../lib/constants";
import Input from "./input/Input";

export default function SearchInput({
  searchText,
  setSearchText,
  style,
  focusOnMount,
  noFocusHighlight,
  action,
  placeholder,
  disableDebounce,
}: {
  searchText: string;
  setSearchText?: (text: string) => void;
  style?: React.CSSProperties;
  focusOnMount?: boolean;
  noFocusHighlight?: boolean;
  action?: React.ReactNode;
  placeholder?: string;
  disableDebounce?: boolean;
}): React.ReactElement {
  const debouncedSetSearchText = useDebouncedCallback((value) => {
    setSearchText?.(value);
  }, /* delay */ 150);

  useEffect(
    () => () => {
      if (!disableDebounce) {
        debouncedSetSearchText.flush();
      }
    },
    [disableDebounce, debouncedSetSearchText]
  );

  return (
    <Input
      type="text"
      focusOnMount={focusOnMount}
      size="medium"
      icon={
        <MagnifyingGlass
          color={lighterGreyHex}
          weight="regular"
          style={{ marginRight: 8 }}
        />
      }
      endIcon={action}
      style={{
        padding: 8,
        height: 32,
        color: lighterGreyHex,
        ...(style || {}),
      }}
      noFocusHighlight={noFocusHighlight}
      trimOnBlur={false}
      placeholder={placeholder || "Search..."}
      readOnly={setSearchText === undefined}
      onChange={
        disableDebounce
          ? setSearchText ||
            ((newText: string) => {
              // noop
            })
          : debouncedSetSearchText
      }
      value={disableDebounce ? searchText : undefined}
      defaultValue={!disableDebounce ? searchText : undefined}
      onBlur={
        !disableDebounce ? () => debouncedSetSearchText.flush() : undefined
      }
    />
  );
}
