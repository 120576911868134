import {
  BusinessManagementDocument,
  BusinessesDocument,
  BusinessesManagementDocument,
  MeDocument,
  ProjectAnalyticsViewsDocument,
  ProjectBranchDocument,
  ProjectDocument,
  PullRequestDocument,
  VercelEdgeConfigStoresDocument,
} from "../../generated/graphql";

export const businessManagementRefetchQueries = [
  BusinessesDocument,
  BusinessManagementDocument,
  BusinessesManagementDocument,
];

export const switchBusinessRefetchQueries = [
  ...businessManagementRefetchQueries,
  VercelEdgeConfigStoresDocument,
  MeDocument,
];

export const newCommitRefetchQueries = [
  ProjectDocument,
  ProjectBranchDocument,
  PullRequestDocument,
];

export const refetchAnalyticsViewsQueries = [
  ProjectDocument,
  ProjectAnalyticsViewsDocument,
];
