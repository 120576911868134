import { useParams } from "react-router-dom";
import TopBarDropdown, {
  LabeledOption,
} from "../../../components/TopBarDropdown";
import { dotSymbol, draftCommitId } from "../../../lib/constants";
import { ProjectBranchQuery } from "../../../generated/graphql";
import { formatJsonTime } from "../../../lib/generic/formatDate";

export default function CommitSelector({
  muted,
  branch,
  setSelectedCommitId,
}: {
  muted: boolean;
  branch?: ProjectBranchQuery["projectBranch"];
  setSelectedCommitId: (newCommitId: string) => void;
}): React.ReactElement | null {
  const { selectedCommitId } = useParams();

  const commitOptions: LabeledOption<string>[] = [
    {
      value: draftCommitId,
      label: "Draft",
    },
    ...[...(branch?.commits || [])]
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((commit) => toDropdownOption(branch?.activeCommit.id, commit)),
  ];
  const value = !selectedCommitId
    ? null
    : commitOptions.find((option) => selectedCommitId === option.value) || null;

  return (
    <TopBarDropdown<string>
      options={{
        type: "options",
        options: commitOptions,
      }}
      value={value}
      isLoading={!branch}
      onChange={(option) => {
        if (option) {
          setSelectedCommitId(option.value);
        }
      }}
      placeholder="Select commit..."
      dropdownStyle={{
        muted: muted ? "button" : "none",
        sectionMaxHeight: 324,
      }}
    />
  );
}

function toDropdownOption(
  activeCommitId: string | undefined,
  commit: ProjectBranchQuery["projectBranch"]["commits"][number]
): LabeledOption<string> {
  const isActive = commit.id === activeCommitId;
  const subtitle = `${
    isActive ? `Active ${dotSymbol} ` : ""
  }${formatJsonTime(commit.createdAt)} ${dotSymbol} ${commit.author.displayName}`;

  return { value: commit.id, label: commit.message, subtitle };
}
