import { Expression, mapExpression } from "@hypertune/sdk/src/shared";

// eslint-disable-next-line import/prefer-default-export
export function removeSplitArmFromExpression(
  expression: Expression | null,
  splitId: string,
  dimensionId: string,
  armIdToDelete: string
): Expression {
  return mapExpression((expr) => {
    if (
      expr &&
      expr.type === "SplitExpression" &&
      expr.splitId === splitId &&
      expr.dimensionId === dimensionId
    ) {
      return {
        ...expr,
        dimensionMapping:
          expr.dimensionMapping.type === "discrete"
            ? {
                ...expr.dimensionMapping,
                cases: Object.fromEntries(
                  Object.entries(expr.dimensionMapping.cases).filter(
                    ([armId]) => armId !== armIdToDelete
                  )
                ),
              }
            : expr.dimensionMapping,
      };
    }
    return expr;
  }, expression) as Expression;
}
