import { Check, ClipboardText } from "@phosphor-icons/react";
import { useState } from "react";
import Button, { Size, Weight } from "./Button";
import { lighterGreyHex } from "../../lib/constants";

export default function CopyButton({
  text,
  size = "small",
  weight = "outlined",
}: {
  text: string;
  size?: Size;
  weight?: Weight;
}): React.ReactElement | null {
  const [copied, setCopied] = useState(false);
  return (
    <div className="rounded-lg bg-white">
      <Button
        weight={weight}
        size={size}
        icon={
          copied ? (
            <Check
              weight="bold"
              color={lighterGreyHex}
              size={size === "small" ? 16 : 12}
            />
          ) : (
            <ClipboardText
              weight="regular"
              color={lighterGreyHex}
              size={size === "small" ? 16 : 12}
            />
          )
        }
        onClick={() => {
          navigator.clipboard
            .writeText(text)
            .catch((error) =>
              console.error("failed to copy text to clipboard", error)
            );
          setTimeout(() => setCopied(false), 1500);
          setCopied(true);
        }}
        className="shadow-button"
      />
    </div>
  );
}
