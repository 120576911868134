import {
  blueHexBoxShadow,
  intentDangerFilter,
  intentDangerHex,
  intentDangerHexBg,
  intentNeutralFilter,
  intentNeutralHex,
  intentNeutralHexBg,
  intentPrimaryFilter,
  intentPrimaryHex,
  intentPrimaryHexBg,
  intentSuccessFilter,
  intentSuccessHex,
  intentSuccessHexBg,
  intentWarningFilter,
  intentWarningHex,
  intentWarningHexBg,
} from "../lib/constants";

export type Intent = "primary" | "neutral" | "warning" | "danger" | "success";

export function intentToActiveClassName(intent: Intent): string {
  switch (intent) {
    case "primary":
      return `bg-intent-primary/10 ${intentToTextColorName(intent)}`;
    case "neutral":
      return `bg-bg-hover/50 ${intentToTextColorName(intent)}`;
    case "warning":
      return `bg-intent-warning/10 ${intentToTextColorName(intent)}`;
    case "danger":
      return `bg-intent-danger/10 ${intentToTextColorName(intent)}`;
    case "success":
      return `bg-intent-success/10 ${intentToTextColorName(intent)}`;
    default:
      return `bg-white ${intentToTextColorName(intent)}`;
  }
}

export function intentToLightBgClassName(intent: Intent): string {
  switch (intent) {
    case "primary":
      return "bg-intent-primary-05";
    case "warning":
      return "bg-intent-warning-10";
    case "danger":
      return "bg-intent-danger-05";
    case "success":
      return "bg-intent-success-05";
    default:
      return "bg-white";
  }
}

export function intentToHoverLightBgClassName(intent: Intent): string {
  switch (intent) {
    case "primary":
      return "hover:bg-intent-primary-05";
    case "warning":
      return "hover:bg-intent-warning-10";
    case "danger":
      return "hover:bg-intent-danger-05";
    case "success":
      return "hover:bg-intent-success-05";
    default:
      return "hover:hover:bg-bg-pressed";
  }
}

export function intentToHoverDarkerBgClassName(intent: Intent): string {
  switch (intent) {
    case "primary":
      return "hover:bg-intent-primary/10";
    case "warning":
      return "hover:bg-intent-warning/15";
    case "danger":
      return "hover:bg-intent-danger/10";
    case "success":
      return "hover:bg-intent-success/10";
    default:
      return "hover:hover:bg-bg-hover";
  }
}

export function intentToBgClassName(intent: Intent): string {
  switch (intent) {
    case "primary":
      return "bg-intent-primary";
    case "neutral":
      return "bg-intent-neutral";
    case "warning":
      return "bg-intent-warning";
    case "danger":
      return "bg-intent-danger";
    case "success":
      return "bg-intent-success";
    default:
      return "bg-white";
  }
}

export function intentToBorderClassName(intent: Intent): string {
  switch (intent) {
    case "primary":
      return "border-intent-primary";
    case "warning":
      return "border-intent-warning";
    case "danger":
      return "border-intent-danger";
    case "success":
      return "border-intent-success";
    default:
      return "border-intent-neutral";
  }
}

export function intentToTextColorName(intent: Intent): string {
  switch (intent) {
    case "primary":
      return "text-intent-primary";
    case "neutral":
      return "text-tx-default";
    case "warning":
      return "text-intent-warning";
    case "danger":
      return "text-intent-danger";
    case "success":
      return "text-intent-success";
    default:
      return "text-tx-default";
  }
}

export function intentToPlaceholderTextColorName(intent: Intent): string {
  switch (intent) {
    case "primary":
      return "placeholder:text-intent-primary/50";
    case "neutral":
      return "placeholder:text-tx-default/50";
    case "warning":
      return "placeholder:text-intent-warning/50";
    case "danger":
      return "placeholder:text-intent-danger/50";
    case "success":
      return "placeholder:text-intent-success/50";
    default:
      return "placeholder:text-tx-default/50";
  }
}

export function intentToHexColor(intent: Intent): string {
  switch (intent) {
    case "primary":
      return intentPrimaryHex;
    case "neutral":
      return intentNeutralHex;
    case "warning":
      return intentWarningHex;
    case "danger":
      return intentDangerHex;
    case "success":
      return intentSuccessHex;
    default:
      return intentNeutralHex;
  }
}

export function intentToHexBackgroundColor(intent: Intent): string {
  switch (intent) {
    case "primary":
      return intentPrimaryHexBg;
    case "neutral":
      return intentNeutralHexBg;
    case "warning":
      return intentWarningHexBg;
    case "danger":
      return intentDangerHexBg;
    case "success":
      return intentSuccessHexBg;
    default:
      return intentNeutralHexBg;
  }
}

export function intentToShadowStyle(intent: Intent): string {
  switch (intent) {
    case "primary":
      return blueHexBoxShadow;
    case "warning":
      return "0px 0px 0px 2px rgba(238, 149, 76, 0.15)";
    case "danger":
      return "0px 0px 0px 2px rgba(237, 84, 75, 0.15)";
    case "success":
      return "0px 0px 0px 2px rgba(59, 158, 98, 0.15)";
    default:
      return "0px 0px 0px 2px rgba(234, 236, 241, 0.70)";
  }
}

export function intentToHexColorFilter(intent: Intent): string {
  switch (intent) {
    case "primary":
      return intentPrimaryFilter;
    case "neutral":
      return intentNeutralFilter;
    case "warning":
      return intentWarningFilter;
    case "danger":
      return intentDangerFilter;
    case "success":
      return intentSuccessFilter;
    default:
      return intentNeutralFilter;
  }
}
