import React from "react";
import { VercelEdgeConfigStoreWithTokens } from "@hypertune/shared-internal";
import {
  CreateVercelEdgeConfigStoreInput,
  VercelEdgeConfigStoresDocument,
  useCreateVercelEdgeConfigStoreMutation,
} from "../../../generated/graphql";
import { redTextHex, smallFontSize } from "../../../lib/constants";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/input/TextInput";
import getErrorMessage from "../../../lib/query/getErrorMessage";
import Button from "../../../components/buttons/Button";

export default function NewStoreModal({
  businessId,
  onCreate,
  onClose,
}: {
  businessId: string;
  onCreate: (newStore: VercelEdgeConfigStoreWithTokens | null) => void;
  onClose: () => void;
}): React.ReactElement {
  const [draftSlug, setDraftSlug] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [createStore, { error }] = useCreateVercelEdgeConfigStoreMutation({
    refetchQueries: [VercelEdgeConfigStoresDocument],
    awaitRefetchQueries: true,
  });

  const isValid = !!draftSlug && isValidSlug(draftSlug);

  const isDisabled = isLoading || !isValid;

  function onCreateButtonClick(): void {
    if (isDisabled) {
      return;
    }
    setIsLoading(true);
    const input: CreateVercelEdgeConfigStoreInput = {
      businessId,
      slug: draftSlug.trim(),
    };
    createStore({ variables: { input } })
      .then((result) => {
        setIsLoading(false);
        const storeJson = result.data?.createVercelEdgeConfigStore ?? null;
        onCreate(storeJson ? JSON.parse(storeJson) : null);
      })
      .catch((createStoreError) => {
        setIsLoading(false);
        console.error("createStore error:", createStoreError);
      });
  }

  return (
    <Modal
      title="Create New Vercel Edge Config Store"
      onClose={onClose}
      confirmClose={isValid}
    >
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>
        {`Store Name (alphanumeric with "_" and "-")`}
      </div>
      <TextInput
        style={{ marginBottom: 12 }}
        value={draftSlug}
        trimOnBlur
        focusOnMount
        placeholder="Enter a name for this store"
        readOnly={false}
        maxLength={32}
        onChange={(newValue: string) => {
          if (!isValidSlug(newValue)) {
            return;
          }
          setDraftSlug(newValue);
        }}
        onEnter={onCreateButtonClick}
      />
      {error ? (
        <div
          style={{
            marginBottom: 12,
            fontSize: smallFontSize,
            color: redTextHex,
          }}
        >
          {getErrorMessage(error)}
        </div>
      ) : null}
      <Button
        disabled={isDisabled}
        onClick={onCreateButtonClick}
        loading={isLoading}
        text="Create"
        loadingText="Creating..."
        intent="primary"
        weight="minimal"
      />
    </Modal>
  );
}
function isValidSlug(slug: string): boolean {
  const pattern = /^[a-zA-Z0-9_-]+$/;
  return !slug || pattern.test(slug);
}
