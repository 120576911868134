import { Expression, Schema, asError } from "@hypertune/sdk/src/shared";
import { Plus } from "@phosphor-icons/react";
import {
  EnumValuePosition,
  formatEnumValueSchemaName,
  renameEnumValue,
  renameEnumValueInExpression,
} from "@hypertune/shared-internal";
import { useState } from "react";
import MutableText from "../../../../../components/input/MutableText";
import { useAppDispatch } from "../../../../../app/hooks";
import { setDraftCommitSchemaAndExpression } from "../../../projectSlice";
import { lighterGreyHex, whiteHex } from "../../../../../lib/constants";
import Button from "../../../../../components/buttons/Button";
import Label from "../../../../../components/Label";
import EnumAddValueModal from "./EnumAddValueModal";
import SearchInput from "../../../../../components/SearchInput";
import matchesSearch from "../../../../../lib/generic/matchesSearch";
import { SelectedType } from "../../schemaHooks";
import Card from "../../../../../components/Card";
import { enumValueNameError, showSchemaNameError } from "../SchemaNameError";
import { useHypertune } from "../../../../../generated/hypertune.react";

export default function EnumEditor({
  readOnly,
  schema,
  expression,
  enumTypeName,
  selectedType,
  setSelectedType,
  setErrorMessage,
  fieldsAndValuesSearchText,
  setFieldsAndValuesSearchText,
}: {
  readOnly: boolean;
  schema: Schema;
  expression: Expression;
  enumTypeName: string;
  selectedType: SelectedType;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  setErrorMessage: (newErrorMessage: string | null) => void;
  fieldsAndValuesSearchText: string;
  setFieldsAndValuesSearchText: (newSearchText: string) => void;
}): React.ReactElement | null {
  const content = useHypertune().content();
  const dispatch = useAppDispatch();

  const [newValueModal, setNewValueModal] = useState<{
    isVisible: boolean;
    valuePosition?: EnumValuePosition;
  }>({ isVisible: false });

  return (
    <>
      <div className="mb-[6px] flex flex-wrap items-center justify-between gap-2">
        <Label className="whitespace-nowrap" type="title1">
          Possible values
        </Label>
        <div className="flex flex-row gap-2">
          <SearchInput
            searchText={fieldsAndValuesSearchText}
            setSearchText={setFieldsAndValuesSearchText}
            style={{ backgroundColor: whiteHex }}
          />
          {!readOnly && (
            <Button
              disabled={readOnly}
              intent="primary"
              weight="filled"
              size="large"
              icon={<Plus weight="regular" color="white" />}
              text="Add"
              onClick={() =>
                setNewValueModal({ isVisible: true, valuePosition: "first" })
              }
            />
          )}
        </div>
      </div>

      {Object.keys(schema.enums[enumTypeName].values)
        .filter((enumValueName) =>
          matchesSearch(fieldsAndValuesSearchText, [enumValueName])
        )
        .map((enumValueName) => (
          <Card
            layout="none"
            key={enumValueName}
            className="max-w-full px-[24px] py-[21px]"
            isSelected={
              selectedType?.name === enumTypeName &&
              selectedType?.selectedChildName === enumValueName
            }
            onClick={(event) => {
              event.stopPropagation();
              setSelectedType({
                ...selectedType,
                selectedChildName: enumValueName,
              });
            }}
          >
            {/* <DotsSixVertical weight="regular" /> */}
            <MutableText
              readOnly={readOnly}
              text={enumValueName}
              setText={async (newValueName) => {
                try {
                  await dispatch(
                    setDraftCommitSchemaAndExpression({
                      schema: renameEnumValue(
                        schema,
                        enumTypeName,
                        enumValueName,
                        newValueName
                      ),
                      expression: renameEnumValueInExpression(
                        expression,
                        enumTypeName,
                        enumValueName,
                        newValueName
                      ) as Expression,
                    })
                  );
                  setSelectedType({
                    ...selectedType,
                    selectedChildName: formatEnumValueSchemaName(newValueName),
                  });
                } catch (error) {
                  setErrorMessage(asError(error).message);
                }
              }}
              showPencil={false}
              stopClickPropagation={false}
              style={{
                lineHeight: "16px",
                color: lighterGreyHex,
                maxWidth: "100%",
              }}
              minWidth={0}
              className="max-w-full overflow-x-clip text-ellipsis whitespace-nowrap"
              confirmModalContent={content.schema().renameConfirmation().get()}
              confirmModalVariables={{ entityName: "enum value" }}
              hasError={(newName) => {
                const newFormattedName = formatEnumValueSchemaName(newName);
                if (newFormattedName === enumValueName) {
                  return null;
                }
                return enumValueNameError(
                  schema,
                  enumTypeName,
                  newFormattedName
                );
              }}
              showError={showSchemaNameError}
            />
          </Card>
        ))}
      {!readOnly && (
        <div className="flex flex-row">
          <div className="bg-white">
            <Button
              disabled={readOnly}
              intent="neutral"
              weight="outlined"
              size="large"
              icon={<Plus weight="regular" />}
              text="Add"
              onClick={() =>
                setNewValueModal({ isVisible: true, valuePosition: "last" })
              }
            />
          </div>
        </div>
      )}
      {newValueModal.isVisible && (
        <EnumAddValueModal
          schema={schema}
          enumTypeName={enumTypeName}
          valuePosition={newValueModal.valuePosition}
          onClose={() => setNewValueModal({ isVisible: false })}
          setSelectedType={setSelectedType}
        />
      )}
    </>
  );
}
