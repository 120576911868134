import {
  mapExpressionWithResult,
  ApplicationExpression,
  Expression,
  FunctionExpression,
  nullThrows,
  stableStringify,
  getBooleanExpression,
  defaultArmKey,
} from "@hypertune/sdk/src/shared";
import {
  EnumSwitchExpression,
  EventTypeMap,
  ObjectExpression,
  Schema,
  SplitExpression,
  SplitMap,
  SwitchExpression,
} from "@hypertune/sdk/src/shared/types";
import dropArgument from "./dropArgument";
import getApplicationFunctionExpression from "./getApplicationFunctionExpression";
import usesVariables from "./usesVariables";
import getComparisonExpression from "./getComparisonExpression";
import getIfExpression from "./getIfExpression";
import isIfElseExpression from "./isIfElseExpression";
import isEmptyExpression from "./isEmptyExpression";
import getEmptyExpression, { DefaultValues } from "./getEmptyExpression";
import getDefaultExpression from "./getDefaultExpression";
import { getEmptyPermissions, resolvePermissions } from "../permissions";
import copyExpression from "./copyExpression";
import deepClonePlainObject from "../deepClonePlainObject";
import isDefaultArmNeeded from "./isDefaultArmNeeded";
import { ValueTypeConstraint } from "./types";
import isValueTypeValid from "./isValueTypeValid";
import getConstraintFromValueType from "./constraint/getConstraintFromValueType";

// Expensive
// eslint-disable-next-line max-params
export default function fixAndSimplify(
  schema: Schema,
  splitMap: SplitMap,
  eventTypeMap: EventTypeMap,
  defaultValues: DefaultValues | null,
  expression: Expression
): {
  newExpression: Expression;
  hasChanged: boolean;
} {
  let result = fixAndSimplifyOnce(
    schema,
    splitMap,
    eventTypeMap,
    defaultValues,
    expression
  );
  let hasChanged = false;
  while (result.numChanges > 0) {
    hasChanged = true;
    result = fixAndSimplifyOnce(
      schema,
      splitMap,
      eventTypeMap,
      defaultValues,
      result.newExpression
    );
  }
  return {
    newExpression: result.newExpression,
    hasChanged,
  };
}

// Expensive
// eslint-disable-next-line max-params
function fixAndSimplifyOnce(
  schema: Schema,
  splitMap: SplitMap,
  eventTypeMap: EventTypeMap,
  defaultValues: DefaultValues | null,
  expression: Expression
): {
  newExpression: Expression;
  numChanges: number;
} {
  const result = mapExpressionWithResult<number>(
    // Typescript seems to fail here occasionally for some reason.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (expr) => {
      if (!expr) {
        return { newExpression: expr, mapResult: 0 };
      }

      // Fix objects with an objectTypeName different to their
      // valueType.objectTypeName
      if (expr.type === "ObjectExpression") {
        if (expr.objectTypeName !== expr.valueType.objectTypeName) {
          return {
            newExpression: {
              ...expr,
              objectTypeName: expr.valueType.objectTypeName,
            },
            mapResult: 1,
          };
        }
        const schemaObjectFields =
          schema.objects[expr.objectTypeName]?.fields || {};
        const expressionFieldNames = Object.keys(expr.fields);
        const extraFieldNames = expressionFieldNames.filter(
          (fieldName) => !schemaObjectFields[fieldName]
        );
        const autoRemovableFieldNames = extraFieldNames.filter((fieldName) =>
          isEmptyExpression(expr.fields[fieldName])
        );
        const autoAddableFieldNames = Object.keys(schemaObjectFields).filter(
          (fieldName) => !expressionFieldNames.includes(fieldName)
        );
        if (
          autoRemovableFieldNames.length > 0 ||
          autoAddableFieldNames.length > 0
        ) {
          const newFields: ObjectExpression["fields"] = Object.fromEntries([
            ...Object.entries(expr.fields).filter(
              ([fieldName]) => !autoRemovableFieldNames.includes(fieldName)
            ),
            ...autoAddableFieldNames.map((fieldName) => {
              const newExpression = getEmptyExpression(
                schema,
                {},
                schemaObjectFields[fieldName].valueType,
                defaultValues
              );
              return [fieldName, newExpression];
            }),
          ]);
          return {
            newExpression: {
              ...expr,
              fields: newFields,
            },
            mapResult: 1,
          };
        }
      }

      if (expr.type === "ApplicationExpression") {
        const functionExpression = getApplicationFunctionExpression(expr);
        if (functionExpression) {
          if (functionExpression.body) {
            // Eliminate applications with no arguments
            if (expr.arguments.length === 0) {
              return {
                newExpression: {
                  ...functionExpression.body,
                  metadata: {
                    permissions: resolvePermissions(
                      expr.metadata?.permissions ?? getEmptyPermissions(),
                      resolvePermissions(
                        functionExpression.metadata?.permissions ??
                          getEmptyPermissions(),
                        functionExpression.body?.metadata?.permissions
                      )
                    ),
                    note: combineNotes(
                      expr.metadata?.note,
                      functionExpression.metadata?.note,
                      functionExpression.body?.metadata?.note
                    ),
                  },
                },
                mapResult: 1,
              };
            }
          }

          for (let i = 0; i < expr.arguments.length; i += 1) {
            const argument = expr.arguments[i];
            // Drop arguments which are just variable expressions or function
            // expressions which pass all their parameters to another function
            // variable in the same order
            if (
              argument &&
              (argument.type === "VariableExpression" ||
                isRedundantFunctionExpression(argument))
            ) {
              const newExpression = dropArgument(expr, i);
              if (newExpression) {
                return { newExpression, mapResult: 1 };
              }
            }
          }

          // Combine nested applications if inner application arguments do not
          // rely on the outer application's arguments, and the permissions are
          // compatible
          // The nesting here is:
          // expr (an application)
          //   functionExpression (which defines outerVariableIds)
          //     innerApplication
          //       innerFunctionExpression
          if (
            functionExpression.body &&
            functionExpression.body.type === "ApplicationExpression"
          ) {
            const innerApplication = functionExpression.body;
            const outerVariableIds = Object.fromEntries(
              functionExpression.parameters.map((parameter) => [
                parameter.id,
                true,
              ])
            );
            const innerFunctionExpression =
              getApplicationFunctionExpression(innerApplication);
            if (
              innerApplication.arguments.every(
                (argument) => !usesVariables(argument, outerVariableIds)
              ) &&
              innerFunctionExpression &&
              arePermissionsMergable(functionExpression, innerApplication) &&
              arePermissionsMergable(
                functionExpression,
                innerFunctionExpression
              )
            ) {
              const newFunctionExpression: FunctionExpression = {
                ...functionExpression,
                valueType: {
                  type: "FunctionValueType",
                  parameterValueTypes: [
                    ...functionExpression.valueType.parameterValueTypes,
                    ...innerFunctionExpression.valueType.parameterValueTypes,
                  ],
                  returnValueType:
                    innerFunctionExpression.valueType.returnValueType,
                },
                parameters: [
                  ...functionExpression.parameters,
                  ...innerFunctionExpression.parameters,
                ],
                body: innerFunctionExpression.body,
              };
              const newExpression: ApplicationExpression = {
                ...expr,
                function: newFunctionExpression,
                arguments: [...expr.arguments, ...innerApplication.arguments],
              };
              return {
                newExpression,
                mapResult: 1,
              };
            }
          }
        }
      }

      if (expr.type === "ComparisonExpression") {
        // Remove comparisons that are comparing a comparison to null.
        // This results in more intuitive delete behavior for comparisons and
        // prevents raw expressions being exposed in the comparison tree.
        if (expr.a && expr.a.type === "ComparisonExpression" && !expr.b) {
          return {
            newExpression: expr.a,
            mapResult: 1,
          };
        }
        if (expr.b && expr.b.type === "ComparisonExpression" && !expr.a) {
          return {
            newExpression: expr.b,
            mapResult: 1,
          };
        }

        // Boolean variables should not be used directly in ANDs and ORs. It's
        // also an unreachable state except when extracting a condition. To make
        // logic consistent, we enforce boolean variables should be compared
        // == true, if they would otherwise be part of an AND or OR directly.
        if (
          expr.a &&
          expr.a.type === "VariableExpression" &&
          expr.a.valueType.type === "BooleanValueType" &&
          (expr.operator === "AND" || expr.operator === "OR")
        ) {
          return {
            newExpression: {
              ...expr,
              a: {
                ...getComparisonExpression(),
                a: expr.a,
                operator: "==",
                b: getBooleanExpression(true),
              },
            },
            mapResult: 1,
          };
        }
        if (
          expr.b &&
          expr.b.type === "VariableExpression" &&
          expr.b.valueType.type === "BooleanValueType" &&
          (expr.operator === "AND" || expr.operator === "OR")
        ) {
          return {
            newExpression: {
              ...expr,
              b: {
                ...getComparisonExpression(),
                a: expr.b,
                operator: "==",
                b: getBooleanExpression(true),
              },
            },
            mapResult: 1,
          };
        }

        // We shouldn't have `comparison == true` or `true/false == true`. This
        // adds complexity to the logic tree. It usually occurs when we drop
        // arguments that are if-elses (i.e. to undo the above).
        if (
          expr.a &&
          (expr.a.type === "ComparisonExpression" ||
            expr.a.type === "BooleanExpression") &&
          expr.operator === "==" &&
          expr.b &&
          expr.b.type === "BooleanExpression" &&
          expr.b.value
        ) {
          return {
            newExpression: expr.a,
            mapResult: 1,
          };
        }

        // When dropping an if expression of the form:
        //   if (x) { return true } else { return false }
        // into a comparison, we should flatten it to `x`.
        if (
          expr.a &&
          expr.a.type === "SwitchExpression" &&
          expr.a.cases.length === 1 &&
          expr.a.cases[0].then &&
          expr.a.cases[0].then.type === "BooleanExpression" &&
          expr.a.cases[0].then.value &&
          expr.a.default &&
          expr.a.default.type === "BooleanExpression" &&
          !expr.a.default.value
        ) {
          return {
            newExpression: {
              ...expr,
              a: expr.a.cases[0].when,
            },
            mapResult: 1,
          };
        }
      }

      if (expr && expr.type === "ApplicationExpression") {
        // If a block with variables has a ComparisonExpression as an argument,
        // wrap it with an 'if' to represent a segment.
        if (
          expr.arguments.some(
            (arg) => arg && arg.type === "ComparisonExpression"
          )
        ) {
          return {
            newExpression: {
              ...expr,
              arguments: expr.arguments.map((arg) =>
                arg && arg.type === "ComparisonExpression"
                  ? {
                      ...getIfExpression(schema, {}, arg.valueType),
                      cases: [
                        {
                          when: arg,
                          then: getBooleanExpression(true),
                        },
                      ],
                      default: getBooleanExpression(false),
                    }
                  : arg
              ),
            },
            mapResult: 1,
          };
        }
      }

      if (expr && expr.type === "LogEventExpression") {
        if (
          expr.eventTypeId &&
          !expr.eventObjectTypeName &&
          eventTypeMap[expr.eventTypeId]
        ) {
          const eventObjectTypeName = eventTypeMap[expr.eventTypeId].name;
          const eventPayload = (expr.eventPayload ||
            getDefaultExpression(
              schema,
              {},
              {
                type: "ObjectValueTypeConstraint",
                objectTypeName: eventObjectTypeName,
              },
              new Set()
            )) as ObjectExpression;
          if (
            !expr.eventPayload &&
            Object.keys(schema.objects[eventObjectTypeName].fields).length ===
              1 &&
            schema.objects[eventObjectTypeName].fields.unitId
          ) {
            eventPayload.fields.unitId = copyExpression(expr.unitId);
          }
          return {
            newExpression: {
              ...expr,
              eventObjectTypeName,
              eventPayload,
            },
            mapResult: 1,
          };
        }
        if (
          expr.eventObjectTypeName &&
          (!expr.eventTypeId ||
            !eventTypeMap[expr.eventTypeId] ||
            eventTypeMap[expr.eventTypeId].name !== expr.eventObjectTypeName)
        ) {
          const eventTypeId = Object.values(eventTypeMap).find(
            (value) => value.name === expr.eventObjectTypeName
          )?.id;
          if (eventTypeId) {
            return {
              newExpression: {
                ...expr,
                eventTypeId,
              },
              mapResult: 1,
            };
          }
        }
      }
      if (expr && expr.type === "SplitExpression") {
        return fixAndSimplifySplitExpression(schema, splitMap, expr);
      }
      if (expr && expr.type === "EnumSwitchExpression") {
        return fixAndSimplifyEnumSwitchExpression(schema, expr);
      }
      if (expr && expr.type === "SwitchExpression") {
        return fixAndSimplifySwitchExpression(schema, expr);
      }

      return { newExpression: expr, mapResult: 0 };
    },
    (...results: number[]) => results.reduce((prev, curr) => prev + curr, 0),
    expression
  );
  return {
    newExpression: nullThrows(
      result.newExpression,
      "fixAndSimplifyOnce returned null expression"
    ),
    numChanges: result.mapResult,
  };
}

// Check if an expression is a function expression which just passes all its
// parameters to a function variable in the same order
function isRedundantFunctionExpression(expression: Expression): boolean {
  if (expression.type !== "FunctionExpression") {
    return false;
  }

  const application = expression.body;
  if (!application || application.type !== "ApplicationExpression") {
    return false;
  }

  const functionVariable = application.function;
  if (
    !functionVariable ||
    functionVariable.type !== "VariableExpression" ||
    functionVariable.valueType.type !== "FunctionValueType"
  ) {
    return false;
  }

  if (
    expression.parameters.length !== application.arguments.length ||
    application.arguments.length !==
      functionVariable.valueType.parameterValueTypes.length
  ) {
    return false;
  }

  return expression.parameters.every((parameter, index) => {
    const argument = application.arguments[index];
    return (
      argument &&
      argument.type === "VariableExpression" &&
      argument.variableId === parameter.id
    );
  });
}

/** Returns whether it's okay to ignore the child permissions when merging a child into a parent */
function arePermissionsMergable(
  parent: Expression,
  /** Should be a direct child of parent, or a direct child that has been validated to be mergable into parent */
  child: Expression
): boolean {
  // Both have no permissions
  if (!parent.metadata?.permissions && !child.metadata?.permissions) {
    return true;
  }

  // If we don't know parent permissions, but child overrides them, we're not sure this is safe
  if (!parent.metadata?.permissions) {
    return false;
  }

  // Child resolves to identical permissions
  const childResolvedPermissions = resolvePermissions(
    parent.metadata.permissions,
    child.metadata?.permissions
  );
  return (
    childResolvedPermissions === parent.metadata.permissions ||
    stableStringify(childResolvedPermissions) ===
      stableStringify(parent.metadata.permissions)
  );
}

function combineNotes(...notes: (string | undefined)[]): string | undefined {
  return (
    (notes.filter(Boolean) as string[])
      .map((note) => {
        const trimmed = note.trimEnd();
        return trimmed.endsWith(".") ? trimmed : `${trimmed}.`;
      })
      .join(" ") || undefined
  );
}

function fixAndSimplifyEnumSwitchExpression(
  schema: Schema,
  currentExpression: EnumSwitchExpression
): {
  newExpression: Expression;
  mapResult: number;
} {
  let newExpression = currentExpression;
  let mapResult = 0;

  const schemaEnumValues =
    newExpression.control &&
    newExpression.control.valueType.type === "EnumValueType"
      ? schema.enums[newExpression.control.valueType.enumTypeName]?.values || {}
      : {};

  // Remove extra cases containing empty expressions
  Object.entries(newExpression.cases).forEach(
    ([enumValue, valueExpression]) => {
      if (
        typeof schemaEnumValues[enumValue] === "undefined" &&
        isEmptyExpression(valueExpression)
      ) {
        const { [enumValue]: _, ...cases } = newExpression.cases;
        newExpression = { ...newExpression, cases };
        mapResult = 1;
      }
    }
  );

  // Add cases for missing expressions containing default expression
  // for the value type.
  const missingEnumValues: string[] = [];
  Object.keys(schemaEnumValues).forEach((schemaEnumValue) => {
    if (typeof newExpression.cases[schemaEnumValue] === "undefined") {
      missingEnumValues.push(schemaEnumValue);
    }
  });
  if (missingEnumValues.length > 0) {
    const cases = { ...newExpression.cases };
    missingEnumValues.forEach((enumValue): void => {
      cases[enumValue] = getDefaultExpression(
        schema,
        {},
        getConstraintFromValueType(newExpression.valueType),
        new Set()
      );
    });
    newExpression = { ...newExpression, cases };
    mapResult = 1;
  }

  return { newExpression, mapResult };
}

function fixAndSimplifySplitExpression(
  schema: Schema,
  splitMap: SplitMap,
  currentExpression: SplitExpression
): {
  newExpression: Expression;
  mapResult: number;
} {
  let newExpression = currentExpression;
  let mapResult = 0;

  if (
    newExpression.splitId &&
    splitMap[newExpression.splitId] &&
    newExpression.eventObjectTypeName !==
      splitMap[newExpression.splitId].eventObjectTypeName
  ) {
    newExpression = {
      ...newExpression,
      eventObjectTypeName: splitMap[newExpression.splitId].eventObjectTypeName,
      eventPayload: splitMap[newExpression.splitId].eventObjectTypeName
        ? newExpression.eventPayload || null
        : null,
    };
    mapResult += 1;
  }

  if (newExpression.splitId && splitMap[newExpression.splitId]) {
    const split = splitMap[newExpression.splitId];

    // If no dimension is set and the split has exactly 1 discrete dimension,
    // then automatically select it
    const dimensions = Object.values(split.dimensions);
    if (
      !newExpression.dimensionId &&
      dimensions.length === 1 &&
      dimensions[0].type === "discrete"
    ) {
      newExpression = { ...newExpression, dimensionId: dimensions[0].id };
      mapResult += 1;
    }

    const dimension =
      newExpression.dimensionId &&
      split &&
      split.dimensions[newExpression.dimensionId]
        ? split.dimensions[newExpression.dimensionId]
        : null;
    const needDefaultArm = isDefaultArmNeeded(split, dimension);
    const extraArmIds: string[] = [];

    if (newExpression.dimensionMapping.type === "discrete") {
      Object.keys(newExpression.dimensionMapping.cases).forEach((armId) => {
        if (armId === defaultArmKey) {
          if (!needDefaultArm) {
            extraArmIds.push(armId);
          }
          return;
        }
        const arm =
          dimension && dimension.type === "discrete" && dimension.arms[armId]
            ? dimension.arms[armId]
            : null;
        if (!arm) {
          extraArmIds.push(armId);
        }
      });
    }

    const casePosition: { [armId: string]: number } = {};

    extraArmIds.forEach((armId) => {
      // Show extra cases at the top
      casePosition[armId] = -1;
    });

    const missingArmIds: string[] = [];
    if (dimension && dimension.type === "discrete") {
      Object.keys(dimension.arms)
        .concat(needDefaultArm ? [defaultArmKey] : [])
        .forEach((armId) => {
          // Sort valid cases by the index of their arms in the dimension and show
          // the default arm last
          casePosition[armId] =
            armId === defaultArmKey
              ? Number.MAX_SAFE_INTEGER
              : dimension.arms[armId].index;
          if (
            newExpression.dimensionMapping.type !== "discrete" ||
            typeof newExpression.dimensionMapping.cases[armId] === "undefined"
          ) {
            missingArmIds.push(armId);
          }
        });
    }

    // If the dimension mapping type does not match the dimension type, reset it
    if (dimension && dimension.type !== newExpression.dimensionMapping.type) {
      newExpression = {
        ...newExpression,
        dimensionMapping:
          dimension.type === "discrete"
            ? {
                type: "discrete",
                cases: {},
              }
            : {
                type: "continuous",
                function: null,
              },
      };
      mapResult += 1;
    }

    if (newExpression.dimensionMapping.type === "discrete") {
      const newDimensionMapping = deepClonePlainObject(
        newExpression.dimensionMapping
      );
      let didUpdate = false;

      // Remove extra cases with empty expressions
      extraArmIds.forEach((armId) => {
        if (isEmptyExpression(newDimensionMapping.cases[armId])) {
          delete newDimensionMapping.cases[armId];
          didUpdate = true;
        }
      });

      const childValueTypeConstraint: ValueTypeConstraint = isValueTypeValid(
        schema,
        newExpression.valueType
      )
        ? getConstraintFromValueType(newExpression.valueType)
        : { type: "ErrorValueTypeConstraint" };

      // Add missing cases
      if (missingArmIds.length > 0) {
        missingArmIds.forEach((armId) => {
          newDimensionMapping.cases[armId] = getDefaultExpression(
            schema,
            {},
            childValueTypeConstraint,
            new Set(),
            {
              defaultBooleanValue:
                dimension?.type === "discrete" &&
                armId !== dimension.controlArmId &&
                armId !== defaultArmKey,
            }
          );
        });
        didUpdate = true;
      }

      if (didUpdate) {
        newExpression = {
          ...newExpression,
          dimensionMapping: newDimensionMapping,
        };
        mapResult += 1;
      }
    }
  }

  return { newExpression, mapResult };
}

function fixAndSimplifySwitchExpression(
  schema: Schema,
  expression: SwitchExpression
): {
  newExpression: SwitchExpression;
  mapResult: number;
} {
  // Force if/else expression when conditions to be comparisons or booleans.
  // This avoids reaching unrecoverable states, and allows us to rely on the
  // comparison simplification rules without reimplementing them for ifs.
  // Boolean support is for kill switches on boolean flags.
  if (
    isIfElseExpression(expression) &&
    expression.cases.some(
      (c) =>
        c.when &&
        c.when.type !== "ComparisonExpression" &&
        c.when.type !== "BooleanExpression"
    )
  ) {
    return {
      newExpression: {
        ...expression,
        cases: expression.cases.map((c) => ({
          ...c,
          when:
            c.when && c.when.type !== "ComparisonExpression"
              ? {
                  ...getComparisonExpression(),
                  a: c.when,
                  operator: "==",
                  b: getBooleanExpression(true),
                }
              : c.when,
        })),
      },
      mapResult: 1,
    };
  }

  return { newExpression: expression, mapResult: 0 };
}
