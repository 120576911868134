import { Expression, Schema } from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import React, { useState } from "react";
import {
  getFieldArgumentsObjectTypeNameParts,
  setObjectDescription,
} from "@hypertune/shared-internal";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import MarkdownView from "../../../components/MarkdownView";
import Label from "../../../components/Label";
import CollapseButton from "../../../components/buttons/CollapseButton";
import CollapseDiv from "../../../components/animations/CollapseDiv";
import {
  Description,
  ContentContainer,
  DetailsContainer,
} from "../../../components/Details";
import { useAppDispatch } from "../../../app/hooks";
import { setDraftCommitSchema } from "../projectSlice";
import ObjectFieldDetails from "../schema/typeEditor/object/ObjectFieldDetails";

type CollapsedExpressions = {
  [expressionId: string]: boolean;
};

export default function ExpressionDetail({
  readOnly,
  fieldName,
  expression,
  variables,
  schema,
  topLevelExpression,
  onDelete,
  setExpression,
}: {
  readOnly: boolean;
  fieldName: string;
  expression: Expression | null;
  variables: VariableMap;
  schema: Schema;
  topLevelExpression: Expression;
  onDelete: () => void;
  setExpression: (newExpression: Expression | null) => void;
}): React.ReactElement | null {
  const [collapsedExpressions, setCollapsedExpressions] =
    useState<CollapsedExpressions>({});

  return (
    <DetailsContainer>
      <ExpressionDetailInner
        readOnly={readOnly}
        fieldName={fieldName}
        expression={expression}
        variables={variables}
        schema={schema}
        onDelete={onDelete}
        topLevelExpression={topLevelExpression}
        collapsedExpressions={collapsedExpressions}
        setCollapsedExpressions={setCollapsedExpressions}
        showDetails
        showTags
        setExpression={setExpression}
      />
    </DetailsContainer>
  );
}

function ExpressionDetailInner({
  readOnly,
  fieldName,
  expression,
  variables,
  schema,
  onDelete,
  topLevelExpression,
  collapsedExpressions,
  setCollapsedExpressions,
  showDetails,
  showTags,
  setExpression,
}: {
  readOnly: boolean;
  fieldName: string;
  expression: Expression | null;
  variables: VariableMap;
  schema: Schema;
  onDelete: () => void;
  topLevelExpression: Expression;
  collapsedExpressions: CollapsedExpressions;
  setCollapsedExpressions: (
    newCollapsedExpressions: CollapsedExpressions
  ) => void;
  showDetails?: boolean;
  setExpression: (newExpression: Expression | null) => void;
  showTags?: boolean;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  if (!expression) {
    return null;
  }
  switch (expression.type) {
    case "ApplicationExpression":
      return (
        <ExpressionDetailInner
          readOnly={readOnly}
          fieldName={fieldName}
          expression={expression.function}
          variables={variables}
          schema={schema}
          onDelete={onDelete}
          topLevelExpression={topLevelExpression}
          collapsedExpressions={collapsedExpressions}
          setCollapsedExpressions={setCollapsedExpressions}
          showDetails={showDetails}
          showTags={showTags}
          setExpression={setExpression}
        />
      );
    case "ObjectExpression":
      return (
        <>
          {showDetails &&
            schema.objects[expression.objectTypeName]?.description && (
              <Description
                readOnly={readOnly}
                text={
                  schema.objects[expression.objectTypeName]
                    .description as string
                }
                setText={(newDescription) =>
                  dispatch(
                    setDraftCommitSchema(
                      setObjectDescription(
                        schema,
                        expression.objectTypeName,
                        newDescription || null
                      )
                    )
                  )
                }
              />
            )}
          {Object.entries(expression.fields).map(([objectFieldName, field]) => {
            return (
              <ExpressionDetailInner
                readOnly={readOnly}
                fieldName={objectFieldName}
                expression={field}
                variables={variables}
                schema={schema}
                onDelete={onDelete}
                topLevelExpression={topLevelExpression}
                collapsedExpressions={collapsedExpressions}
                setCollapsedExpressions={setCollapsedExpressions}
                showDetails={false}
                showTags
                setExpression={setExpression}
              />
            );
          })}
        </>
      );
    case "FunctionExpression": {
      const argsDef =
        expression.valueType.parameterValueTypes.length === 1 &&
        expression.valueType.parameterValueTypes[0].type === "ObjectValueType"
          ? getFieldArgumentsObjectTypeNameParts(
              expression.valueType.parameterValueTypes[0].objectTypeName
            )
          : null;
      return (
        <>
          {(showDetails || showTags) &&
            argsDef &&
            argsDef?.parentObjectTypeName &&
            schema.objects[argsDef.parentObjectTypeName].fields[
              argsDef.fieldName
            ] && (
              <ObjectFieldDetails
                readOnly={readOnly}
                view="logic"
                schema={schema}
                expression={topLevelExpression}
                selectedExpression={expression}
                objectTypeName={argsDef.parentObjectTypeName}
                fieldName={argsDef.fieldName}
                onDelete={onDelete}
                setExpression={setExpression}
                showDetails={showDetails}
                showTags={showTags}
              />
            )}
          <ExpressionDetailInner
            readOnly={readOnly}
            fieldName={fieldName}
            expression={expression.body}
            variables={variables}
            schema={schema}
            onDelete={onDelete}
            topLevelExpression={topLevelExpression}
            collapsedExpressions={collapsedExpressions}
            setCollapsedExpressions={setCollapsedExpressions}
            showDetails={false}
            setExpression={setExpression}
            showTags={showTags}
          />
        </>
      );
    }

    case "StringConcatExpression":
      return (
        <ExpressionDetailInner
          readOnly={readOnly}
          fieldName={fieldName}
          expression={{
            ...expression,
            type: "StringExpression",
            value:
              expression.strings !== null &&
              expression.strings.type === "ListExpression" &&
              expression.strings.valueType.itemValueType.type ===
                "StringValueType"
                ? expression.strings.items
                    .map((item) =>
                      item?.type === "StringExpression"
                        ? item.value
                        : item?.type === "VariableExpression"
                          ? `# {{ ${variables[item.variableId].name} }}`
                          : `# {{ dynamicValue }}`
                    )
                    .join("\n")
                : "",
          }}
          variables={variables}
          schema={schema}
          onDelete={onDelete}
          topLevelExpression={topLevelExpression}
          collapsedExpressions={collapsedExpressions}
          setCollapsedExpressions={setCollapsedExpressions}
          showDetails={showDetails}
          showTags={showTags}
          setExpression={setExpression}
        />
      );
    case "StringExpression":
      if (isMarkdown(fieldName)) {
        const isOpen = !collapsedExpressions[expression.id];
        return (
          <ContentContainer className={isOpen ? "" : "border-b-0"}>
            <div className="flex flex-row justify-between border-b border-bd-darker px-3 py-2">
              <Label type="title3">{toStartCase(fieldName)}</Label>
              <CollapseButton
                isOpen={isOpen}
                setOpen={(newIsOpen) => {
                  setCollapsedExpressions({
                    ...collapsedExpressions,
                    [expression.id]: !newIsOpen,
                  });
                }}
              />
            </div>
            <CollapseDiv isOpen={isOpen} className="overflow-auto">
              <div className="px-3 pt-2">
                <MarkdownView markdown={expression.value} />
              </div>
            </CollapseDiv>
          </ContentContainer>
        );
      }
      return null;
    default:
      return null;
  }
}

export function expressionNodeHasMarkdown(
  expression: Expression | null,
  fieldName: string
): boolean {
  if (!expression) {
    return false;
  }
  switch (expression.type) {
    case "ApplicationExpression":
      return expressionNodeHasMarkdown(expression.function, fieldName);
    case "FunctionExpression":
      return expressionNodeHasMarkdown(expression.body, fieldName);
    case "ObjectExpression":
      return Object.entries(expression.fields)
        .map(([childFieldName, field]) =>
          expressionNodeHasMarkdown(field, childFieldName)
        )
        .some(Boolean);
    case "StringExpression":
    case "StringConcatExpression":
      return isMarkdown(fieldName);
    default:
      return false;
  }
}

function isMarkdown(fieldName: string): boolean {
  return fieldName.toLowerCase().includes("markdown");
}
