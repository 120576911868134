import React from "react";
import Button from "./buttons/Button";
import twMerge from "../lib/twMerge";

export default function ModeSwitcher<T>({
  modes,
  setMode,
  selectedMode,
  className,
}: {
  modes: { icon: React.ReactNode; value: T }[];
  setMode: (newEnabled: T) => void;
  selectedMode: T;
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "relative inline-flex items-center gap-[2px] rounded-[10px] border border-bd-darker bg-bg-medium p-[2px]",
        className
      )}
    >
      {modes.map((mode) => (
        <Button
          weight={mode.value === selectedMode ? "elevated" : "default"}
          icon={mode.icon}
          onClick={() => setMode(mode.value)}
          size="small"
        />
      ))}
    </div>
  );
}
