import { Expression } from "@hypertune/sdk/src/shared";

export default function canCollapse(
  parentExpression: Expression,
  expression: Expression
): boolean {
  switch (parentExpression.type) {
    case "NoOpExpression":
    case "BooleanExpression":
    case "IntExpression":
    case "FloatExpression":
    case "StringExpression":
    case "RegexExpression":
    case "EnumExpression":
      return false;

    case "ObjectExpression":
      return true;

    case "GetFieldExpression":
      return false;

    case "UpdateObjectExpression":
      return expression !== parentExpression.object;

    case "ListExpression":
      return true;

    case "SwitchExpression":
    case "EnumSwitchExpression":
      return false;

    case "ComparisonExpression":
    case "ArithmeticExpression":
    case "RoundNumberExpression":
    case "StringifyNumberExpression":
    case "StringConcatExpression":
      return false;

    case "SplitExpression":
    case "LogEventExpression":
      return false;

    case "FunctionExpression":
    case "VariableExpression":
      return false;

    case "ApplicationExpression":
      return expression !== parentExpression.function;

    default:
      return false;
  }
}
