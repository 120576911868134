import React, { useEffect } from "react";
import { CountMap, Expression, Schema } from "@hypertune/sdk/src/shared";
import { getEmptyPermissions, isAllowed } from "@hypertune/shared-internal";
import { queryPollIntervalMs } from "../../lib/constants";
import {
  ExpressionControlContext,
  ImplementationContext,
} from "../../lib/types";
import ExpressionView from "./expression/ExpressionView";
import { useEvaluationsQuery } from "../../generated/graphql";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setDraftCommitSchemaSplitsAndEventTypes,
  setLogicEditorExpressionEditorState,
} from "./projectSlice";
import { ExpressionNodeMap } from "./expression/toTree";

export default function LogicEditor({
  isVisible,
  hasError,
  meId,
  projectId,
  commitId,
  schema,
  implementationContext,
  expressionTree,
  expression,
  setExpression,
  setSelectedFieldPath,
  readOnly,
  defaultFieldPath,
}: {
  isVisible: boolean;
  hasError: boolean;
  meId: string;
  projectId: string;
  commitId: string | null;
  schema: Schema;
  implementationContext: ImplementationContext;
  expressionTree: ExpressionNodeMap;
  expression: Expression;
  setExpression: (newExpression: Expression) => void;
  setSelectedFieldPath: (newSelectedFieldPath: string) => void;
  readOnly: boolean;
  defaultFieldPath: string[];
}): React.ReactElement {
  function setExpressionIfVisible(newExpression: Expression | null): void {
    if (!isVisible) {
      return;
    }

    if (!newExpression) {
      // eslint-disable-next-line no-alert
      alert("You cannot delete the root expression.");
      return;
    }

    if (!isAllowed(meId, getEmptyPermissions(), expression, newExpression)) {
      // eslint-disable-next-line no-alert
      alert("You don't have permissions to make that change.");
      return;
    }

    setExpression(newExpression);
  }
  const dispatch = useAppDispatch();
  const editorState = useAppSelector((state) => state.project.logicEditor);

  // TODO: Validate with zod
  const {
    data: evaluationsQueryData,
    startPolling,
    stopPolling,
  } = useEvaluationsQuery({
    variables: { input: { projectId, commitId } },
  });

  const evaluations: CountMap = evaluationsQueryData
    ? JSON.parse(evaluationsQueryData.evaluationsJson)
    : {};

  useEffect(() => {
    if (isVisible) {
      startPolling(queryPollIntervalMs);
      return;
    }
    stopPolling();
  }, [startPolling, stopPolling, isVisible]);

  const context: ExpressionControlContext = {
    meId,
    fullFieldPath: "",
    commitContext: {
      schema,
      ...implementationContext,
    },
    evaluations,
    setSelectedFieldPath,
    expressionEditorState: editorState.expressionEditorState,
    setExpressionEditorState: (newExpressionEditorState) =>
      dispatch(setLogicEditorExpressionEditorState(newExpressionEditorState)),
    setSchemaSplitsAndEventTypes: (newSchema, newSplits, newEventTypes) =>
      dispatch(
        setDraftCommitSchemaSplitsAndEventTypes({
          schema: newSchema,
          splits: newSplits,
          eventTypes: newEventTypes,
        })
      ),
    ignoreErrors: false,
    readOnly,
    resolvedPermissions: getEmptyPermissions(),
    expandByDefault: false,
  };

  return (
    <ExpressionView
      isVisible={isVisible}
      hasError={hasError}
      context={context}
      schema={schema}
      expressionTree={expressionTree}
      expression={expression}
      setExpression={setExpressionIfVisible}
      defaultFieldPath={defaultFieldPath}
    />
  );
}
