import { useCookies } from "react-cookie";
import { anonymousIdCookieName } from "@hypertune/shared-internal/src/constants";
import env from "../environment";
import { BusinessType, useMeQuery } from "../generated/graphql";
import { Environment, RootArgs } from "../generated/hypertune";
import { HypertuneProvider } from "../generated/hypertune.react";
import HypertuneToolbar from "../generated/HypertuneToolbar";
import { sessionIdKey } from "../lib/constants";

const branchNameSearchParamKey = "hypertune_branch_name";

export default function AppHypertuneProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { data } = useMeQuery();
  const [cookies] = useCookies([anonymousIdCookieName]);
  const anonymousId = (cookies.hypertuneAnonymousId as string) ?? "";
  const url = new URL(window.location.href);
  const sessionId = window.sessionStorage.getItem(sessionIdKey);
  const rootArgs: RootArgs = {
    context: {
      user: {
        anonymousId,
        sessionId: sessionId || "",
        id: data?.me.id || "",
        email: data?.me.email || "",
      },
      teamId: data?.primaryBusiness?.business.id ?? "",
      teamType:
        data?.primaryBusiness?.business.type === BusinessType.Business
          ? "BUSINESS"
          : "PERSONAL",
      env: env.NAME.toUpperCase() as Environment,
      plan: data?.primaryBusiness?.business.plan ?? "free",
    },
  };

  console.debug("Hypertune RootArgs:", rootArgs);

  return (
    <HypertuneProvider
      createSourceOptions={{
        branchName: url.searchParams.get(branchNameSearchParamKey) || undefined,
        remoteLogging: { mode: env.NAME === "production" ? "session" : "off" },
      }}
      rootArgs={rootArgs}
    >
      {children}
      <HypertuneToolbar showFlagPath="showToolbar" />
    </HypertuneProvider>
  );
}
