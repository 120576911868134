import { Schema } from "@hypertune/sdk/src/shared/types";
import {
  allFunnelAggregationTypes,
  FunnelAggregation,
  FunnelAggregationType,
  FunnelDerivedField,
  FunnelField,
} from "@hypertune/shared-internal/src/types";
import { useCallback, useMemo, useState } from "react";
import { getAllValuePathsForObject } from "@hypertune/shared-internal";
import { Pencil } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import Button from "../../../../components/buttons/Button";
import { intentPrimaryHex, plusSymbol } from "../../../../lib/constants";
import Modal from "../../../../components/Modal";
import ConfigurationContainer from "./ConfigurationContainer";
import Dropdown, { LabeledOption } from "../../../../components/Dropdown";
import toLabeledOption from "../../../../lib/toLabeledOption";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import TextInput from "../../../../components/input/TextInput";
import { getStartCasePayloadPathString } from "../../../../lib/getPayloadPathString";
import isNumericValueType from "../../../../lib/isNumericValueType";
import { useHypertune } from "../../../../generated/hypertune.react";
import ModalWithContent from "../../../../components/ModalWithContent";

export default function SelectAggregation({
  schema,
  payloadObjectTypeName,
  derivedFields,
  aggregations,
  setAggregations,
  canEdit,
}: {
  schema: Schema;
  payloadObjectTypeName: string | null;
  derivedFields: FunnelDerivedField[];
  aggregations?: FunnelAggregation[];
  setAggregations: (newAggregations: FunnelAggregation[] | undefined) => void;
  canEdit: boolean;
}): React.ReactElement | null {
  const navigate = useNavigate();
  const hypertune = useHypertune();
  const canUseAggregations = hypertune
    .features()
    .analyticsAggregationsEnabled({ fallback: false });

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [draftAggregationIndex, setDraftAggregationIndex] = useState<
    number | null
  >(null);

  const options: LabeledOption<FunnelField>[] = useMemo(
    () =>
      payloadObjectTypeName
        ? getAllValuePathsForObject(
            schema,
            payloadObjectTypeName,
            isNumericValueType
          )
            .map<LabeledOption<FunnelField>>((payloadPath) => ({
              value: { type: "payloadPath", payloadPath },
              label: getStartCasePayloadPathString(payloadPath),
            }))
            .concat(
              derivedFields
                .filter(({ valueType }) => isNumericValueType(valueType))
                .map<LabeledOption<FunnelField>>(({ name }) => ({
                  value: { type: "derivedField", fieldName: name },
                  label: toStartCase(name),
                }))
            )
        : [],
    [schema, payloadObjectTypeName, derivedFields]
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <ConfigurationContainer title="Aggregations">
      {aggregations && aggregations?.length > 0 ? (
        <>
          {aggregations.map(({ type, name, data }, index) => (
            <div
              key={`${type}-${data.type}-${data.type === "payloadPath" ? data.payloadPath.join("-") : data.fieldName}`}
              className="mb-2 flex flex-row items-center"
            >
              <DeleteButton
                className="mr-1"
                size="x-small"
                disabled={!canEdit}
                onClick={() =>
                  setAggregations([
                    ...aggregations.slice(0, index),
                    ...aggregations.slice(index + 1),
                  ])
                }
              />
              <Button
                className="mr-1"
                size="x-small"
                weight="minimal"
                intent="primary"
                disabled={!canEdit}
                icon={<Pencil color={intentPrimaryHex} size={12} />}
                onClick={() => setDraftAggregationIndex(index)}
              />
              <div>{name}</div>
            </div>
          ))}
        </>
      ) : null}
      <Button
        intent="primary"
        weight="minimal"
        size="x-small"
        disabled={!canEdit}
        icon={plusSymbol}
        text="Aggregation"
        className="mb-2"
        onClick={
          canUseAggregations
            ? () => setDraftAggregationIndex(aggregations?.length ?? 0)
            : () => setShowUpgradeModal(true)
        }
      />
      {draftAggregationIndex !== null && (
        <AggregationModal
          options={options}
          draftIndex={draftAggregationIndex}
          aggregations={aggregations ?? []}
          setAggregations={setAggregations}
          onClose={() => setDraftAggregationIndex(null)}
        />
      )}
      {showUpgradeModal && (
        <ModalWithContent
          content={hypertune
            .content()
            .plans()
            .analyticsAddAggregationModal()
            .get()}
          onClose={() => setShowUpgradeModal(false)}
          onSave={() => navigate("/plans")}
        />
      )}
    </ConfigurationContainer>
  );
}

function AggregationModal({
  options,
  draftIndex,
  aggregations,
  setAggregations,
  onClose,
}: {
  options: LabeledOption<FunnelField>[];
  draftIndex: number;
  aggregations: FunnelAggregation[];
  setAggregations: (newAggregations: FunnelAggregation[] | undefined) => void;
  onClose: () => void;
}): React.ReactElement | null {
  const draftBase = aggregations?.[draftIndex];
  const [name, setName] = useState(draftBase?.name ?? "");
  const [data, setData] = useState<FunnelField>(
    draftBase?.data ?? options[0].value
  );
  const [type, setType] = useState<FunnelAggregationType>(
    draftBase?.type ?? "sum"
  );
  const onSave = useCallback(() => {
    if (!name) {
      return;
    }
    setAggregations([
      ...aggregations.slice(0, draftIndex),
      { type, name, data },
      ...aggregations.slice(draftIndex + 1),
    ]);
    onClose();
  }, [onClose, setAggregations, aggregations, draftIndex, type, name, data]);

  return (
    <Modal
      title="Add aggregation"
      onClose={onClose}
      saveWeight="filled"
      saveDisabled={!name}
      onSave={onSave}
    >
      <TextInput
        value={name}
        onChange={setName}
        readOnly={false}
        placeholder="Set name for this aggregation"
        focusOnMount
        onEnter={onSave}
        label="Name"
        labelVariant="muted"
        style={{ marginBottom: 12 }}
      />
      <Dropdown<FunnelField>
        options={{ type: "options", options }}
        value={{
          value: data,
          label:
            data.type === "payloadPath"
              ? getStartCasePayloadPathString(data.payloadPath)
              : toStartCase(data.fieldName),
        }}
        onChange={(option) => {
          if (option) {
            setData(option.value);
          }
        }}
        placeholder=""
        noOptionsMessage=""
        height={30}
        label="Path"
        labelVariant="muted"
        style={{ marginBottom: 12 }}
      />
      <Dropdown<FunnelAggregationType>
        options={{
          type: "options",
          options: allFunnelAggregationTypes.map((option) =>
            toLabeledOption(option)
          ),
        }}
        value={toLabeledOption(type)}
        onChange={(option) => {
          if (option) {
            setType(option.value);
          }
        }}
        placeholder=""
        noOptionsMessage=""
        height={30}
        label="Type"
        labelVariant="muted"
      />
    </Modal>
  );
}
