import { BooleanExpression, Expression } from "@hypertune/sdk/src/shared";
import { normal, singlePanelInnerHeight, small } from "../../../lib/constants";
import { ExpressionControlContext } from "../../../lib/types";
import Toggle from "../../../components/Toggle";
import isReadOnly from "../../../lib/expression/isReadOnly";
import { useHypertune } from "../../../generated/hypertune.react";

export default function BooleanExpressionControl({
  expression,
  context,
  setExpression,
  optionsButton,
}: {
  expression: BooleanExpression;
  context: ExpressionControlContext;
  setExpression: (newExpression: Expression | null) => void;
  optionsButton: React.ReactNode;
}): React.ReactElement {
  const hypertuneRoot = useHypertune();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: normal,
        marginLeft: -(normal - small),
        height: singlePanelInnerHeight,
      }}
      onMouseDown={(event) => {
        if (hypertuneRoot.expressionsSelectability({ fallback: false })) {
          context.setExpressionEditorState({
            ...context.expressionEditorState,
            selectedItem: { type: "expression", id: expression.id },
          });
          event.stopPropagation();
        }
      }}
    >
      <Toggle
        key={expression.id}
        disabled={isReadOnly(context)}
        className="mx-[1.5px]"
        value={expression.value}
        setValue={(newValue) => {
          setExpression({
            ...expression,
            value: newValue,
          });
          if (hypertuneRoot.expressionsSelectability({ fallback: false })) {
            context.setExpressionEditorState({
              ...context.expressionEditorState,
              selectedItem: { type: "expression", id: expression.id },
            });
          }
        }}
      />
      {optionsButton && <div>{optionsButton}</div>}
    </div>
  );
}
