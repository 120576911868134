export function decreaseItemIndex<T>(items: T[], index: number): T[] {
  if (index < 1 || index > items.length - 1) {
    return items;
  }

  return [
    ...items.slice(0, index - 1),
    items[index],
    items[index - 1],
    ...items.slice(index + 1),
  ];
}

export function increaseItemIndex<T>(items: T[], index: number): T[] {
  if (index < 0 || index > items.length - 2) {
    return items;
  }

  return [
    ...items.slice(0, index),
    items[index + 1],
    items[index],
    ...items.slice(index + 2),
  ];
}
