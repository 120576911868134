import isValidDomain from "@hypertune/shared-internal/src/isValidDomain";
import { plusSymbol } from "../../lib/constants";
import TextListInput from "./TextListInput";

export default function DomainListInput({
  disallowedDomains,
  domains,
  setDomains,
  onEnter,
  allowEmpty,
  isLoading,
  inputStyle,
  focusLastOnMount,
}: {
  disallowedDomains: Set<string>;
  domains: string[];
  setDomains: (newDomains: string[]) => void;
  onEnter?: () => void;
  allowEmpty: boolean;
  isLoading: boolean;
  inputStyle?: React.CSSProperties;
  focusLastOnMount?: boolean;
}): React.ReactElement | null {
  return (
    <TextListInput
      textList={domains}
      setTextList={setDomains}
      placeholder="Enter a domain"
      newLabel={`${plusSymbol} Domain`}
      onEnter={onEnter}
      isLoading={isLoading}
      focusLastOnMount={focusLastOnMount}
      allowEmpty={allowEmpty}
      valueError={(domain) => getDomainError(disallowedDomains, domain)}
      inputStyle={inputStyle}
    />
  );
}

export function getDomainError(
  disallowedDomains: Set<string>,
  domain: string
): string | null {
  return !domain
    ? null
    : !isValidDomain(domain)
      ? "Invalid domain"
      : disallowedDomains.has(domain)
        ? "Domain not allowed"
        : null;
}
