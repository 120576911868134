import { ObjectRole, ObjectSchema, Schema } from "@hypertune/sdk/src/shared";
import { argsObjectSuffix } from "./fieldArgumentsObjectTypeName";
import { queryObjectTypeName } from "../constants";
import {
  rootContextTypeNameFromSchema,
  rootObjectTypeNameFromSchema,
} from "./schemaOperationsObject";

export default function filterAndSortObjectEntries(
  schema: Schema
): [string, ObjectSchema][] {
  const contextObjectTypeName = rootContextTypeNameFromSchema(schema);
  const rootObjectTypeName = rootObjectTypeNameFromSchema(schema);

  return Object.entries(schema.objects)
    .filter(
      ([objectTypeName, objectType]) =>
        !objectTypeName.endsWith(argsObjectSuffix) && objectType.role !== "args"
    )
    .sort(([objectTypeNameA, objectTypeA], [objectTypeNameB, objectTypeB]) => {
      // The ordering is Query, input objects in alphabetical order,
      // Root, output objects in alphabetical order, events in alphabetical order
      if (objectTypeNameA === queryObjectTypeName) {
        return -1;
      }
      if (objectTypeNameB === queryObjectTypeName) {
        return 1;
      }
      if (objectTypeNameA === contextObjectTypeName) {
        return -1;
      }
      if (objectTypeNameB === contextObjectTypeName) {
        return 1;
      }
      return compareObjectRolesAndNames(
        rootObjectTypeName,
        objectTypeA.role,
        objectTypeNameA,
        objectTypeB.role,
        objectTypeNameB
      );
    });
}

// eslint-disable-next-line max-params
export function compareObjectRolesAndNames(
  rootObjectTypeName: string,
  objectRoleA: ObjectRole,
  objectTypeNameA: string,
  objectRoleB: ObjectRole,
  objectTypeNameB: string
): number {
  if (objectRoleA === "input" && objectRoleB !== "input") {
    return -1;
  }
  if (objectRoleB === "input" && objectRoleA !== "input") {
    return 1;
  }
  if (objectTypeNameA === rootObjectTypeName) {
    return -1;
  }
  if (objectTypeNameB === rootObjectTypeName) {
    return 1;
  }
  if (objectRoleA === "event" && objectRoleB !== "event") {
    return 1;
  }
  if (objectRoleB === "event" && objectRoleA !== "event") {
    return -1;
  }

  return objectTypeNameA.localeCompare(objectTypeNameB);
}
