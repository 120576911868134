import twMerge from "../lib/twMerge";

export default function ConditionalText({
  text,
  className,
}: {
  text: string;
  className?: string;
}): React.ReactElement | null {
  if (!text) {
    return null;
  }
  return (
    <p
      className={twMerge(
        "whitespace-pre-line text-tx-default",
        className || ""
      )}
    >
      {text}
    </p>
  );
}
