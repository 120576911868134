const startsWithLetterRegex = /^[a-zA-Z].*$/;
const noSpecialCharactersRegex = /^[a-zA-Z0-9_]+$/;

export type SchemaCheck = {
  valid: boolean;
  startsWithLetter: boolean;
  noSpecialCharacters: boolean;
};

export default function isSchemaNameValid(name: string): boolean {
  return checkSchemaName(name).valid;
}

export function checkSchemaName(name: string): SchemaCheck {
  const startsWithLetter = startsWithLetterRegex.test(name);
  const noSpecialCharacters = noSpecialCharactersRegex.test(name);

  return {
    startsWithLetter,
    noSpecialCharacters,
    valid: startsWithLetter && noSpecialCharacters,
  };
}

export function schemaNameErrorMessage(typeName: string, name: string): string {
  return `${typeName} name "${name}" is not valid. Please don't use special characters and make sure the name starts with a letter.`;
}

export function alreadyExistsMessage(typeName: string, name: string): string {
  return `${typeName} with name "${name}" already exists. Please use a different name.`;
}
