import React, { useEffect, useMemo, useState } from "react";
import { useBeforeUnload, useBlocker, useSearchParams } from "react-router-dom";
import {
  DbVercelConnection,
  ProjectTokenMapWithMeta,
  VercelEdgeConfigStoreWithTokens,
} from "@hypertune/shared-internal";
import Skeleton from "react-loading-skeleton";
import {
  AddVercelAuthInput,
  BusinessesQuery,
  VercelEdgeConfigStoresDocument,
  useAddVercelAuthMutation,
  useVercelEdgeConfigStoresQuery,
} from "../../../generated/graphql";
import { vercelAuthCodeSearchParamKey } from "../../../lib/constants";
import {
  storesJsonToStoreIdToStoreMap,
  useUpdateVercelEdgeConnections,
} from "./hooks";
import ErrorMessageCard from "../../../components/ErrorMessageCard";
import Logo from "../../../components/icons/Logo";
import { Dot, Line, LinkIcon } from "../../../components/ProgressShapes";
import Label from "../../../components/Label";
import {
  EnvVars,
  ProjectDropdown,
  VercelStoreDropdown,
  getConnectionEnvVars,
} from "./ConnectionEditModal";
import Button from "../../../components/buttons/Button";
import { updateSearchParamState } from "../../../app/useSearchParamsState";
import ContainerWithLogo from "../../../components/container/ContainerWithLogo";
import { canEditBusiness } from "../../../lib/query/rolePermissions";
import { useHypertune } from "../../../generated/hypertune.react";
import ErrorMessage from "../../../components/ErrorMessage";

export default function VercelEdgeConfigSetup({
  data,
}: {
  data: BusinessesQuery["primaryBusiness"];
}): React.ReactElement {
  const [searchParams] = useSearchParams();
  const nextEncodedUrl = searchParams.get("next") ?? "/";
  const navigateToVercel = searchParams.get("navigateToVercel") === "true";

  useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname === nextLocation.pathname) {
      return false;
    }
    console.log(
      "[useBlocker:vercelConnection] prompted user to confirm they want to leave"
    );
    // eslint-disable-next-line no-restricted-globals, no-alert
    return !confirm("Leave site? Changes that you made may not be saved.");
  });
  // Handles closing the page
  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (!navigateToVercel) {
          console.log(
            "[useBeforeUnload:vercelConnection] prompted user to confirm they want to leave"
          );
          event.preventDefault();
          // This value is overridden by most modern browsers
          // eslint-disable-next-line no-param-reassign
          event.returnValue =
            "Leave site? Changes that you made may not be saved.";
        }
      },
      [navigateToVercel]
    )
  );

  useEffect(() => {
    // Navigate to Vercel when the search param is set.
    // This ensures that the blockers above work correctly
    // when clicking the go to Vercel button.
    if (navigateToVercel) {
      window.location.href = decodeURI(nextEncodedUrl);
    }
  }, [navigateToVercel, nextEncodedUrl]);

  return <VercelEdgeConfigSetupInner data={data} />;
}

function VercelEdgeConfigSetupInner({
  data,
}: {
  data: BusinessesQuery["primaryBusiness"];
}): React.ReactElement | null {
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const code = searchParams.get(vercelAuthCodeSearchParamKey);

  const [addVercelAuth, { error }] = useAddVercelAuthMutation({
    refetchQueries: [VercelEdgeConfigStoresDocument],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (!code) {
      setIsLoaded(true);
      return;
    }
    if (!data?.id) {
      return;
    }
    const input: AddVercelAuthInput = { businessId: data.id, code };
    addVercelAuth({ variables: { input } })
      .then(() => {
        console.debug(`addVercelAuth success for code ${code}`);
      })
      .catch((error_) => {
        console.log("addVercelAuth error:", error_);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [code, data?.id, addVercelAuth]);

  if (error) {
    return <ErrorMessageCard error={error} />;
  }
  if (!isLoaded || !data) {
    return <LoadedVercelEdgeConfigInner.LoadingSkeleton />;
  }

  return <LoadedVercelEdgeConfig data={data} />;
}

function LoadedVercelEdgeConfig({
  data,
}: {
  data: NonNullable<BusinessesQuery["primaryBusiness"]>;
}): React.ReactElement | null {
  const content = useHypertune().content().settings();
  const canEdit = canEditBusiness(data?.role);

  const projectIdToProject = useMemo(
    () =>
      Object.fromEntries(
        data.business.projects.map((project) => [
          project.id,
          { name: project.name, tokens: JSON.parse(project.tokensJson) },
        ])
      ),
    [data.business.projects]
  );
  const connections = useMemo(
    () =>
      JSON.parse(data.business.vercelConnectionsJson) as DbVercelConnection[],
    [data.business.vercelConnectionsJson]
  );

  const { data: storesQuery, loading } = useVercelEdgeConfigStoresQuery();

  const storeIdToStore = useMemo(
    () =>
      storesJsonToStoreIdToStoreMap(
        storesQuery?.primaryBusiness?.business?.vercelEdgeConfigStores || null
      ),
    [storesQuery?.primaryBusiness?.business?.vercelEdgeConfigStores]
  );

  if (!canEdit) {
    return (
      <ContainerWithLogo className="px-0 lg-h:px-0">
        <ErrorMessageCard
          className="mt-10"
          error={content.vercelUserPermissionsError({ fallback: "" })}
          action="home"
        />
      </ContainerWithLogo>
    );
  }

  if (loading || !storeIdToStore) {
    return <LoadedVercelEdgeConfigInner.LoadingSkeleton />;
  }

  return (
    <LoadedVercelEdgeConfigInner
      businessId={data.business.id}
      flagsSecret={data.business.vercelFlagsSecret ?? ""}
      connections={connections}
      storeIdToStore={storeIdToStore}
      projectIdToProject={projectIdToProject}
    />
  );
}

function LoadedVercelEdgeConfigInner({
  businessId,
  flagsSecret,
  connections,
  projectIdToProject,
  storeIdToStore,
}: {
  businessId: string;
  flagsSecret: string;
  connections: DbVercelConnection[];
  projectIdToProject: {
    [projectId: string]: { name: string; tokens: ProjectTokenMapWithMeta };
  };
  storeIdToStore: { [storeId: string]: VercelEdgeConfigStoreWithTokens };
}): React.ReactElement | null {
  const [, setSearchParams] = useSearchParams();

  const {
    loading: nextLoading,
    errorMessage,
    update: onNext,
  } = useUpdateVercelEdgeConnections(businessId);

  const [draftConnection, setDraftConnection] = useState<DbVercelConnection>(
    connections[0] ?? {
      projectId: null,
      vercelOwnerId: null,
      vercelEdgeConfigStoreId: null,
    }
  );

  const isValid =
    !!draftConnection.projectId &&
    !!draftConnection.vercelEdgeConfigStoreId &&
    !!draftConnection.vercelOwnerId;

  const envVars =
    draftConnection.projectId && draftConnection.vercelEdgeConfigStoreId
      ? getConnectionEnvVars({
          vercelEdgeConfigStoreId: draftConnection.vercelEdgeConfigStoreId,
          vercelStore: storeIdToStore[draftConnection.vercelEdgeConfigStoreId],
          projectId: draftConnection.projectId,
          project: projectIdToProject[draftConnection.projectId],
          flagsSecret,
        })
      : "";
  console.log("errorMessage", errorMessage);

  return (
    <Content
      projectDropdown={
        <ProjectDropdown
          businessId={businessId}
          selectedProjectId={draftConnection.projectId}
          setSelectedProjectId={(newProjectId) =>
            setDraftConnection({
              ...draftConnection,
              projectId: newProjectId,
            })
          }
          projectIdToProject={projectIdToProject}
          dropdownStyle={{ maxWidth: 181 }}
        />
      }
      storeDropdown={
        <VercelStoreDropdown
          businessId={businessId}
          selectedStoreId={draftConnection.vercelEdgeConfigStoreId}
          setSelectedStoreIdAndOwner={(update) =>
            setDraftConnection({
              ...draftConnection,
              ...update,
            })
          }
          storeIdToStore={storeIdToStore}
          dropdownStyle={{ maxWidth: 181 }}
        />
      }
      envVars={
        envVars && (
          <>
            <Label type="title4" className="mb-2 mt-6 text-tx-muted">
              Environment variables
            </Label>
            <EnvVars envVars={envVars} className="shadow-container" />
          </>
        )
      }
      controls={
        <Button
          size="2x-large"
          intent="primary"
          weight="filled"
          className="px-5"
          text="Copy env vars and continue"
          onClick={() =>
            onNext([draftConnection, ...connections.slice(1)], () => {
              // Set the search param so that we navigate to Vercel, when
              // the useEffect hook is triggered without triggering the blockers.
              navigator.clipboard.writeText(envVars).finally(() => {
                setSearchParams((searchParams) =>
                  updateSearchParamState(searchParams, {
                    searchParamName: "navigateToVercel",
                    value: true,
                  })
                );
              });
            })
          }
          disabled={!isValid}
          loading={nextLoading}
        />
      }
      errorMessage={errorMessage}
    />
  );
}

LoadedVercelEdgeConfigInner.LoadingSkeleton = function (): React.ReactElement {
  return (
    <Content
      projectDropdown={<Skeleton className="h-[34px] w-[181px] rounded-lg" />}
      storeDropdown={<Skeleton className="h-[34px] w-[181px] rounded-lg" />}
    />
  );
};

function Content({
  projectDropdown,
  storeDropdown,
  envVars,
  controls,
  errorMessage,
}: {
  projectDropdown: React.ReactNode;
  storeDropdown: React.ReactNode;
  envVars?: React.ReactNode;
  controls?: React.ReactNode;
  errorMessage?: string;
}): React.ReactElement | null {
  const content = useHypertune().content().settings();
  const title = content.vercelEdgeConfigSetupTitle({ fallback: "" });
  const subtitle = content.vercelEdgeConfigSetupSubtitle({ fallback: "" });

  return (
    <ContainerWithLogo className="px-0 md-h:mt-4 lg-h:px-0">
      <h1 className="text-center text-h1 font-semibold text-base-dark-grey">
        {title}
      </h1>
      {subtitle && (
        <h5 className="mt-3 text-center text-h5 font-[400] text-tx-muted">
          {subtitle}
        </h5>
      )}
      <div className="mt-[30px] flex w-full flex-col items-center">
        <div className="mb-8 w-[450px] rounded-lg border border-bd-darker shadow-elevated">
          <div className="flex flex-row items-center rounded-tl-lg rounded-tr-lg border-b border-bd-darker bg-bg-light px-[30px] py-8">
            <LogoContainer>
              <Logo size={40} />
            </LogoContainer>
            <Dot className="-ml-[6px]" selected visible />
            <Line className="" selected />
            <LinkIcon className="h-10 w-10 p-[10px]" visible />
            <Line className="" selected />
            <Dot className="-mr-[6px]" selected visible />
            <LogoContainer>
              <img src="/Vercel.svg" alt="" className="h-10 w-10" />
            </LogoContainer>
          </div>
          <div className=" p-6">
            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col gap-2">
                <Label type="title4" className="text-tx-muted">
                  Hypertune project
                </Label>
                {projectDropdown}
              </div>
              <div className="flex flex-col gap-2">
                <Label type="title4" className="text-tx-muted">
                  Vercel Edge Config store
                </Label>
                {storeDropdown}
              </div>
            </div>
            {envVars}
          </div>
        </div>
        <div className="flex flex-row gap-5">{controls}</div>
        {errorMessage && (
          <ErrorMessage
            errorMessage={errorMessage}
            hideIcon
            style={{ marginTop: 20 }}
          />
        )}
      </div>
    </ContainerWithLogo>
  );
}

function LogoContainer({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <div className="flex-shrink-0 rounded-xl border border-bd-darker bg-white p-5 shadow-container">
      {children}
    </div>
  );
}
