import { Location } from "react-router-dom";

export function locationStateWithEmail(location: Location, email: string): any {
  return {
    ...(location.state || {}),
    email,
  };
}

export function getEmailFromLocation(location: Location): string {
  return location.state?.email ? (location.state.email as string) : "";
}

export function locationStateWithPrevious(location: Location): any {
  return {
    from: location,
  };
}

export function getPreviousFromLocation(
  location: Location
): Location | undefined {
  return location.state?.from as Location | undefined;
}
