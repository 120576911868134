import React, { useRef } from "react";
import { useFloating, autoUpdate, arrow } from "@floating-ui/react-dom";
import { twJoin } from "tailwind-merge";
import ErrorCircle from "../icons/ErrorCircle";
import TooltipArrow from "../icons/TooltipArrow";

export default function ErrorTooltip({
  position = "top",
  error,
  showDetailOnHover,
}: {
  position?: "top" | "top-end";
  error?: React.ReactNode;
  showDetailOnHover?: boolean;
}): React.ReactElement | null {
  const arrowRef = useRef(null);
  const errorTooltip = useFloating({
    placement: position,
    whileElementsMounted: autoUpdate,
    middleware: [
      arrow({
        element: arrowRef,
      }),
    ],
  });

  if (!error) {
    return null;
  }
  return (
    <div className="group">
      <span ref={errorTooltip.refs.setReference}>
        <ErrorCircle
          size={16}
          weight={showDetailOnHover ? "regular" : "fill"}
        />
      </span>
      <div
        ref={errorTooltip.refs.setFloating}
        style={errorTooltip.floatingStyles}
        className={twJoin(
          "flex flex-col items-center",
          showDetailOnHover && "hidden group-hover:block"
        )}
      >
        <div
          className={`mb-[7px] max-w-[240px] text-wrap rounded-[10px] border border-bd-darker
                      bg-white px-3 py-2 text-base font-medium leading-[16px] text-intent-danger 
                      ${position === "top-end" ? "-mr-3" : ""}`}
        >
          {error}
        </div>
        <div
          ref={arrowRef}
          style={{
            position: "absolute",
            left: errorTooltip.middlewareData.arrow?.x,
            top: errorTooltip.refs.floating.current?.clientHeight
              ? errorTooltip.refs.floating.current.clientHeight - 19
              : errorTooltip.middlewareData.arrow?.y,
          }}
        >
          <TooltipArrow className="-rotate-90" />
        </div>
      </div>
    </div>
  );
}
