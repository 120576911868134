import { nanoid } from "nanoid";
import { useState } from "react";
import { LinkSimple } from "@phosphor-icons/react";
import CopyableText from "../../../components/CopyableText";
import Modal from "../../../components/Modal";
import Button from "../../../components/buttons/Button";
import { useUpdateBusinessMutation } from "../../../generated/graphql";
import { businessManagementRefetchQueries } from "../../../lib/query/refetchQueries";
import ConditionalText from "../../../components/ConditionalText";
import { useHypertune } from "../../../generated/hypertune.react";

export default function InviteLinkButton({
  businessId,
  inviteToken,
}: {
  businessId: string;
  inviteToken: string;
}): React.ReactElement {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <>
      {isModalVisible ? (
        <InviteLinkModal
          businessId={businessId}
          inviteToken={inviteToken}
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      ) : null}
      <Button
        onClick={() => {
          setIsModalVisible(true);
        }}
        icon={<LinkSimple weight="bold" color="white" />}
        text="Invite link"
        intent="primary"
        weight="filled"
        size="large"
      />
    </>
  );
}

export function InviteLinkModal({
  businessId,
  inviteToken,
  onClose,
}: {
  businessId: string;
  inviteToken: string;
  onClose: () => void;
}): React.ReactElement | null {
  const content = useHypertune().content();
  const inviteURL = businessInviteLinkURL(inviteToken);
  const [updateBusiness, { loading }] = useUpdateBusinessMutation({
    refetchQueries: businessManagementRefetchQueries,
    awaitRefetchQueries: true,
  });

  return (
    <Modal title="Invite link" onClose={onClose}>
      <ConditionalText
        text={content.team().inviteLinkMessage({ fallback: "" })}
        className="mb-4"
      />
      <CopyableText text={inviteURL} />
      <Button
        text="Reset invite link"
        weight="outlined"
        size="large"
        loading={loading}
        onClick={() => {
          updateBusiness({
            variables: {
              input: {
                id: businessId,
                inviteToken: nanoid(),
              },
            },
          }).catch((error) => console.error("[updateBusiness] error", error));
        }}
        className="mb-2 mt-4"
      />
    </Modal>
  );
}

export function businessInviteLinkURL(inviteToken: string): string {
  return `${window.location.origin}/invites/${encodeURIComponent(inviteToken)}`;
}
