import { blueHex, whiteHex } from "../../lib/constants";

export default function Flag({
  size = 16,
  color,
}: {
  size?: number;
  color?: string;
}): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="6" fill={color || blueHex} />
      <rect x="4" y="4" width="8" height="6" rx="1" fill={whiteHex} />
      <rect x="4" y="5" width="2" height="8" rx="1" fill={whiteHex} />
    </svg>
  );
}
