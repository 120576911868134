import { Expression } from "@hypertune/sdk/src/shared";

export default function getExpressionChildren(
  expression: Expression
): (Expression | null)[] {
  switch (expression.type) {
    case "NoOpExpression":
    case "BooleanExpression":
    case "IntExpression":
    case "FloatExpression":
    case "StringExpression":
    case "RegexExpression":
    case "EnumExpression":
      return [];

    case "ObjectExpression":
      return Object.values(expression.fields);

    case "GetFieldExpression":
      return [expression.object];

    case "UpdateObjectExpression":
      return [expression.object, ...Object.values(expression.updates)];

    case "ListExpression":
      return expression.items;

    case "SwitchExpression":
      return [
        expression.control,
        ...expression.cases.flatMap((item) => [item.then, item.when]),
        expression.default,
      ];

    case "EnumSwitchExpression":
      return [expression.control, ...Object.values(expression.cases)];

    case "ComparisonExpression":
    case "ArithmeticExpression":
      return [expression.a, expression.b];

    case "RoundNumberExpression":
    case "StringifyNumberExpression":
      return [expression.number];

    case "StringConcatExpression":
      return [expression.strings];

    case "GetUrlQueryParameterExpression":
      return [expression.url, expression.queryParameterName];

    case "SplitExpression":
      return [
        expression.expose,
        expression.unitId,
        ...(expression.dimensionMapping.type === "discrete"
          ? Object.values(expression.dimensionMapping.cases)
          : [expression.dimensionMapping.function]),
        ...Object.values(expression.featuresMapping),
      ];

    case "LogEventExpression":
      return [expression.unitId, ...Object.values(expression.featuresMapping)];

    case "FunctionExpression":
      return [expression.body];

    case "VariableExpression":
      return [];

    case "ApplicationExpression":
      return [expression.function, ...expression.arguments];

    default: {
      const neverExpression: never = expression;
      throw new Error(
        `unexpected expression: ${JSON.stringify(neverExpression)}`
      );
    }
  }
}
