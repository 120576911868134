import React, { useState } from "react";
import {
  DbVercelConnection,
  VercelEdgeConfigStoreWithTokens,
} from "@hypertune/shared-internal";
import {
  ArrowUpRight,
  ClipboardText,
  Pencil,
  Plus,
  Trash,
} from "@phosphor-icons/react";
import { uniqueId } from "@hypertune/sdk/src/shared";
import { BusinessesQuery } from "../../../generated/graphql";
import ConnectionEditModal, {
  getConnectionEnvVars,
  getStoreLabel,
} from "./ConnectionEditModal";
import { canEditProject } from "../../../lib/query/rolePermissions";
import CardGroup from "../../../components/CardGroup";
import Label from "../../../components/Label";
import Menu from "../../../components/Menu";
import { intentDangerHex, whiteHex } from "../../../lib/constants";
import ModalWithContent from "../../../components/ModalWithContent";
import { useHypertune } from "../../../generated/hypertune.react";
import ErrorMessage from "../../../components/ErrorMessage";
import { Intent } from "../../../components/intent";
import Button from "../../../components/buttons/Button";
import ButtonLink from "../../../components/buttons/ButtonLink";
import { useUpdateVercelEdgeConnections } from "./hooks";

export default function ConfigureIntegration({
  projectId,
  connections,
  data,
  storeIdToStore,
}: {
  projectId?: string;
  connections: DbVercelConnection[];
  data: NonNullable<BusinessesQuery["primaryBusiness"]>;
  storeIdToStore: { [storeId: string]: VercelEdgeConfigStoreWithTokens };
}): React.ReactElement {
  const content = useHypertune().content().settings();
  const canEdit = canEditProject(data.role);

  const [connectionToDeleteIndex, setConnectionToDeleteIndex] = useState<
    number | null
  >(null);
  const [connectionToEditIndex, setConnectionToEditIndex] = useState<
    number | null
  >(null);

  const {
    loading,
    errorMessage,
    update: onSave,
  } = useUpdateVercelEdgeConnections(data.id);

  const projectIdToProject = Object.fromEntries(
    data.business.projects.map((project) => [
      project.id,
      { name: project.name, tokens: JSON.parse(project.tokensJson) },
    ])
  );
  const hideProjectSelector = !!projectId;
  const showConnections = projectId
    ? connections.filter(
        (connection) => connection.projectId?.toString() === projectId
      )
    : connections;

  return (
    <>
      <Header
        projectId={projectId}
        onNewConnectionClick={
          Object.keys(storeIdToStore).length > 0
            ? () => setConnectionToEditIndex(connections.length)
            : undefined
        }
        hideButton={!canEdit}
      />
      <p className="mb-5 text-tx-muted">
        Sync Hypertune to Vercel Edge Config by adding a connection below.
      </p>
      <CardGroup
        layout="list"
        cardLayout="horizontal-with-icon"
        cards={showConnections.map((connection, index) => {
          const project = connection.projectId
            ? projectIdToProject[connection.projectId]
            : null;
          const vercelStore = connection.vercelEdgeConfigStoreId
            ? storeIdToStore[connection.vercelEdgeConfigStoreId] || null
            : null;
          const hasError =
            connection.vercelEdgeConfigStoreId !== null && vercelStore === null;

          return {
            key: `vercel-connection-${index}`,
            children: (
              <>
                <img src="/Vercel.svg" alt="Vercel logo" />
                <div className="flex flex-col gap-[6px]">
                  <div className="flex flex-row items-center gap-2">
                    <Label type="title3">Vercel Edge Config connection</Label>
                    {hasError && (
                      <ErrorMessage
                        errorMessage={content.vercelErrorMessage({
                          fallback: "",
                        })}
                        showOnHover
                      />
                    )}
                  </div>
                  <div className="flex flex-row gap-4">
                    {!hideProjectSelector && (
                      <Label type="title4" className="text-sm">
                        <span className="text-tx-muted">Project: </span>
                        {project?.name || "Unknown project"}
                      </Label>
                    )}
                    <Label type="title4" className="text-sm">
                      <span className="text-tx-muted">Edge Config store: </span>
                      {getStoreLabel(vercelStore)}
                    </Label>
                  </div>
                </div>
                <Menu
                  itemsClassName="w-36"
                  items={[
                    ...(canEdit
                      ? [
                          {
                            icon: <Pencil />,
                            intent: "neutral" as Intent,
                            title: "Edit",
                            onClick: () => setConnectionToEditIndex(index),
                          },
                        ]
                      : []),
                    {
                      icon: <ClipboardText />,
                      intent: "neutral",
                      title: "Copy env vars",
                      onClick: () =>
                        navigator.clipboard
                          .writeText(
                            getConnectionEnvVars({
                              vercelEdgeConfigStoreId:
                                connection.vercelEdgeConfigStoreId,
                              vercelStore,
                              projectId: connection.projectId,
                              project,
                              flagsSecret:
                                data.business.vercelFlagsSecret ?? null,
                            })
                          )
                          .catch((error) =>
                            console.error(
                              "failed to copy env vars to clipboard",
                              error
                            )
                          ),
                    },
                    ...(canEdit
                      ? [
                          {
                            icon: <Trash weight="regular" />,
                            iconActive: (
                              <Trash weight="regular" color={intentDangerHex} />
                            ),
                            intent: "danger" as Intent,
                            title: "Remove",
                            onClick: () => setConnectionToDeleteIndex(index),
                          },
                        ]
                      : []),
                  ]}
                  className="border border-intent-neutral/20 shadow-button hover:border-intent-neutral/20 hover:bg-intent-neutral/5 hover:shadow-button"
                />
                <ButtonLink
                  href={`https://vercel.com/${connection.vercelOwnerId}/~/stores/edge-config/${connection.vercelEdgeConfigStoreId}`}
                  icon={<ArrowUpRight weight="regular" />}
                  weight="elevated"
                  text=""
                />
              </>
            ),
          };
        })}
      />
      {connectionToDeleteIndex !== null && (
        <ModalWithContent
          content={content.deleteVercelConnectionModal().get()}
          onSave={() =>
            onSave(
              [
                ...connections.slice(0, connectionToDeleteIndex),
                ...connections.slice(connectionToDeleteIndex + 1),
              ],
              () => setConnectionToDeleteIndex(null)
            )
          }
          saveLoading={loading}
          onClose={() => setConnectionToDeleteIndex(null)}
        >
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </ModalWithContent>
      )}
      {connectionToEditIndex !== null && (
        <ConnectionEditModal
          businessId={data.business.id}
          projectIdToProject={projectIdToProject}
          storeIdToStore={storeIdToStore}
          connection={
            connections[connectionToEditIndex] ?? {
              id: uniqueId(),
              projectId: projectId ?? null,
              vercelOwnerId: null,
              vercelEdgeConfigStoreId: null,
            }
          }
          flagsSecret={data.business.vercelFlagsSecret ?? null}
          onSave={(newConnection) =>
            onSave(
              [
                ...connections.slice(0, connectionToEditIndex),
                newConnection,
                ...connections.slice(connectionToEditIndex + 1),
              ],
              () => setConnectionToEditIndex(null)
            )
          }
          saving={loading}
          errorMessage={errorMessage}
          hideProjectSelector={hideProjectSelector}
          onClose={() => setConnectionToEditIndex(null)}
        />
      )}
      {}
    </>
  );
}

export function Header({
  projectId,
  onNewConnectionClick,
  hideButton,
}: {
  projectId?: string;
  onNewConnectionClick?: () => void;
  hideButton?: boolean;
}): React.ReactElement | null {
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <h3 className="mb-2 text-md font-semibold text-tx-default">
        Vercel Edge Config
      </h3>
      {hideButton ? null : onNewConnectionClick ? (
        projectId ? (
          <Button
            icon={<Plus weight="regular" color={whiteHex} />}
            text="New connection"
            weight="filled"
            intent="primary"
            onClick={onNewConnectionClick}
          />
        ) : (
          <Button
            icon={<Plus weight="regular" />}
            weight="elevated"
            onClick={onNewConnectionClick}
          />
        )
      ) : (
        <ButtonLink
          text="Set up Vercel integration"
          intent="primary"
          weight="filled"
          href="https://vercel.com/integrations/hypertune"
        />
      )}
    </div>
  );
}

ConfigureIntegration.LoadingSkeleton = function (): React.ReactElement {
  return (
    <CardGroup
      loadingCount={2}
      className="mt-[39.5px]"
      layout="list"
      cardLayout="horizontal-with-icon"
      cards={[]}
    />
  );
};
