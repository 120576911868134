import { DbBillingData, isValidEmail } from "@hypertune/shared-internal";
import { useCallback, useState } from "react";
import iso3311a2 from "iso-3166-1-alpha-2";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/input/TextInput";
import Dropdown from "../../../components/Dropdown";
import { useUpdateBillingDataMutation } from "../../../generated/graphql";
import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";

export default function UpdateBillingDetailsModal({
  businessId,
  billingData: initialBillingData,
  onClose,
}: {
  businessId: string;
  billingData: DbBillingData;
  onClose: () => void;
}): React.ReactElement | null {
  const [updateBillingData, { loading }] = useUpdateBillingDataMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });
  const [draftBillingData, setDraftBillingData] = useState(initialBillingData);
  const updateAddressField = useCallback(
    function updateFunction<
      Key extends keyof Required<DbBillingData>["address"],
    >(key: Key, value: Required<DbBillingData>["address"][Key]): void {
      setDraftBillingData((currentData) => ({
        ...currentData,
        address: {
          ...(currentData.address || {}),
          [key]: value,
        },
      }));
    },
    [setDraftBillingData]
  );

  const countryOptions = Object.entries(iso3311a2.getData())
    .filter(([, countryName]) => !!countryName)
    .map(([code, countryName]) => ({
      value: code,
      label: countryName as string,
    }));
  const countryValue =
    countryOptions.find(
      (option) => option.value === draftBillingData.address?.country
    ) ?? null;
  const isEmailValid = isValidEmail(draftBillingData.email ?? "");

  return (
    <Modal
      title="Update billing details"
      onClose={onClose}
      onSave={async () => {
        await updateBillingData({
          variables: {
            input: {
              businessId,
              billingDataJson: JSON.stringify(draftBillingData),
            },
          },
        });
        onClose();
      }}
      saveLoading={loading}
      saveText="Update"
      saveWeight="filled"
    >
      <TextInput
        label="Organization name"
        value={draftBillingData.name ?? ""}
        onChange={(name) => setDraftBillingData({ ...draftBillingData, name })}
        readOnly={false}
        style={{ marginBottom: 12 }}
      />
      <TextInput
        label="Billing email"
        value={draftBillingData.email ?? ""}
        onChange={(email) =>
          setDraftBillingData({ ...draftBillingData, email })
        }
        readOnly={false}
        style={{ marginBottom: 12 }}
        error={!isEmailValid ? "Invalid email address" : null}
      />
      <Dropdown
        label="Country"
        value={countryValue}
        options={{ type: "options", options: countryOptions }}
        onChange={(newOption) => {
          if (newOption) {
            updateAddressField("country", newOption.value);
          }
        }}
        placeholder="Select a country..."
        noOptionsMessage=""
        height={30}
        style={{ marginBottom: 12 }}
      />
      <TextInput
        label="Address line 1"
        placeholder="Street address"
        value={draftBillingData.address?.line1 ?? ""}
        onChange={(newLine1) => updateAddressField("line1", newLine1)}
        readOnly={false}
        style={{ marginBottom: 12 }}
      />
      <TextInput
        label="Address line 2"
        placeholder="Apt., suite, unit number, etc. (optional)"
        value={draftBillingData.address?.line2 ?? ""}
        onChange={(newLine2) => updateAddressField("line2", newLine2)}
        readOnly={false}
        style={{ marginBottom: 12 }}
      />
      <TextInput
        label="Town or city"
        value={draftBillingData.address?.city ?? ""}
        onChange={(newCity) => updateAddressField("city", newCity)}
        readOnly={false}
        style={{ marginBottom: 12 }}
      />
      <TextInput
        label="Postal code"
        value={draftBillingData.address?.postal_code ?? ""}
        onChange={(newPostalCode) =>
          updateAddressField("postal_code", newPostalCode)
        }
        readOnly={false}
        style={{ marginBottom: 12 }}
      />
    </Modal>
  );
}
