import { ComparisonExpression, uniqueId } from "@hypertune/sdk/src/shared";

export default function getComparisonExpression(): ComparisonExpression {
  const expression: ComparisonExpression = {
    id: uniqueId(),
    type: "ComparisonExpression",
    valueType: { type: "BooleanValueType" },
    operator: null,
    a: null,
    b: null,
  };
  return expression;
}
