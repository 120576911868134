import {
  borderDisabledGrey,
  lighterGreyHex,
  normalFontWeight,
} from "../../lib/constants";

export default function Divider(): React.ReactElement {
  return (
    <div
      style={{
        marginBottom: 24,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        style={{ flexGrow: 1, height: 1, backgroundColor: borderDisabledGrey }}
      />
      <div
        style={{
          margin: "0 8px",
          color: lighterGreyHex,
          fontSize: 12,
          fontWeight: normalFontWeight,
        }}
      >
        OR
      </div>
      <div
        style={{ flexGrow: 1, height: 1, backgroundColor: borderDisabledGrey }}
      />
    </div>
  );
}
