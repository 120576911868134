import { useState, useMemo } from "react";
import { EditTracking } from "../../lib/types";
import TextArea from "./TextArea";
import twMerge from "../../lib/twMerge";

// TODO: this can maybe be unified with MutableText
export default function MutableTextArea({
  readOnly,
  placeholder,
  text,
  setText,
  editTracking,
  className,
}: {
  readOnly?: boolean;
  placeholder: string;
  text: string;
  setText?: (newText: string) => void;
  editTracking?: EditTracking;
  className?: string;
}): React.ReactElement {
  const [draft, _setDraft] = useState<string | null>(null);

  const setDraft = useMemo(
    () => (newDraft: string | null) => {
      _setDraft(newDraft);
      if (editTracking) {
        editTracking.track((previous) => {
          if (newDraft !== null && !previous.has(editTracking.id)) {
            return new Set([...previous, editTracking.id]);
          }
          if (newDraft === null && previous.has(editTracking.id)) {
            return new Set(
              [...previous].filter((id) => id !== editTracking.id)
            );
          }
          return previous;
        });
      }
    },
    [editTracking]
  );

  return (
    <>
      {!readOnly && setText && draft !== null ? (
        <TextArea
          readOnly={false}
          focusOnMount
          shiftForNewLine
          placeholder={placeholder}
          value={draft}
          setValue={setDraft}
          onBlur={() => {
            setText(draft);
            setDraft(null);
          }}
          onEsc={() => setDraft(null)}
          minWidth={0}
          className={twMerge(
            "focus:border-base-blue focus:shadow-inputs",
            className || ""
          )}
        />
      ) : (
        <p
          className={twMerge(
            `min-h-[34px] whitespace-pre-wrap rounded-lg border border-transparent px-2 py-[6px] text-tx-muted
                      ${
                        !readOnly && setText
                          ? "cursor-pointer hover:border-base-blue"
                          : ""
                      }`,
            className || ""
          )}
          onClick={() => setDraft(text)}
        >
          {text || placeholder}
        </p>
      )}{" "}
    </>
  );
}
