import { CaretDown } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import { iconColor } from "../icons/icons";
import Button from "./Button";
import twMerge from "../../lib/twMerge";

export default function CollapseButton({
  isOpen,
  setOpen,
  isSelected,
  disabled,
}: {
  isOpen: boolean;
  setOpen: (newOpen: boolean) => void;
  isSelected?: boolean;
  disabled?: boolean;
}): React.ReactElement | null {
  return (
    <Button
      intent={isSelected ? "primary" : "neutral"}
      size="x-small"
      icon={<CollapseIcon isOpen={isOpen} isSelected={isSelected} />}
      onClick={() => setOpen(!isOpen)}
      disabled={disabled}
    />
  );
}

export function CollapseIcon({
  isOpen,
  size = 12,
  color,
  isSelected,
  className,
}: {
  isOpen: boolean;
  size?: number;
  color?: string;
  isSelected?: boolean;
  className?: string;
}): React.ReactElement | null {
  return (
    <motion.div
      className={twMerge("self-center", className)}
      initial={isOpen ? "open" : "closed"}
      animate={isOpen ? "open" : "closed"}
      variants={{
        open: { rotate: 0 },
        closed: { rotate: -90 },
      }}
    >
      <CaretDown
        size={size}
        weight="bold"
        color={iconColor(!!isSelected) ?? color}
      />
    </motion.div>
  );
}
