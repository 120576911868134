import React, { useCallback } from "react";
import CloseButton from "./buttons/CloseButton";
import Button, { Weight } from "./buttons/Button";
import { Intent } from "./intent";
import twMerge from "../lib/twMerge";

export type ModalStyle = "compact" | "medium" | "large";

const buttonSizeClassName = {
  stretch: "auto-cols-fr",
  end: "auto-cols-max",
} as const;
export type ModalButtonLayout = keyof typeof buttonSizeClassName;

export default function Modal({
  title,

  children,
  confirmClose,
  disableAwayClickClose,
  closeOnEsc,
  modalStyle = "compact",
  style,
  className,
  childrenStyle,
  buttonLayout = "stretch",
  onClose,
  closeText,
  closeWeight,
  closeLoading,

  saveText,
  saveLoadingText,
  saveIntent = "primary",
  saveWeight = "minimal",
  saveDisabled,
  saveLoading,
  onSave,
}: {
  title?: string | React.ReactElement;
  closeText?: string;
  closeWeight?: Weight;
  children: React.ReactNode;
  confirmClose?: boolean;
  disableAwayClickClose?: boolean;
  closeOnEsc?: boolean;
  modalStyle?: ModalStyle;
  style?: React.CSSProperties;
  className?: string;
  childrenStyle?: React.CSSProperties;
  buttonLayout?: ModalButtonLayout;
  onClose?: ((options?: { isAction?: boolean }) => void) | null;
  closeLoading?: boolean;

  saveText?: string;
  saveLoadingText?: string;
  saveIntent?: Intent;
  saveWeight?: Weight;
  saveDisabled?: boolean;
  saveLoading?: boolean;
  onSave?: (() => void) | null;
}): React.ReactElement {
  const onCloseWithConfirmation = useCallback(() => {
    if (!onClose) {
      return;
    }
    const confirmed =
      !confirmClose ||
      // eslint-disable-next-line no-alert
      window.confirm(
        "Are you sure you want to close this panel and lose your changes?"
      );
    if (confirmed) {
      onClose();
    }
  }, [onClose, confirmClose]);

  const actions = [
    ...(onClose && closeText
      ? [
          <Button
            weight={closeWeight}
            onClick={() => onClose({ isAction: true })}
            text={closeText}
            loading={closeLoading}
          />,
        ]
      : []),
    ...(onSave
      ? [
          <Button
            intent={saveIntent}
            weight={saveWeight}
            onClick={onSave}
            disabled={saveDisabled}
            loading={saveLoading}
            text={saveText || "Save"}
            loadingText={saveLoadingText}
          />,
        ]
      : []),
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-200 bg-bg-blurred text-base text-tx-default">
      <div
        className={`flex h-full w-full cursor-auto flex-col items-center justify-start ${
          modalStyle === "compact"
            ? "py-16 lg-h:py-52"
            : "py-4 md-h:py-16 lg-h:py-24"
        }`}
        onClick={
          onClose && !disableAwayClickClose
            ? (event) => {
                event.stopPropagation();
                onCloseWithConfirmation();
              }
            : undefined
        }
        onKeyDown={(event) => {
          if (closeOnEsc && event.key === "Escape") {
            onCloseWithConfirmation();
          }
        }}
      >
        <div
          className={twMerge(
            "flex min-w-[400px] max-w-[980px] flex-col items-stretch justify-start rounded-2xl border border-base-grey-1-medium bg-white",
            className
          )}
          style={style}
          onClick={(event) => event.stopPropagation()}
        >
          {title && (
            <div
              className={`flex flex-row items-center px-3 ${
                modalStyle === "large"
                  ? "border-b border-base-grey-1-medium py-2"
                  : "pt-2"
              }`}
            >
              <div className="flex-grow font-semibold">{title}</div>
              {onClose ? (
                <CloseButton onClick={onCloseWithConfirmation} />
              ) : null}
            </div>
          )}

          <div
            className="flex h-full flex-col items-stretch justify-start px-3 py-2"
            style={childrenStyle}
          >
            {children}
          </div>
          <div className="flex-grow" />
          {actions.length > 0 ? (
            <div
              className={twMerge(
                "grid grid-flow-col justify-end gap-2 px-3 py-2",
                buttonSizeClassName[buttonLayout]
              )}
            >
              {actions}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
