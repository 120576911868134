import { BusinessUserRole } from "../../generated/graphql";

export function canEditBusiness(role: BusinessUserRole | undefined): boolean {
  return role === BusinessUserRole.Admin;
}

export function canEditProject(role?: BusinessUserRole | undefined): boolean {
  return role === BusinessUserRole.Admin || role === BusinessUserRole.Editor;
}

export function canContributeToProject(
  role?: BusinessUserRole | undefined
): boolean {
  return (
    role === BusinessUserRole.Admin ||
    role === BusinessUserRole.Editor ||
    role === BusinessUserRole.Contributor
  );
}
