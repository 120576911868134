import { Label } from "../../features/project/expression/LabeledExpressionControlList";
import getTextWidth from "./getTextWidth";
import { interFontFamily, mediumFontSize } from "../constants";

export default function getTextLabel(text: string): Label {
  return {
    component: text,
    width: getTextWidth(interFontFamily, mediumFontSize, text),
  };
}
