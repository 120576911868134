import { Expression, Schema } from "@hypertune/sdk/src/shared/types";
import { FunnelFilter } from "@hypertune/shared-internal/src/types";
import { useCallback, useMemo, useState } from "react";
import getComparisonExpression from "@hypertune/shared-internal/src/expression/getComparisonExpression";
import getExpressionRecursiveErrorMessages from "@hypertune/shared-internal/src/expression/getExpressionRecursiveErrorMessages";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import { Pencil } from "@phosphor-icons/react";
import Label from "../../../../components/Label";
import Button from "../../../../components/buttons/Button";
import { intentPrimaryHex, plusSymbol } from "../../../../lib/constants";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import { CommitContext, ExpressionEditorState } from "../../../../lib/types";
import Modal from "../../../../components/Modal";
import ExpressionTree from "../../expression/ExpressionTree";
import ConfigurationContainer from "./ConfigurationContainer";
import TextInput from "../../../../components/input/TextInput";
import ErrorCircle from "../../../../components/icons/ErrorCircle";

export default function SelectFilter({
  meId,
  filter,
  stepType,
  schema,
  payloadObjectTypeName,
  setFilter,
  canEdit,
}: {
  meId: string;
  stepType: "FunnelEventStep" | "FunnelExposureStep";
  payloadObjectTypeName: string | null;
  schema: Schema;
  filter?: FunnelFilter;
  setFilter: (newFilter: FunnelFilter | undefined) => void;
  canEdit: boolean;
}): React.ReactElement | null {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const commitContext: CommitContext = useMemo(() => {
    return getFilterCommitContext(schema, stepType, payloadObjectTypeName);
  }, [schema, stepType, payloadObjectTypeName]);

  const hasError = useMemo(
    () => filterHasExpressionError(commitContext, filter),
    [commitContext, filter]
  );

  return (
    <ConfigurationContainer title="Filter">
      <div className="flex flex-row items-center gap-1">
        {filter && (
          <DeleteButton size="x-small" onClick={() => setFilter(undefined)} />
        )}
        {filter && (
          <Button
            intent="primary"
            weight="minimal"
            size="x-small"
            disabled={!canEdit && !filter}
            icon={<Pencil color={intentPrimaryHex} size={12} />}
            onClick={() => setIsModalVisible(true)}
          />
        )}
        {filter?.description && (
          <Label type="title4" className="break-all">
            {filter?.description}
          </Label>
        )}
        {hasError && <ErrorCircle />}
      </div>
      {!filter && (
        <Button
          intent="primary"
          weight="minimal"
          size="x-small"
          disabled={!canEdit && !filter}
          icon={plusSymbol}
          text="Filter"
          onClick={() => setIsModalVisible(true)}
        />
      )}
      <div className="h-2" />
      {isModalVisible && (
        <FilterModal
          meId={meId}
          readOnly={!canEdit}
          commitContext={commitContext}
          filter={filter}
          setFilter={setFilter}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </ConfigurationContainer>
  );
}

const funnelStepArgsObjectTypeName = "__Funnel_step_args";
const funnelVariables: VariableMap = {
  funnelStepArgs: {
    id: "funnelStepArgs",
    name: "args",
    valueType: {
      type: "ObjectValueType",
      objectTypeName: funnelStepArgsObjectTypeName,
    },
  },
};

function FilterModal({
  meId,
  readOnly,
  commitContext,
  filter,
  setFilter,
  onClose,
}: {
  meId: string;
  readOnly: boolean;
  commitContext: CommitContext;
  filter?: FunnelFilter;
  setFilter: (newFilter: FunnelFilter | undefined) => void;
  onClose: () => void;
}): React.ReactElement | null {
  const [description, setDescription] = useState<string>(
    filter?.description ?? ""
  );
  const [editorState, setEditorState] = useState<ExpressionEditorState>({
    selectedItem: null,
    collapsedExpressionIds: {},
  });
  const [draftExpression, _setDraftExpression] = useState<Expression | null>(
    filter?.expression ?? getComparisonExpression()
  );
  const setDraftExpression = useCallback(
    (newDraftExpression: Expression | null) =>
      _setDraftExpression(
        !newDraftExpression ? getComparisonExpression() : newDraftExpression
      ),
    [_setDraftExpression]
  );

  const expressionErrors = getExpressionRecursiveErrorMessages(
    commitContext.schema,
    commitContext.splits,
    funnelVariables,
    { type: "BooleanValueTypeConstraint" },
    null,
    draftExpression
  );
  const hasError = !description
    ? true
    : draftExpression
      ? filterHasExpressionError(commitContext, {
          type: "ExpressionFilter",
          description,
          expression: draftExpression,
        })
      : true;
  console.debug("filter", { expressionErrors, hasErrors: hasError });

  const onSave = useCallback(() => {
    if (hasError) {
      return;
    }
    setFilter(
      draftExpression
        ? {
            type: "ExpressionFilter",
            expression: draftExpression,
            description,
          }
        : undefined
    );
    onClose();
  }, [onClose, setFilter, description, draftExpression, hasError]);

  return (
    <Modal
      title="Configure filter"
      onClose={onClose}
      saveWeight="filled"
      onSave={onSave}
      saveDisabled={hasError}
      style={{ maxHeight: "900px", overflow: "hidden" }}
      childrenStyle={{ overflow: "auto", paddingBottom: 150 }}
    >
      <TextInput
        readOnly={readOnly}
        value={description}
        onChange={setDescription}
        placeholder="Add name for this filter"
        onEnter={onSave}
        label="Name"
        labelVariant="muted"
        style={{ marginBottom: 12 }}
        focusOnMount
      />
      <Label type="title4" className="mt-3 text-tx-muted">
        Filter logic
      </Label>
      <ExpressionTree
        className="w-full px-0 py-2"
        context={{
          meId,
          commitContext,
          evaluations: {},
          expressionEditorState: editorState,
          setExpressionEditorState: setEditorState,
          ignoreErrors: false,
          readOnly,
          expandByDefault: true,
          disableVariableCreation: true,
          disablePermissionsManagement: true,
          fullFieldPath: "",
          resolvedPermissions: {
            user: {},
            group: { team: { write: "allow" } },
          },
          includeComparisonOperator: (operator) =>
            operator !== "matches" && operator !== "notMatches",
        }}
        variables={funnelVariables}
        setVariableName={{}}
        valueTypeConstraint={{ type: "BooleanValueTypeConstraint" }}
        expression={draftExpression}
        setExpression={setDraftExpression}
        parentExpression={null}
        lift={() => {
          // noop
        }}
        includeExpressionOption={({ expressionOption }) => {
          if (
            expressionOption.type === "SwitchExpression" ||
            expressionOption.type === "SplitExpression" ||
            expressionOption.type === "EnumSwitchExpression" ||
            expressionOption.type === "GetUrlQueryParameterExpression" ||
            (expressionOption.type === "GetFieldExpression" &&
              expressionOption.valueType.type === "ListValueType")
          ) {
            return false;
          }
          return true;
        }}
      />
    </Modal>
  );
}

export function getFilterCommitContext(
  schema: Schema,
  stepType: "FunnelEventStep" | "FunnelExposureStep",
  payloadObjectTypeName: string | null
): CommitContext {
  return {
    schema: {
      ...schema,
      objects: {
        ...schema.objects,
        [funnelStepArgsObjectTypeName]: {
          role: "args",
          description: null,
          fields: {
            ...(stepType === "FunnelExposureStep"
              ? {
                  unitId: {
                    description: null,
                    valueType: { type: "StringValueType" },
                  },
                }
              : {}),
            ...(payloadObjectTypeName
              ? {
                  payload: {
                    description: null,
                    valueType: {
                      type: "ObjectValueType",
                      objectTypeName: payloadObjectTypeName,
                    },
                  },
                }
              : {}),
          },
        },
      },
    },
    splits: {},
    setSplits: () => {
      // noop
    },
    eventTypes: {},
  };
}

export function filterHasExpressionError(
  commitContext: CommitContext,
  filter: FunnelFilter | undefined
): boolean {
  if (!filter) {
    return false;
  }
  const expressionErrors = getExpressionRecursiveErrorMessages(
    commitContext.schema,
    commitContext.splits,
    funnelVariables,
    { type: "BooleanValueTypeConstraint" },
    null,
    filter.expression
  );
  return expressionErrors.length > 0;
}
