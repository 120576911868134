import { Permissions, Expression } from "@hypertune/sdk/src/shared";
import { teamGroupId } from "./constants";
import isExpressionEqualExceptIdAndChildren from "./isExpressionEqualExceptIdAndChildren";
import getExpressionChildren from "./getExpressionChildren";

// This makes the assumption that we only ever have allows, and don't reset the
// permissions part way down the tree, or equivalently that permissions are more
// permissive down the tree.
export function resolvePermissions(
  parent: Permissions,
  current?: Permissions
): Permissions {
  if (!current) {
    return parent;
  }

  return {
    group: { ...parent.group, ...current.group },
    user: { ...parent.user, ...current.user },
  };
}

export function getEmptyPermissions(): Permissions {
  return { group: {}, user: {} };
}

export function isEmptyPermissions(
  permissions: Permissions | undefined
): boolean {
  if (!permissions) {
    return true;
  }
  return (
    Object.keys(permissions.user).length === 0 &&
    Object.keys(permissions.group).length === 0
  );
}

export function canWrite(
  userId: string,
  resolvedPermissions: Permissions
): boolean {
  // We can write if we're explicitly allowed
  return (
    resolvedPermissions.group[teamGroupId]?.write === "allow" ||
    resolvedPermissions.user[userId]?.write === "allow"
  );
}

export function isAllowed(
  userId: string,
  parentPermissions: Permissions,
  original: Expression | null,
  updated: Expression | null
): boolean {
  // This helps improve performance on the frontend, where due to our use of
  // immutable data structures it's likely we reuse expression objects.
  if (original === updated) {
    return true;
  }

  const perms = resolvePermissions(
    parentPermissions,
    original?.metadata?.permissions
  );
  if (canWrite(userId, perms)) {
    return true;
  }

  if (!original || !updated) {
    return false;
  }

  if (!isExpressionEqualExceptIdAndChildren(original, updated)) {
    return false;
  }

  const originalChildren = getExpressionChildren(original);
  const updatedChildren = getExpressionChildren(updated);
  return (
    // Check we have the same number of children...
    originalChildren.length === updatedChildren.length &&
    // ...in the same place with only allowed edits
    originalChildren.every((child, i) =>
      isAllowed(userId, perms, child, updatedChildren[i])
    )
  );
}
