import { Expression } from "@hypertune/sdk/src/shared";
import React from "react";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/input/TextInput";

export default function ExpressionNoteModal({
  expression,
  setExpression,
  onClose,
}: {
  expression: Expression;
  setExpression: (newExpression: Expression | null) => void;
  onClose: () => void;
}): React.ReactElement {
  const [note, setNote] = React.useState<string>(
    expression.metadata?.note ?? ""
  );

  function modalOnSave(): void {
    setExpression({
      ...expression,
      metadata: { ...expression.metadata, note },
    });
    onClose();
  }

  return (
    <Modal
      className="text-tx-default"
      style={{ overflow: "initial" }}
      title="Add Note"
      onClose={onClose}
      onSave={modalOnSave}
      confirmClose={note !== expression.metadata?.note}
    >
      <TextInput
        style={{ marginBottom: 12 }}
        value={note}
        trimOnBlur
        focusOnMount
        placeholder="Enter note here"
        readOnly={false}
        onChange={(newValue) => {
          setNote(newValue);
        }}
        onEnter={modalOnSave}
      />
    </Modal>
  );
}
