import { SmileyXEyes } from "@phosphor-icons/react";
import ContainerWithLogo from "../components/container/ContainerWithLogo";
import { useHypertune } from "../generated/hypertune.react";

export default function Maintenance(): React.ReactElement | null {
  const hypertune = useHypertune();
  return (
    <ContainerWithLogo>
      <div className="flex h-full flex-col items-center gap-4">
        <SmileyXEyes size={254} />
        <p className="text-center">
          {hypertune.maintenanceMode().message({
            fallback: "We are experiencing issues. Please come back later.",
          })}
        </p>
      </div>
    </ContainerWithLogo>
  );
}
