import { VariableMap } from "./types";

export default function getVariableNames(variables: VariableMap): {
  [variableName: string]: boolean;
} {
  const variableNames: { [variableName: string]: boolean } = {};

  Object.values(variables).forEach((variable) => {
    variableNames[variable.name] = true;
  });

  return variableNames;
}
