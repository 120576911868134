import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "./hooks";
import { MeQuery, MeQueryVariables, MeDocument } from "../generated/graphql";
import getApolloClient from "../lib/query/getApolloClient";
import { setIntercomInitialized } from "./appSlice";

export default function useIntercom(): {
  showIntercom: { (): Promise<void>; loading: boolean };
  updateIntercom: () => void;
  shutdownIntercom: () => void;
} {
  const dispatch = useAppDispatch();
  const [isInitializing, setIsInitializing] = useState<boolean | null>(null);
  const initialized = useAppSelector((state) => state.app.intercomInitialized);

  const boot = useCallback(async () => {
    setIsInitializing(null);
    setTimeout(() => {
      // Only set loading to true if we haven't finished after 150ms.
      setIsInitializing((isLoading) => isLoading === null);
    }, 150);

    const res = await getApolloClient().query<MeQuery, MeQueryVariables>({
      query: MeDocument,
      fetchPolicy: "network-only",
    });
    if (res.error) {
      console.error("[bootIntercom] Failed to retrieve user from backend");
      setIsInitializing(null);
      return false;
    }
    const backendUser = res.data.me;

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "i4cn2moq",
      user_id: backendUser.id,
      name: backendUser.displayName,
      email: backendUser.email,
      user_hash: backendUser.intercomToken,
      hide_default_launcher: true,
    });
    // Wait for Intercom to boot (max 30 seconds)
    const timeout = setTimeout(() => clearInterval(interval), 30000);
    const interval = setInterval(() => {
      if (window.Intercom && window.Intercom.booted) {
        // Intercom has booted.
        clearInterval(interval);
        clearTimeout(timeout);
        dispatch(setIntercomInitialized(true));
      }
    }, 100);
    await interval;
    setIsInitializing(false);

    return true;
  }, [dispatch]);

  const show = useCallback(async () => {
    if (isInitializing) {
      return;
    }
    if (!initialized) {
      const booted = await boot();
      if (!booted) {
        return;
      }
    }
    window.Intercom("show");
  }, [boot, initialized, isInitializing]);

  const update = useCallback(() => {
    if (initialized) {
      window.Intercom("update");
    }
  }, [initialized]);

  const shutdown = useCallback(() => {
    if (initialized) {
      window.Intercom("shutdown");
      dispatch(setIntercomInitialized(false));
    }
  }, [dispatch, initialized]);

  // eslint-disable-next-line func-style
  const showIntercom = (): Promise<void> => show();
  showIntercom.loading = !!isInitializing;

  return {
    showIntercom,
    updateIntercom: update,
    shutdownIntercom: shutdown,
  };
}
