import {
  Eye,
  GitPullRequest,
  PencilSimple,
  ShieldCheck,
} from "@phosphor-icons/react";
import TopBarDropdown, {
  LabeledOption,
} from "../../../components/TopBarDropdown";
import { BusinessUserRole } from "../../../generated/graphql";
import { intentPrimaryHex } from "../../../lib/constants";
import { useHypertune } from "../../../generated/hypertune.react";
import twMerge from "../../../lib/twMerge";

const allRoleOptions: LabeledOption<BusinessUserRole>[] = [
  {
    value: BusinessUserRole.Admin,
    label: BusinessUserRole.Admin,
    icon: <ShieldCheck weight="regular" />,
    selectedIcon: <ShieldCheck weight="regular" color={intentPrimaryHex} />,
    showIconWhenSelected: true,
  },
  {
    value: BusinessUserRole.Editor,
    label: BusinessUserRole.Editor,
    icon: <PencilSimple weight="regular" />,
    selectedIcon: <PencilSimple weight="regular" color={intentPrimaryHex} />,
    showIconWhenSelected: true,
  },
  {
    value: BusinessUserRole.Contributor,
    label: BusinessUserRole.Contributor,
    icon: <GitPullRequest weight="regular" />,
    selectedIcon: <GitPullRequest weight="regular" color={intentPrimaryHex} />,
    showIconWhenSelected: true,
  },
  {
    value: BusinessUserRole.Viewer,
    label: BusinessUserRole.Viewer,
    icon: <Eye weight="regular" />,
    selectedIcon: <Eye weight="regular" color={intentPrimaryHex} />,
    showIconWhenSelected: true,
  },
];

export default function RoleDropDown({
  role,
  changeRole,
  loading,
  readOnly,
  muted,
}: {
  role: BusinessUserRole;
  changeRole: (newRole: BusinessUserRole) => Promise<unknown>;
  loading: boolean;
  readOnly: boolean;
  muted?: boolean;
}): React.ReactElement | null {
  const branchingEnabled = useHypertune()
    .features()
    .branchingEnabled({ fallback: false });
  const availableOptions = !branchingEnabled
    ? allRoleOptions.filter(
        (option) => option.value !== BusinessUserRole.Contributor
      )
    : allRoleOptions;

  return (
    <TopBarDropdown<BusinessUserRole>
      options={{
        type: "options",
        options: availableOptions,
      }}
      value={availableOptions.find((option) => option.value === role) ?? null}
      onChange={(option) => {
        if (option) {
          changeRole(option.value).catch((error) =>
            console.error("failed to change role", {
              role,
              newRole: option.value,
              error,
            })
          );
        }
      }}
      placeholder="No role"
      dropdownStyle={{
        caret: "down",
        muted: muted ? "all" : "none",
        hideSearch: true,
        buttonClassName: twMerge(
          "py-[5.5px] font-medium rounded-[8px] border border-transparent",
          !readOnly ? " hover:bg-white hover:border-bd-darker" : undefined
        ),
        panelClassName: "-mt-4 data-top:mt-4 data-top:-mb-4",
        optionClassName: "font-medium",
      }}
      isLoading={loading}
      readOnly={readOnly}
    />
  );
}
