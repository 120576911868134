export default function getNextName(
  existingNames: { [name: string]: boolean },
  baseName: string
): string {
  let index = 1;
  function getName(i: number): string {
    return `${baseName}${i > 1 ? i : ""}`;
  }
  while (existingNames[getName(index)]) {
    index += 1;
  }
  return getName(index);
}
