export default function prefixError<T>(block: () => T, prefix: string): T {
  try {
    return block();
  } catch (error) {
    if (error instanceof Error) {
      error.message = prefix + error.message;
      throw error;
    }
    throw new Error(prefix + JSON.stringify(error));
  }
}
