import React, { useState } from "react";
import { UserPlus } from "@phosphor-icons/react";
import { ProjectTokenMapWithMeta } from "@hypertune/shared-internal";
import CopyableText from "../../../components/CopyableText";
import WebhookSettingsEditor from "./WebhookSettingsEditor";
import MutableText from "../../../components/input/MutableText";
import {
  BusinessesDocument,
  ProjectDocument,
  useBusinessesQuery,
  useUpdateProjectMutation,
} from "../../../generated/graphql";
import env from "../../../environment";
import VercelEdgeConfig from "../../business/vercel/VercelEdgeConfig";
import CenteredContainer, {
  CenteredContainerHeader,
} from "../../../components/container/CenteredContainer";
import CardGroup from "../../../components/CardGroup";
import Button from "../../../components/buttons/Button";
import { whiteHex } from "../../../lib/constants";
import NewBusinessModal from "../../business/team/NewBusinessModal";
import { getAllToken } from "../../../lib/tokens";
import TokensEditor from "./TokensEditor";

export default function SettingsEditor({
  isPersonal,
  projectName,
  isVisible,
  projectId,
  projectTokens,
  canEdit,
}: {
  isPersonal: boolean;
  projectName: string;
  isVisible: boolean;
  projectId: string;
  projectTokens: ProjectTokenMapWithMeta;
  canEdit: boolean;
}): React.ReactElement {
  const [updateProject] = useUpdateProjectMutation({
    refetchQueries: [BusinessesDocument, ProjectDocument],
    awaitRefetchQueries: true,
  });
  const { data } = useBusinessesQuery();
  const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);

  const allToken = getAllToken(projectTokens) ?? "YOUR_ADMIN_PROJECT_TOKEN";

  return (
    <CenteredContainer hidden={!isVisible}>
      <CenteredContainerHeader title="Project settings">
        {isPersonal && canEdit && (
          <>
            <Button
              intent="primary"
              weight="filled"
              icon={<UserPlus color={whiteHex} weight="regular" />}
              text="Share"
              onClick={() => setShowNewBusinessModal(true)}
            />
            {showNewBusinessModal && (
              <NewBusinessModal
                initialTransferProjects={[{ id: projectId, name: projectName }]}
                onClose={() => setShowNewBusinessModal(false)}
              />
            )}
          </>
        )}
      </CenteredContainerHeader>

      <CardGroup
        layout="list"
        cardLayout="horizontal"
        cards={[
          {
            key: "name",
            className: "items-start",
            children: (
              <>
                <div style={{ fontWeight: "bold" }}>Project name</div>
                <div className="flex flex-row justify-between">
                  <MutableText
                    text={projectName}
                    setText={async (newName) => {
                      try {
                        await updateProject({
                          variables: {
                            input: { id: projectId, name: newName },
                          },
                        });
                      } catch (error) {
                        console.error("updateProject error:", error);
                      }
                    }}
                    showPencil
                  />
                </div>
              </>
            ),
          },
          {
            key: "endpoint",
            className: "items-start",
            children: (
              <>
                <div style={{ marginBottom: 8, fontWeight: "bold" }}>
                  GraphQL endpoint
                </div>
                <div>
                  <CopyableText
                    style={{ width: 390 }}
                    text={`${env.EDGE_CDN_BASE_URL}/graphql`}
                  />
                </div>
              </>
            ),
          },
          {
            key: "schema",
            className: "items-start",
            children: (
              <>
                <div style={{ marginBottom: 8, fontWeight: "bold" }}>
                  GraphQL query schema
                </div>
                <div>
                  <CopyableText
                    style={{ width: 390 }}
                    text={`${
                      env.EDGE_CDN_BASE_URL
                    }/schema?token=${encodeURIComponent(allToken)}`}
                  />
                </div>
              </>
            ),
          },
          {
            key: "initialization",
            className: "items-start",
            children: (
              <>
                <div style={{ marginBottom: 8, fontWeight: "bold" }}>
                  GraphQL initialization query schema
                </div>
                <div>
                  <CopyableText
                    style={{ width: 390 }}
                    text={`${
                      env.EDGE_CDN_BASE_URL
                    }/schema?body={"optionalInputTypes":true}&token=${encodeURIComponent(
                      allToken
                    )}`}
                  />
                </div>
              </>
            ),
          },
        ]}
      />

      <TokensEditor
        projectId={projectId}
        projectTokens={projectTokens}
        canEdit={canEdit}
      />

      <WebhookSettingsEditor projectId={projectId} canEdit={canEdit} />

      {data && data.primaryBusiness && (
        <VercelEdgeConfig projectId={projectId} data={data.primaryBusiness} />
      )}
    </CenteredContainer>
  );
}

SettingsEditor.LoadingSkeleton = function LoadingSkeleton({
  projectId,
}: {
  projectId: string;
}): React.ReactElement {
  return (
    <CenteredContainer>
      <CenteredContainerHeader title="Project settings" />
      <CardGroup
        layout="list"
        cardLayout="horizontal"
        loadingCount={5}
        cards={[]}
      />
      <TokensEditor.LoadingSkeleton />
      <WebhookSettingsEditor.LoadingSkeleton />
      <VercelEdgeConfig.LoadingSkeleton projectId={projectId} />
    </CenteredContainer>
  );
};
