import SearchInput from "../../components/SearchInput";
import { greyHex } from "../../lib/constants";
import SidebarItem from "../../components/SidebarItem";
import twMerge from "../../lib/twMerge";

export default function SidebarContainer({
  children,
  searchText,
  setSearchText,
  actions,
  controls,
  className,
  childrenClassName,
}: {
  children: React.ReactNode;
  searchText: string;
  setSearchText?: (newText: string) => void;
  actions?: React.ReactNode;
  controls?: React.ReactNode;
  className?: string;
  childrenClassName?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        `flex h-full w-[287px] min-w-[287px] flex-col items-stretch overflow-hidden
         border-r border-bd-darker bg-white`,
        className || ""
      )}
    >
      <SearchInput
        searchText={searchText}
        setSearchText={setSearchText}
        noFocusHighlight
        style={{
          border: "none",
          borderRadius: 0,
          borderBottom: `1px solid ${greyHex}`,
          maxWidth: "inherit",
          minHeight: 42,
          height: 42,
          padding: "8px 11px",
        }}
        action={actions}
      />
      {controls || null}
      <div className="flex h-full w-full flex-row overflow-hidden">
        <div
          className={twMerge(
            "flex h-full w-full min-w-0 flex-col items-stretch gap-[2px] overflow-y-auto overflow-x-hidden px-[5px] pb-16 pt-[5px]",
            childrenClassName
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

SidebarContainer.LoadingSkeleton = function (): React.ReactElement {
  return (
    <SidebarContainer searchText="">
      {Array(4).fill(<SidebarItem.LoadingSkeleton className="p-[11px]" />)}
    </SidebarContainer>
  );
};
