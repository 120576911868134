import { Schema } from "@hypertune/sdk/src/shared";
import { GraphQLSchema } from "graphql";
import {
  oldSchemaWelcomeMessage,
  newSchemaWelcomeMessage,
  oldSchemaRootComment,
  newSchemaRootComment,
} from "../constants";
import getSchemaCodeFromSchema, {
  SchemaFilter,
} from "./getSchemaCodeFromSchema";
import getSchema from "./getSchema";
import getGraphqlSchema from "./getGraphqlSchema";

export default function formatSchemaCode(
  schemaCode: string,
  typeFilter?: SchemaFilter
): string {
  return getSchemaCodeFromSchema(
    getSchemaWithDescriptions(schemaCode),
    typeFilter
  );
}

export function getSchemaWithDescriptions(schemaCode: string): Schema {
  const codeWithNewMessage = schemaCode
    .replace(oldSchemaWelcomeMessage, newSchemaWelcomeMessage)
    .replace(oldSchemaRootComment, newSchemaRootComment);

  let graphqlSchema: GraphQLSchema;

  try {
    graphqlSchema = getGraphqlSchema(
      convertCommentsToDescriptions(codeWithNewMessage)
    );
  } catch (error) {
    // Continue with the fallback schema without dangling comments.
    graphqlSchema = getGraphqlSchema(codeWithNewMessage);
  }
  return getSchema(graphqlSchema);
}

function convertCommentsToDescriptions(code: string): string {
  return code
    .split("\n")
    .map<string>((line) => {
      // Handle comments before value definition.
      const trimmedLine = line.trim();
      if (trimmedLine.startsWith("#")) {
        return `"""
${trimmedLine.slice(1).trim()}
"""`;
      }
      // Handle comments after field definition.
      const parts = trimmedLine.split("#", 2);
      if (parts.length === 2) {
        return `"""
${parts[1].trim()}
"""
${parts[0]}`;
      }
      return line;
    })
    .join("\n")
    .split(`"""\n"""\n`) // Merge multiline descriptions.
    .join("");
}
