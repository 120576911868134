import { useState } from "react";
import { DateTime } from "luxon";
import { Pencil } from "@phosphor-icons/react";
import Skeleton from "react-loading-skeleton";
import { DetailsContainerBase } from "../../../components/Details";
import {
  PullRequestCommentType,
  PullRequestDocument,
  PullRequestQuery,
  useCreatePullRequestCommentMutation,
  useUpdatePullRequestCommentMutation,
} from "../../../generated/graphql";
import UserWithTime from "../../../components/UserWithTime";
import Button from "../../../components/buttons/Button";
import MarkdownView from "../../../components/MarkdownView";
import twMerge from "../../../lib/twMerge";
import Label from "../../../components/Label";
import CodeEditor from "../../../components/CodeEditor";

const detailsContainerClassName = "block flex-col divide-y px-4 pb-52";

export default function PullRequestComments({
  meId,
  pullRequestId,
  comments,
}: {
  meId: string;
  pullRequestId: string;
  comments: PullRequestQuery["pullRequest"]["comments"];
}): React.ReactElement {
  return (
    <DetailsContainerBase className={detailsContainerClassName}>
      {[...comments]
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((comment) => (
          <Comment comment={comment} meId={meId} />
        ))}
      <CreateComment
        pullRequestId={pullRequestId}
        commentType={PullRequestCommentType.Comment}
      />
    </DetailsContainerBase>
  );
}

function Comment({
  meId,
  comment,
}: {
  meId: string;
  comment: PullRequestQuery["pullRequest"]["comments"][number];
}): React.ReactElement | null {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return (
      <UpdateComment
        pullRequestCommentId={comment.id}
        initialMessage={comment.message}
        onClose={() => setIsEditing(false)}
      />
    );
  }

  return (
    <div className="group flex flex-col gap-4 pb-1 pt-5">
      <div className="relative w-full">
        <UserWithTime
          user={comment.author}
          time={DateTime.fromISO(comment.createdAt).toRelative() || ""}
        />
        {meId === comment.author.id && (
          <Button
            icon={<Pencil size={14} />}
            size="small"
            className="absolute -top-[5px] right-0 ml-auto hidden group-hover:flex"
            onClick={() => setIsEditing(true)}
          />
        )}
      </div>
      <div>
        <MarkdownView markdown={comment.message} />
      </div>
    </div>
  );
}

function CreateComment({
  pullRequestId,
  commentType,
}: {
  pullRequestId: string;
  commentType: PullRequestCommentType;
}): React.ReactElement | null {
  const [createComment, { loading }] = useCreatePullRequestCommentMutation();

  return (
    <CommentEditor
      initialMessage=""
      title="Add comment"
      saveText="Comment"
      saveLoading={loading}
      onSave={(message) =>
        createComment({
          variables: {
            input: {
              message,
              pullRequestId,
              type: commentType,
            },
          },
          refetchQueries: [PullRequestDocument],
          awaitRefetchQueries: true,
        }).catch((error) =>
          console.error("failed to create PR comment", { error })
        )
      }
    />
  );
}

function UpdateComment({
  pullRequestCommentId,
  initialMessage,
  onClose,
}: {
  pullRequestCommentId: string;
  initialMessage: string;
  onClose: () => void;
}): React.ReactElement | null {
  const [createComment, { loading }] = useUpdatePullRequestCommentMutation();

  return (
    <CommentEditor
      initialMessage={initialMessage}
      title="Edit comment"
      saveText="Save"
      saveLoading={loading}
      onSave={(message) =>
        createComment({
          variables: {
            input: {
              id: pullRequestCommentId,
              message,
            },
          },
          refetchQueries: [PullRequestDocument],
          awaitRefetchQueries: true,
        })
          .then(() => onClose())
          .catch((error) =>
            console.error("failed to update PR comment", { error })
          )
      }
      onCancel={onClose}
      className="mb-4"
    />
  );
}

function CommentEditor({
  title,
  initialMessage,
  saveText,
  saveLoading,
  onSave,
  onCancel,
  className,
}: {
  initialMessage: string;
  title: string;
  saveText: string;
  saveLoading: boolean;
  onSave: (message: string) => Promise<unknown>;
  onCancel?: () => void;
  className?: string;
}): React.ReactElement | null {
  const [message, setMessage] = useState(initialMessage);

  return (
    <div className={twMerge("flex flex-col gap-4 pt-4", className)}>
      <Label type="title3">{title}</Label>
      <CodeEditor
        code={message}
        setCode={setMessage}
        language="markdown"
        className="overflow-hidden rounded-xl border bg-bg-light"
      />
      <div className="flex flex-row justify-end gap-2">
        <Button
          text="Cancel"
          weight="outlined"
          disabled={!message}
          onClick={() => {
            setMessage("");
            onCancel?.();
          }}
        />
        <Button
          text={saveText}
          intent="primary"
          weight="filled"
          disabled={!message}
          loading={saveLoading}
          onClick={async () => {
            await onSave(message);
            setMessage("");
          }}
        />
      </div>
    </div>
  );
}

PullRequestComments.LoadingSkeleton =
  function LoadingSkeleton(): React.ReactElement | null {
    return (
      <DetailsContainerBase className={detailsContainerClassName}>
        <CommentLoadingSkeleton />
        <CommentLoadingSkeleton />
      </DetailsContainerBase>
    );
  };

function CommentLoadingSkeleton(): React.ReactElement | null {
  return (
    <div className="flex flex-col gap-4 py-4">
      <div className="flex flex-row items-center">
        <Skeleton height={20} width={20} />
        <Skeleton height={13} width={120} className="ml-[13px]" />
      </div>
      <Skeleton height={30} />
    </div>
  );
}
