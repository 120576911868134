import {
  ExpressionMap,
  ExpressionMapPointer,
  ExpressionMapPointerMap,
} from "./types";

export default function getExpressionMapPointersMap(
  exprMap: ExpressionMap
): ExpressionMapPointerMap {
  return Object.fromEntries(
    getExpressionMapPointers(exprMap)
      .filter(Boolean)
      .map((pointer) => [pointer!.id, pointer])
  );
}

function getExpressionMapPointers(
  exprMap: ExpressionMap
): ExpressionMapPointer[] {
  return Object.values(exprMap).flatMap((expression) => {
    switch (expression.type) {
      case "NoOpExpression":
      case "BooleanExpression":
      case "StringExpression":
      case "IntExpression":
      case "FloatExpression":
      case "RegexExpression":
      case "EnumExpression":
      case "VariableExpression":
        return [];

      case "ObjectExpressionMapValue":
        return Object.values(expression.fields);
      case "GetFieldExpressionMapValue":
        return [expression.object];
      case "UpdateObjectExpressionMapValue":
        return Object.values(expression.updates).concat([expression.object]);
      case "ListExpressionMapValue": {
        return Object.values(expression.items);
      }
      case "SwitchExpressionMapValue": {
        return Object.values(expression.cases)
          .flatMap(({ when, then }) => [when, then])
          .concat([expression.control, expression.default]);
      }
      case "EnumSwitchExpressionMapValue":
        return Object.values(expression.cases).concat([expression.control]);
      case "ArithmeticExpressionMapValue":
      case "ComparisonExpressionMapValue":
        return [expression.a, expression.b];
      case "RoundNumberExpressionMapValue":
      case "StringifyNumberExpressionMapValue":
        return [expression.number];
      case "StringConcatExpressionMapValue":
        return [expression.strings];
      case "GetUrlQueryParameterExpressionMapValue":
        return [expression.url, expression.queryParameterName];
      case "SplitExpressionMapValue":
        return [
          expression.expose,
          expression.unitId,
          ...(expression.dimensionMapping.type ===
          "ExpressionMapValueDiscreteDimensionMapping"
            ? Object.values(expression.dimensionMapping.cases)
            : [expression.dimensionMapping.function]),
          expression.eventPayload,
          ...Object.values(expression.featuresMapping),
        ];
      case "LogEventExpressionMapValue":
        return [
          expression.unitId,
          expression.eventPayload,
          ...Object.values(expression.featuresMapping),
        ];
      case "FunctionExpressionMapValue":
        return [expression.body];

      default: {
        const neverExpression: never = expression;
        throw new Error(`unexpected expression: ${neverExpression}`);
      }
    }
  });
}
