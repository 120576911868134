import ErrorMessage from "../../../components/ErrorMessage";
import { Plan } from "../../../generated/graphql";
import twMerge from "../../../lib/twMerge";
import UpgradeToProButton from "../billing/UpgradeToProButton";

export default function UpgradeErrorMessage({
  errorMessage,
  plan,
  className,
}: {
  errorMessage: string;
  plan: Plan;
  className?: string;
}): React.ReactElement | null {
  if (!errorMessage || plan !== Plan.Free) {
    return null;
  }
  return (
    <div
      className={twMerge(
        "flex flex-row items-center gap-2 rounded-lg border border-intent-danger p-2",
        className
      )}
    >
      <ErrorMessage errorMessage={errorMessage} hideIcon />
      <UpgradeToProButton intent="danger" weight="elevated" />
    </div>
  );
}
