import { useEffect, useState } from "react";
import { SquaresFour, ListBullets } from "@phosphor-icons/react";
import { useBusinessesQuery } from "../../../generated/graphql";
import BusinessPage from "../BusinessPage";
import trackAnalyticsEvent from "../../../lib/trackAnalyticsEvent";
import ProjectsList from "./ProjectsList";
import Label from "../../../components/Label";
import ModeSwitcher from "../../../components/ModeSwitcher";
import SearchInput from "../../../components/SearchInput";
import { lighterGreyHex, whiteHex } from "../../../lib/constants";
import NewProjectButton from "./NewProjectButton";
import useStickyState from "../../../lib/hooks/useStickyState";
import ErrorMessageCard from "../../../components/ErrorMessageCard";
import { canEditProject } from "../../../lib/query/rolePermissions";

export default function ProjectsPage(): React.ReactElement {
  useEffect(() => {
    document.title = "Projects - Hypertune";
    trackAnalyticsEvent("projects_page_view");
  }, []);

  const { error, data } = useBusinessesQuery();

  const [searchText, setSearchText] = useState<string>("");
  const [showList, setShowList] = useStickyState(false, "use-project-list");
  const readOnly = !canEditProject(data?.primaryBusiness?.role);

  return (
    <BusinessPage>
      <div className="mb-5 flex h-8 justify-between">
        <Label type="title1">Projects</Label>
        {data?.primaryBusiness && (
          <div className="flex flex-row gap-2">
            <ModeSwitcher
              selectedMode={showList}
              setMode={setShowList}
              modes={[
                {
                  icon: <SquaresFour weight="regular" color={lighterGreyHex} />,
                  value: false,
                },
                {
                  icon: <ListBullets weight="regular" color={lighterGreyHex} />,
                  value: true,
                },
              ]}
            />
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              style={{ backgroundColor: whiteHex }}
            />
            {!readOnly && (
              <NewProjectButton businessId={data.primaryBusiness.id} />
            )}
          </div>
        )}
      </div>
      {error ? (
        <ErrorMessageCard error={error} />
      ) : (
        <ProjectsList
          data={data}
          searchText={searchText}
          showList={showList}
          readOnly={readOnly}
        />
      )}
    </BusinessPage>
  );
}
