import { useEffect, useState } from "react";
import { ObjectValue } from "@hypertune/sdk/src/shared";
import Skeleton from "react-loading-skeleton";
import ContainerWithLogo from "../../../components/container/ContainerWithLogo";
import { useHypertune } from "../../../generated/hypertune.react";
import {
  InputMultiSelectNode,
  InputNode,
  InputTextNode,
} from "../../../generated/hypertune";
import TextInput from "../../../components/input/TextInput";
import Button from "../../../components/buttons/Button";
import CardGroup from "../../../components/CardGroup";
import {
  MeDocument,
  MeQuery,
  useMeQuery,
  useUpdateMeMutation,
} from "../../../generated/graphql";
import useLoginRedirectQuery from "../../../lib/hooks/useLoginRedirectQuery";

export default function PersonalizationPage(): React.ReactElement {
  useEffect(() => {
    document.title = "Personalize - Hypertune";
  }, []);

  const { data } = useMeQuery();

  if (!data) {
    return (
      <ContainerWithLogo>
        <Skeleton className="h-[39px] w-[400px] rounded-lg" />
        <Skeleton className="mt-16 h-[450px] w-[488px] rounded-xl" />
      </ContainerWithLogo>
    );
  }
  return <PersonalizationPageInner me={data.me} />;
}

function PersonalizationPageInner({
  me,
}: {
  me: MeQuery["me"];
}): React.ReactElement {
  const redirect = useLoginRedirectQuery({ skipPersonalize: true });
  const [data, setData] = useState<ObjectValue>(
    JSON.parse(me.metadataJson) ?? {}
  );

  const [updateMe] = useUpdateMeMutation({
    refetchQueries: [MeDocument],
    awaitRefetchQueries: true,
  });
  const [loading, setLoading] = useState(false);

  const content = useHypertune().content().onboarding().personalizePage();
  const inputNodes = content.inputs();
  const subtitle = content.subtitle({ fallback: "" });

  return (
    <ContainerWithLogo className="lg-h:mt-6 lg-h:p-4">
      <h1 className="text-center text-h1 font-semibold text-base-dark-grey">
        {content.title({ fallback: "Tell us a bit more about you" })}
      </h1>
      {subtitle && (
        <h5 className="mt-3 text-center text-h5 font-[400] text-tx-muted">
          {subtitle}
        </h5>
      )}
      <HypertuneInputs inputNodes={inputNodes} data={data} setData={setData} />
      <div className="mt-7 flex w-full flex-col items-center">
        <Button
          size="2x-large"
          text="Next"
          weight="filled"
          intent="primary"
          disabled={
            Object.values(data).filter((value) =>
              typeof value === "object"
                ? Object.values(value).some(Boolean)
                : Boolean(value)
            ).length !== inputNodes.length
          }
          loading={loading}
          onClick={() => {
            setLoading(true);
            updateMe({
              variables: { input: { metadataJson: JSON.stringify(data) } },
            })
              .catch((error) =>
                console.error("failed to update user metadata", { error })
              )
              .finally(() => redirect());
          }}
          className="w-[175px]"
        />
      </div>
    </ContainerWithLogo>
  );
}

function HypertuneInputs({
  inputNodes,
  data,
  setData,
}: {
  inputNodes: InputNode[];
  data: ObjectValue;
  setData: (newData: ObjectValue) => void;
}): React.ReactElement | null {
  return (
    <div className="mt-7 flex flex-col items-center divide-y overflow-auto rounded-xl border shadow-elevated">
      {inputNodes.map((node) => {
        if (node instanceof InputTextNode) {
          return (
            <HypertuneTextInput node={node} data={data} setData={setData} />
          );
        }
        if (node instanceof InputMultiSelectNode) {
          return (
            <HypertuneMultiSelectInput
              node={node}
              data={data}
              setData={setData}
            />
          );
        }
        console.error("unexpected input type node", { node });

        return null;
      })}
    </div>
  );
}

function HypertuneTextInput({
  node,
  data,
  setData,
}: {
  node: InputTextNode;
  data: ObjectValue;
  setData: (newData: ObjectValue) => void;
}): React.ReactElement | null {
  const inputId = node.id({ fallback: "" });

  const value = (data[inputId] as string) ?? "";
  return (
    <div className="flex w-full flex-col items-stretch gap-4 p-[30px]">
      <p className="text-lg text-tx-muted">{node.label({ fallback: "" })}</p>
      <TextInput
        size="large"
        value={value}
        onChange={(newValue) => {
          setData({ ...data, [inputId]: newValue });
        }}
        readOnly={false}
        placeholder={node.placeholder({ fallback: "" })}
      />
    </div>
  );
}

function HypertuneMultiSelectInput({
  node,
  data,
  setData,
}: {
  node: InputMultiSelectNode;
  data: ObjectValue;
  setData: (newData: ObjectValue) => void;
}): React.ReactElement | null {
  const inputId = node.id({ fallback: "" });

  const selectedOptions = (data[inputId] ?? {}) as {
    [option: string]: boolean;
  };

  return (
    <div className="flex w-full flex-col items-start gap-4 p-[30px]">
      <p className="text-lg text-tx-muted">{node.label({ fallback: "" })}</p>
      <CardGroup
        layout="leftAlignedGrid"
        cardLayout="none"
        cards={node
          .options({ itemFallback: "" })
          .filter(Boolean)
          .map((option) => {
            const isSelected = selectedOptions[option] ?? false;

            return {
              key: `-${option}`,
              isSelected,
              onClick: () =>
                setData({
                  ...data,
                  [inputId]: {
                    ...selectedOptions,
                    [option]: !isSelected,
                  },
                }),
              children: (
                <p
                  className={
                    isSelected ? "text-intent-primary" : "text-base-dark-grey"
                  }
                >
                  {option}
                </p>
              ),
              className: "justify-center select-none px-3 py-2",
            };
          })}
      />
    </div>
  );
}
