import { Location, To, useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { getPreviousFromLocation } from "../query/locationState";
import {
  BusinessesLoginDocument,
  BusinessesLoginQuery,
  BusinessesLoginQueryVariables,
} from "../../generated/graphql";
import { getProjectUrl } from "../../features/project/projectHooks";
import getApolloClient from "../query/getApolloClient";
import getErrorMessage from "../query/getErrorMessage";

export default function useLoginRedirectQuery({
  skipPersonalize,
}: {
  skipPersonalize?: boolean;
} = {}): () => Promise<void> {
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = useCallback(async () => {
    let data: BusinessesLoginQuery | null = null;
    try {
      const { data: queryData } = await getApolloClient().query<
        BusinessesLoginQuery,
        BusinessesLoginQueryVariables
      >({
        query: BusinessesLoginDocument,
        fetchPolicy: "network-only",
      });
      data = queryData;
    } catch (error) {
      console.error("failed to fetch login query", {
        message: getErrorMessage(error),
        error,
      });
      return;
    }

    navigate(getLoginRedirectURL(location, data, skipPersonalize), {
      state: location.state,
    });
  }, [location, navigate, skipPersonalize]);

  return redirect;
}

function getLoginRedirectURL(
  location: Location,
  data: BusinessesLoginQuery | null,
  skipPersonalize?: boolean
): To {
  const previousLocation = getPreviousFromLocation(location);

  if (previousLocation && previousLocation.pathname !== "/") {
    return previousLocation;
  }
  if (
    !skipPersonalize &&
    location.pathname !== "/personalize" &&
    data?.me?.metadataJson &&
    Object.keys(JSON.parse(data.me.metadataJson) ?? {}).length === 0
  ) {
    return "/personalize";
  }

  if (
    !data ||
    !data.primaryBusiness ||
    data.primaryBusiness.business.projects.length > 1
  ) {
    return "/";
  }

  return data.primaryBusiness.business.projects[0]
    ? getProjectUrl({
        projectId: data.primaryBusiness.business.projects[0].id,
        selectedView: "logic",
      })
    : "/";
}
