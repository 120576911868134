import { Plus } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import Label from "./Label";
import Button from "./buttons/Button";
import twMerge from "../lib/twMerge";
import { EmptyStateContent } from "../generated/hypertune";

export default function EmptyStateContainer({
  icon,
  content,
  buttonHideIcon,
  buttonOnClick,
  className,
}: {
  icon: React.ReactNode;
  content: EmptyStateContent;
  buttonHideIcon?: boolean;
  buttonOnClick?: () => void;
  className?: string;
}): React.ReactElement | null {
  const buttonText = content.buttonText || "Add";

  return (
    <div className={twMerge("h-full p-4", className)}>
      <div className="flex h-full flex-col items-center rounded-lg bg-bg-medium p-[30px] ">
        <div className="mb-[10px] rounded-lg bg-base-grey-1-medium p-[6px]">
          {icon}
        </div>
        <Label type="title3" className="mb-[7px] text-base-dark-grey">
          {content.heading}
        </Label>
        <Label
          type="small-body"
          className="mb-[30px] text-center text-tx-muted"
        >
          {content.text}
          {content.learnMoreLink && (
            <>
              {" "}
              <Link
                to={content.learnMoreLink}
                target="_blank"
                className="text-intent-primary underline"
              >
                Learn more
              </Link>
            </>
          )}
        </Label>
        {buttonOnClick && (
          <Button
            text={buttonText}
            weight="elevated"
            size="large"
            icon={!buttonHideIcon ? <Plus weight="regular" /> : null}
            onClick={buttonOnClick}
            className="px-[10px]"
          />
        )}
      </div>
    </div>
  );
}
