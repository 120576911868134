import formatCount from "../../../lib/generic/formatCount";
import { darkBlueFilter, darkBlueHex, small } from "../../../lib/constants";

export default function EvaluationCount({
  count,
  style,
}: {
  count: number;
  style?: React.CSSProperties;
}): React.ReactElement {
  return (
    <div
      key="evaluationCount"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 2,
        ...(style || {}),
      }}
    >
      <img
        src="/graph.svg"
        width={9}
        height={9}
        style={{ filter: darkBlueFilter, marginTop: 2 }}
        alt=""
      />
      <div
        style={{
          color: darkBlueHex,
          whiteSpace: "nowrap",
          marginLeft: small,
        }}
      >
        {formatCount(count)}
      </div>
    </div>
  );
}
