import { cookieConsentCookieName } from "@hypertune/shared-internal";

export function getCookieConsent(): boolean {
  return JSON.parse(
    decodeURIComponent(document.cookie)
      .split(";")
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith(cookieConsentCookieName))
      ?.substring(cookieConsentCookieName.length + 1) ?? "false"
  );
}

export function onCookieConsent(callback: () => void): void {
  let lastCookie = document.cookie;

  // Check cookie consent every 5 seconds.
  const intervalId = window.setInterval(() => {
    if (lastCookie !== document.cookie && getCookieConsent()) {
      callback();
      clearInterval(intervalId);
    }
    lastCookie = document.cookie;
  }, 5_000);
}
