import { ComparisonOperator, ValueType } from "@hypertune/sdk/src/shared";
import getConstraintFromValueType from "./getConstraintFromValueType";

import { ValueTypeConstraint } from "../types";

export default function getComparisonBValueTypeConstraint(
  aValueType: ValueType | null,
  operator: ComparisonOperator | null
): ValueTypeConstraint {
  if (!operator) {
    return { type: "ErrorValueTypeConstraint" };
  }
  switch (operator) {
    case "==":
    case "!=":
      return aValueType
        ? getConstraintFromValueType(aValueType)
        : { type: "ErrorValueTypeConstraint" };

    case "<":
    case "<=":
    case ">":
    case ">=":
      return { type: "FloatValueTypeConstraint" };

    case "AND":
    case "OR":
      return { type: "BooleanValueTypeConstraint" };

    case "in":
    case "notIn":
      return aValueType
        ? {
            type: "ListValueTypeConstraint",
            itemValueTypeConstraint: getConstraintFromValueType(aValueType),
          }
        : {
            type: "ListValueTypeConstraint",
            itemValueTypeConstraint: { type: "AnyValueTypeConstraint" },
          };

    case "startsWith":
    case "notStartsWith":
    case "endsWith":
    case "notEndsWith":
    case "contains":
    case "notContains": {
      if (aValueType && aValueType.type === "StringValueType") {
        return { type: "StringValueTypeConstraint" };
      }

      if (aValueType && aValueType.type === "ListValueType") {
        return getConstraintFromValueType(aValueType.itemValueType);
      }

      return { type: "ErrorValueTypeConstraint" };
    }

    case "matches":
    case "notMatches":
      return { type: "RegexValueTypeConstraint" };

    default: {
      const neverOperator: never = operator;
      throw new Error(`Unexpected operator: ${neverOperator}`);
    }
  }
}
