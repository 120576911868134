import React from "react";
import { DbVercelConnection } from "@hypertune/shared-internal";
import {
  BusinessesQuery,
  useVercelEdgeConfigStoresQuery,
} from "../../../generated/graphql";
import ConfigureIntegration, { Header } from "./ConfigureIntegration";
import { storesJsonToStoreIdToStoreMap } from "./hooks";

export default function VercelEdgeConfig({
  projectId,
  data,
}: {
  projectId?: string;
  data: NonNullable<BusinessesQuery["primaryBusiness"]>;
}): React.ReactElement {
  const { data: storesQuery, loading } = useVercelEdgeConfigStoresQuery();

  if (loading) {
    return <VercelEdgeConfig.LoadingSkeleton projectId={projectId} />;
  }

  const storeIdToStore = storesJsonToStoreIdToStoreMap(
    storesQuery?.primaryBusiness?.business.vercelEdgeConfigStores || null
  );
  const connections = JSON.parse(
    data.business.vercelConnectionsJson
  ) as DbVercelConnection[];

  if (storeIdToStore && connections.length > 0) {
    console.debug("storeIdToStore", storeIdToStore);
    return (
      <ConfigureIntegration
        projectId={projectId}
        connections={connections}
        data={data}
        storeIdToStore={storeIdToStore}
      />
    );
  }

  return <Header projectId={projectId} />;
}

VercelEdgeConfig.LoadingSkeleton = function ({
  projectId,
}: {
  projectId?: string;
}): React.ReactElement | null {
  return (
    <>
      <Header projectId={projectId} hideButton />
      <ConfigureIntegration.LoadingSkeleton />
    </>
  );
};
