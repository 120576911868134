import { localEdgeWorkerBaseUrl } from "@hypertune/sdk/src/shared";
import { Env } from "./type";

const env: Env = {
  NAME: "development",
  FRONTEND_BASE_URL: "http://localhost:3000",
  BACKEND_BASE_URL: "http://localhost:3001",
  EDGE_CDN_BASE_URL: localEdgeWorkerBaseUrl,
};

export default env;
