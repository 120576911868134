import { motion } from "framer-motion";
import React from "react";
import twMerge from "../../lib/twMerge";

export default function CollapseDiv({
  isOpen,
  className,
  children,
}: {
  isOpen: boolean;
  className?: string;
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <motion.div
      className={twMerge("flex min-w-0 overflow-hidden", className)}
      initial={isOpen ? "open" : "closed"}
      animate={isOpen ? "open" : "closed"}
      variants={{
        open: { height: "auto", opacity: 1 },
        closed: { height: 0, opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}
