import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FunnelStep } from "@hypertune/shared-internal";
import { hash, stableStringify } from "@hypertune/sdk/src/shared";

export const countVariants = ["Unique", "Total"] as const;
export type CountsVariant = (typeof countVariants)[number];

export const hypothesisVariants = ["One-sided", "Two-sided"] as const;
export type HypothesisVariant = (typeof hypothesisVariants)[number];

export type SelectedAnalyticsView = {
  steps?: FunnelStep[];
  funnelStepsJson?: string;
};

export type AnalyticsEditorState = {
  activeStateHash: string;
  steps: FunnelStep[];
  hasChanges: boolean;
  showNewViewModal: boolean;
};

const initialState: AnalyticsEditorState = {
  steps: [],
  activeStateHash: "",
  hasChanges: false,
  showNewViewModal: false,
};

export const analyticsSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setActiveAnalyticsView: (
      draftState,
      action: PayloadAction<FunnelStep[]>
    ) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      draftState.steps = action.payload;
      draftState.activeStateHash = getStepsHash(draftState.steps);
      draftState.hasChanges = false;
    },
    resetAnalyticsEditor: (draftState) => {
      draftState.steps = [];
      draftState.activeStateHash = "";
      draftState.hasChanges = false;
    },
    setFunnelSteps: (draftState, action: PayloadAction<FunnelStep[]>) => {
      draftState.steps = action.payload;
      updateHasChanges(draftState);
    },
    setShowNewViewModal: (draftState, action: PayloadAction<boolean>) => {
      draftState.showNewViewModal = action.payload;
    },
  },
});

export const {
  setActiveAnalyticsView,
  resetAnalyticsEditor,
  setFunnelSteps,
  setShowNewViewModal,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

function updateHasChanges(draftState: AnalyticsEditorState): void {
  draftState.hasChanges =
    draftState.activeStateHash !== getStepsHash(draftState.steps);
}

function getStepsHash(steps: FunnelStep[]): string {
  return hash(stableStringify(steps)).toString();
}
