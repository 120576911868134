import twMerge from "../lib/twMerge";

export default function Table({
  colClassNames,
  rows,
  variant,
  className,
}: {
  colClassNames?: string[];
  rows: React.ReactNode[][];
  variant?: "default" | "no-border";
  className?: string;
}): React.ReactElement {
  const numCols =
    rows.length > 0 ? rows[0].length : colClassNames ? colClassNames.length : 0;
  return (
    <div className={twMerge("flex w-full flex-row", className)}>
      {new Array(numCols).fill(0).map((_, colIndex) => (
        <div
          className={twMerge(
            "flex flex-col items-stretch",
            colClassNames?.[colIndex]
          )}
        >
          {rows.map((row, rowIndex) => (
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={`col_${colIndex}_row_${rowIndex}`}
              className={variant === "no-border" ? "" : "border-t"}
            >
              {rows[rowIndex][colIndex]}
            </TableCell>
          ))}
        </div>
      ))}
    </div>
  );
}

export function TableCell({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}): React.ReactElement {
  return (
    <div
      className={twMerge(
        "flex h-full flex-col items-start justify-center overflow-x-clip overflow-y-visible text-ellipsis whitespace-nowrap p-2",
        className
      )}
    >
      {children}
    </div>
  );
}
