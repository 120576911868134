import { X } from "@phosphor-icons/react";
import { lighterGreyHex } from "../../lib/constants";
import Button, { Size } from "./Button";
import twMerge from "../../lib/twMerge";

export default function CloseButton({
  size = "small",
  onClick,
  className,
}: {
  size?: Size;
  onClick: () => void;
  className?: string;
}): React.ReactElement {
  return (
    <Button
      icon={<X weight="regular" color={lighterGreyHex} />}
      size={size}
      weight="outlined"
      onClick={onClick}
      className={twMerge("px-1", className || "")}
    />
  );
}
