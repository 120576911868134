import { Schema } from "@hypertune/sdk/src/shared/types";
import getNextName from "../getNextName";

export default function getNextSchemaName(
  schema: Schema,
  typeName: string
): string {
  return getNextName(
    Object.fromEntries(
      Object.keys(schema.objects)
        .concat(Object.keys(schema.enums).concat(Object.keys(schema.unions)))
        .map((name) => [name, true])
    ),
    typeName
  );
}
