import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";

export default function getPayloadPathString(path: string[]): string {
  return `payload${fieldPathSeparator}${path.join(fieldPathSeparator)}`;
}

export function getStartCasePayloadPathString(path: string[]): string {
  return `Payload${fieldPathSeparator}${path.map((step) => toStartCase(step)).join(fieldPathSeparator)}`;
}
