import {
  DiscreteDimension,
  SplitMap,
  SplitType,
} from "@hypertune/sdk/src/shared";
import { diff } from "deep-object-diff";
import {
  SplitControlDimensionArmIntentMap,
  SplitControlDimensionIntentMap,
  SplitControlIntentMap,
} from "./SplitControl";
import { rightArrowSymbol } from "../../../lib/constants";

export type SplitDiffMeta = {
  splitId: string;
  splitType: SplitType;
  shortLabel: string;
  longLabel: string;
};

export default function getSplitDiffsMetaAndIntentMap({
  currentSplits,
  newSplits,
}: {
  currentSplits: SplitMap;
  newSplits: SplitMap;
}): {
  splitsDiffsMeta: SplitDiffMeta[];
  newSplitsIntentMap: Record<string, SplitControlIntentMap>;
} {
  const diffValue = diff(currentSplits, newSplits);
  console.debug("Splits diff value", diffValue);
  const newSplitsIntentMap = diffToNewValueIntentMap(diffValue, currentSplits);

  return {
    splitsDiffsMeta: Object.keys(diffValue)
      .map((splitId) => {
        const shortLabel =
          newSplits[splitId]?.name ?? currentSplits[splitId]?.name;
        const longLabel =
          newSplits[splitId] &&
          currentSplits[splitId] &&
          newSplits[splitId].name !== currentSplits[splitId].name
            ? `${currentSplits[splitId].name} ${rightArrowSymbol} ${newSplits[splitId].name}`
            : shortLabel;
        const splitType =
          newSplits[splitId]?.type ?? currentSplits[splitId]?.type;

        return { splitId, splitType, shortLabel, longLabel };
      })
      .sort((a, b) => a.splitId.localeCompare(b.shortLabel)),
    newSplitsIntentMap,
  };
}

function diffToNewValueIntentMap(
  diffValue: object,
  currentSplits: SplitMap
): {
  [splitId: string]: SplitControlIntentMap;
} {
  return Object.fromEntries(
    Object.entries(diffValue).map(([splitId, splitDiff]) => {
      const intentMap: SplitControlIntentMap = {};

      if (splitDiff && typeof splitDiff === "object") {
        if ("name" in splitDiff) {
          intentMap.name = "success";
        }
        if ("type" in splitDiff) {
          intentMap.type = "success";
        }
        if ("eventObjectTypeName" in splitDiff) {
          intentMap.payloadEventType = "success";
        }
        if (
          "rewardEvents" in splitDiff &&
          (splitDiff.rewardEvents === undefined ||
            Array.isArray(splitDiff.rewardEvents))
        ) {
          if (splitDiff.rewardEvents?.length > 0) {
            const rewardEvent = splitDiff.rewardEvents[0];
            if (
              typeof rewardEvent === "object" &&
              "eventObjectTypeName" in rewardEvent
            ) {
              intentMap.goalEventType = "success";
            }
            if (
              typeof rewardEvent === "object" &&
              "unitIdPayloadPath" in rewardEvent
            ) {
              intentMap.goalEventUnitIdPath = "success";
            }
          } else {
            intentMap.goalEventType = "success";
            intentMap.goalEventUnitIdPath = "success";
          }
        }
        if (
          "dimensions" in splitDiff &&
          typeof splitDiff.dimensions === "object"
        ) {
          intentMap.dimensions = Object.fromEntries(
            Object.entries(splitDiff.dimensions).map(
              ([dimensionId, dimensionDiff]) => {
                const dimensionIntentMap: SplitControlDimensionIntentMap = {};

                if (dimensionDiff === undefined) {
                  dimensionIntentMap.name = "success";
                  // Add all arms from current split to ensure
                  // we correctly highlight all of them.
                  if (
                    currentSplits[splitId]?.dimensions[dimensionId]?.type ===
                    "discrete"
                  ) {
                    const armsIntentMap: {
                      [armId: string]: SplitControlDimensionArmIntentMap;
                    } = {};
                    Object.keys(
                      (
                        currentSplits[splitId]?.dimensions[
                          dimensionId
                        ] as DiscreteDimension
                      ).arms
                    ).forEach((armId) => {
                      armsIntentMap[armId] = {
                        name: "success",
                        allocation: "success",
                      };
                    });
                    dimensionIntentMap.arms = armsIntentMap;
                  }
                  return [dimensionId, dimensionIntentMap];
                }
                if (!dimensionDiff || typeof dimensionDiff !== "object") {
                  return [dimensionId, dimensionIntentMap];
                }
                if ("name" in dimensionDiff) {
                  dimensionIntentMap.name = "success";
                }
                if (
                  "arms" in dimensionDiff &&
                  dimensionDiff.arms &&
                  typeof dimensionDiff.arms === "object"
                ) {
                  dimensionIntentMap.arms = Object.fromEntries(
                    Object.entries(dimensionDiff.arms).map(
                      ([armId, armDiff]) => {
                        const armIntentMap: SplitControlDimensionArmIntentMap =
                          {};
                        if (!armDiff || typeof armDiff !== "object") {
                          // Arm must have been deleted.
                          return [
                            armId,
                            { name: "success", allocation: "success" },
                          ];
                        }
                        if ("name" in armDiff) {
                          armIntentMap.name = "success";
                        }
                        if ("allocation" in armDiff) {
                          armIntentMap.allocation = "success";
                        }
                        return [armId, armIntentMap];
                      }
                    )
                  );
                }
                return [dimensionId, dimensionIntentMap];
              }
            )
          );
        }
      }
      return [splitId, intentMap];
    })
  );
}
