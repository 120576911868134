import { IconSize } from "./icons";

export default function IconContainer({
  size,
  className,
  children,
}: {
  className: string;
  size: IconSize;
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <div
      className={`flex flex-shrink-0 items-center justify-center 
                  ${iconSizeToClassName(size)} ${className}`}
    >
      {children}
    </div>
  );
}
function iconSizeToClassName(size: IconSize): string {
  switch (size) {
    case "small":
      return "h-[16px] w-[16px] rounded-md";
    case "medium":
      return "h-[20px] w-[20px] rounded-md";
    case "large":
      return "h-[28px] w-[28px] rounded-lg";
    default:
      throw new Error(`Unknown icon size: ${size}`);
  }
}
