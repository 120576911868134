import { format } from "date-fns";

export default function formatDate(d: Date): string {
  return format(d, "d MMM u");
}

export function formatRawDate(date: string): string {
  return formatDate(new Date(date));
}

/** @example "2023-04-01 at 14:53" */
export function formatJsonTime(time: string): string {
  const createdAt = new Date(time);
  return `${createdAt.getFullYear()}-${(createdAt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${createdAt
    .getDate()
    .toString()
    .padStart(2, "0")} at ${createdAt
    .getHours()
    .toString()
    .padStart(2, "0")}:${createdAt.getMinutes().toString().padStart(2, "0")}`;
}
