import React from "react";
import twMerge from "../lib/twMerge";

export default function BigIcon({
  icon,
  size = 40,
  className,
}: {
  icon: React.ReactNode;
  size?: number;
  className?: string;
}): React.ReactElement {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center rounded-lg bg-black text-white",
        className || ""
      )}
      style={{
        height: size,
        width: size,
      }}
    >
      {icon}
    </div>
  );
}
