import useIntercom from "../../../app/useIntercom";
import ModalWithContent from "../../../components/ModalWithContent";
import {
  Plan as GraphqlPlan,
  useUpdatePlanMutation,
} from "../../../generated/graphql";
import { Plan } from "../../../generated/hypertune";
import { useHypertune } from "../../../generated/hypertune.react";
import { calendlyUrl } from "../../../lib/constants";
import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";

export default function UpdatePlanModal({
  businessId,
  currentPlan,
  newPlan,
  hasNewBilling,
  onClose,
}: {
  businessId: string;
  currentPlan: Plan;
  newPlan: Plan;
  hasNewBilling: boolean;
  onClose: () => void;
}): React.ReactElement | null {
  const hypertune = useHypertune();
  const content = hypertune.content().plans();

  const { showIntercom } = useIntercom();
  const [update, { loading }] = useUpdatePlanMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });

  const isCancel = newPlan === "free";

  return (
    <ModalWithContent
      content={
        currentPlan === "enterprise"
          ? content.enterpriseDowngradeModal().get()
          : hasNewBilling
            ? isCancel
              ? content.proDowngradeModal().get()
              : content
                  .changePlanConfirmationModal({
                    args: {
                      oldPlanName: hypertune
                        .plansContent()
                        .planContent({ args: { plan: currentPlan } })
                        .name({ fallback: currentPlan }),
                      newPlanName: hypertune
                        .plansContent()
                        .planContent({ args: { plan: newPlan } })
                        .name({ fallback: newPlan }),
                    },
                  })
                  .get()
            : content.legacyProDowngradeModal().get()
      }
      onClose={async (options?: { isAction?: boolean }) => {
        if (currentPlan === "enterprise" && options?.isAction) {
          await showIntercom();
        }
        onClose();
      }}
      closeLoading={showIntercom.loading}
      onSave={async () => {
        if (currentPlan === "enterprise" || !hasNewBilling) {
          window.open(calendlyUrl, "_blank");
        } else {
          await update({
            variables: {
              input: {
                businessId,
                cancel: isCancel ? true : undefined,
                newPlan: isCancel
                  ? undefined
                  : (newPlan as GraphqlPlan | undefined),
              },
            },
          });
        }
        onClose();
      }}
      saveLoading={loading}
    />
  );
}
