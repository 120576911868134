export default function TooltipArrow({
  className,
}: {
  className?: string;
}): React.ReactElement | null {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="30"
      viewBox="0 0 8 30"
      fill="none"
      className={className}
    >
      <path
        d="M0.634965 15.3559C0.723859 15.5608 0.8536 15.7435 1.01548 15.8916L4.17851 18.788C5.22187 19.7436 6.05802 20.9217 6.63089 22.2431C7.20375 23.5646 7.50011 24.999 7.50008 26.4502L8.00098 26.4502L8.00098 0.450195L7.50008 0.450195L7.50008 2.95673C7.50011 4.4079 7.20375 5.8423 6.63089 7.16379C6.05802 8.48527 5.22188 9.66329 4.17851 10.6189L1.01548 13.5154C0.8536 13.6635 0.723859 13.8461 0.634965 14.051C0.546071 14.256 0.500081 14.4784 0.500081 14.7035C0.500081 14.9285 0.546071 15.151 0.634965 15.3559Z"
        fill="white"
      />
      <path
        d="M7.5 0.450195L7.5 2.95673C7.50003 4.4079 7.20367 5.8423 6.63081 7.16379C6.05794 8.48527 5.2218 9.66329 4.17843 10.6189L1.0154 13.5154C0.853521 13.6635 0.72378 13.8461 0.634886 14.051C0.545993 14.256 0.500002 14.4784 0.500002 14.7035C0.500002 14.9285 0.545993 15.151 0.634886 15.3559C0.72378 15.5608 0.853521 15.7435 1.0154 15.8916L4.17843 18.788C5.2218 19.7436 6.05794 20.9217 6.63081 22.2431C7.20367 23.5646 7.50003 24.999 7.5 26.4502"
        stroke="#EEEEF1"
      />
    </svg>
  );
}
