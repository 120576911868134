import { Schema } from "@hypertune/sdk/src/shared";
import getValueTypeFromConstraint from "./getValueTypeFromConstraint";

import { ValueTypeConstraint } from "../types";

export default function isValueTypeConstraintValid(
  schema: Schema,
  valueTypeConstraint: ValueTypeConstraint
): boolean {
  switch (valueTypeConstraint.type) {
    case "VoidValueTypeConstraint":
    case "BooleanValueTypeConstraint":
    case "IntValueTypeConstraint":
    case "FloatValueTypeConstraint":
    case "StringValueTypeConstraint":
    case "RegexValueTypeConstraint":
      return true;

    case "EnumValueTypeConstraint":
      return !!schema.enums[valueTypeConstraint.enumTypeName];

    case "AnyEnumValueTypeConstraint":
      return true;

    case "ObjectValueTypeConstraint":
      return !!schema.objects[valueTypeConstraint.objectTypeName];

    case "AnyObjectValueTypeConstraint":
      return true;

    case "UnionValueTypeConstraint":
      return !!schema.unions[valueTypeConstraint.unionTypeName];

    case "ListValueTypeConstraint":
      return isValueTypeConstraintValid(
        schema,
        valueTypeConstraint.itemValueTypeConstraint
      );

    case "FunctionValueTypeConstraint":
      return (
        valueTypeConstraint.parameterValueTypeConstraints.every(
          (constraint) =>
            isValueTypeConstraintValid(schema, constraint) &&
            // It must be possible to convert parameter type constraints to
            // concrete types, as this is necessary to check if a function type
            // is compatible with a function type constraint. In particular, a
            // function type is compatible with a function type constraint only
            // if the parameter type constraints converted to concrete types are
            // all compatible with the function value type's parameter types
            // converted to type constraints, i.e. parameter types are
            // contravariant.
            getValueTypeFromConstraint(constraint)
        ) &&
        isValueTypeConstraintValid(
          schema,
          valueTypeConstraint.returnValueTypeConstraint
        )
      );

    case "FunctionWithReturnValueTypeConstraint":
      return isValueTypeConstraintValid(
        schema,
        valueTypeConstraint.returnValueTypeConstraint
      );

    case "AnyValueTypeConstraint":
    case "AnyNonFunctionValueTypeConstraint":
    case "ErrorValueTypeConstraint":
      return true;

    default: {
      const neverValueTypeConstraint: never = valueTypeConstraint;
      throw new Error(
        `Unexpected value type constraint: ${JSON.stringify(
          neverValueTypeConstraint
        )}`
      );
    }
  }
}
