import { ValueTypeConstraint } from "@hypertune/shared-internal/src/expression/types";
import Block from "../../../components/icons/Block";
import Event from "../../../components/icons/Event";
import Flag from "../../../components/icons/Flag";
import Variable from "../../../components/icons/Variable";
import Folder from "../../../components/icons/Folder";
import List from "../../../components/icons/List";
import { IconSize } from "../../../components/icons/icons";
import IconContainer from "../../../components/icons/IconContainer";

export default function ValueTypeConstraintIcon({
  isVariable,
  hasChildren,
  valueTypeConstraint,
  size = "small",
}: {
  isVariable: boolean;
  hasChildren: boolean;
  valueTypeConstraint: ValueTypeConstraint;
  size?: IconSize;
}): React.ReactElement {
  if (isVariable) {
    return (
      <IconContainer size={size} className="bg-base-pink">
        <Variable />{" "}
      </IconContainer>
    );
  }
  const constraint = unwrapValueTypeConstraint(valueTypeConstraint);

  switch (constraint.type) {
    case "VoidValueTypeConstraint":
      return (
        <IconContainer size={size} className="bg-intent-success">
          <Event />
        </IconContainer>
      );

    case "BooleanValueTypeConstraint":
    case "StringValueTypeConstraint":
    case "IntValueTypeConstraint":
    case "FloatValueTypeConstraint":
    case "EnumValueTypeConstraint":
    case "AnyEnumValueTypeConstraint":
      return (
        <IconContainer size={size} className="bg-intent-primary">
          <Flag />
        </IconContainer>
      );

    case "ListValueTypeConstraint":
      return (
        <IconContainer size={size} className="bg-intent-primary">
          <List />{" "}
        </IconContainer>
      );
    case "UnionValueTypeConstraint":
    case "ObjectValueTypeConstraint":
      if (hasChildren) {
        return (
          <IconContainer size={size} className="bg-base-orange">
            <Folder />
          </IconContainer>
        );
      }
      return (
        <IconContainer size={size} className="bg-base-purple">
          <Block />
        </IconContainer>
      );

    default:
      return (
        <IconContainer size={size} className="bg-base-purple">
          <Block />
        </IconContainer>
      );
  }
}

export function unwrapChildValueTypeConstraint(
  valueTypeConstraint: ValueTypeConstraint
): ValueTypeConstraint {
  const unwrappedValueTypeConstraint =
    unwrapValueTypeConstraint(valueTypeConstraint);
  switch (unwrappedValueTypeConstraint.type) {
    case "ListValueTypeConstraint":
      return unwrappedValueTypeConstraint.itemValueTypeConstraint;
    default:
      return valueTypeConstraint;
  }
}

export function unwrapValueTypeConstraint(
  valueTypeConstraint: ValueTypeConstraint
): ValueTypeConstraint {
  switch (valueTypeConstraint.type) {
    case "FunctionValueTypeConstraint":
    case "FunctionWithReturnValueTypeConstraint":
      return unwrapValueTypeConstraint(
        valueTypeConstraint.returnValueTypeConstraint
      );
    default:
      return valueTypeConstraint;
  }
}
