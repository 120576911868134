import { Dimension } from "@hypertune/sdk/src/shared";
import getArmAllocationsSum from "./getArmAllocationsSum";

export default function getDimensionErrorMessage(
  dimension: Dimension
): string | null {
  // TODO: Support continuous dimensions
  if (dimension.type !== "discrete") {
    return "The dimension is not discrete.";
  }

  if (dimension.name.trim().length === 0) {
    return "The dimension must have a name.";
  }

  const arms = Object.values(dimension.arms);

  if (arms.length === 0) {
    return "The dimension must have at least one arm.";
  }

  if (new Set(arms.map((arm) => arm.index)).size !== arms.length) {
    return "Two arms have the same index.";
  }

  if (arms.some((arm) => arm.name.trim().length === 0)) {
    return "All arms must have a name.";
  }

  if (new Set(arms.map((arm) => arm.name)).size !== arms.length) {
    return "Two arms have the same name.";
  }

  const armAllocationsSum = getArmAllocationsSum(dimension);

  if (Number.isNaN(armAllocationsSum)) {
    return "All arms must have valid allocations.";
  }

  if (armAllocationsSum > 1) {
    return "Arm allocations total more than 100%.";
  }

  return null;
}
