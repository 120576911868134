import { useState } from "react";
import Button from "../../../components/buttons/Button";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setFunnelSteps } from "./analyticsSlice";
import { useActiveSelectedAnalyticsView } from "./analyticsHooks";
import ModalWithContent from "../../../components/ModalWithContent";
import { useHypertune } from "../../../generated/hypertune.react";

export default function ResetButton(): React.ReactElement | null {
  const content = useHypertune().content().analytics();
  const dispatch = useAppDispatch();
  const activeView = useActiveSelectedAnalyticsView();
  const hasChanges = useAppSelector(
    (state) => state.projectAnalytics.hasChanges
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div>
      <Button
        intent="warning"
        size="large"
        weight="elevated"
        text="Reset"
        disabled={!hasChanges}
        onClick={() => setIsModalVisible(true)}
      />
      {isModalVisible && (
        <ModalWithContent
          content={content.resetViewConfirmation().get()}
          onClose={() => setIsModalVisible(false)}
          onSave={() => {
            dispatch(
              setFunnelSteps(JSON.parse(activeView?.funnelStepsJson ?? "[]"))
            );
            setIsModalVisible(false);
          }}
        />
      )}
    </div>
  );
}
