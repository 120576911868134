import { mapExpression, Expression, uniqueId } from "@hypertune/sdk/src/shared";

// Expensive
export default function copyExpression(
  expression: Expression | null
): Expression | null {
  return mapExpression((expr) => {
    if (!expr) {
      return null;
    }
    return {
      ...expr,
      id: uniqueId(),
    };
  }, expression);
}
