import { useCallback, useState } from "react";
import Button from "../../../components/buttons/Button";
import { useDeleteAnalyticsViewMutation } from "../../../generated/graphql";
import ModalWithContent from "../../../components/ModalWithContent";
import { useSelectedAnalyticsViewId } from "./analyticsHooks";
import { useHypertune } from "../../../generated/hypertune.react";
import { refetchAnalyticsViewsQueries } from "../../../lib/query/refetchQueries";

export default function DeleteButton(): React.ReactElement | null {
  const content = useHypertune().content();
  const [selectedViewId, setSelectedViewId] = useSelectedAnalyticsViewId();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const [deleteAnalyticsView, { loading }] = useDeleteAnalyticsViewMutation({
    refetchQueries: refetchAnalyticsViewsQueries,
    awaitRefetchQueries: true,
  });

  const deleteView = useCallback(async () => {
    if (selectedViewId === null) {
      return;
    }
    await deleteAnalyticsView({
      variables: {
        input: { id: selectedViewId },
      },
    });
    setSelectedViewId(null);
  }, [selectedViewId, deleteAnalyticsView, setSelectedViewId]);

  return (
    <>
      {showConfirmModal && (
        <ModalWithContent
          content={content.analytics().deleteViewConfirmation().get()}
          onClose={() => setShowConfirmModal(false)}
          onSave={() => {
            deleteView()
              .then(() => {
                setShowConfirmModal(false);
              })
              .catch((error) => {
                console.error("updateAnalyticsView error:", error);
              });
          }}
          saveLoading={loading}
        />
      )}
      <Button
        // icon={<Trash weight="regular" color={intentDangerHex} />}
        text="Delete"
        intent="danger"
        weight="elevated"
        size="large"
        onClick={() => setShowConfirmModal(true)}
      />
    </>
  );
}
