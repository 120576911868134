import { ObjectRole, Schema, ValueType } from "@hypertune/sdk/src/shared/types";
import { TypeReferencesMap } from "./schemaHooks";
import { SchemaContentNode } from "../../../generated/hypertune";
import { getObjectRoleName } from "./typeEditor/object/ValueTypeSelector";

export default function getValueTypeMessage({
  content,
  schema,
  objectTypeName,
  baseValueType,
  typeReferences,
}: {
  content: SchemaContentNode;
  schema: Schema;
  objectTypeName: string;
  baseValueType: ValueType;
  typeReferences: TypeReferencesMap;
}): string | null {
  return baseValueType.type === "ObjectValueType" &&
    !objectRolesAreCompatible(
      schema.objects[objectTypeName].role,
      schema.objects[baseValueType.objectTypeName].role
    )
    ? typeReferences[baseValueType.objectTypeName] &&
      typeReferences[baseValueType.objectTypeName].size > 0
      ? content.cloneObjectToChangeRoleContent({
          args: {
            currentRole: getObjectRoleName(
              schema.objects[baseValueType.objectTypeName].role
            ),
            targetRole: getObjectRoleName(schema.objects[objectTypeName].role),
          },
          fallback: "",
        })
      : content.changeObjectRoleContent({
          args: {
            currentRole: getObjectRoleName(
              schema.objects[baseValueType.objectTypeName].role
            ),
            targetRole: getObjectRoleName(schema.objects[objectTypeName].role),
          },
          fallback: "",
        })
    : null;
}

export function objectRolesAreCompatible(
  role1: ObjectRole,
  role2: ObjectRole
): boolean {
  return (
    role1 === role2 ||
    (role1 === "event" && role2 === "input") ||
    (role1 === "input" && role2 === "event")
  );
}
