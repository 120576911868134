import React from "react";
import TextInput from "./TextInput";
import Button from "../buttons/Button";
import DeleteButton from "../buttons/DeleteButton";
import splitString from "../../lib/generic/splitString";

export default function TextListInput({
  textList,
  setTextList,
  placeholder,
  newLabel,
  onEnter,
  isLoading,
  focusFirstOnMount,
  focusLastOnMount,
  allowEmpty,
  valueError,
  inputStyle,
}: {
  textList: string[];
  setTextList: (newTextList: string[]) => void;
  placeholder: string;
  newLabel: string;
  onEnter?: () => void;
  isLoading: boolean;
  focusFirstOnMount?: boolean;
  focusLastOnMount?: boolean;
  allowEmpty: boolean;
  valueError?: (value: string) => string | null;
  inputStyle?: React.CSSProperties;
}): React.ReactElement {
  return (
    <>
      {textList.map((text, index) => (
        <div className="relative mb-2 flex flex-row">
          <TextInput
            style={{ flexGrow: 1, paddingRight: 4, ...(inputStyle || {}) }}
            value={text}
            trimOnBlur
            focusOnMount={
              (index === 0 && focusFirstOnMount) ||
              (index === textList.length - 1 && focusLastOnMount)
            }
            placeholder={placeholder}
            readOnly={false}
            onChange={(newValue: string) => {
              setTextList([
                ...textList.slice(0, index),
                newValue,
                ...textList.slice(index + 1),
              ]);
            }}
            onEnter={onEnter}
            endIcon={
              textList.length > 1 || allowEmpty ? (
                <DeleteButton
                  size="x-small"
                  onClick={() => {
                    setTextList([
                      ...textList.slice(0, index),
                      ...textList.slice(index + 1),
                    ]);
                  }}
                />
              ) : undefined
            }
            error={valueError ? valueError(text) : undefined}
            errorPosition="top-end"
            onPaste={(newValue) => {
              const lines = splitString(newValue);
              if (lines.length > 1) {
                setTextList([
                  ...textList.slice(0, index),
                  ...lines,
                  ...textList.slice(index + 1),
                ]);
                return true;
              }
              return false;
            }}
          />
        </div>
      ))}
      <Button
        weight="outlined"
        text={newLabel}
        disabled={isLoading}
        onClick={() => {
          setTextList(textList.concat(""));
        }}
        className="mb-2"
      />
    </>
  );
}
