import { Auth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React from "react";

import useLoginRedirectQuery from "../../lib/hooks/useLoginRedirectQuery";
import Button from "../../components/buttons/Button";
import { useHypertune } from "../../generated/hypertune.react";

export default function GoogleLogin({
  auth,
  setError,
  resetError,
  isRegister,
}: {
  auth: Auth;
  setError: (caller: string, error: any) => void;
  resetError: () => void;
  isRegister?: boolean;
}): React.ReactElement {
  const hypertune = useHypertune();
  const redirect = useLoginRedirectQuery({ skipPersonalize: !isRegister });

  return (
    <Button
      intent="neutral"
      weight="outlined"
      size="2x-large"
      className="mb-6 text-md font-semibold"
      icon={<img src="/google.svg" alt="" width={12} height={12} />}
      text={`${isRegister ? "Sign up" : "Sign in"} with Google`}
      onClick={async () => {
        resetError();
        const provider = new GoogleAuthProvider();

        try {
          await signInWithPopup(auth, provider);
          if (isRegister) {
            hypertune.events().signedUp();
          }
          console.debug("Signed in, redirecting...");

          await redirect();
        } catch (error) {
          setError("signInWithPopup", error);
        }
      }}
    />
  );
}
