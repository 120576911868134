import { ValueType } from "@hypertune/sdk/src/shared/types";

export default function valueTypesAreEqual(
  a: ValueType,
  b: ValueType
): boolean {
  if (a.type !== b.type) {
    return false;
  }
  switch (a.type) {
    case "VoidValueType":
    case "BooleanValueType":
    case "IntValueType":
    case "FloatValueType":
    case "StringValueType":
    case "RegexValueType":
      return true;
    case "EnumValueType":
      return a.type === b.type && a.enumTypeName === b.enumTypeName;
    case "ObjectValueType":
      return a.type === b.type && a.objectTypeName === b.objectTypeName;
    case "UnionValueType":
      return a.type === b.type && a.unionTypeName === b.unionTypeName;
    case "ListValueType":
      return (
        a.type === b.type &&
        valueTypesAreEqual(a.itemValueType, b.itemValueType)
      );
    case "FunctionValueType":
      return (
        a.type === b.type &&
        valueTypesAreEqual(a.returnValueType, b.returnValueType) &&
        a.parameterValueTypes.length === b.parameterValueTypes.length &&
        a.parameterValueTypes.every((aParameterValueType, index) =>
          valueTypesAreEqual(aParameterValueType, b.parameterValueTypes[index])
        )
      );
    default: {
      const neverValueType: never = a;
      throw new Error(`unexpected value type: ${neverValueType}`);
    }
  }
}
