import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AppState = {
  intercomInitialized: boolean;
  hideTooltips: boolean;
};

const initialState: AppState = {
  intercomInitialized: false,
  hideTooltips: false,
};

export const appSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setIntercomInitialized: (draftState, action: PayloadAction<boolean>) => {
      draftState.intercomInitialized = action.payload;
    },
    setHideTooltips: (draftState, action: PayloadAction<boolean>) => {
      draftState.hideTooltips = action.payload;
    },
  },
});

export const { setIntercomInitialized, setHideTooltips } = appSlice.actions;

export default appSlice.reducer;
