import { small } from "../lib/constants";
import { Intent, intentToHexColor, intentToHexColorFilter } from "./intent";

export default function ToggleLabel({
  label,
  isOpen,
  setIsOpen,
  intent = "neutral",
}: {
  label: string;
  isOpen: boolean;
  setIsOpen: (newIsOpen: boolean) => void;
  intent?: Intent;
}): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        userSelect: "none",
        color: intent !== "neutral" ? intentToHexColor(intent) : undefined,
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <img
        style={{
          transform: isOpen ? "none" : "rotate(-90deg)",
          transition: "transform 0.1s",
          filter:
            intent !== "neutral" ? intentToHexColorFilter(intent) : undefined,
        }}
        src="/down_arrow.svg"
        alt=""
        width={10}
        height={10}
      />
      <div style={{ marginLeft: small }}>{label}</div>
    </div>
  );
}
