import React, { useState } from "react";
import {
  ArrowFatLineRight,
  Copy,
  Globe,
  Trash,
  UserPlus,
} from "@phosphor-icons/react";
import { DateTime } from "luxon";
import BigIcon from "../../../components/BigIcon";
import Label from "../../../components/Label";
import Menu from "../../../components/Menu";
import { intentDangerHex, intentSuccessHex } from "../../../lib/constants";
import DeleteProjectModal from "./DeleteProjectModal";
import { Intent } from "../../../components/intent";
import CloneProjectModal from "./CloneProjectModal";
import TransferProjectModal from "./TransferProjectModal";
import NewBusinessModal from "../team/NewBusinessModal";

export default function ProjectCardContent({
  projectId,
  projectName,
  lastCommit,
  readOnly,
  showShare,
  showDeleteAndTransfer,
}: {
  projectId: string;
  projectName: string;
  lastCommit: string;
  readOnly: boolean;
  showShare: boolean;
  showDeleteAndTransfer: boolean;
}): React.ReactElement {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);

  return (
    <>
      <BigIcon icon={<Globe size={20} color="white" />} />
      <div>
        <Label type="title3">{projectName}</Label>
        <Label type="small-body" className="text-tx-muted">
          {`Last updated ${
            DateTime.fromISO(lastCommit).toRelative() || "unknown"
          }`}
        </Label>
      </div>

      {!readOnly && (
        <Menu
          showOnGroupHover
          items={[
            ...(showShare
              ? [
                  {
                    icon: <UserPlus />,
                    title: "Share",
                    iconActive: <UserPlus color={intentSuccessHex} />,
                    onClick: () => setShowNewBusinessModal(true),
                    intent: "success" as Intent,
                  },
                ]
              : [
                  ...(showDeleteAndTransfer
                    ? [
                        {
                          icon: <ArrowFatLineRight />,
                          title: "Transfer",
                          onClick: () => setShowTransferModal(true),
                          intent: "neutral" as Intent,
                        },
                      ]
                    : []),
                ]),
            {
              icon: <Copy weight="regular" />,
              title: "Clone",
              onClick: () => setShowCloneModal(true),
              intent: "neutral" as Intent,
            },
            ...(showDeleteAndTransfer
              ? [
                  {
                    icon: <Trash weight="regular" />,
                    iconActive: (
                      <Trash weight="regular" color={intentDangerHex} />
                    ),
                    title: "Delete",
                    onClick: () => setShowDeleteModal(true),
                    intent: "danger" as Intent,
                  },
                ]
              : []),
          ]}
        />
      )}

      {showDeleteModal && (
        <DeleteProjectModal
          projectId={projectId}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      {showTransferModal && (
        <TransferProjectModal
          projectId={projectId}
          projectName={projectName}
          onClose={() => setShowTransferModal(false)}
        />
      )}
      {showCloneModal && (
        <CloneProjectModal
          projectId={projectId}
          onClose={() => setShowCloneModal(false)}
        />
      )}
      {showNewBusinessModal && (
        <NewBusinessModal
          initialTransferProjects={[{ id: projectId, name: projectName }]}
          onClose={() => setShowNewBusinessModal(false)}
        />
      )}
    </>
  );
}
