import Container from "./Container";

import MarkdownView from "../../../components/MarkdownView";

export default function Step3UseClient({
  appConnected,
  selectedFrameworkIndex,
  data,
  onBack,
  onNext,
  formatDocs,
}: {
  appConnected: boolean;
  selectedFrameworkIndex: number;
  data: Map<number, string>;
  onBack: () => void;
  onNext: () => void;
  formatDocs: (docs: string) => string;
}): React.ReactElement {
  const markdown = formatDocs(data.get(selectedFrameworkIndex) || "");

  return (
    <Container
      onBack={onBack}
      nextText="Finish"
      onNext={onNext}
      nextDisabled={!appConnected}
    >
      <MarkdownView markdown={markdown} />
    </Container>
  );
}
