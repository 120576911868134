import { ApolloError } from "@apollo/client";
import { asError } from "@hypertune/sdk/src/shared";

export default function getErrorMessage(throwable: unknown): string {
  const error = asError(throwable);

  // It would be nice to modify the Apollo client behavior to do this for us. I
  // couldn't figure out how to do this though, given that their 'Link' onError
  // callback doesn't appear to support modifying the final error itself - just
  // performing actions on errors.
  if (error instanceof ApolloError) {
    if (error.graphQLErrors.length > 0 || error.clientErrors.length > 0) {
      return error.message;
    }

    if (
      error.networkError &&
      typeof error.networkError === "object" &&
      "result" in error.networkError &&
      typeof error.networkError.result === "object" &&
      "errors" in error.networkError.result &&
      Array.isArray(error.networkError.result.errors) &&
      error.networkError.result.errors.every(
        (v) => "message" in v && typeof v.message === "string"
      )
    ) {
      const combinedMessage = error.networkError.result.errors
        .map((v) => v.message)
        .join(". ");

      if (combinedMessage.endsWith(".")) {
        return combinedMessage;
      }
      return `${combinedMessage}.`;
    }
  }

  return error.message;
}
