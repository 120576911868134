import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TabsState = {
  [tabKey: string]: string;
};

const initialState: TabsState = {};

export const tabsSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setTabValue: (
      draftState,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      draftState[action.payload.key] = action.payload.value;
    },
  },
});

export const { setTabValue } = tabsSlice.actions;

export default tabsSlice.reducer;
