export default function asError(value: unknown): Error {
  if (value instanceof Error) {
    return value;
  }

  if (typeof value === "string") {
    return new Error(value);
  }

  try {
    const stringified = JSON.stringify(value);
    if (typeof stringified !== "string") {
      // Can happen in some edge cases, e.g. JSON.stringify(() => {})
      return new Error("Error that stringified to non-string value.");
    }
    return new Error(stringified);
  } catch (error) {
    // Indicates value was non-stringifyable, e.g. recursive object, BigInt
    return new Error("Error that couldn't be stringified.");
  }
}
