import ErrorMessageCard from "../../components/ErrorMessageCard";
import LogsEditor from "./LogsEditor";
import SettingsEditor from "./settings/SettingsEditor";
import SidebarContainer from "./SidebarContainer";
import { ProjectView } from "./projectHooks";
import DiffEditor from "./diff/DiffEditor";
import { draftCommitId } from "../../lib/constants";
import PullRequestEditor from "./pull-request/PullRequestEditor";
import BranchesEditor from "./BranchesEditor";

export default function LoadingOrErrorView({
  projectId,
  selectedView,
  commitId,
  error,
}: {
  projectId: string;
  selectedView: ProjectView;
  commitId?: string;
  error?: Error;
}): React.ReactElement | null {
  const loadingSidebar =
    selectedView === "logic" ||
    selectedView === "schema" ||
    selectedView === "splits" ||
    selectedView === "analytics";

  return (
    <div
      className={`flex h-full w-full flex-row items-stretch ${loadingSidebar ? `bg-bg-light ${selectedView !== "logic" ? "bg-dotted" : ""}` : ""}`}
    >
      {error ? (
        <div className="flex-flex-col w-full p-3">
          <ErrorMessageCard error={error} />
        </div>
      ) : loadingSidebar ? (
        <SidebarContainer.LoadingSkeleton />
      ) : selectedView === "settings" ? (
        <SettingsEditor.LoadingSkeleton projectId={projectId} />
      ) : selectedView === "branches" ? (
        <BranchesEditor.LoadingSkeleton />
      ) : selectedView === "logs" ? (
        <LogsEditor.LoadingSkeleton />
      ) : selectedView === "diff" ? (
        <DiffEditor.LoadingSkeleton
          isCurrent={!commitId || commitId === draftCommitId}
        />
      ) : selectedView === "pull-requests" ? (
        <PullRequestEditor.LoadingSkeleton />
      ) : null}
    </div>
  );
}
