import {
  differenceInCalendarDays,
  endOfDay,
  isEqual,
  isToday,
  startOfDay,
  subDays,
} from "date-fns";
import React from "react";
import { DateRangePicker } from "rsuite";
import { Calendar } from "@phosphor-icons/react";
import formatDate from "../lib/generic/formatDate";
import { TimeRange } from "../lib/types";

export default function TimeRangePicker({
  range,
  setRange,
}: {
  range: TimeRange;
  setRange: (newRange: TimeRange) => void;
}): React.ReactElement {
  return (
    <div className="relative flex flex-row items-center">
      <Calendar className="absolute left-[8px] top-[7px] z-10" weight="fill" />
      <DateRangePicker
        caretAs={emptyComponent}
        defaultValue={[range.start, range.end]}
        disabledDate={
          DateRangePicker.afterToday ? DateRangePicker.afterToday() : undefined
        }
        onChange={(value): void => {
          if (value && value.length === 2) {
            const start = startOfDay(value[0]);
            const end = endOfDay(value[1]);
            setRange({ start, end });
          }
        }}
        appearance="subtle"
        cleanable={false}
        editable={false}
        renderValue={(value): React.ReactNode => {
          if (value.length !== 2) {
            return "Select a date range...";
          }
          const start = value[0];
          const end = value[1];
          let prefix = "";
          if (isToday(end)) {
            if (isToday(start)) {
              prefix = "Today: ";
            } else if (isEqual(start, new Date(2022, 0))) {
              prefix = "All: ";
            } else {
              prefix = `Last ${differenceInCalendarDays(end, start) + 1} days: `;
            }
          }
          return `${prefix}${formatDate(start)} – ⁠${formatDate(end)}`;
        }}
        ranges={[
          {
            label: "Today",
            value: [new Date(), new Date()],
          },
          {
            label: "Last 7 days",
            value: [subDays(new Date(), 6), new Date()],
          },
          {
            label: "Last 30 days",
            value: [subDays(new Date(), 29), new Date()],
          },
          {
            label: "Last 90 days",
            value: [subDays(new Date(), 89), new Date()],
          },
          {
            label: "All",
            value: [new Date(2022, 0), new Date()],
          },
        ]}
      />
    </div>
  );
}

function emptyComponent(): null {
  return null;
}
