import { CaretDown } from "@phosphor-icons/react/dist/ssr";
import { CaretRight } from "@phosphor-icons/react";
import { Expression } from "@hypertune/sdk/src/shared";
import { useState } from "react";
import Button from "../../../components/buttons/Button";
import {
  Intent,
  intentToBorderClassName,
  intentToLightBgClassName,
} from "../../../components/intent";

export default function MetadataPanel({
  tags,
  expressionIntentMap,
  expression,
}: {
  tags: { [tagName: string]: true };
  expressionIntentMap: Record<string, Intent>;
  expression: Expression;
}): React.ReactElement {
  const [showTags, setShowTags] = useState<boolean>(true);
  return (
    <div className="rounded-xl! flex w-[100%] flex-col rounded-xl border border-solid border-base-grey-1-medium">
      <Button
        text="Metadata"
        icon={
          showTags ? (
            <CaretDown weight="bold" className="h-3 w-3" />
          ) : (
            <CaretRight weight="bold" className="h-3 w-3" />
          )
        }
        className={`border-gray-700 items-left justify-start rounded-none border-0 border-b border-solid p-[10.315px] text-base-grey-1-dark hover:rounded-tl-xl hover:rounded-tr-xl hover:!bg-transparent ${!showTags && "border-none"}`}
        onClick={() => {
          setShowTags(!showTags);
        }}
      />
      {showTags && (
        <>
          <div className="py-[13px] pl-3 text-start text-base-grey-1-dark">
            Labels
          </div>
          {Object.keys(tags).length > 0 && (
            <div className="flex flex-row gap-[7px] p-3 pt-0">
              {Object.entries(tags).map(([tagName]) => {
                const intent = Object.keys(expressionIntentMap).some(
                  (expressionIntentKey) => {
                    if (expressionIntentKey.includes("_metadata_")) {
                      const [_expressionId, _tagName] =
                        expressionIntentKey.split("_metadata_");
                      return (
                        _expressionId === expression.id && _tagName === tagName
                      );
                    }
                    return false;
                  }
                )
                  ? expressionIntentMap[`${expression?.id}_metadata_${tagName}`]
                  : "neutral";
                return (
                  <Button
                    intent={intent}
                    weight="minimal"
                    text={tagName}
                    className={`${intent !== "neutral" ? `${intentToLightBgClassName(intent)} ${intentToBorderClassName(intent)}` : " border-gray-700 !bg-white"} pointer-events-none h-[26px] max-w-fit rounded-[10px] p-[5px] pl-2 pr-2`}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}
