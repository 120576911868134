import { useState } from "react";
import { useParams } from "react-router-dom";
import Button, { Size, Weight } from "../../components/buttons/Button";
import { useSendVerifyAndInviteEmailMutation } from "../../generated/graphql";
import ModalWithContent from "../../components/ModalWithContent";
import { useHypertune } from "../../generated/hypertune.react";

export default function VerifyAndInviteEmailButton({
  text,
  weight = "outlined",
  size = "default",
}: {
  text?: string;
  weight?: Weight;
  size?: Size;
}): React.ReactElement | null {
  const content = useHypertune().content();
  const [sendVerifyAndInviteEmail, { loading }] =
    useSendVerifyAndInviteEmailMutation();
  const [showModal, setShowModal] = useState(false);
  const { verifyEmailAndInviteToken } = useParams();

  return (
    <div>
      <Button
        intent="primary"
        size={size}
        weight={weight}
        text={text || "Verify"}
        loading={loading}
        onClick={() => {
          sendVerifyAndInviteEmail({
            variables: { input: { verifyEmailAndInviteToken } },
          })
            .then(() => setShowModal(true))
            .catch((error) =>
              console.error("[sendVerifyAndInviteEmail]", error)
            );
        }}
      />
      {showModal && (
        <ModalWithContent
          onClose={() => setShowModal(false)}
          content={content.settings().verifyOrInviteEmailNotification().get()}
        />
      )}
    </div>
  );
}
