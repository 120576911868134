import { NavigateOptions, useSearchParams } from "react-router-dom";

export default function useSearchParamsState<T>(
  searchParamName: string,
  defaultValue: T
): readonly [
  searchParamsState: T,
  setSearchParamsState: (newState: T, options?: NavigateOptions) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam
    ? tryParseJsonOrDefault<T>(acquiredSearchParam, defaultValue)
    : defaultValue;

  if (typeof defaultValue === "boolean") {
    return [
      searchParamsState,
      (newState: T, options) => {
        setSearchParams(
          (currentSearchParams) =>
            updateSearchParamState(currentSearchParams, {
              searchParamName,
              value: newState ? 1 : 0,
            }),
          options
        );
      },
    ];
  }

  return [
    searchParamsState,
    (newState: T, options) => {
      setSearchParams(
        (currentSearchParams) =>
          updateSearchParamState(currentSearchParams, {
            searchParamName,
            value: newState,
          }),
        options
      );
    },
  ];
}

export type SearchParamUpdate = {
  searchParamName: string;
  value: any;
};

export function updateSearchParamState(
  searchParams: URLSearchParams,
  update: SearchParamUpdate | SearchParamUpdate[]
): URLSearchParams {
  const updates = Array.isArray(update) ? update : [update];
  const next = {
    ...[...searchParams.entries()].reduce(
      (o, [key, value]) => ({ ...o, [key]: value }),
      {}
    ),
    ...updates.reduce(
      (o, { searchParamName, value }) => ({
        ...o,
        [searchParamName]:
          typeof value === "string" ? value : JSON.stringify(value),
      }),
      {}
    ),
  };
  return next as URLSearchParams;
}

function tryParseJsonOrDefault<T>(rawJson: string, defaultValue: T): T {
  if (typeof defaultValue === "boolean") {
    return (rawJson === "1") as T;
  }
  if (typeof defaultValue === "string") {
    return rawJson as T;
  }
  try {
    return JSON.parse(rawJson);
  } catch (error) {
    console.error("Failed to parse json url state", error);

    return defaultValue;
  }
}
