import React from "react";
import twMerge from "../../lib/twMerge";

export default function CenteredContainer({
  hidden,
  children,
  className,
  childrenClassName,
}: {
  hidden?: boolean;
  children: React.ReactNode;
  className?: string;
  childrenClassName?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        hidden ? "hidden" : "flex",
        "h-full flex-grow justify-center overflow-auto bg-bg-light pt-10",
        className
      )}
    >
      <div
        className={twMerge(
          "h-full w-full px-2 md:w-[472px] lg:w-[672px] xl:w-[872px]",
          childrenClassName
        )}
      >
        {children}
        <div className="h-20" />
      </div>
    </div>
  );
}

export function CenteredContainerHeader({
  title,
  children,
  className,
}: {
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "mb-5 flex flex-row items-start justify-between",
        className
      )}
    >
      {typeof title === "string" ? (
        <div className="text-md font-semibold">{title}</div>
      ) : (
        title
      )}
      {children}
    </div>
  );
}
