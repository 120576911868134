import {
  checkSchemaName,
  formatFieldSchemaName,
  rootObjectTypeNameFromSchema,
  variablePathSuffix,
} from "@hypertune/shared-internal";
import { Schema, fieldPathSeparator } from "@hypertune/sdk/src/shared";
import { ObjectValueTypeConstraint } from "@hypertune/shared-internal/src/expression/types";
import RenameTopBar from "../../../components/RenameTopBar";
import ValueTypeConstraintIcon, {
  unwrapValueTypeConstraint,
} from "./ValueTypeConstraintIcon";
import { ExpressionNode } from "./toTree";
import { useRenameObjectField } from "../schema/schemaHooks";
import {
  SchemaCheckOrError,
  objectFieldNameError,
  showSchemaNameError,
} from "../schema/typeEditor/SchemaNameError";
import { toggleShowDetails } from "../projectSlice";
import { useAppDispatch } from "../../../app/hooks";

export type ExpressionViewTopBarProps = {
  readOnly: boolean;
  schema: Schema;
  selectedFieldPath: string | null;
  setSelectedFieldPath: (newSelectedFieldPath: string) => void;
  selectedExpressionNode: ExpressionNode;
  selectedExpressionNodeParent: ExpressionNode | null;
};

export default function ExpressionViewTopBar({
  readOnly,
  schema,
  selectedExpressionNode,
  selectedExpressionNodeParent,
  selectedFieldPath,
  setSelectedFieldPath,
}: ExpressionViewTopBarProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const renameObjectField = useRenameObjectField();

  const renameProps = getRenameProps({
    readOnly,
    schema,
    selectedExpressionNode,
    selectedExpressionNodeParent,
    selectedFieldPath,
    setSelectedFieldPath,
    renameObjectField,
  });

  return (
    <RenameTopBar
      icon={
        <ValueTypeConstraintIcon
          isVariable={!!selectedExpressionNode.variableContext}
          hasChildren={!!selectedExpressionNode.childExpressions}
          valueTypeConstraint={selectedExpressionNode.valueTypeConstraint}
          size="large"
        />
      }
      label={
        selectedExpressionNode.selectedFieldLabel ??
        selectedExpressionNode.fieldLabel
      }
      entityName="flag"
      disableConfirmDialog={!!selectedExpressionNode.variableContext}
      rename={renameProps?.rename}
      hasError={renameProps?.hasError}
      showError={showSchemaNameError}
      toggleSidebar={() => dispatch(toggleShowDetails())}
    />
  );
}

function getRenameProps({
  readOnly,
  schema,
  selectedExpressionNode,
  selectedExpressionNodeParent,
  selectedFieldPath,
  setSelectedFieldPath,
  renameObjectField,
}: ExpressionViewTopBarProps & {
  renameObjectField: (
    objectTypeName: string,
    fieldName: string,
    newFieldName: string
  ) => void;
}): {
  rename?: (newName: string) => Promise<void>;
  hasError?: (newText: string) => SchemaCheckOrError;
} | null {
  const canRename = selectedExpressionNode?.hasActions;

  if (readOnly || !canRename || !selectedFieldPath || !selectedExpressionNode) {
    return null;
  }

  const path = selectedExpressionNode.fullFieldPath.split(fieldPathSeparator);
  const fieldName = path[path.length - 1].replace(variablePathSuffix, "");

  if (selectedExpressionNode.variableContext) {
    return {
      rename: newRenameFunction(
        selectedExpressionNode,
        setSelectedFieldPath,
        (newName) => selectedExpressionNode.variableContext?.rename(newName)
      ),
      hasError: (newName) => {
        const newFormattedName = formatFieldSchemaName(newName);
        if (newFormattedName === fieldName) {
          return null;
        }
        const schemaCheck = checkSchemaName(newFormattedName);
        if (!schemaCheck.valid) {
          return schemaCheck;
        }
        // Check if a variable with conflicting name already exists
        if (
          selectedExpressionNodeParent?.childExpressions?.[
            newFormattedName + variablePathSuffix
          ]?.variableContext
        ) {
          return "A variable with this name already exists";
        }
        return null;
      },
    };
  }

  const objectTypeName =
    selectedExpressionNodeParent === null
      ? rootObjectTypeNameFromSchema(schema)
      : (
          unwrapValueTypeConstraint(
            selectedExpressionNodeParent.valueTypeConstraint
          ) as ObjectValueTypeConstraint
        ).objectTypeName;

  if (!objectTypeName) {
    return null;
  }

  return {
    rename: newRenameFunction(
      selectedExpressionNode,
      setSelectedFieldPath,
      (newName) => renameObjectField(objectTypeName, fieldName, newName)
    ),
    hasError: (newName) => {
      const newFormattedName = formatFieldSchemaName(newName);
      if (newFormattedName === fieldName) {
        return null;
      }
      return objectFieldNameError(
        schema,
        "flag",
        objectTypeName,
        newFormattedName
      );
    },
  };
}

function newRenameFunction(
  selectedExpressionNode: ExpressionNode,
  setSelectedFieldPath: (newSelectedFieldPath: string) => void,
  rename: (newName: string) => void
): (rawNewName: string) => Promise<void> {
  return (rawNewName) => {
    const newName = formatFieldSchemaName(rawNewName);
    const path = selectedExpressionNode.fullFieldPath.split(fieldPathSeparator);

    const newFullFieldPath = path
      .slice(0, -1)
      .concat([
        selectedExpressionNode.variableContext
          ? newName + variablePathSuffix
          : newName,
      ])
      .join(fieldPathSeparator) as string;

    rename(newName);

    setSelectedFieldPath(newFullFieldPath);

    return Promise.resolve();
  };
}
