import { Circle } from "@phosphor-icons/react";
import { LabeledOption } from "../components/TopBarDropdown";

export default function getLabeledOption(
  tagName: string,
  colorHex: string,
  showIconWhenSelected: boolean,
  isSelected: boolean
): LabeledOption<string> {
  return {
    label: tagName,
    value: tagName,
    icon: <Circle color={colorHex} size="9.85px" weight="fill" />,
    showIconWhenSelected,
    isSelected,
  };
}
