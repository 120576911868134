import { Schema } from "@hypertune/sdk/src/shared";
import getSchemaCodeFromSchema, {
  SchemaFilter,
  objectToCode,
} from "./getSchemaCodeFromSchema";
import { queryRootArgsObjectTypeName, queryObjectTypeName } from "../constants";

export const editableSchemaCodeFilter: SchemaFilter = {
  type: "exclude",
  values: new Set([queryObjectTypeName]),
};

export function splitSchemaCode(
  schema: Schema,
  schemaCode?: string
): {
  readOnlySchemaCode: string;
  editableSchemaCode: string;
} {
  const hasDefaultQuerySchema =
    Object.keys(schema.objects[queryRootArgsObjectTypeName].fields).length ===
      1 && !!schema.objects[queryRootArgsObjectTypeName].fields.context;

  const readOnlySchemaCode = hasDefaultQuerySchema
    ? objectToCode(schema, queryObjectTypeName)
    : "";
  const editableSchemaCode = hasDefaultQuerySchema
    ? getSchemaCodeFromSchema(schema, editableSchemaCodeFilter)
    : (schemaCode ?? getSchemaCodeFromSchema(schema));

  return { readOnlySchemaCode, editableSchemaCode };
}

export function getFullSchemaCode(
  readOnlySchemaCode: string,
  editableSchemaCode: string
): string {
  return readOnlySchemaCode
    ? `${readOnlySchemaCode}\n\n${editableSchemaCode}`
    : editableSchemaCode;
}
