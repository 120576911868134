import { uniqueId } from "@hypertune/sdk/src/shared/helpers";
import { Expression } from "@hypertune/sdk/src/shared";
import { queryRootArgsTypeName } from "../constants";

export default function getContextExpression(
  contextTypeName: string,
  rootArgsVariableId: string
): Expression {
  return {
    id: uniqueId(),
    type: "GetFieldExpression",
    fieldPath: "context",
    valueType: {
      type: "ObjectValueType",
      objectTypeName: contextTypeName,
    },
    object: {
      id: uniqueId(),
      type: "VariableExpression",
      variableId: rootArgsVariableId,
      valueType: {
        type: "ObjectValueType",
        objectTypeName: queryRootArgsTypeName,
      },
    },
  };
}
