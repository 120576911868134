import { lighterGreyHex } from "../../lib/constants";

export default function RightSidebar({
  className,
}: {
  className?: string;
}): React.ReactElement {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="8"
        y="2"
        width="5.5"
        height="10"
        rx="3.5"
        fill={lighterGreyHex}
        stroke={lighterGreyHex}
      />
      <rect x="8" y="2" width="2" height="10" stroke={lighterGreyHex} />
      <rect
        x="1"
        y="1"
        width="12"
        height="12"
        rx="3.5"
        stroke={lighterGreyHex}
      />
    </svg>
  );
}
