import PlusButton from "../../../../components/buttons/PlusButton";
import { useAppDispatch } from "../../../../app/hooks";
import { setNewSplitModalState } from "../../projectSlice";

export default function NewSplitButton(): React.ReactElement | null {
  const dispatch = useAppDispatch();

  return (
    <PlusButton
      onClick={() => {
        dispatch(setNewSplitModalState({}));
      }}
    />
  );
}
