import {
  Arm,
  BaseCommitData,
  Dimension,
  EventType,
  EventTypeMap,
  Expression,
  Logs,
  Split,
  SplitMap,
  mapExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import { teamGroupId } from "./constants";
import { Implementation } from "./types";
import { isEmptyPermissions } from "./permissions";

type IdMap = { [oldId: string]: string };

export default function copyCommitData(
  commitData: BaseCommitData
): BaseCommitData {
  return {
    // Schema code is safe to copy directly.
    schemaCode: commitData.schemaCode,
    // The rest of the fields needs to have all ids replaced.
    ...copyImplementation(commitData),
  };
}

export function copyImplementation(
  implementation: Implementation,
  options?: {
    namePrefix?: string;
    skipVariableCopy?: boolean;
  }
): Implementation {
  const featureIdMap: IdMap = {};
  const eventIdMap: IdMap = {};
  const splitIdMap: IdMap = {};
  const dimensionIdMap: IdMap = {};
  const armIdMap: IdMap = {};
  const expressionParamIdMap: IdMap = {};

  const namePrefix = options?.namePrefix || "";

  const newEventTypes: EventTypeMap = Object.fromEntries(
    Object.entries(implementation.eventTypes).map<[string, EventType]>(
      ([eventId, eventType]) => {
        const newEventId = uniqueId();
        eventIdMap[eventId] = newEventId;

        return [
          newEventId,
          {
            id: newEventId,
            name: namePrefix + eventType.name,
            featureIds: mapFeatureIds(featureIdMap, eventType.featureIds),
          },
        ];
      }
    )
  );
  const newSplits: SplitMap = Object.fromEntries(
    Object.values(implementation.splits).map<[string, Split]>((split) => {
      const newSplit = copySplit(split, namePrefix, dimensionIdMap, armIdMap);
      splitIdMap[split.id] = newSplit.id;
      return [newSplit.id, newSplit];
    })
  );
  const newExpression = mapExpression((expr: Expression | null) => {
    if (!expr) {
      return null;
    }

    const overrideFields: {
      id: string;
      logs: Logs;
      metadata: Expression["metadata"];
    } = {
      id: uniqueId(),
      logs: {},
      metadata: expr.metadata
        ? {
            ...expr.metadata,
            permissions: isEmptyPermissions(expr.metadata.permissions)
              ? undefined
              : {
                  group: { [teamGroupId]: { write: "allow" } },
                  user: {},
                },
          }
        : undefined,
    };
    if (expr.type === "FunctionExpression") {
      return {
        ...expr,
        ...overrideFields,
        parameters: expr.parameters.map((param) => {
          const newParamId = options?.skipVariableCopy
            ? param.id
            : expressionParamIdMap[param.id] || uniqueId();
          expressionParamIdMap[param.id] = newParamId;

          return {
            ...param,
            id: newParamId,
          };
        }),
        body: expr.body,
      };
    }
    if (expr.type === "VariableExpression") {
      const newVariableId = options?.skipVariableCopy
        ? expr.variableId
        : expressionParamIdMap[expr.variableId] || uniqueId();
      expressionParamIdMap[expr.variableId] = newVariableId;

      return {
        ...expr,
        ...overrideFields,
        variableId: newVariableId,
      };
    }
    if (expr.type === "LogEventExpression") {
      return {
        ...expr,
        ...overrideFields,
        eventTypeId: expr.eventTypeId ? eventIdMap[expr.eventTypeId] : null,
        featuresMapping: {},
      };
    }
    if (expr.type === "SplitExpression") {
      return {
        ...expr,
        ...overrideFields,
        dimensionId: expr.dimensionId ? dimensionIdMap[expr.dimensionId] : null,
        splitId: expr.splitId ? splitIdMap[expr.splitId] : null,
        dimensionMapping:
          expr.dimensionMapping.type === "discrete"
            ? {
                ...expr.dimensionMapping,
                cases: Object.fromEntries(
                  Object.entries(expr.dimensionMapping.cases).map(
                    ([armId, caseExpr]) => [armIdMap[armId], caseExpr]
                  )
                ),
              }
            : expr.dimensionMapping,
        featuresMapping: {},
      };
    }
    return {
      ...expr,
      ...overrideFields,
    };
  }, implementation.expression) as Expression;

  return {
    // The rest of the fields had all ids replaced.
    features: {},
    eventTypes: newEventTypes,
    splits: newSplits,
    expression: newExpression,
  };
}

export function copySplit(
  split: Split,
  namePrefix: string,
  dimensionIdMap: { [dimensionId: string]: string },
  armIdMap: { [armId: string]: string }
): Split {
  const name = namePrefix + split.name;
  const dimensions = Object.fromEntries(
    Object.entries(split.dimensions).map<[string, Dimension]>(
      ([dimensionId, dimension]) => {
        const newDimensionId = uniqueId();
        dimensionIdMap[dimensionId] = newDimensionId;

        return [
          newDimensionId,
          {
            ...dimension,
            id: newDimensionId,
            ...(dimension.type === "discrete"
              ? {
                  arms: Object.fromEntries(
                    Object.entries(dimension.arms).map<[string, Arm]>(
                      ([armId, arm]) => {
                        const newArmId = uniqueId();
                        armIdMap[armId] = newArmId;

                        return [
                          newArmId,
                          {
                            ...arm,
                            id: newArmId,
                            dimensionId: dimensionIdMap[arm.dimensionId],
                          },
                        ];
                      }
                    )
                  ),
                }
              : {}),
          },
        ];
      }
    )
  );
  if (split.type === "test") {
    return {
      id: uniqueId(),
      type: split.type,
      name,
      description: split.description,
      dimensions,
      eventObjectTypeName: split.eventObjectTypeName,
      featureIds: {},
    };
  }

  return {
    id: uniqueId(),
    type: split.type,
    name,
    description: split.description,
    dimensions,
    eventObjectTypeName: split.eventObjectTypeName,
    rewardEvents: split.rewardEvents,
    rewardSql: split.rewardSql,
    featureIds: {},
  };
}

function mapFeatureIds(
  featureIDMap: IdMap,
  featureIds: { [featureID: string]: true }
): { [featureID: string]: true } {
  return Object.fromEntries(
    Object.keys(featureIds).map<[string, true]>((featureId) => [
      featureIDMap[featureId],
      true,
    ])
  );
}
