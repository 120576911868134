import {
  DbVercelConnection,
  VercelEdgeConfigStoreWithTokens,
} from "@hypertune/shared-internal";
import { useMemo, useState } from "react";
import {
  BusinessesDocument,
  UpdateBusinessInput,
  useBusinessesQuery,
  useUpdateBusinessMutation,
  useVercelEdgeConfigStoresQuery,
} from "../../../generated/graphql";
import getErrorMessage from "../../../lib/query/getErrorMessage";

export function useHasVercelConnectionError(projectId?: string): boolean {
  const { data } = useBusinessesQuery();
  const { data: vercelStoresData } = useVercelEdgeConfigStoresQuery();

  const connections = JSON.parse(
    data?.primaryBusiness?.business.vercelConnectionsJson || "null"
  ) as DbVercelConnection[] | null;
  const storeIdToStore = storesJsonToStoreIdToStoreMap(
    vercelStoresData?.primaryBusiness?.business.vercelEdgeConfigStores || null
  );

  const hasError = useMemo(() => {
    if (!connections || !storeIdToStore) {
      return false;
    }

    return connections.some(
      (connection) =>
        (!projectId || connection.projectId?.toString() === projectId) &&
        connection.vercelEdgeConfigStoreId &&
        !storeIdToStore[connection.vercelEdgeConfigStoreId]
    );
  }, [projectId, connections, storeIdToStore]);

  return hasError;
}

export function storesJsonToStoreIdToStoreMap(
  storesJson: string | null
): Record<string, VercelEdgeConfigStoreWithTokens> | null {
  if (!storesJson) {
    return null;
  }
  const stores = JSON.parse(storesJson) as VercelEdgeConfigStoreWithTokens[];

  return Object.fromEntries(
    stores.filter((store) => !!store.id).map((store) => [store.id, store])
  );
}

export function useUpdateVercelEdgeConnections(businessId: string): {
  update: (newConnections: DbVercelConnection[], onSuccess: () => void) => void;
  loading: boolean;
  errorMessage: string;
} {
  const [errorMessage, setErrorMessage] = useState("");
  const [updateBusiness, { loading }] = useUpdateBusinessMutation({
    refetchQueries: [BusinessesDocument],
    awaitRefetchQueries: true,
  });

  return {
    loading,
    errorMessage,
    update: (newConnections, onSuccess) => {
      if (loading) {
        return;
      }
      setErrorMessage("");

      const input: UpdateBusinessInput = {
        id: businessId,
        vercelConnectionsJson: JSON.stringify(newConnections),
      };
      updateBusiness({ variables: { input } })
        .then(onSuccess)
        .catch((error) => {
          const message = getErrorMessage(error);
          setErrorMessage(message ?? "Error saving connections.");
          console.error("updateBusiness error:", message);
        });
    },
  };
}
