import Button from "../../../components/buttons/Button";
import { useProjectSelectedState } from "../projectHooks";

export default function ViewChangesButton({
  hasChanges,
}: {
  hasChanges: boolean;
}): React.ReactElement | null {
  const { setSelected } = useProjectSelectedState();

  return (
    <Button
      weight="elevated"
      size="large"
      disabled={!hasChanges}
      text="View changes"
      onClick={() => setSelected({ view: "diff" })}
    />
  );
}
