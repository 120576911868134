export default function getNestedValue(
  o: any,
  path: string[]
): any | undefined {
  let value = o;
  for (const key of path) {
    if (typeof value === "undefined") {
      return undefined;
    }
    value = value[key];
  }
  return value;
}
