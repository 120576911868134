import {
  GraphQLInputType,
  isEnumType,
  isInputObjectType,
  isListType,
  isNonNullType,
  isScalarType,
} from "graphql";
import { voidGraphqlScalarTypeName } from "../constants";

export default function isValidGraphqlInputType(
  inputType: GraphQLInputType
): boolean {
  if (!isNonNullType(inputType)) {
    return false;
  }
  const innerType = inputType.ofType;

  if (isListType(innerType)) {
    return isValidGraphqlInputType(innerType.ofType);
  }
  return (
    (isScalarType(innerType) && innerType.name !== voidGraphqlScalarTypeName) ||
    isEnumType(innerType) ||
    isInputObjectType(innerType)
  );
}
