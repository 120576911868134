/* eslint-disable @typescript-eslint/ban-ts-comment */

"use client";

import { cookieConsentCookieName } from "@hypertune/shared-internal/src/constants";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { blueHex, privacyPolicyUrl } from "../lib/constants";
import { useMeQuery } from "../generated/graphql";
import { useHypertune } from "../generated/hypertune.react";

export default function CookieConsentBanner(): React.ReactElement | null {
  const { refetch } = useMeQuery();
  const hypertune = useHypertune();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept all"
      cookieName={cookieConsentCookieName}
      extraCookieOptions={
        window.location.hostname.includes("hypertune.com")
          ? { domain: "hypertune.com" }
          : undefined
      }
      style={{ background: blueHex, alignItems: "center" }}
      buttonStyle={{
        fontSize: "13px",
        background: "white",
        borderRadius: "10px",
        fontWeight: 600,
      }}
      expires={365}
      onAccept={async () => {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        function gtag(): void {
          // @ts-ignore
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        }
        // @ts-ignore
        gtag("consent", "update", {
          analytics_storage: "granted",
        });
        await refetch();
      }}
    >
      {hypertune.website().cookieBannerText({
        fallback: "This website uses cookies to enhance the user experience.",
      })}{" "}
      <Link
        className="font-[500] underline"
        to={privacyPolicyUrl}
        target="_blank"
      >
        Privacy policy
      </Link>
    </CookieConsent>
  );
}
