import { ValueType } from "@hypertune/sdk/src/shared";

import { InnerValueTypeConstraint } from "../types";

export default function getConstraintFromValueType(
  valueType: ValueType
): InnerValueTypeConstraint {
  switch (valueType.type) {
    case "VoidValueType":
      return { type: "VoidValueTypeConstraint" };

    case "BooleanValueType":
      return { type: "BooleanValueTypeConstraint" };

    case "IntValueType":
      return { type: "IntValueTypeConstraint" };

    case "FloatValueType":
      return { type: "FloatValueTypeConstraint" };

    case "StringValueType":
      return { type: "StringValueTypeConstraint" };

    case "RegexValueType":
      return { type: "RegexValueTypeConstraint" };

    case "EnumValueType":
      return {
        type: "EnumValueTypeConstraint",
        enumTypeName: valueType.enumTypeName,
      };

    case "ObjectValueType":
      return {
        type: "ObjectValueTypeConstraint",
        objectTypeName: valueType.objectTypeName,
      };
    case "UnionValueType":
      return {
        type: "UnionValueTypeConstraint",
        unionTypeName: valueType.unionTypeName,
      };
    case "ListValueType":
      return {
        type: "ListValueTypeConstraint",
        itemValueTypeConstraint: getConstraintFromValueType(
          valueType.itemValueType
        ),
      };
    case "FunctionValueType":
      return {
        type: "FunctionValueTypeConstraint",
        parameterValueTypeConstraints: valueType.parameterValueTypes.map(
          (parameterValueType) => getConstraintFromValueType(parameterValueType)
        ),
        returnValueTypeConstraint: getConstraintFromValueType(
          valueType.returnValueType
        ),
      };
    default: {
      const neverValueType: never = valueType;
      throw new Error(
        `Unexpected value type type: ${JSON.stringify(neverValueType)}`
      );
    }
  }
}
