import React, { useEffect } from "react";
import {
  CommitSyncStatus,
  CommitSyncType,
  ProjectBranchQuery,
  useCommitSyncsQuery,
  useCreateCommitMutation,
} from "../../../generated/graphql";
import Toast, { ToastMessage } from "../../../components/Toast";
import getErrorMessage from "../../../lib/query/getErrorMessage";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  DraftCommit,
  FullDraftCommitDerivedData,
  setDraftCommit,
  setIsActivating,
  setRebaseState,
} from "../projectSlice";
import Button from "../../../components/buttons/Button";
import { draftCommitId, queryPollIntervalMs } from "../../../lib/constants";
import getCreateCommitInput from "../../../lib/query/getCreateCommitInput";
import { newCommitRefetchQueries } from "../../../lib/query/refetchQueries";
import { useProjectSelectedState } from "../projectHooks";
import { getPullRequestParams } from "../pull-request/pullRequestHooks";
import { useHypertune } from "../../../generated/hypertune.react";

export default function ActivateButton({
  projectId,
  branch,
  isAlreadyActive,
  setSelectedCommitId,
  commitId,
  commitMessage,
  draftCommit,
  draftCommitDerivedData,
}: {
  projectId: string;
  branch: ProjectBranchQuery["projectBranch"];
  isAlreadyActive: boolean;
  setSelectedCommitId: (newCommitId: string) => void;
  commitId: string | null;
  commitMessage?: string;
  draftCommit?: DraftCommit;
  draftCommitDerivedData: FullDraftCommitDerivedData;
}): React.ReactElement | null {
  const hypertune = useHypertune();
  const { setSelected } = useProjectSelectedState();
  const dispatch = useAppDispatch();
  const rebase = useAppSelector((state) => state.project.rebase);
  const isCreatingNewCommit = commitId === null;
  const isActivating = useAppSelector((state) => state.project.isActivating);
  const hasError =
    !!draftCommitDerivedData.schemaCodeError ||
    !!draftCommitDerivedData.logicError ||
    !!draftCommitDerivedData.splitsError;

  const disabledMessage = isActivating
    ? "Saving..."
    : isAlreadyActive
      ? "Already active"
      : !draftCommit
        ? "Loading..."
        : // If it's a draft, require a change and validation
          isCreatingNewCommit && !draftCommitDerivedData.hasChanges
          ? "No changes"
          : isCreatingNewCommit && hasError
            ? "Draft has errors"
            : undefined;
  const disabled = disabledMessage !== undefined;

  const [createCommit] = useCreateCommitMutation({
    refetchQueries: newCommitRefetchQueries,
    awaitRefetchQueries: true,
  });

  const [pollSyncsForCommitId, setPollSyncsForCommitId] = React.useState<{
    commitId: string;
    baseMessages: ToastMessage[];
  } | null>(null);
  const [toast, internalSetToast] = React.useState<{
    messages: ToastMessage[];
    intervalId?: NodeJS.Timeout;
  } | null>(null);

  const setToast = React.useCallback(
    (messages: ToastMessage[], skipClear?: boolean) => {
      internalSetToast((currentToast) => {
        if (currentToast?.intervalId) {
          clearInterval(currentToast.intervalId);
        }
        const intervalId = !skipClear
          ? setTimeout(() => {
              internalSetToast(null);
            }, 3000)
          : undefined;
        return { messages, intervalId };
      });
    },
    [internalSetToast]
  );

  if (!branch) {
    return null;
  }

  const nextCommitVersion = branch.commits.length + 1;
  const activeCommitId = branch.activeCommit.id;

  async function activate(): Promise<void> {
    let message: string;

    if (!draftCommit) {
      setToast([{ type: "error", text: "Missing data for commit." }]);
      return;
    }

    if (isCreatingNewCommit && draftCommit) {
      message = `v${nextCommitVersion}`;
    } else {
      // Rollback
      if (!commitId || !commitMessage) {
        setToast([
          { type: "error", text: "Missing data for commit to roll back to." },
        ]);
        return;
      }
      message = `v${nextCommitVersion} (rollback to ${commitMessage})`;
    }
    const input = getCreateCommitInput({
      projectId,
      message,
      draftCommit,
      parentId: activeCommitId,
      branchName: branch.name,
    });
    if (rebase) {
      input.message = rebase.commitMessage;
      input.rebaseBranchName = rebase.branchName;
    }

    const resp = await createCommit({ variables: { input } });
    const pollSyncs =
      resp.data?.createCommit.syncs &&
      resp.data.createCommit.syncs.some(
        ({ status }) => status === CommitSyncStatus.Pending
      );
    const baseMessages: ToastMessage[] = [
      { type: "success", text: `Created new commit "${message}".` },
    ];
    setToast(
      [
        ...baseMessages,
        ...(resp.data?.createCommit.syncs
          ? graphqlSyncsToToastMessages(resp.data.createCommit.syncs)
          : []),
      ],
      /* skipClear */ pollSyncs
    );
    if (resp.data?.createCommit && pollSyncs) {
      setPollSyncsForCommitId({
        baseMessages,
        commitId: resp.data.createCommit.newCommitId,
      });
    }
    if (!isCreatingNewCommit && !resp.data?.createCommit.newBranchName) {
      dispatch(setDraftCommit(draftCommit));
      setSelectedCommitId(draftCommitId);
    }
    if (rebase) {
      dispatch(setRebaseState(undefined));
    }
    if (
      resp.data?.createCommit.newBranchName &&
      resp.data?.createCommit.newPullRequestId
    ) {
      setSelected({
        branchName: resp.data.createCommit.newBranchName,
        view: "pull-requests",
        searchParams: getPullRequestParams(
          resp.data.createCommit.newPullRequestId.toString(),
          /* showShareModal */ true
        ),
      });
    }
  }

  async function onSubmit(): Promise<void> {
    if (disabled) {
      return;
    }

    dispatch(setIsActivating(true));
    try {
      await activate();
      hypertune.events().commitCreated();
      console.debug("ActivateButton logged commitCreated");
    } catch (error) {
      console.error("Activate commit error:", error);
      setToast([{ type: "error", text: getErrorMessage(error) }]);
    } finally {
      dispatch(setIsActivating(false));
    }
  }

  return (
    <div
      style={{
        position: "relative",
        display: commitId === branch.activeCommit.id ? "none" : "block",
      }}
    >
      <Button
        intent="primary"
        weight="elevated"
        size="large"
        disabled={disabled}
        title={disabledMessage}
        onClick={onSubmit}
        text={
          rebase
            ? "Squash and rebase"
            : isCreatingNewCommit
              ? "Save"
              : "Roll back"
        }
        loading={isActivating}
      />
      {toast ? <Toast messages={toast.messages} /> : null}
      {pollSyncsForCommitId ? (
        <SyncPoller
          commitId={pollSyncsForCommitId.commitId}
          baseMessages={pollSyncsForCommitId.baseMessages}
          setToast={setToast}
          stop={() => setPollSyncsForCommitId(null)}
        />
      ) : null}
    </div>
  );
}

function SyncPoller({
  commitId,
  baseMessages,
  setToast,
  stop,
}: {
  commitId: string;
  baseMessages: ToastMessage[];
  setToast: (messages: ToastMessage[], skipClear?: boolean) => void;
  stop: () => void;
}): React.ReactElement | null {
  const { data, startPolling, stopPolling } = useCommitSyncsQuery({
    variables: { commitId },
  });
  useEffect(() => {
    if (!data) {
      return;
    }
    const keepPolling = data.commitSyncs.some(
      ({ status }) => status === CommitSyncStatus.Pending
    );
    setToast(
      [...baseMessages, ...graphqlSyncsToToastMessages(data.commitSyncs)],
      /* skipClear */ keepPolling
    );
    if (!keepPolling) {
      stop();
    }
  }, [baseMessages, data, setToast, stop]);
  useEffect(() => {
    startPolling(queryPollIntervalMs);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return null;
}

function graphqlSyncsToToastMessages(
  messages: { type: CommitSyncType; status: CommitSyncStatus }[]
): ToastMessage[] {
  return messages.map(({ type, status }) => {
    return {
      type:
        status === CommitSyncStatus.Success
          ? "success"
          : status === CommitSyncStatus.Error
            ? "error"
            : "loading",
      text: `${
        status === "Success"
          ? "Synced commit to"
          : status === "Error"
            ? "Failed to sync commit to"
            : "Syncing commit to"
      } ${type === CommitSyncType.Edge ? "Hypertune Edge" : type}`,
    };
  });
}
