import { Expression, Schema } from "@hypertune/sdk/src/shared";
import {
  BaseExpressionForValueTypeOptions,
  getBaseExpressionOptionsForValueType,
} from "./getExpressionOptionGroups";
import getValueTypeFromConstraint from "./constraint/getValueTypeFromConstraint";
import { ValueTypeConstraint, VariableMap } from "./types";

// eslint-disable-next-line max-params
export default function getDefaultExpression(
  schema: Schema,
  variables: VariableMap,
  valueTypeConstraint: ValueTypeConstraint,
  /**
   * Set that keeps track of what object types we've seen.
   * This allows us to avoid recursing infinitely on recursive types.
   * We don't use depth checking because we want to fill deeply nested objects.
   *
   * If you're calling this function non-recursively, pass in `new Set()`.
   * */
  seenObjectTypeNames: Set<string>,
  options?: BaseExpressionForValueTypeOptions
): Expression | null {
  if (valueTypeConstraint.type === "ErrorValueTypeConstraint") {
    return null;
  }

  const valueType = getValueTypeFromConstraint(valueTypeConstraint);

  if (!valueType || valueType.type === "UnionValueType") {
    return null;
  }

  const expression: Expression | null =
    getBaseExpressionOptionsForValueType(
      schema,
      variables,
      valueType,
      seenObjectTypeNames,
      options
    )[0] || null;

  return expression;
}
