import { defaultBranchName } from "@hypertune/sdk/src/shared";
import Button from "../../../components/buttons/Button";
import {
  ProjectDocument,
  ProjectQuery,
  useCreatePullRequestMutation,
} from "../../../generated/graphql";
import { useNavigateToPR } from "../pull-request/pullRequestHooks";

export default function CreatePullRequestButton({
  projectId,
  selectedBranchName,
  branches,
  pullRequest,
  canContribute,
}: {
  projectId: string;
  selectedBranchName: string;
  branches?: ProjectQuery["project"]["branches"];
  pullRequest?: ProjectQuery["project"]["pullRequests"][number];
  canContribute: boolean;
}): React.ReactElement | null {
  const navigateToPR = useNavigateToPR();
  const [createPullRequest, { loading }] = useCreatePullRequestMutation({
    refetchQueries: [ProjectDocument],
    awaitRefetchQueries: true,
  });

  const intoBranch = branches?.find(
    (branch) => branch.name === defaultBranchName
  );
  const fromBranch = branches?.find(
    (branch) => branch.name === selectedBranchName
  );

  if (
    !canContribute ||
    selectedBranchName === defaultBranchName ||
    !intoBranch ||
    !fromBranch ||
    intoBranch.activeCommit.id === fromBranch.activeCommit.id ||
    pullRequest
  ) {
    return null;
  }

  return (
    <Button
      intent="success"
      weight="elevated"
      size="large"
      onClick={async () => {
        const resp = await createPullRequest({
          variables: {
            input: {
              projectId,
              fromBranchId: fromBranch.id,
              intoBranchId: intoBranch.id,
            },
          },
        });
        if (resp.data?.createPullRequest) {
          navigateToPR(resp.data?.createPullRequest);
        }
      }}
      loading={loading}
      text="Create pull request"
    />
  );
}
