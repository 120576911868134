import {
  ComparisonExpression,
  ComparisonOperator,
  Expression,
} from "@hypertune/sdk/src/shared";
import getComparisonExpression from "./getComparisonExpression";

export default function wrapExpressionWithComparison(
  inner: Expression | null,
  operator: ComparisonOperator | null
): ComparisonExpression {
  return {
    ...getComparisonExpression(),
    a: inner,
    b: getComparisonExpression(),
    operator,
  };
}
