import {
  ApplicationExpression,
  ArithmeticExpression,
  ArithmeticOperator,
  arithmeticOperators,
  Expression,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import createApplication from "../../../lib/expression/createApplication";
import {
  liftPermissionsDeniedErrorMessage,
  singlePanelHeight,
  small,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../lib/types";
import Dropdown, { LabeledOption } from "../../../components/Dropdown";
import ExpressionControl from "./ExpressionControl";
import isReadOnly from "../../../lib/expression/isReadOnly";

export default function ArithmeticExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: ArithmeticExpression;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const childValueTypeConstraint: ValueTypeConstraint =
    expression.valueType.type === "IntValueType"
      ? { type: "IntValueTypeConstraint" }
      : { type: "FloatValueTypeConstraint" };

  const options = arithmeticOperators.map(toLabeledOption);

  const readOnly = isReadOnly(context);
  const operatorIntent =
    context.expressionIdToIntent?.[`${expression.id}operator`] ?? "neutral";

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: small }}>
      <ExpressionControl
        context={context}
        variables={variables}
        setVariableName={setVariableName}
        valueTypeConstraint={childValueTypeConstraint}
        expression={expression.a}
        setExpression={(newExpression: Expression | null): void =>
          setExpression({
            ...expression,
            a: newExpression,
          })
        }
        lift={(child): void => {
          if (readOnly) {
            // eslint-disable-next-line no-alert
            alert(liftPermissionsDeniedErrorMessage);
            return;
          }
          function replaceArgument(
            variable: VariableExpression | ApplicationExpression
          ): ArithmeticExpression {
            const newExpression: ArithmeticExpression = {
              ...expression,
              a: child.replaceArgument(variable),
            };
            return newExpression;
          }
          const applicationExpression = createApplication({
            variables,
            rawArgument: child.argument,
            replacedVariableIdToNewVariable:
              child.replacedVariableIdToNewVariable,
            valueType: expression.valueType,
            replaceArgument,
            newVariableName: child.newVariableName,
            setExpressionEditorSelectedItem: (newSelectedItem) =>
              context.setExpressionEditorState({
                ...context.expressionEditorState,
                selectedItem: newSelectedItem,
              }),
          });
          setExpression(applicationExpression);
        }}
        parentExpression={expression}
        setParentExpression={setExpression}
        includeExpressionOption={includeExpressionOption}
      />
      <Dropdown
        intent={operatorIntent}
        height={singlePanelHeight}
        options={{ type: "options", options }}
        value={
          expression.operator ? toLabeledOption(expression.operator) : null
        }
        placeholder="Select operator..."
        noOptionsMessage="No operators"
        onChange={(option) => {
          if (!option) {
            return;
          }
          const operator = option.value as ArithmeticOperator;
          setExpression({ ...expression, operator });
        }}
      />
      <ExpressionControl
        context={context}
        variables={variables}
        setVariableName={setVariableName}
        valueTypeConstraint={childValueTypeConstraint}
        expression={expression.b}
        setExpression={(newExpression: Expression | null): void =>
          setExpression({
            ...expression,
            b: newExpression,
          })
        }
        lift={(child): void => {
          if (readOnly) {
            // eslint-disable-next-line no-alert
            alert(liftPermissionsDeniedErrorMessage);
            return;
          }
          function replaceArgument(
            variable: VariableExpression | ApplicationExpression
          ): ArithmeticExpression {
            const newExpression: ArithmeticExpression = {
              ...expression,
              b: child.replaceArgument(variable),
            };
            return newExpression;
          }
          const applicationExpression = createApplication({
            variables,
            rawArgument: child.argument,
            replacedVariableIdToNewVariable:
              child.replacedVariableIdToNewVariable,
            valueType: expression.valueType,
            replaceArgument,
            newVariableName: child.newVariableName,
            setExpressionEditorSelectedItem: (newSelectedItem) =>
              context.setExpressionEditorState({
                ...context.expressionEditorState,
                selectedItem: newSelectedItem,
              }),
          });
          setExpression(applicationExpression);
        }}
        parentExpression={expression}
        setParentExpression={setExpression}
        includeExpressionOption={includeExpressionOption}
      />
    </div>
  );
}

function toLabeledOption(
  operator: ArithmeticOperator
): LabeledOption<ArithmeticOperator> {
  return { value: operator, label: operator };
}
