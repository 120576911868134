import { Expression, mapExpression } from "@hypertune/sdk/src/shared";
import { formatEnumValueSchemaName } from "../schema/schemaOperationsEnum";
import { renameEnumInValueType } from "../schema/valueTypeOperations";

export function renameEnumInExpression(
  expression: Expression | null,
  oldEnumTypeName: string,
  newEnumTypeName: string
): Expression {
  return mapExpression((expr) => {
    if (expr) {
      return {
        ...expr,
        valueType: renameEnumInValueType(
          expr.valueType,
          oldEnumTypeName,
          newEnumTypeName
        ),
      } as Expression;
    }
    return expr;
  }, expression) as Expression;
}

export function renameEnumValueInExpression(
  expression: Expression,
  enumTypeName: string,
  oldValueName: string,
  rawNewValueName: string
): Expression {
  const newValueName = formatEnumValueSchemaName(rawNewValueName);

  return mapExpression((expr) => {
    if (
      expr &&
      expr.type === "EnumExpression" &&
      expr.valueType.enumTypeName === enumTypeName &&
      expr.value === oldValueName
    ) {
      return {
        ...expr,
        value: newValueName,
      };
    }
    if (
      expr &&
      expr.type === "EnumSwitchExpression" &&
      expr.control?.valueType.type === "EnumValueType" &&
      expr.control?.valueType.enumTypeName === enumTypeName
    ) {
      return {
        ...expr,
        value: newValueName,
        cases: Object.fromEntries(
          Object.entries(expr.cases).map(([enumValueName, field]) => [
            enumValueName === oldValueName ? newValueName : enumValueName,
            field,
          ])
        ),
      };
    }
    return expr;
  }, expression) as Expression;
}
