import twMerge from "../lib/twMerge";
import { Intent } from "./intent";

export default function Tag({
  icon,
  text,
  intent,
  className,
}: {
  icon?: React.ReactNode;
  text: string;
  intent: Intent;
  className?: string;
}): React.ReactElement | null {
  const colors = intentToColors(intent);
  return (
    <div
      className={twMerge(
        `flex flex-row items-center gap-1 rounded-md px-2 py-1 ${colors.bg}`,
        className
      )}
    >
      {icon || null}
      <div className={`text-sm leading-none ${colors.text}`}>{text}</div>
    </div>
  );
}

function intentToColors(intent: Intent): { bg: string; text: string } {
  switch (intent) {
    case "primary":
      return {
        bg: "bg-bg-primary",
        text: "text-base-blue",
      };
    case "success":
      return {
        bg: "bg-intent-success/10",
        text: "text-intent-success",
      };
    case "danger":
      return {
        bg: "bg-intent-danger/10",
        text: "text-intent-danger",
      };
    case "warning":
      return {
        bg: "bg-intent-warning/10",
        text: "text-intent-warning",
      };
    case "neutral":
      return {
        bg: "bg-bg-pressed",
        text: "text-tx-muted",
      };
    default: {
      const neverType: never = intent;
      throw new Error(`unexpected tag type: "${neverType}"`);
    }
  }
}
