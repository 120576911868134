import Button from "../../../components/buttons/Button";
import { ProjectQuery } from "../../../generated/graphql";
import { useNavigateToPR } from "../pull-request/pullRequestHooks";

export default function GoToPullRequestButton({
  pullRequest,
}: {
  pullRequest: ProjectQuery["project"]["pullRequests"][number];
}): React.ReactElement | null {
  const navigateToPR = useNavigateToPR();

  return (
    <Button
      intent="neutral"
      weight="elevated"
      size="large"
      onClick={() => navigateToPR(pullRequest.id)}
      text="Go to pull request"
    />
  );
}
