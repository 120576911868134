import toWords from "@hypertune/sdk/src/shared/helpers/toWords";

export default function matchesSearch(
  searchText: string,
  values: string[]
): boolean {
  const search = searchTransform(searchText);

  return values.some((value) => searchTransform(value).includes(search));
}

function searchTransform(text: string): string {
  return toWords(text).join("").toLowerCase();
}
