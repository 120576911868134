import { blackHex, intentDangerHex, intentWarningHex } from "../lib/constants";
import ErrorCircle from "./icons/ErrorCircle";
import ErrorTooltip from "./input/ErrorTooltip";

export default function ErrorMessage({
  hideIcon,
  showOnHover,
  intent = "danger",
  errorMessage,
  noErrorsMessage,
  style,
}: {
  hideIcon?: boolean;
  showOnHover?: boolean;
  intent?: "danger" | "warning";
  errorMessage?: string | null;
  noErrorsMessage?: string | null;
  style?: React.CSSProperties;
}): React.ReactElement {
  const errorText = errorMessage ?? noErrorsMessage ?? "No errors.";
  return (
    <div
      style={{
        display: errorMessage || noErrorsMessage ? "flex" : "none",
        color: errorMessage
          ? intent === "danger"
            ? intentDangerHex
            : intentWarningHex
          : blackHex,
        flexDirection: "row",
        alignItems: "center",
        ...(style || {}),
      }}
    >
      {errorMessage && !hideIcon && !showOnHover ? (
        <ErrorCircle
          intent={intent}
          style={{ marginRight: 6, flexShrink: 0 }}
        />
      ) : null}
      {showOnHover ? (
        <ErrorTooltip position="top" error={errorText} showDetailOnHover />
      ) : (
        <div>{errorText}</div>
      )}
    </div>
  );
}
