import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { isValidEmail } from "@hypertune/shared-internal";
import { darkBlueHex, normal } from "../../lib/constants";
import TextInput from "../../components/input/TextInput";
import { onUpdateProfile } from "../../lib/query/auth";
import trackAnalyticsEvent from "../../lib/trackAnalyticsEvent";
import ContainerWithLogo from "../../components/container/ContainerWithLogo";
import Divider from "./Divider";
import GoogleLogin from "./GoogleLogin";
import {
  locationStateWithEmail,
  getEmailFromLocation,
} from "../../lib/query/locationState";
import Button from "../../components/buttons/Button";
import useLoginRedirectQuery from "../../lib/hooks/useLoginRedirectQuery";
import useFirebaseErrorMessage from "./useFirebaseErrorMessage";

export default function LoginPage(): React.ReactElement {
  useEffect(() => {
    document.title = "Login - Hypertune";
  }, []);

  const auth = getAuth();
  const location = useLocation();
  const redirect = useLoginRedirectQuery({ skipPersonalize: true });

  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const { errorMessage, setError, resetError } = useFirebaseErrorMessage();

  const [email, setEmail] = React.useState<string>(
    getEmailFromLocation(location)
  );
  const [password, setPassword] = React.useState<string>("");

  useEffect(() => {
    if (auth.currentUser && !inProgress) {
      redirect().catch((error) =>
        console.error("Login redirect failed", error)
      );
    }
  });

  useEffect(() => {
    trackAnalyticsEvent("login_page_view");
  }, []);

  const isValid = isValidEmail(email) && password.length > 0;
  const passwordRef = useRef<HTMLInputElement>(null);

  async function onSubmit(): Promise<void> {
    if (!isValid || inProgress) {
      return;
    }
    setInProgress(true);
    resetError();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await onUpdateProfile();
      await redirect();
    } catch (error) {
      setError("signInWithEmailAndPassword", error);
      setInProgress(false);
    }
  }

  return (
    <ContainerWithLogo errorMessage={errorMessage}>
      <GoogleLogin auth={auth} setError={setError} resetError={resetError} />
      <Divider />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
        onSubmit={async (event) => {
          event.preventDefault();
          if (!password) {
            passwordRef.current?.focus();
            return;
          }
          await onSubmit();
        }}
      >
        <div style={{ marginBottom: normal }}>Email address</div>
        <TextInput
          size="large"
          style={{ marginBottom: 24, fontSize: 14 }}
          value={email}
          trimOnBlur
          focusOnMount
          placeholder="Email address"
          readOnly={inProgress}
          autoComplete="email"
          onChange={(newValue: string) => {
            setEmail(newValue.toLowerCase());
          }}
        />
        <div style={{ marginBottom: normal }}>Password</div>
        <TextInput
          elementRef={passwordRef}
          size="large"
          style={{ marginBottom: 24, fontSize: 14 }}
          value={password}
          trimOnBlur
          focusOnMount={getEmailFromLocation(location) !== ""}
          placeholder="Password"
          isPassword
          readOnly={inProgress}
          autoComplete="current-password"
          onChange={(newValue: string) => {
            setPassword(newValue);
          }}
        />
        {/* Allows for submitting the form on hitting enter (because then it triggers the form's onSubmit). This is better than listening to keydown events ourselves as it supports accessibility tools better and simplifies our input logic. */}
        <input type="submit" hidden />
        <Button
          weight="filled"
          size="2x-large"
          intent="primary"
          disabled={!isValid}
          text="Sign In"
          loading={inProgress}
          loadingText="Signing In..."
          onClick={onSubmit}
        />
        <div className="mt-6 text-center">
          <Link
            style={{ color: darkBlueHex }}
            to="/reset-password"
            state={locationStateWithEmail(location, email)}
            replace
          >
            Forgot password?
          </Link>
          {" · "}
          <Link
            style={{ color: darkBlueHex }}
            to="/register"
            state={locationStateWithEmail(location, email)}
            replace
          >
            Register
          </Link>
        </div>
      </form>
    </ContainerWithLogo>
  );
}
