import { EventTypeMap, Schema, uniqueId } from "@hypertune/sdk/src/shared";
import {
  addEmptyObject,
  addFieldToObject,
  formatTypeSchemaName,
} from "./schema/schemaOperationsObject";
import getNextName from "./getNextName";

export default function reconcileSchemaAndEventTypeMap(
  schema: Schema,
  eventTypeMap: EventTypeMap,
  options?: {
    discardExtraEventTypesInTypeMap?: boolean;
  }
): {
  newSchema: Schema;
  newEventTypeMap: EventTypeMap;
} {
  const schemaEvents = Object.fromEntries(
    Object.entries(schema.objects).filter(
      ([, objectSchema]) => objectSchema.role === "event"
    )
  );

  // eventTypeNames contains all type names that are already unavailable
  // as they are used in non event types or they are used in event types.
  const eventTypeNames: { [name: string]: boolean } = Object.fromEntries(
    Object.entries(schema.objects)
      .filter(([, objectSchema]) => objectSchema.role !== "event")
      .map(([name]) => [name, true])
      .concat(Object.keys(schema.enums).map((name) => [name, true]))
      .concat(Object.keys(schema.unions).map((name) => [name, true]))
  );
  const eventTypeMapByName = Object.fromEntries(
    Object.values(eventTypeMap).map((eventType) => {
      const name = getNextName(
        eventTypeNames,
        formatTypeSchemaName(eventType.name)
      );
      return [name, { ...eventType, name }];
    })
  );

  const newSchema = options?.discardExtraEventTypesInTypeMap
    ? schema
    : Object.values(eventTypeMapByName).reduce((currentSchema, eventType) => {
        if (eventType.name in schemaEvents) {
          return currentSchema;
        }
        // Add missing event with unitId field
        return addFieldToObject(
          addEmptyObject(currentSchema, eventType.name, "event", null),
          eventType.name,
          "unitId",
          { type: "StringValueType" },
          /* description */ null
        );
      }, schema);

  const startEventTypeMap = Object.fromEntries(
    Object.values(eventTypeMapByName)
      .filter(
        (eventType) =>
          !options?.discardExtraEventTypesInTypeMap ||
          eventType.name in schemaEvents
      )
      .map((eventType) => [eventType.id, eventType])
  );

  const newEventTypeMap = Object.keys(schemaEvents).reduce(
    (currentEventTypes, eventName) => {
      if (eventName in eventTypeMapByName) {
        return currentEventTypes;
      }
      const newEventId = uniqueId();
      return {
        ...currentEventTypes,
        [newEventId]: { id: newEventId, name: eventName, featureIds: {} },
      };
    },
    startEventTypeMap
  );

  return { newSchema, newEventTypeMap };
}
