import React, { useEffect } from "react";
import {
  ArrowsSplit,
  ChartBar,
  CodesandboxLogo,
  CursorClick,
  FileText,
  Flag,
  GearSix,
  GitBranch,
  GitCommit,
  GitPullRequest,
  PlayCircle,
} from "@phosphor-icons/react";
import toWords from "@hypertune/sdk/src/shared/helpers/toWords";
import Sidebar from "../../../components/Sidebar";
import { iconColor, iconWeight } from "../../../components/icons/icons";
import { useHasVercelConnectionError } from "../../business/vercel/hooks";
import { ProjectView, projectViews } from "../projectHooks";
import useShowSetupModal from "../setup/useShowSetupModal";
import { FullDraftCommitDerivedData } from "../projectSlice";

const viewNamesMap = Object.fromEntries(
  projectViews.map((view) => [
    view,
    toWords(view)
      .map((word, index) =>
        index === 0 ? word[0].toUpperCase() + word.slice(1) : word
      )
      .join(" "),
  ])
);

export default function ProjectSidebar({
  projectId,
  showSetup,
  commitDerivedData,
  selectedView,
  setSelectedView,
  setIsCommandPaletteVisible,
}: {
  projectId?: string;
  showSetup: boolean;
  commitDerivedData: FullDraftCommitDerivedData;
  selectedView: ProjectView;
  setSelectedView: (view: ProjectView) => void;
  setIsCommandPaletteVisible: (isVisible: boolean) => void;
}): React.ReactElement {
  const [, setShowSetupModal] = useShowSetupModal();
  const hasVercelError = useHasVercelConnectionError(projectId);

  useEffect(() => {
    if (selectedView !== "logic") {
      document.title = `${selectedView === "settings" ? "Project Settings" : viewNamesMap[selectedView]} - Hypertune`;
    }
  }, [selectedView]);

  return (
    <Sidebar
      collapsible
      setIsCommandPaletteVisible={setIsCommandPaletteVisible}
      showCommandPaletteTooltip={selectedView === "logic"}
      sidebarId="project"
      sections={[
        {
          items: projectViews.map((view) => {
            return {
              icon: viewToIcon(selectedView === view, view),
              title: viewNamesMap[view],
              onClick: () => {
                setSelectedView(view);
              },
              hasError:
                (view === "logic" && !!commitDerivedData.logicError) ||
                (view === "schema" && !!commitDerivedData.schemaCodeError) ||
                (view === "splits" && !!commitDerivedData.splitsError) ||
                (view === "settings" && hasVercelError),
              isSelected: selectedView === view,
            };
          }),
        },
      ]}
      bottomItems={
        showSetup
          ? [
              {
                icon: <CodesandboxLogo />,
                title: "Setup",
                onClick: () => {
                  setShowSetupModal(true);
                },
              },
            ]
          : undefined
      }
    />
  );
}

function viewToIcon(
  isSelected: boolean,
  view: ProjectView
): React.ReactElement {
  const color = iconColor(isSelected);
  const weight = iconWeight(isSelected);
  switch (view) {
    case "splits":
      return <ArrowsSplit color={color} weight={weight ?? "regular"} />;
    case "diff":
      return <GitCommit color={color} weight={weight} />;
    case "pull-requests":
      return <GitPullRequest color={color} weight={weight} />;
    case "schema":
      return <FileText color={color} weight={weight} />;
    case "preview":
      return <PlayCircle color={color} weight={weight} />;
    case "analytics":
      return <ChartBar color={color} weight={weight} />;
    case "logs":
      return <CursorClick color={color} weight={weight} />;
    case "settings":
      return <GearSix color={color} weight={weight} />;
    case "branches":
      return <GitBranch color={color} weight={weight} />;
    default:
      return <Flag color={color} weight={weight} />;
  }
}
