import {
  Expression,
  Schema,
  fieldPathSeparator,
  mapExpression,
} from "@hypertune/sdk/src/shared";
import {
  RemovedFields,
  formatFieldSchemaName,
} from "../schema/schemaOperationsObject";
import { renameObjectInValueType } from "../schema/valueTypeOperations";

export function renameObjectInExpression(
  expression: Expression | null,
  oldObjectTypeName: string,
  newObjectTypeName: string
): Expression {
  return mapExpression((expr) => {
    if (
      expr &&
      expr.type === "ObjectExpression" &&
      expr.objectTypeName === oldObjectTypeName
    ) {
      return {
        ...expr,
        objectTypeName: newObjectTypeName,
        valueType: renameObjectInValueType(
          expr.valueType,
          oldObjectTypeName,
          newObjectTypeName
        ),
      } as Expression;
    }
    if (
      expr &&
      expr.type === "SplitExpression" &&
      expr.eventObjectTypeName === oldObjectTypeName
    ) {
      return {
        ...expr,
        eventObjectTypeName: newObjectTypeName,
        valueType: renameObjectInValueType(
          expr.valueType,
          oldObjectTypeName,
          newObjectTypeName
        ),
      } as Expression;
    }
    if (
      expr &&
      expr.type === "LogEventExpression" &&
      expr.eventObjectTypeName === oldObjectTypeName
    ) {
      return {
        ...expr,
        eventObjectTypeName: newObjectTypeName,
      } as Expression;
    }
    if (expr) {
      return {
        ...expr,
        valueType: renameObjectInValueType(
          expr.valueType,
          oldObjectTypeName,
          newObjectTypeName
        ),
      } as Expression;
    }
    return expr;
  }, expression) as Expression;
}

// eslint-disable-next-line max-params
export function renameObjectFieldInExpression(
  schema: Schema,
  expression: Expression | null,
  objectTypeName: string,
  oldFieldName: string,
  rawNewFieldName: string
): Expression {
  const newFieldName = formatFieldSchemaName(rawNewFieldName);

  return mapExpression((expr) => {
    if (
      expr &&
      expr.type === "ObjectExpression" &&
      expr.objectTypeName === objectTypeName
    ) {
      return {
        ...expr,
        fields: Object.fromEntries(
          Object.entries(expr.fields).map(([fieldName, field]) =>
            fieldName === oldFieldName
              ? [newFieldName, field]
              : [fieldName, field]
          )
        ),
      };
    }
    if (
      expr &&
      expr.type === "GetFieldExpression" &&
      expr.fieldPath &&
      expr.object?.valueType.type === "ObjectValueType"
    ) {
      return {
        ...expr,
        fieldPath: renameObjectFieldInPath(
          schema,
          objectTypeName,
          oldFieldName,
          newFieldName,
          expr.fieldPath,
          expr.object.valueType.objectTypeName
        ),
      };
    }
    if (
      expr &&
      expr.type === "UpdateObjectExpression" &&
      expr.valueType.objectTypeName === objectTypeName
    ) {
      return {
        ...expr,
        updates: Object.fromEntries(
          Object.entries(expr.updates).map(([fieldName, field]) =>
            fieldName === oldFieldName
              ? [newFieldName, field]
              : [fieldName, field]
          )
        ),
      };
    }
    return expr;
  }, expression) as Expression;
}

export function removeObjectFieldsFromExpression(
  schema: Schema,
  expression: Expression | null,
  fieldsToRemove: RemovedFields
): Expression {
  return mapExpression((expr) => {
    if (
      expr &&
      expr.type === "ObjectExpression" &&
      fieldsToRemove[expr.valueType.objectTypeName]
    ) {
      return {
        ...expr,
        fields: Object.fromEntries(
          Object.entries(expr.fields).filter(
            ([fieldName]) =>
              fieldsToRemove[expr.valueType.objectTypeName].indexOf(
                fieldName
              ) === -1
          )
        ),
      };
    }
    if (
      expr &&
      expr.type === "GetFieldExpression" &&
      expr.fieldPath &&
      expr.object?.valueType.type === "ObjectValueType"
    ) {
      const { fieldPath } = expr;
      const startObjectTypeName = expr.object.valueType.objectTypeName;

      const isAnyFieldInPath = Object.entries(fieldsToRemove)
        .flatMap(([objectTypeName, fieldNamesToRemove]) =>
          fieldNamesToRemove.map((fieldToRemove) =>
            isObjectFieldInPath(
              schema,
              objectTypeName,
              fieldToRemove,
              fieldPath,
              startObjectTypeName
            )
          )
        )
        .some(Boolean);

      if (isAnyFieldInPath) {
        // Retrieving the removed field, so this expression is no longer valid.
        return null;
      }
      return expr;
    }
    if (
      expr &&
      expr.type === "UpdateObjectExpression" &&
      fieldsToRemove[expr.valueType.objectTypeName]
    ) {
      return {
        ...expr,
        updates: Object.fromEntries(
          Object.entries(expr.updates).filter(
            ([fieldName]) =>
              fieldsToRemove[expr.valueType.objectTypeName].indexOf(
                fieldName
              ) === -1
          )
        ),
      };
    }
    return expr;
  }, expression) as Expression;
}

// eslint-disable-next-line max-params
function isObjectFieldInPath(
  schema: Schema,
  objectTypeName: string,
  fieldName: string,
  path: string,
  startObjectTypeName: string
): boolean {
  return walkObjectPath(
    schema,
    path,
    startObjectTypeName,
    (result, currentObjectTypeName, step) => {
      if (currentObjectTypeName === objectTypeName && step === fieldName) {
        return { stop: true, result: true };
      }
      return { stop: false, result };
    },
    false
  );
}
// eslint-disable-next-line max-params
function renameObjectFieldInPath(
  schema: Schema,
  objectTypeName: string,
  oldFieldName: string,
  newFieldName: string,
  path: string,
  startObjectTypeName: string
): string {
  return walkObjectPath(
    schema,
    path,
    startObjectTypeName,
    (result, currentObjectTypeName, step) => {
      const currentStep =
        currentObjectTypeName === objectTypeName && step === oldFieldName
          ? newFieldName
          : step;

      return {
        result: !result
          ? currentStep
          : `${result}${fieldPathSeparator}${currentStep}`,
      };
    },
    ""
  );
}

// eslint-disable-next-line max-params
function walkObjectPath<T>(
  schema: Schema,
  path: string,
  startObjectTypeName: string,
  evaluateCurrent: (
    currentResult: T,
    objectTypeName: string,
    step: string
  ) => { result: T; stop?: boolean },
  initial: T
): T {
  return path?.split(fieldPathSeparator).reduce<{
    result: T;
    stop?: boolean;
    currentObjectTypeName?: string;
  }>(
    (value, step) => {
      if (value.stop) {
        return value;
      }
      if (!value.currentObjectTypeName) {
        // This is in case something is very broken.
        return { stop: true, result: value.result };
      }

      const { stop, result } = evaluateCurrent(
        value.result,
        value.currentObjectTypeName,
        step
      );
      if (stop) {
        return { stop, result };
      }

      const currentValueType =
        schema.objects[value.currentObjectTypeName]?.fields[step]?.valueType;

      if (!currentValueType || currentValueType.type !== "ObjectValueType") {
        // We have reached a non-object value, so we can stop here
        // as we must be at the end or the path is broken.
        return { stop: true, result };
      }

      return {
        stop,
        result,
        currentObjectTypeName: currentValueType.objectTypeName,
      };
    },
    { result: initial, currentObjectTypeName: startObjectTypeName }
  ).result;
}
