import {
  Fragment,
  InlineFragment,
  ObjectValueWithVariables,
  ObjectExpression,
  FragmentDefinitions,
} from "../types";
import nullThrows from "./nullThrows";

export default function getInlineFragment<
  TFieldArguments extends ObjectValueWithVariables | ObjectExpression,
>(
  fragmentDefinitions: FragmentDefinitions<TFieldArguments>,
  fragment: Fragment<TFieldArguments>
): InlineFragment<TFieldArguments> {
  return fragment.type === "InlineFragment"
    ? fragment
    : nullThrows(
        fragmentDefinitions[fragment.fragmentName],
        `Missing fragment definition for fragment with name: "${fragment.fragmentName}".`
      );
}
