import Button from "../../../components/buttons/Button";

export default function Container({
  onBack,
  nextText,
  onNext,
  nextDisabled,
  nextLoading,
  children,
}: {
  onBack?: () => void;
  nextText?: string;
  onNext: () => void;
  nextDisabled: boolean;
  nextLoading?: boolean;
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <>
      <div className="flex h-full w-full flex-col items-stretch overflow-y-auto p-6">
        {children}
      </div>
      <div className="flex flex-row-reverse gap-4 border-t px-6 py-3">
        <Button
          text={nextText || "Next"}
          onClick={onNext}
          disabled={nextDisabled}
          loading={nextLoading}
          intent="primary"
          weight="filled"
          size="large"
        />
        {onBack && <Button onClick={onBack} text="Back" size="large" />}
      </div>
    </>
  );
}
