export default function InputLabel({
  text,
  variant = "default",
}: {
  text?: string;
  variant?: "default" | "muted" | "large";
}): React.ReactElement | null {
  if (!text) {
    return null;
  }
  return (
    <div
      className={
        variant === "large"
          ? "mb-[10px] text-md font-semibold leading-6 text-tx-default"
          : variant === "muted"
            ? "mb-2 font-medium text-tx-muted"
            : "mb-2 text-sm font-semibold text-tx-default"
      }
    >
      {text}
    </div>
  );
}
