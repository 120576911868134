import { Schema, ValueType } from "@hypertune/sdk/src/shared/types";
import { unwrapValueType } from "@hypertune/shared-internal";

export default function canCloneObjectType(
  schema: Schema,
  objectTypeName: string
): boolean {
  const objectType = schema.objects[objectTypeName];

  if (!objectType) {
    return false;
  }

  return (
    (objectType.role === "input" || objectType.role === "output") &&
    !Object.values(schema.objects[objectTypeName]?.fields ?? {}).some(
      (fieldSchema) => {
        const valueType = unwrapValueType(fieldSchema.valueType);
        return (
          valueType.type === "VoidValueType" ||
          valueType.type === "ObjectValueType" ||
          valueTypeHasArguments(schema, fieldSchema.valueType)
        );
      }
    )
  );
}

export function valueTypeHasArguments(
  schema: Schema,
  valueType: ValueType
): boolean {
  return (
    valueType.type === "FunctionValueType" &&
    valueType.parameterValueTypes.some(
      (parameter) =>
        parameter.type !== "ObjectValueType" ||
        Object.keys(schema.objects[parameter.objectTypeName].fields).length > 0
    )
  );
}
