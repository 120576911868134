import { useState } from "react";
import {
  addDefaultEvent,
  addEmptyEnum,
  addEmptyObject,
  addFieldToObject,
  cloneObject,
  formatFieldSchemaName,
  formatTypeSchemaName,
} from "@hypertune/shared-internal";
import { Schema, ValueType } from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import Modal from "../../../../components/Modal";
import TextInput from "../../../../components/input/TextInput";
import Label from "../../../../components/Label";
import CardGroup from "../../../../components/CardGroup";
import TypeIcon from "../../../../components/icons/TypeIcon";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setDraftCommitSchema, setNewTypeModalState } from "../../projectSlice";
import {
  TypeOption,
  typeOptions,
  useSchemaEditorSelectedType,
} from "../schemaHooks";
import SchemaNameError, {
  objectFieldNameError,
  typeNameError,
} from "./SchemaNameError";
import { combineValueTypes } from "./object/ValueTypeSelector";

export default function NewTypeModal(): React.ReactElement | null {
  const schema = useAppSelector(
    (globalState) => globalState.project.draftCommit?.schema
  );
  const state = useAppSelector(
    (globalState) => globalState.project.newTypeModal
  );

  if (!state || !schema) {
    return null;
  }
  return (
    <NewTypeModalInner
      schema={schema}
      addFieldToObjectData={state.addFieldToObject}
      defaultTypeName={state.defaultTypeName}
      defaultTypeOption={state.defaultTypeOption}
      sourceObjectTypeName={state.sourceObjectTypeName}
      onCreate={state.onCreate}
    />
  );
}

function NewTypeModalInner({
  schema,
  addFieldToObjectData,
  defaultTypeName,
  defaultTypeOption,
  sourceObjectTypeName,
  onCreate,
}: {
  schema: Schema;
  addFieldToObjectData?: {
    objectTypeName: string;
    valueTypes: ValueType[];
  };
  defaultTypeName?: string;
  defaultTypeOption?: TypeOption;
  sourceObjectTypeName?: string;
  onCreate?: (typeOption: TypeOption, typeName: string) => void;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();

  const [, setSelectedType] = useSchemaEditorSelectedType();

  const [typeOption, setTypeOption] = useState<TypeOption>(
    defaultTypeOption || "input"
  );
  const [typeName, setTypeName] = useState(defaultTypeName || "");

  const typeSchemaName = formatTypeSchemaName(typeName);
  const fieldSchemaName = formatFieldSchemaName(typeName);
  const nameError =
    typeNameError(schema, typeSchemaName) ??
    (addFieldToObjectData
      ? objectFieldNameError(
          schema,
          "field",
          addFieldToObjectData.objectTypeName,
          fieldSchemaName
        )
      : null);

  const canSelectTypeOption = !defaultTypeOption || !!sourceObjectTypeName;
  const isValid = typeName !== "" && nameError === null;

  function onClose(): void {
    dispatch(setNewTypeModalState(undefined));
  }
  function onSubmit(): void {
    if (!isValid) {
      return;
    }
    let newSchema: Schema;

    switch (typeOption) {
      case "input":
      case "object":
      case "event": {
        const role = typeOption === "object" ? "output" : typeOption;
        newSchema = sourceObjectTypeName
          ? cloneObject(schema, sourceObjectTypeName, typeSchemaName, role)
          : typeOption === "event"
            ? addDefaultEvent(schema, typeSchemaName, null)
            : addEmptyObject(schema, typeSchemaName, role, null);

        break;
      }
      case "enum":
        newSchema = addEmptyEnum(schema, typeSchemaName, null);
        break;
      default:
        throw new Error(`Unknown type option: ${typeOption}`);
    }
    dispatch(
      setDraftCommitSchema(
        addFieldToObjectData
          ? addFieldToObject(
              newSchema,
              addFieldToObjectData.objectTypeName,
              fieldSchemaName,
              combineValueTypes([
                ...addFieldToObjectData.valueTypes,
                defaultTypeOption === "enum"
                  ? { type: "EnumValueType", enumTypeName: typeSchemaName }
                  : { type: "ObjectValueType", objectTypeName: typeSchemaName },
              ]),
              null
            )
          : newSchema
      )
    );
    setSelectedType({
      name: typeSchemaName,
      type: typeOption,
      selectedChildName: null,
    });
    if (onCreate) {
      onCreate(typeOption, typeSchemaName);
    }
    onClose();
  }

  return (
    <Modal
      buttonLayout="end"
      modalStyle="medium"
      onClose={onClose}
      closeOnEsc
      closeText="Cancel"
      saveText={sourceObjectTypeName ? "Clone" : "Create"}
      saveIntent="neutral"
      saveWeight="outlined"
      saveDisabled={!isValid}
      onSave={onSubmit}
      title={
        <div className="flex flex-row items-center gap-2">
          {!canSelectTypeOption && <TypeIcon type={defaultTypeOption} />}
          <Label type="title3" className="text-tx-default">
            {sourceObjectTypeName
              ? `Clone ${toStartCase(sourceObjectTypeName)} type`
              : !canSelectTypeOption
                ? `New ${defaultTypeOption}`
                : "New type"}
          </Label>
        </div>
      }
    >
      <div className="flex flex-col pt-4 text-tx-default">
        {canSelectTypeOption && (
          <>
            <Label type="title4" className="mb-[9px] text-tx-muted">
              Type
            </Label>
            <CardGroup
              layout="grid"
              cardLayout="horizontal-with-icon"
              className="mb-6"
              cards={typeOptions
                .filter(
                  (option) =>
                    option !== "union" && // TODO: Add support for unions
                    (!sourceObjectTypeName || option !== "enum")
                )
                .map((option) => ({
                  key: option,
                  isSelected: option === typeOption,
                  onClick: () => setTypeOption(option),
                  children: (
                    <>
                      <TypeIcon type={option} />
                      <Label type="title3">{toStartCase(option)}</Label>
                    </>
                  ),
                }))}
            />
          </>
        )}
        <Label type="title4" className="mb-[9px] text-tx-muted">
          Name
        </Label>
        <TextInput
          placeholder={`Enter a name for this ${canSelectTypeOption ? "type" : typeOption}`}
          value={typeName}
          onChange={setTypeName}
          focusOnMount
          trimOnBlur={false}
          readOnly={false}
          onEnter={onSubmit}
          size="medium"
          error={
            nameError && <SchemaNameError schemaCheckOrError={nameError} />
          }
        />
        {typeSchemaName && (
          <TextInput
            value={typeSchemaName}
            trimOnBlur={false}
            readOnly
            onChange={() => {
              // Dummy
            }}
            style={{ marginTop: 10 }}
          />
        )}
      </div>
    </Modal>
  );
}
