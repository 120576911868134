import { IconWeight, WarningCircle } from "@phosphor-icons/react";
import { intentDangerHex, intentWarningHex } from "../../lib/constants";

export default function ErrorCircle({
  size,
  weight = "regular",
  intent = "danger",
  style,
}: {
  size?: number;
  weight?: IconWeight;
  intent?: "danger" | "warning";
  style?: React.CSSProperties;
}): React.ReactElement {
  return (
    <WarningCircle
      size={size || 14.5}
      color={intent === "danger" ? intentDangerHex : intentWarningHex}
      weight={weight}
      style={style}
      className="flex-shrink-0"
    />
  );
}
