import { Schema } from "@hypertune/sdk/src/shared";

export default function getStringValuePathsForObject(
  schema: Schema,
  objectTypeName: string
): string[][] {
  return Object.entries(schema.objects[objectTypeName]?.fields ?? {}).flatMap(
    ([fieldName, fieldSchema]) => {
      if (fieldSchema.valueType.type === "StringValueType") {
        return [[fieldName]];
      }
      if (fieldSchema.valueType.type === "ObjectValueType") {
        return getStringValuePathsForObject(
          schema,
          fieldSchema.valueType.objectTypeName
        ).map((path) => [fieldName, ...path]);
      }
      return [];
    }
  );
}
