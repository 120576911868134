import {
  FunctionValueType,
  ListValueType,
  ValueType,
} from "@hypertune/sdk/src/shared/types";

export default function unwrapValueType(
  valueType: ValueType
): Exclude<Exclude<ValueType, ListValueType>, FunctionValueType> {
  switch (valueType.type) {
    case "ListValueType":
      return unwrapValueType(valueType.itemValueType);
    case "FunctionValueType":
      return unwrapValueType(valueType.returnValueType);
    default:
      return valueType;
  }
}
