import { useFloating, offset, autoUpdate } from "@floating-ui/react-dom";
import { Intent, intentToBgClassName } from "../intent";

export default function TooltipDot({
  children,
  intent = "primary",
  placement = "top-end",
  pulse = true,
  className,
}: {
  children: React.ReactNode;
  placement?: "top-end" | "bottom-start";
  intent?: Intent;
  pulse?: boolean;
  className?: string;
}): React.ReactElement | null {
  const floatingDot = useFloating({
    placement,
    middleware: [
      offset({
        mainAxis: -3,
        crossAxis: placement === "top-end" ? 5 : -5,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  return (
    <>
      <div ref={floatingDot.refs.setReference} className={className}>
        {children}
      </div>
      <div
        ref={floatingDot.refs.setFloating}
        style={floatingDot.floatingStyles}
        className={`h-[10px] w-[10px] cursor-pointer rounded-[40px]
                    ${pulse ? "animate-pulse" : ""}
                    ${intentToBgClassName(intent)} 
                    ${intentToShadowClassName(intent)}`}
      />
    </>
  );
}

function intentToShadowClassName(intent: Intent): string {
  switch (intent) {
    case "danger":
      return "shadow-notification-danger";
    case "warning":
      return "shadow-notification-warning";
    case "success":
      return "shadow-notification-success";
    case "primary":
      return "shadow-notification-primary";
    default:
      return "shadow-notification-neutral";
  }
}
