import { Expression, ListExpression } from "@hypertune/sdk/src/shared";

export default function isComparisonOperand(
  parentExpression: Expression | null,
  expression: ListExpression
): boolean {
  return (
    !!parentExpression &&
    parentExpression.type === "ComparisonExpression" &&
    (parentExpression.operator === "in" ||
      parentExpression.operator === "notIn") &&
    parentExpression.b === expression
  );
}
