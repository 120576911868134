import { Schema, ValueType } from "@hypertune/sdk/src/shared";

export default function getAllValuePathsForObject(
  schema: Schema,
  objectTypeName: string,
  includeLeafValueTypes?: (valueType: ValueType) => boolean
): string[][] {
  return Object.entries(schema.objects[objectTypeName]?.fields ?? {}).flatMap(
    ([fieldName, fieldSchema]) => {
      switch (fieldSchema.valueType.type) {
        case "BooleanValueType":
        case "StringValueType":
        case "IntValueType":
        case "FloatValueType":
        case "EnumValueType":
          if (
            includeLeafValueTypes &&
            !includeLeafValueTypes(fieldSchema.valueType)
          ) {
            return [];
          }
          return [[fieldName]];
        case "ObjectValueType":
          return getAllValuePathsForObject(
            schema,
            fieldSchema.valueType.objectTypeName,
            includeLeafValueTypes
          ).map((path) => [fieldName, ...path]);
        default:
          return [];
      }
    }
  );
}
