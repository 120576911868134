import {
  fieldPathSeparator,
  Schema,
  ObjectValueType,
  ValueType,
} from "@hypertune/sdk/src/shared";

export default function getFieldValueType(
  schema: Schema,
  objectValueType: ObjectValueType,
  fieldPath: string
): ValueType | null {
  const schemaObjectFields =
    schema.objects[objectValueType.objectTypeName]?.fields;
  if (!schemaObjectFields) {
    return null;
  }

  const [head, ...tail] = fieldPath.split(fieldPathSeparator);
  const fieldValueType = schemaObjectFields[head]?.valueType;

  if (!fieldValueType) {
    return null;
  }

  if (tail.length > 0) {
    if (fieldValueType.type !== "ObjectValueType") {
      return null;
    }
    return getFieldValueType(
      schema,
      fieldValueType,
      tail.join(fieldPathSeparator)
    );
  }

  return fieldValueType;
}
