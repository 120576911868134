import { ObjectValue, Value } from "../types";

export default function asPlainObjectOrThrow(
  value: Value,
  error: Error
): ObjectValue {
  if (!value || Array.isArray(value) || typeof value !== "object") {
    throw error;
  }
  return value;
}
