import {
  Query,
  Schema,
  ValueType,
  ObjectValueWithVariables,
  Fragment,
  FieldQuery,
  InlineFragment,
  FragmentDefinitions,
} from "@hypertune/sdk/src/shared/types";
import { isPartialObjectKey } from "@hypertune/sdk/src/shared";
import getDefaultValue from "./getDefaultValue";
import { queryObjectTypeName, rootObjectTypeName } from "../constants";

export default function getDefaultQuery({
  schema,
  includeDeprecated,
  includeArguments,
  markQueryFieldArgumentsPartial,
  useSharedFragments,
  objectTypeNames,
}: {
  schema: Schema;
  includeDeprecated: boolean;
  includeArguments: boolean;
  markQueryFieldArgumentsPartial: boolean;
  useSharedFragments: boolean;
  objectTypeNames: string[];
}): Query<ObjectValueWithVariables> | null {
  if (objectTypeNames.length === 0) {
    return null;
  }
  const fragmentDefinitions: FragmentDefinitions<ObjectValueWithVariables> = {};
  const fieldQuery =
    getDefaultFieldQuery({
      schema,
      includeDeprecated,
      includeArguments,
      markQueryFieldArgumentsPartial,
      useSharedFragments,
      fragmentDefinitions,
      objectTypeNames,
    }) ?? {};
  if (Object.keys(fieldQuery).length === 0) {
    return null;
  }

  return {
    name: "FullQuery",
    variableDefinitions: {},
    fragmentDefinitions: Object.fromEntries(
      Object.entries(fragmentDefinitions).filter(
        ([, fragment]) => Object.keys(fragment.selection).length > 0
      )
    ),
    fieldQuery,
  };
}

function getDefaultFieldQuery({
  schema,
  includeDeprecated,
  includeArguments,
  markQueryFieldArgumentsPartial,
  useSharedFragments,
  fragmentDefinitions,
  objectTypeNames,
}: {
  schema: Schema;
  includeDeprecated: boolean;
  includeArguments: boolean;
  markQueryFieldArgumentsPartial: boolean;
  useSharedFragments: boolean;
  fragmentDefinitions: FragmentDefinitions<ObjectValueWithVariables>;
  objectTypeNames: string[];
}): FieldQuery<ObjectValueWithVariables> | null {
  if (objectTypeNames.length === 0) {
    return null;
  }

  const query = Object.fromEntries(
    objectTypeNames
      .map<[string, Fragment<ObjectValueWithVariables>]>((objectTypeName) => {
        const inlineFragment: InlineFragment<ObjectValueWithVariables> = {
          type: "InlineFragment",
          objectTypeName,
          selection: Object.fromEntries(
            Object.entries(schema.objects[objectTypeName]?.fields || {})
              .filter(
                ([, field]) =>
                  includeDeprecated || field.deprecationReason === undefined
              )
              .flatMap(([fieldName, field]) => {
                const fieldValueType = field.valueType;
                if (fieldValueType.type !== "FunctionValueType") {
                  // We should never hit this case as fields of
                  // an object are always functions.
                  return [];
                }
                const fieldQuery = getDefaultFieldQuery({
                  schema,
                  includeDeprecated,
                  includeArguments,
                  markQueryFieldArgumentsPartial,
                  useSharedFragments,
                  fragmentDefinitions,
                  objectTypeNames: getObjectTypeNames(
                    schema,
                    fieldValueType.returnValueType
                  ),
                });
                if (
                  fieldValueType.returnValueType.type === "ObjectValueType" &&
                  fieldQuery === null
                ) {
                  // Don't query objects with no fields.
                  return [];
                }
                const argsValueType = fieldValueType.parameterValueTypes[0];

                return [
                  [
                    fieldName,
                    {
                      fieldArguments:
                        includeArguments &&
                        fieldValueType.parameterValueTypes.length === 1
                          ? // We know the top element is an object,
                            // so we can just cast to the desired type.
                            (getDefaultValue(
                              schema,
                              argsValueType
                            ) as ObjectValueWithVariables)
                          : markQueryFieldArgumentsPartial &&
                              fieldValueType.parameterValueTypes.length === 1 &&
                              argsValueType.type === "ObjectValueType" &&
                              Object.keys(
                                schema.objects[argsValueType.objectTypeName]
                                  .fields
                              ).length > 0
                            ? // Mark fieldArguments as partial if the field has any arguments.
                              { [isPartialObjectKey]: true }
                            : {},
                      fieldQuery,
                    },
                  ],
                ];
              })
          ),
        };

        if (
          useSharedFragments &&
          objectTypeName !== queryObjectTypeName &&
          objectTypeName !== rootObjectTypeName
        ) {
          if (useSharedFragments && !fragmentDefinitions[objectTypeName]) {
            fragmentDefinitions[objectTypeName] = inlineFragment;
          }
          return [
            objectTypeName,
            {
              type: "FragmentSpread",
              fragmentName: objectTypeName,
            },
          ];
        }
        return [objectTypeName, inlineFragment];
      })
      .filter(
        ([objectTypeName, fragment]) =>
          (fragment.type === "FragmentSpread" &&
            Object.keys(fragmentDefinitions[objectTypeName].selection).length >
              0) ||
          (fragment.type === "InlineFragment" &&
            Object.keys(fragment.selection).length > 0)
      )
  );
  if (Object.keys(query).length === 0) {
    return null;
  }

  return query;
}

function getObjectTypeNames(
  schema: Schema,
  returnValueType: ValueType
): string[] {
  switch (returnValueType.type) {
    case "ObjectValueType":
      return [returnValueType.objectTypeName];
    case "ListValueType":
      return getObjectTypeNames(schema, returnValueType.itemValueType);
    case "UnionValueType": {
      return Object.keys(
        schema.unions[returnValueType.unionTypeName].variants || {}
      );
    }
    default:
      // Nothing to do for primitive types.
      return [];
  }
}
