import { useMemo, useState } from "react";
import { Pencil, Plus } from "@phosphor-icons/react";
import { asError } from "@hypertune/sdk/src/shared";
import {
  BusinessesDocument,
  Plan,
  UpdateBusinessInput,
  useUpdateBusinessMutation,
} from "../../generated/graphql";
import { intentPrimaryHex } from "../../lib/constants";
import Modal from "../../components/Modal";
import Button from "../../components/buttons/Button";
import ConditionalText from "../../components/ConditionalText";
import { useHypertune } from "../../generated/hypertune.react";
import DomainListInput, {
  getDomainError,
} from "../../components/input/DomainListInput";
import UpgradeErrorMessage from "./team/UpgradeErrorMessage";
import getErrorMessage from "../../lib/query/getErrorMessage";

export default function EditDomainsButton({
  businessId,
  plan,
  domains,
}: {
  businessId: string;
  plan: Plan;
  domains: string[];
}): React.ReactElement {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <>
      {isModalVisible ? (
        <EditDomainsModal
          businessId={businessId}
          plan={plan}
          domains={domains}
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      ) : null}
      <Button
        text={domains.length === 0 ? "Add" : "Edit"}
        icon={
          domains.length === 0 ? (
            <Plus color={intentPrimaryHex} weight="regular" />
          ) : (
            <Pencil color={intentPrimaryHex} />
          )
        }
        intent="primary"
        weight="outlined"
        onClick={() => {
          setIsModalVisible(true);
        }}
      />
    </>
  );
}

function EditDomainsModal({
  businessId,
  plan,
  domains,
  onClose,
}: {
  businessId: string;
  plan: Plan;
  domains: string[];
  onClose: () => void;
}): React.ReactElement {
  const hypertune = useHypertune();
  const disallowedDomains = useMemo(
    () =>
      new Set(
        hypertune
          .disallowedBusinessEmailDomains({ itemFallback: "" })
          .filter(Boolean)
      ),
    [hypertune]
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [draftDomains, _setDraftDomains] = useState<string[]>(domains);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function setDraftDomains(newDomains: string[]): void {
    _setDraftDomains(newDomains);
    setIsDirty(true);
  }

  const [updateBusiness] = useUpdateBusinessMutation({
    refetchQueries: [BusinessesDocument],
    awaitRefetchQueries: true,
  });

  const isValid =
    draftDomains.every((domain) => !!domain) &&
    !draftDomains.some((domain) => getDomainError(disallowedDomains, domain));

  const hasChanges =
    domains.length !== draftDomains.length ||
    domains.some((domain, i) => domain !== draftDomains[i]);

  function onCreateButtonClick(): void {
    if (isLoading || !isValid || !hasChanges) {
      return;
    }
    setIsLoading(true);
    const input: UpdateBusinessInput = {
      id: businessId,
      domains: draftDomains,
    };
    updateBusiness({ variables: { input } })
      .then(() => {
        setIsLoading(false);
        onClose();
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(getErrorMessage(error));
        console.error("updateBusiness error:", asError(error));
      });
  }

  return (
    <Modal
      title="Edit auto-join domains"
      onClose={onClose}
      confirmClose={isDirty}
      saveWeight="filled"
      onSave={onCreateButtonClick}
      saveDisabled={!isValid || !hasChanges}
      saveLoading={isLoading}
      childrenStyle={{ maxWidth: 500 }}
    >
      <ConditionalText
        text={hypertune.content().settings().domainsMessage({ fallback: "" })}
        className="mb-4"
      />
      <DomainListInput
        disallowedDomains={disallowedDomains}
        domains={draftDomains}
        setDomains={setDraftDomains}
        isLoading={isLoading}
        onEnter={onCreateButtonClick}
        allowEmpty
        focusLastOnMount
      />
      <UpgradeErrorMessage
        errorMessage={errorMessage}
        plan={plan}
        className="mt-2"
      />
    </Modal>
  );
}
