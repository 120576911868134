import { Schema, ValueType } from "@hypertune/sdk/src/shared";

export default function isValueTypeValid(
  schema: Schema,
  valueType: ValueType
): boolean {
  switch (valueType.type) {
    case "VoidValueType":
    case "BooleanValueType":
    case "IntValueType":
    case "FloatValueType":
    case "StringValueType":
    case "RegexValueType":
      return true;

    case "EnumValueType":
      return !!schema.enums[valueType.enumTypeName];

    case "ObjectValueType":
      return !!schema.objects[valueType.objectTypeName];

    case "UnionValueType":
      return !!schema.unions[valueType.unionTypeName];

    case "ListValueType":
      return isValueTypeValid(schema, valueType.itemValueType);

    case "FunctionValueType":
      return (
        valueType.parameterValueTypes.every((parameterValueType) =>
          isValueTypeValid(schema, parameterValueType)
        ) && isValueTypeValid(schema, valueType.returnValueType)
      );

    default: {
      const neverValueType: never = valueType;
      throw new Error(
        `Unexpected value type: ${JSON.stringify(neverValueType)}`
      );
    }
  }
}
