import { Switch } from "@headlessui/react";

export default function Toggle({
  value,
  setValue,
  disabled,
  className,
  size: variant = "default",
}: {
  value: boolean;
  setValue?: (newValue: boolean) => void;
  disabled?: boolean;
  className?: string;
  size?: "default" | "large";
}): React.ReactElement | null {
  return (
    <Switch
      disabled={disabled}
      checked={value}
      onChange={setValue}
      className={`relative inline-flex rounded-full p-[2px] transition-colors ease-in-out
                 ${
                   disabled
                     ? value
                       ? "bg-base-blue/50"
                       : "bg-base-grey-2-medium/50"
                     : value
                       ? "bg-base-blue hover:bg-base-blue/90"
                       : "bg-base-grey-2-medium hover:bg-base-grey-2-medium/80"
                 }
                ${variant === "large" ? "h-[20px] w-[36px]" : "h-[18px] w-[45px]"}
                ${className || ""}`}
    >
      <span
        className={`relative rounded-full bg-white transition-all ease-in-out
        ${value ? "left-[100%] -translate-x-full" : "left-[0%] -translate-x-[0%]"} 
        ${variant === "large" ? "h-4 w-4" : "h-[14px] w-[14px]"}`}
      />
    </Switch>
  );
}
