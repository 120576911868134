import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import { useState } from "react";
import ConfigurationContainer from "./ConfigurationContainer";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import Button from "../../../../components/buttons/Button";
import { plusSymbol } from "../../../../lib/constants";
import Modal from "../../../../components/Modal";
import Dropdown from "../../../../components/Dropdown";

export default function MultiSelect({
  canEdit,
  noun,
  options,
  selectedIds,
  setSelectedIds,
}: {
  canEdit: boolean;
  noun: string;
  options: { [id: string]: string };
  selectedIds: { [id: string]: true };
  setSelectedIds: (newSelectedOptions: { [id: string]: true }) => void;
}): React.ReactElement {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const labeledOptions = Object.entries(options)
    .filter(([id]) => !selectedIds[id])
    .map(([id, label]) => ({ value: id, label }));

  const lowerCaseNoun = noun.toLowerCase();
  const startCaseNoun = toStartCase(noun);

  return (
    <ConfigurationContainer title={`${startCaseNoun}s`}>
      <MultiSelectList
        canEdit={canEdit}
        noun={noun}
        options={options}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
      {canEdit && labeledOptions.length > 0 ? (
        <Button
          intent="primary"
          weight="minimal"
          size="x-small"
          icon={plusSymbol}
          text={startCaseNoun}
          className="mb-2"
          onClick={() => {
            setIsModalVisible(true);
          }}
        />
      ) : null}
      {isModalVisible ? (
        <Modal
          style={{ overflow: "initial" }}
          title={`Add ${startCaseNoun}`}
          onClose={() => {
            setIsModalVisible(false);
          }}
        >
          <Dropdown
            height={30}
            style={{ marginBottom: 4 }}
            options={{ type: "options", options: labeledOptions }}
            value={null}
            onChange={(option) => {
              if (!option) {
                return;
              }
              const id = option.value;
              setSelectedIds({ ...selectedIds, [id]: true });
              setIsModalVisible(false);
            }}
            placeholder={`Select ${lowerCaseNoun}...`}
            noOptionsMessage={`No ${lowerCaseNoun}s found`}
          />
        </Modal>
      ) : null}
    </ConfigurationContainer>
  );
}

export function MultiSelectList({
  canEdit,
  noun,
  options,
  selectedIds,
  setSelectedIds,
}: {
  canEdit: boolean;
  noun: string;
  options: { [id: string]: string };
  selectedIds: { [id: string]: true };
  setSelectedIds: (newSelectedOptions: { [id: string]: true }) => void;
}): React.ReactElement | null {
  const startCaseNoun = toStartCase(noun);

  return Object.keys(selectedIds).length > 0 ? (
    <>
      {Object.keys(selectedIds)
        .sort()
        .map((id) => (
          <div key={id} className="mb-2 flex flex-row items-center">
            <DeleteButton
              className="mr-1"
              size="x-small"
              disabled={!canEdit}
              onClick={() => {
                const newSelectedIds = { ...selectedIds };
                delete newSelectedIds[id];
                setSelectedIds(newSelectedIds);
              }}
            />
            <div>{options[id] || `Unknown ${startCaseNoun}`}</div>
          </div>
        ))}
    </>
  ) : null;
}
