import React from "react";
import Modal from "../../../components/Modal";
import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";
import {
  BusinessManagementDocument,
  BusinessesDocument,
  EvictUserInput,
  useEvictUserMutation,
} from "../../../generated/graphql";

export default function EvictUserModal({
  businessId,
  userId,
  isLeave,
  onClose,
}: {
  businessId: string;
  userId: string;
  isLeave: boolean;
  onClose: () => void;
}): React.ReactElement {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [evictUser] = useEvictUserMutation({
    refetchQueries: [BusinessesDocument, BusinessManagementDocument],
    awaitRefetchQueries: true,
  });
  const [leave] = useEvictUserMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });

  return (
    <Modal
      title={isLeave ? "Leave Team" : "Remove User"}
      onClose={onClose}
      onSave={() => {
        if (isLoading) {
          return;
        }
        setIsLoading(true);
        const input: EvictUserInput = { businessId, userId };
        (isLeave ? leave : evictUser)({ variables: { input } })
          .then(() => {
            setIsLoading(false);
            onClose();
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("evictUser/leave error:", error);
          });
      }}
      saveIntent="danger"
      saveLoading={isLoading}
      saveText={isLeave ? "Leave" : "Remove"}
      saveLoadingText={isLeave ? "Leaving..." : "Removing..."}
    >
      Are you sure you want to{" "}
      {isLeave ? "leave this team?" : "remove this user from your team?"}
    </Modal>
  );
}
