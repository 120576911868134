import React from "react";
import {
  BusinessManagementDocument,
  BusinessesDocument,
  useRevokeInviteMutation,
} from "../../../generated/graphql";
import Modal from "../../../components/Modal";
import Button from "../../../components/buttons/Button";

export default function RevokeInviteModal({
  inviteId,
  onClose,
}: {
  inviteId: string;
  onClose: () => void;
}): React.ReactElement {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [revokeInvite] = useRevokeInviteMutation({
    refetchQueries: [BusinessesDocument, BusinessManagementDocument],
    awaitRefetchQueries: true,
  });

  return (
    <Modal title="Delete Invite" onClose={onClose}>
      <div style={{ marginBottom: 12 }}>
        Are you sure you want to delete this invite?
      </div>
      <Button
        intent="danger"
        weight="minimal"
        text="Delete"
        loadingText="Deleting..."
        loading={isLoading}
        onClick={() => {
          if (isLoading) {
            return;
          }
          setIsLoading(true);
          revokeInvite({ variables: { input: { id: inviteId } } })
            .then(() => {
              setIsLoading(false);
              onClose();
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("revokeInvite error:", error);
            });
        }}
      />
    </Modal>
  );
}
