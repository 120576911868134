import React from "react";
import { Expression, ValueType } from "@hypertune/sdk/src/shared";
import { valueTypeToString } from "@hypertune/shared-internal";
import getValueTypeFromConstraint from "@hypertune/shared-internal/src/expression/constraint/getValueTypeFromConstraint";
import { InnerValueTypeConstraint } from "@hypertune/shared-internal/src/expression/types";
import { Trash } from "@phosphor-icons/react";
import Button from "../../../components/buttons/Button";
import { intentDangerHex } from "../../../lib/constants";

export default function DeleteOrResetValueTypeButton({
  valueTypeConstraint,
  expression,
  setExpression,
}: {
  valueTypeConstraint: InnerValueTypeConstraint;
  expression: Expression;
  setExpression: (newExpression: Expression | null) => void;
}): React.ReactElement | null {
  const valueType = getValueTypeFromConstraint(valueTypeConstraint);
  if (!valueType) {
    return null;
  }

  const onClick = getClickHandler(valueType, expression, setExpression);
  if (!onClick) {
    return (
      <Button
        intent="danger"
        weight="minimal"
        size="x-small"
        className="text-[11px] leading-none"
        onClick={() => setExpression(null)}
        icon={<Trash color={intentDangerHex} weight="regular" size={12} />}
        text="Delete"
      />
    );
  }

  return (
    <Button
      intent="primary"
      weight="minimal"
      size="small"
      className="text-[11px] leading-none"
      onClick={onClick}
      text={`Set type to ${valueTypeToString(valueType)}`}
    />
  );
}

function getClickHandler(
  valueType: ValueType,
  expression: Expression,
  setExpression: (newExpression: Expression | null) => void
): (() => void) | null {
  switch (expression.type) {
    case "NoOpExpression":
    case "BooleanExpression":
    case "IntExpression":
    case "FloatExpression":
    case "StringExpression":
    case "RegexExpression":
    case "ComparisonExpression":
    case "RoundNumberExpression":
    case "StringifyNumberExpression":
    case "StringConcatExpression":
    case "GetUrlQueryParameterExpression":
    case "LogEventExpression":
    case "VariableExpression":
      return null;

    case "GetFieldExpression":
    case "SwitchExpression":
    case "EnumSwitchExpression":
    case "SplitExpression":
    case "ApplicationExpression":
      return (): void => {
        setExpression({ ...expression, valueType });
      };

    case "EnumExpression":
      if (valueType.type !== "EnumValueType") {
        return null;
      }
      return (): void => {
        setExpression({ ...expression, valueType });
      };

    case "ObjectExpression": {
      if (valueType.type !== "ObjectValueType") {
        return null;
      }
      const { objectTypeName } = valueType;
      return (): void => {
        setExpression({ ...expression, valueType, objectTypeName });
      };
    }

    case "UpdateObjectExpression":
      if (valueType.type !== "ObjectValueType") {
        return null;
      }
      return (): void => {
        setExpression({ ...expression, valueType });
      };

    case "ListExpression":
      if (valueType.type !== "ListValueType") {
        return null;
      }
      return (): void => {
        setExpression({ ...expression, valueType });
      };

    case "ArithmeticExpression":
      if (
        valueType.type !== "IntValueType" &&
        valueType.type !== "FloatValueType"
      ) {
        return null;
      }
      return (): void => {
        setExpression({ ...expression, valueType });
      };

    case "FunctionExpression":
      if (valueType.type !== "FunctionValueType") {
        return null;
      }
      return (): void => {
        setExpression({ ...expression, valueType });
      };

    default: {
      const neverExpression: never = expression;
      throw new Error(
        `Expression with unexpected type: ${JSON.stringify(neverExpression)}`
      );
    }
  }
}
