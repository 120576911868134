import { useState } from "react";
import Modal from "../../../components/Modal";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Button from "../../../components/buttons/Button";
import { setDraftCommit } from "../projectSlice";
import { ProjectBranchQuery } from "../../../generated/graphql";
import getCommitData from "../../../lib/query/getCommitData";

export default function ResetButton({
  branch,
}: {
  branch: ProjectBranchQuery["projectBranch"];
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    (state) => !state.project.draftCommitDerived.hasChanges
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      {isModalVisible && (
        <Modal
          onClose={() => setIsModalVisible(false)}
          saveText="Reset"
          saveIntent="warning"
          title="Reset"
          onSave={() => {
            dispatch(setDraftCommit(getCommitData(branch.activeCommit)));
            setIsModalVisible(false);
          }}
        >
          <p className="w-[374px] text-tx-default">
            Are you sure you want to discard your changes and reset to the last
            commit?
          </p>
        </Modal>
      )}
      <Button
        intent="warning"
        weight="elevated"
        size="large"
        disabled={disabled}
        onClick={() => setIsModalVisible(true)}
        text="Reset"
      />
    </>
  );
}
