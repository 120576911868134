import Tooltip from "../../../../components/tooltips/Tooltip";
import PlusButton from "../../../../components/buttons/PlusButton";
import { useAppDispatch } from "../../../../app/hooks";
import { setNewTypeModalState } from "../../projectSlice";
import { useHypertune } from "../../../../generated/hypertune.react";

export default function NewTypeButton(): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const content = useHypertune().content();

  return (
    <Tooltip id="schema" allSteps={content.schema().tooltips()} step={4}>
      <PlusButton
        onClick={() => {
          dispatch(setNewTypeModalState({}));
        }}
      />
    </Tooltip>
  );
}
