import { Expression } from "@hypertune/sdk/src/shared";

export default function isEmptyExpression(
  expression: Expression | null
): boolean {
  if (!expression) {
    return true;
  }
  if (expression.metadata?.note || expression.metadata?.permissions) {
    return false;
  }
  switch (expression.type) {
    case "BooleanExpression":
      return !expression.value;

    case "StringExpression":
      return !expression.value;

    case "ListExpression":
      return expression.items.every((item) => isEmptyExpression(item));

    case "ObjectExpression":
      return Object.values(expression.fields).every((field) =>
        isEmptyExpression(field)
      );

    case "IntExpression":
    case "FloatExpression":
      return expression.value === 0;

    case "SwitchExpression":
      return (
        expression.cases.length === 0 &&
        expression.control?.type === "BooleanExpression" &&
        expression.control.value &&
        isEmptyExpression(expression.default)
      );

    case "FunctionExpression":
      return isEmptyExpression(expression.body);

    default:
      return false;
  }
}
