import { Expression, mapExpressionWithResult } from "@hypertune/sdk/src/shared";

export default function collectSplitAndEventTypeIds(
  expression: Expression | null
): {
  splitIds: Set<string>;
  eventTypeIds: Set<string>;
} {
  const result = mapExpressionWithResult<{
    splitIds: string[];
    eventTypeIds: string[];
  }>(
    (expr) => {
      const splitIds = new Array<string>();
      const eventTypeIds = new Array<string>();
      if (expr) {
        switch (expr?.type) {
          case "SplitExpression":
            if (expr.splitId) {
              splitIds.push(expr.splitId);
            }
            break;

          case "LogEventExpression":
            if (expr.eventTypeId) {
              eventTypeIds.push(expr.eventTypeId);
            }
            break;
          default:
        }
      }

      return {
        newExpression: expr,
        mapResult: {
          splitIds,
          eventTypeIds,
        },
      };
    },
    (
      ...results: {
        splitIds: string[];
        eventTypeIds: string[];
      }[]
    ) => {
      return {
        splitIds: results.flatMap<string>((res) => res.splitIds),
        eventTypeIds: results.flatMap<string>((res) => res.eventTypeIds),
      };
    },
    expression
  );
  return {
    splitIds: new Set(result.mapResult.splitIds),
    eventTypeIds: new Set(result.mapResult.eventTypeIds),
  };
}
