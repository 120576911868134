import React, { useCallback, useMemo, useState } from "react";
import {
  EventTypeMap,
  Expression,
  Schema,
  SplitMap,
} from "@hypertune/sdk/src/shared";
import {
  SelectedType,
  useSchemaEditorSelectedType,
  useSelectedSchemaEditorMode,
} from "./schemaHooks";
import SchemaEditorSidebar from "./SchemaEditorSidebar";
import SchemaCodeEditor from "./SchemaCodeEditor";
import TypeEditor from "./typeEditor/TypeEditor";
import getSchemaTypeReferences from "./getSchemaTypeReferences";

export const typeFilters = [
  "All",
  "Inputs",
  "Objects",
  "Events",
  "Enums",
] as const;
export type TypeFilter = (typeof typeFilters)[number];

export default function SchemaEditor({
  isVisible,
  schema,
  splits,
  eventTypeMap,
  expression,
  readOnlySchemaCode,
  editableSchemaCode,
  errorMessage,
  readOnly,
}: {
  isVisible: boolean;
  schema: Schema;
  splits: SplitMap;
  eventTypeMap: EventTypeMap;
  expression: Expression;
  readOnlySchemaCode: string;
  editableSchemaCode: string;
  errorMessage?: string;
  readOnly: boolean;
}): React.ReactElement {
  const [selectedType, _setSelectedType] = useSchemaEditorSelectedType();
  const [mode, setMode] = useSelectedSchemaEditorMode();
  const [fieldsAndValuesSearchText, setFieldsAndValuesSearchText] =
    useState("");

  const setSelectedType = useCallback(
    (newSelectedType: SelectedType | null) => {
      setFieldsAndValuesSearchText("");
      _setSelectedType(newSelectedType);
    },
    [setFieldsAndValuesSearchText, _setSelectedType]
  );
  const typeReferences = useMemo(
    () => getSchemaTypeReferences(schema),
    [schema]
  );

  return (
    <div
      className={`${isVisible ? "flex" : "hidden"} h-full flex-row items-stretch overflow-hidden`}
    >
      <SchemaEditorSidebar
        readOnly={readOnly}
        schema={schema}
        mode={mode}
        setMode={setMode}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        typeReferences={typeReferences}
      />
      <SchemaCodeEditor
        readOnlySchemaCode={readOnlySchemaCode}
        editableSchemaCode={editableSchemaCode}
        selectedType={selectedType}
        errorMessage={errorMessage}
        readOnly={readOnly}
        isVisible={mode === "code"}
      />
      <TypeEditor
        readOnly={readOnly}
        schema={schema}
        splits={splits}
        eventTypeMap={eventTypeMap}
        expression={expression}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        fieldsAndValuesSearchText={fieldsAndValuesSearchText}
        setFieldsAndValuesSearchText={setFieldsAndValuesSearchText}
        isVisible={mode === "type"}
        typeReferences={typeReferences}
      />
    </div>
  );
}
