import {
  BooleanExpressionType,
  EnumExpressionType,
  Expression,
  FloatExpressionType,
  IntExpressionType,
  RegexExpressionType,
  StringExpressionType,
} from "@hypertune/sdk/src/shared";

const primitiveExpressionTypes: string[] = [
  BooleanExpressionType,
  IntExpressionType,
  FloatExpressionType,
  StringExpressionType,
  RegexExpressionType,
  EnumExpressionType,
];

export default function isPrimitiveExpression(
  expression: Expression | null
): boolean {
  return !!expression && primitiveExpressionTypes.includes(expression.type);
}
