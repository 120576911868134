import { Expression } from "@hypertune/sdk/src/shared";
import { resolvePermissions } from "@hypertune/shared-internal";
import { ExpressionControlContext } from "../types";

/** Returns the ExpressionControlContext for an expression given its parent context */
export default function resolveContext(
  parentContext: ExpressionControlContext,
  expression: Expression | null
): ExpressionControlContext {
  return {
    ...parentContext,
    resolvedPermissions: resolvePermissions(
      parentContext.resolvedPermissions,
      expression?.metadata?.permissions
    ),
  };
}
