import ConditionalText from "../../../components/ConditionalText";
import Button from "../../../components/buttons/Button";
import Flags3 from "../../../components/icons/Flags3";
import { useHypertune } from "../../../generated/hypertune.react";

export default function Step0Welcome({
  onNext,
  onClose,
}: {
  onNext: () => void;
  onClose: () => void;
}): React.ReactElement | null {
  const content = useHypertune().content();

  return (
    <div className="flex w-full flex-col items-center justify-center px-36">
      <Flags3 />
      <p className="text-h3 font-semibold">
        {content
          .onboarding()
          .welcomeMessageTitle({ fallback: "Welcome to Hypertune" })}
      </p>
      <ConditionalText
        text={content.onboarding().welcomeMessage({ fallback: "" })}
        className="mt-2 text-center text-[16px] font-medium text-tx-muted"
      />
      <div className="mt-8 flex flex-row gap-2">
        <Button text="Skip" onClick={onClose} size="large" className="px-3" />
        <Button
          text="Get started"
          onClick={onNext}
          intent="primary"
          weight="filled"
          size="large"
          className="px-3"
        />
      </div>
    </div>
  );
}
