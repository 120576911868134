import React, { MouseEventHandler } from "react";
import { Expression, FunctionExpression } from "@hypertune/sdk/src/shared";
import {
  isEmptyPermissions,
  unimplementedExpressionErrorMessage,
} from "@hypertune/shared-internal";
import getApplicationFunctionExpression from "@hypertune/shared-internal/src/expression/getApplicationFunctionExpression";
import getExpressionErrorMessage from "@hypertune/shared-internal/src/expression/getExpressionErrorMessage";
import getExpressionRecursiveErrorMessages from "@hypertune/shared-internal/src/expression/getExpressionRecursiveErrorMessages";
import isValueTypeCompatible from "@hypertune/shared-internal/src/expression/constraint/isValueTypeCompatible";
import valueTypeConstraintToString from "@hypertune/shared-internal/src/expression/constraint/valueTypeConstraintToString";
import isValueTypeConstraintValid from "@hypertune/shared-internal/src/expression/constraint/isValueTypeConstraintValid";
import {
  ValueTypeConstraint,
  Variable,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import getExpressionLabel from "../../../lib/expression/getExpressionLabel";
import getSetVariableNameFunction from "../../../lib/expression/getSetVariableNameFunction";
import isEmbeddedListExpression from "../../../lib/expression/isEmbeddedListExpression";
import {
  darkBlueFilter,
  functionSymbol,
  normal,
  small,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
  VariableContext,
} from "../../../lib/types";
import canCollapse from "../../../lib/expression/canCollapse";
import resolveContext from "../../../lib/expression/resolveContext";
import isReadOnly from "../../../lib/expression/isReadOnly";
import ExpressionControlInner from "./ExpressionControlInner";
import ExpressionControlOptionsButton from "./ExpressionControlOptionsButton";
import Panel from "./Panel";
import DeleteOrResetValueTypeButton from "./DeleteOrResetValueTypeButton";
import VariableNameControl from "./VariableNameControl";
import Button from "../../../components/buttons/Button";
import { getStringExpressionInAListOrComparisonExpressionWarning } from "./StringExpressionControl";

export default function ExpressionControl({
  context: contextFromParent,
  variables,
  setVariableName,
  valueTypeConstraint,
  expression,
  setExpression,
  lift,
  parentExpression,
  setParentExpression,
  fromParentFunction,
  variableContext,
  includeExpressionOption,
  hideTopLevelFunctionName,
  useInsert,
  disablePanelOnSelect,
  isLeafExpression,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  valueTypeConstraint: ValueTypeConstraint;
  expression: Expression | null;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  parentExpression: Expression | null;
  setParentExpression?: (newParentExpression: Expression | null) => void;
  fromParentFunction?: {
    shouldStack: boolean;
    collapse?: MouseEventHandler;
  };
  variableContext?: VariableContext;
  includeExpressionOption: IncludeExpressionOptionFunction;
  hideTopLevelFunctionName?: boolean;
  useInsert?: boolean;
  disablePanelOnSelect?: boolean;
  isLeafExpression?: boolean;
}): React.ReactElement {
  const context = resolveContext(contextFromParent, expression);

  if (
    valueTypeConstraint &&
    !isValueTypeConstraintValid(
      context.commitContext.schema,
      valueTypeConstraint
    )
  ) {
    throw new Error(
      `Invalid value type constraint (${valueTypeConstraintToString(
        valueTypeConstraint
      )}) for expression: ${JSON.stringify(expression)}`
    );
  }

  const isSelected =
    !!expression &&
    !!context.expressionEditorState.selectedItem &&
    context.expressionEditorState.selectedItem.type === "expression" &&
    context.expressionEditorState.selectedItem.id === expression.id;

  // If the expression is collapsed, we only show an expand button
  if (
    expression &&
    context.expressionEditorState.collapsedExpressionIds[expression.id]
  ) {
    const hasError =
      !context.ignoreErrors &&
      getExpressionRecursiveErrorMessages(
        context.commitContext.schema,
        context.commitContext.splits,
        variables,
        valueTypeConstraint,
        parentExpression,
        expression
      ).length > 0;

    return (
      <Panel
        header={null}
        message={null}
        showErrorIcon={hasError}
        intent={
          hasError
            ? "danger"
            : (context.expressionIdToIntent?.[expression.id] ?? "neutral")
        }
        isSelected={isSelected}
        shouldStack={false}
        expressionId={expression?.id ?? ""}
        context={context}
      >
        <Button
          intent="primary"
          weight="minimal"
          title="Expand"
          icon={
            <img
              style={{ filter: darkBlueFilter }}
              src="/expand.svg"
              width={12}
              height={12}
              alt=""
            />
          }
          onClick={() => {
            const { [expression.id]: _, ...collapsedExpressionIds } =
              context.expressionEditorState.collapsedExpressionIds;
            context.setExpressionEditorState({
              ...context.expressionEditorState,
              collapsedExpressionIds,
              selectedItem: null,
            });
          }}
        />
      </Panel>
    );
  }

  const errorMessage = context.ignoreErrors
    ? null
    : getExpressionErrorMessage(
        context.commitContext.schema,
        context.commitContext.splits,
        variables,
        valueTypeConstraint,
        parentExpression,
        expression
      );

  const isApplicationFunctionExpression =
    parentExpression &&
    parentExpression.type === "ApplicationExpression" &&
    getApplicationFunctionExpression(parentExpression) === expression;

  const functionParameterNameControls =
    !hideTopLevelFunctionName &&
    expression &&
    expression.type === "FunctionExpression" &&
    // Hide function parameter name controls for top-level expression in view
    parentExpression &&
    !isApplicationFunctionExpression
      ? getFunctionParameterNameControls({
          context,
          expression,
          setExpression,
        })
      : null;

  const onCollapseButtonClick: MouseEventHandler | undefined =
    fromParentFunction?.collapse
      ? fromParentFunction.collapse
      : expression &&
          parentExpression &&
          !isLeafExpression &&
          canCollapse(parentExpression, expression)
        ? (event) => {
            event.stopPropagation();
            context.setExpressionEditorState({
              ...context.expressionEditorState,
              collapsedExpressionIds: {
                ...context.expressionEditorState.collapsedExpressionIds,
                [expression.id]: true,
              },
              selectedItem: null,
            });
          }
        : undefined;

  const collapseButton = onCollapseButtonClick ? (
    <img
      style={{ cursor: "pointer", marginRight: normal, filter: darkBlueFilter }}
      src="/collapse.svg"
      width={11}
      height={11}
      alt=""
      onClick={onCollapseButtonClick}
    />
  ) : null;

  const deleteOrResetValueTypeButton =
    !isReadOnly(context) &&
    expression &&
    valueTypeConstraint.type !== "ErrorValueTypeConstraint" &&
    !isValueTypeCompatible(
      context.commitContext.schema,
      valueTypeConstraint,
      expression.valueType
    ) &&
    valueTypeConstraint ? (
      <DeleteOrResetValueTypeButton
        key="resetValueType"
        valueTypeConstraint={valueTypeConstraint}
        expression={expression}
        setExpression={setExpression}
      />
    ) : null;

  const optionsButton = (
    <ExpressionControlOptionsButton
      context={context}
      parentResolvedPermissions={contextFromParent.resolvedPermissions}
      expression={expression}
      setExpression={setExpression}
      lift={lift}
      parentExpression={parentExpression}
      setParentExpression={setParentExpression}
      variables={variables}
      variableContext={variableContext}
    />
  );

  const disableHeader =
    !expression ||
    ((expression.type === "NoOpExpression" ||
      expression.type === "BooleanExpression" ||
      expression.type === "IntExpression" ||
      expression.type === "FloatExpression" ||
      expression.type === "StringExpression" ||
      expression.type === "EnumExpression" ||
      expression.type === "RegexExpression" ||
      (expression.type === "ListExpression" &&
        isEmbeddedListExpression(parentExpression, expression)) ||
      (expression.type === "GetFieldExpression" &&
        expression.object &&
        expression.object.type === "VariableExpression") ||
      expression.type === "ComparisonExpression" ||
      expression.type === "FunctionExpression" ||
      expression.type === "VariableExpression") &&
      !errorMessage);

  const header = functionParameterNameControls
    ? {
        component: (
          <>
            {collapseButton}
            {functionParameterNameControls}
          </>
        ),
        options: deleteOrResetValueTypeButton
          ? [deleteOrResetValueTypeButton]
          : [],
      }
    : disableHeader
      ? null
      : {
          component: (
            <>
              {collapseButton}
              <div style={{ whiteSpace: "nowrap" }}>
                {getExpressionLabel(expression)}
              </div>
            </>
          ),
          options: [
            ...(deleteOrResetValueTypeButton
              ? [deleteOrResetValueTypeButton]
              : []),
            ...(optionsButton ? [optionsButton] : []),
          ],
        };

  const stringExpressionWarning =
    getStringExpressionInAListOrComparisonExpressionWarning({
      context,
      parentExpression,
      expression,
    });

  const errorMessageText =
    errorMessage === unimplementedExpressionErrorMessage ? "" : errorMessage;
  const permissions = expression?.metadata?.permissions;
  const permissionsText =
    isEmptyPermissions(permissions) || context.ignoreErrors
      ? ""
      : "Permissions set.";
  const noteText = expression?.metadata?.note
    ? `${errorMessageText || permissionsText ? "Note: " : ""}${
        expression.metadata.note
      }`
    : (stringExpressionWarning ?? "");
  const message = [errorMessageText, permissionsText, noteText]
    .filter(Boolean)
    .join(" ");

  const enablePanelChildrenContainer = !(
    !expression ||
    expression.type === "IntExpression" ||
    expression.type === "FloatExpression" ||
    expression.type === "StringExpression" ||
    expression.type === "RegexExpression" ||
    expression.type === "EnumExpression" ||
    expression.type === "FunctionExpression" ||
    (!context.readOnly &&
      // Disable container in cases when we use insert expression dropdown
      ((expression.type === "VariableExpression" &&
        expression.valueType.type !== "FunctionValueType") ||
        (expression.type === "GetFieldExpression" &&
          expression.object &&
          expression.object.type === "VariableExpression") ||
        // First child of a comparison expression that itself
        // isn't a comparison expression.
        (expression?.type !== "ComparisonExpression" &&
          expression?.type !== "ApplicationExpression" &&
          parentExpression?.type === "ComparisonExpression" &&
          expression?.id === parentExpression.a?.id))) ||
    (!message &&
      ((expression.type === "ListExpression" &&
        isEmbeddedListExpression(parentExpression, expression)) ||
        (!context.showComparisonExpressionPanel &&
          expression.type === "ComparisonExpression" &&
          parentExpression?.type === "SwitchExpression") ||
        (!!parentExpression &&
          parentExpression.type === "GetFieldExpression" &&
          expression.type === "VariableExpression")))
  );

  // If this is a function expression with parameter controls or a message then
  // stack the child body expression under
  const forFunctionExpressionBodyShouldStack =
    !!functionParameterNameControls || !!message;

  const panelShouldStack = !!fromParentFunction?.shouldStack;

  // If the panel's children container is disabled, the inner control will be
  // rendered directly and may need to stack itself if the panel should stack
  // (due to a parent function expression with parameter name controls or an
  // error message) or if the panel has a header or message
  const innerShouldStack =
    !enablePanelChildrenContainer && (panelShouldStack || header || message);

  return (
    <Panel
      header={header}
      message={message}
      showErrorIcon={!!errorMessageText}
      intent={
        errorMessageText
          ? "danger"
          : expression?.id
            ? (context.expressionIdToIntent?.[expression?.id] ??
              (stringExpressionWarning ? "warning" : "neutral"))
            : "neutral"
      }
      isSelected={isSelected}
      childContainer={
        enablePanelChildrenContainer
          ? expression?.type === "NoOpExpression"
            ? "small"
            : "normal"
          : "disabled"
      }
      shouldStack={panelShouldStack}
      expressionId={expression?.id ?? ""}
      disablePanelOnSelect={disablePanelOnSelect}
      context={context}
    >
      <ExpressionControlInner
        context={context}
        variables={variables}
        setVariableName={setVariableName}
        valueTypeConstraint={valueTypeConstraint}
        expression={expression}
        setExpression={setExpression}
        lift={lift}
        parentExpression={parentExpression}
        setParentExpression={setParentExpression}
        shouldStack={!!innerShouldStack}
        optionsButton={
          !context.hideOptions && disableHeader ? optionsButton : null
        }
        forFunctionExpressionBody={{
          shouldStack: forFunctionExpressionBodyShouldStack,
          // If we're not stacking the body under, it needs to render its own
          // collapse button
          collapse: !forFunctionExpressionBodyShouldStack
            ? onCollapseButtonClick
            : undefined,
        }}
        variableContext={variableContext}
        includeExpressionOption={includeExpressionOption}
        useInsert={useInsert}
        disablePanelOnSelect={disablePanelOnSelect}
      />
    </Panel>
  );
}

function getFunctionParameterNameControls({
  context,
  expression,
  setExpression,
}: {
  context: ExpressionControlContext;
  expression: FunctionExpression;
  setExpression: (newExpression: Expression | null) => void;
}): React.ReactNode | null {
  if (
    expression.valueType.parameterValueTypes.length !==
    expression.parameters.length
  ) {
    return null;
  }

  const parameterNameControls =
    expression.valueType.parameterValueTypes.flatMap(
      (parameterValueType, index) => {
        // Don't show parameters with object types that have no fields
        if (
          parameterValueType.type === "ObjectValueType" &&
          Object.keys(
            context.commitContext.schema.objects[
              parameterValueType.objectTypeName
            ]?.fields || {}
          ).length === 0
        ) {
          return [];
        }
        const parameter = expression.parameters[index];
        const variable: Variable = {
          ...parameter,
          valueType: parameterValueType,
        };
        return [
          <VariableNameControl
            isInput
            key={variable.id}
            schema={context.commitContext.schema}
            variable={variable}
            setVariableName={getSetVariableNameFunction(
              expression,
              setExpression,
              index
            )}
            readOnly={
              isReadOnly(context) ||
              (parameterValueType.type === "ObjectValueType" &&
                context.commitContext.schema.objects[
                  parameterValueType.objectTypeName
                ]?.role === "args")
            }
          />,
        ];
      }
    );

  if (parameterNameControls.length === 0) {
    return null;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div
        style={{
          marginRight: small,
          fontWeight: "bold",
        }}
      >
        {functionSymbol}
      </div>
      <div style={{ marginRight: small }}>(</div>
      {parameterNameControls[0]}
      {parameterNameControls.slice(1).map((control) => (
        <>
          <div style={{ marginRight: small }}>,</div>
          {control}
        </>
      ))}
      <div style={{ marginLeft: small }}>)</div>
    </div>
  );
}
