import { Schema } from "@hypertune/sdk/src/shared/types";
import { queryObjectTypeName } from "../constants";
import getDefaultQuery from "./getDefaultQuery";
import getQueryCodeFromQuery from "../getQueryCodeFromQuery";

export default function getDefaultQueryCode({
  schema,
  includeDeprecated,
  includeComments,
  includeArguments,
  useSharedFragments,
}: {
  schema: Schema;
  includeDeprecated: boolean;
  includeComments: boolean;
  includeArguments: boolean;
  useSharedFragments: boolean;
}): string | null {
  const query = getDefaultQuery({
    schema,
    includeDeprecated,
    useSharedFragments,
    includeArguments: includeArguments || includeComments,
    markQueryFieldArgumentsPartial: false,
    objectTypeNames: [queryObjectTypeName],
  });

  return query
    ? getQueryCodeFromQuery({
        query,
        includeComments,
        includeArguments,
        inlineSharedFragments: !useSharedFragments,
      })
    : null;
}
