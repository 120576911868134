import { Expression, StringExpression } from "@hypertune/sdk/src/shared";

export default function getListFieldLabelExpression(
  expression: Expression | null
): StringExpression | null {
  if (!expression) {
    return null;
  }
  if (expression.type === "StringExpression") {
    return expression;
  }
  if (expression.type === "FunctionExpression" && expression.body) {
    return getListFieldLabelExpression(expression.body);
  }
  if (expression.type === "ApplicationExpression") {
    return getListFieldLabelExpression(expression.function);
  }

  if (expression.type === "ObjectExpression") {
    const labels = Object.entries(expression.fields)
      .filter(
        ([fieldName]) =>
          fieldName.toLowerCase().includes("title") ||
          fieldName.toLowerCase().includes("name")
      )
      .map(([, field]) => getListFieldLabelExpression(field))
      .filter(Boolean);
    return labels.length > 0 ? labels[0] : null;
  }
  return null;
}
