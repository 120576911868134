import {
  GraphQLType,
  isEnumType,
  isInputObjectType,
  isInterfaceType,
  isListType,
  isNonNullType,
  isObjectType,
  isScalarType,
  isUnionType,
} from "graphql";
import { ValueType } from "@hypertune/sdk/src/shared/types";
import { GraphQLScalarTypeName } from "../types";

export default function getValueTypeFromGraphqlType(
  graphqlType: GraphQLType
): ValueType {
  if (!isNonNullType(graphqlType)) {
    throw new Error(`GraphQL type "${graphqlType.name}" must be non-nullable.`);
  }

  const innerType = graphqlType.ofType as GraphQLType;

  if (isScalarType(innerType)) {
    const scalarTypeName = innerType.name as GraphQLScalarTypeName;
    switch (scalarTypeName) {
      case "Void":
        return { type: "VoidValueType" };
      case "Boolean":
        return { type: "BooleanValueType" };
      case "Int":
        return { type: "IntValueType" };
      case "Float":
        return { type: "FloatValueType" };
      case "String":
        return { type: "StringValueType" };
      default: {
        const neverScalarTypeName: never = scalarTypeName;
        throw new Error(`Unexpected scalar type name: ${neverScalarTypeName}`);
      }
    }
  }

  if (isEnumType(innerType)) {
    return {
      type: "EnumValueType",
      enumTypeName: innerType.name,
    };
  }

  if (isObjectType(innerType) || isInputObjectType(innerType)) {
    return {
      type: "ObjectValueType",
      objectTypeName: innerType.name,
    };
  }

  if (isUnionType(innerType)) {
    return {
      type: "UnionValueType",
      unionTypeName: innerType.name,
    };
  }

  if (isInterfaceType(innerType)) {
    throw new Error("Unexpected interface type");
  }

  if (isListType(innerType)) {
    return {
      type: "ListValueType",
      itemValueType: getValueTypeFromGraphqlType(innerType.ofType),
    };
  }

  const neverGraphqlOutputType: never = innerType;
  throw new Error(
    `No value type conversion implementation for GraphQL output type: ${neverGraphqlOutputType}`
  );
}
