import { Schema } from "@hypertune/sdk/src/shared";
import { useState } from "react";
import {
  EnumValuePosition,
  addEnumValue,
  formatEnumValueSchemaName,
} from "@hypertune/shared-internal";
import { useAppDispatch } from "../../../../../app/hooks";
import Modal from "../../../../../components/Modal";
import TextInput from "../../../../../components/input/TextInput";
import { setDraftCommitSchema } from "../../../projectSlice";
import Label from "../../../../../components/Label";
import { SelectedType } from "../../schemaHooks";
import SchemaNameError, { enumValueNameError } from "../SchemaNameError";

export default function EnumAddValueModal({
  schema,
  enumTypeName,
  valuePosition = "last",
  onClose,
  setSelectedType,
}: {
  schema: Schema;
  enumTypeName: string;
  valuePosition?: EnumValuePosition;
  onClose: () => void;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const [newValueName, setNewValueName] = useState<string>("");
  const schemaValueName = formatEnumValueSchemaName(newValueName);
  const nameError = enumValueNameError(schema, enumTypeName, schemaValueName);

  const isValid = newValueName !== "" && nameError === null;

  function onSave(): void {
    if (!isValid) {
      return;
    }
    dispatch(
      setDraftCommitSchema(
        addEnumValue(schema, enumTypeName, schemaValueName, null, valuePosition)
      )
    );
    setSelectedType({
      type: "enum",
      name: enumTypeName,
      selectedChildName: schemaValueName,
    });
    onClose();
  }

  return (
    <Modal
      closeOnEsc
      onClose={onClose}
      title="Add new enum value"
      saveText="Add"
      saveDisabled={!isValid}
      onSave={onSave}
    >
      <Label type="title4" className="mb-[9px] text-tx-muted">
        Value Name
      </Label>
      <TextInput
        value={newValueName}
        placeholder="Enter a name for this value"
        focusOnMount
        onChange={(newValue) => setNewValueName(newValue)}
        trimOnBlur={false}
        readOnly={false}
        error={nameError && <SchemaNameError schemaCheckOrError={nameError} />}
        onEnter={onSave}
      />
      {schemaValueName && (
        <TextInput
          value={schemaValueName}
          trimOnBlur={false}
          readOnly
          onChange={() => {
            // Dummy
          }}
          style={{ marginTop: 10 }}
        />
      )}
    </Modal>
  );
}
