import { defaultBranchName } from "@hypertune/sdk/src/shared";
import { useState } from "react";
import { GitBranch } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import TopBarDropdown, {
  LabeledOption,
} from "../../../components/TopBarDropdown";
import { dotSymbol } from "../../../lib/constants";
import { ProjectQuery } from "../../../generated/graphql";
import { formatJsonTime } from "../../../lib/generic/formatDate";
import NewBranchModal from "./NewBranchModal";
import ModalWithContent from "../../../components/ModalWithContent";
import { useHypertune } from "../../../generated/hypertune.react";

export default function BranchSelector({
  project,
  canAdd,
  showUpgradeModalOnCreate,
  selectedBranchName,
  setSelectedBranch,
}: {
  project?: ProjectQuery["project"];
  canAdd: boolean;
  showUpgradeModalOnCreate: boolean;
  selectedBranchName: string;
  setSelectedBranch: (newBranchName: string) => void;
}): React.ReactElement | null {
  const navigate = useNavigate();
  const content = useHypertune().content().plans();
  const [showNewBranchModal, setShowNewBranchModal] = useState(false);

  const branchOptions: LabeledOption<string>[] = [...(project?.branches || [])]
    .sort((a, b) =>
      a.name === defaultBranchName
        ? -1
        : b.name === defaultBranchName
          ? 1
          : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .map((branch) => toDropdownOption(branch));
  const value = !selectedBranchName
    ? null
    : branchOptions.find((option) => selectedBranchName === option.value) ||
      null;

  return (
    <>
      {showNewBranchModal &&
        selectedBranchName &&
        project &&
        (showUpgradeModalOnCreate ? (
          <ModalWithContent
            content={content.createBranchUpgradeModal().get()}
            onSave={() => navigate("/plans")}
            onClose={() => setShowNewBranchModal(false)}
            saveWeight="filled"
          />
        ) : (
          <NewBranchModal
            projectId={project.id}
            baseBranchName={selectedBranchName}
            branches={project.branches}
            onClose={(newBranchName) => {
              if (newBranchName) {
                setSelectedBranch(newBranchName);
              }
              setShowNewBranchModal(false);
            }}
          />
        ))}
      <TopBarDropdown<string>
        options={{
          type: "groups",
          groups: [
            {
              label: "Branches",
              options: branchOptions,
              newOption: canAdd ? () => setShowNewBranchModal(true) : undefined,
            },
          ],
        }}
        value={value}
        isLoading={!project}
        onChange={(option) => {
          if (option) {
            setSelectedBranch(option.value);
          }
        }}
        placeholder="Select branch..."
        dropdownStyle={{
          muted: "button",
          sectionMaxHeight: 324,
          buttonPrefix: <GitBranch />,
        }}
      />
    </>
  );
}

function toDropdownOption(
  branch: ProjectQuery["project"]["branches"][number]
): LabeledOption<string> {
  const isDefault = branch.name === defaultBranchName;
  const subtitle = `${
    isDefault ? `Default ${dotSymbol} ` : ""
  }${formatJsonTime(branch.createdAt)}`;

  return { value: branch.name, label: branch.name, subtitle };
}
