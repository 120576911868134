/* eslint-disable unused-imports/no-unused-vars */
import {
  ApplicationExpression,
  ArithmeticExpression,
  ComparisonExpression,
  EnumSwitchExpression,
  Expression,
  FunctionExpression,
  GetFieldExpression,
  GetUrlQueryParameterExpression,
  ListExpression,
  LogEventExpression,
  ObjectExpression,
  RoundNumberExpression,
  SplitExpression,
  StringConcatExpression,
  StringifyNumberExpression,
  SwitchExpression,
  UpdateObjectExpression,
  stableStringify,
} from "@hypertune/sdk/src/shared";
import deepEqualsPlainObject from "./deepEqualsPlainObject";

export default function isExpressionEqualExceptIdAndChildren(
  a: Expression,
  b: Expression
): boolean {
  if (a === b) {
    return true;
  }

  if (a.type !== b.type) {
    return false;
  }

  // Where there are multiple consecutive variable-length children containers,
  // we must guard against moving children within the expression such as:
  // { childA: [subexpr], childB: [] } -> { childA: [], childB: [subexpr] }
  // So that we don't need to reason about when to check for this or not, always
  // validate object keys and array lengths.
  // Object.keys() is appropriate for checking objects, since ordering has been
  // stable since 2015: https://stackoverflow.com/q/30076219 (and practically
  // before that too in most JS engines).
  switch (a.type) {
    case "NoOpExpression":
    case "BooleanExpression":
    case "IntExpression":
    case "FloatExpression":
    case "StringExpression":
    case "RegexExpression":
    case "EnumExpression":
    case "VariableExpression": {
      const { id: aId, ...aExceptExcluded } = a;
      const { id: bId, ...bExceptExcluded } = b;
      return deepEqualsPlainObject(aExceptExcluded, bExceptExcluded);
    }

    case "ObjectExpression": {
      const { id: aId, fields: aFields, ...aExceptExcluded } = a;
      const {
        id: bId,
        fields: bFields,
        ...bExceptExcluded
      } = b as ObjectExpression;
      return (
        deepEqualsPlainObject(aExceptExcluded, bExceptExcluded) &&
        deepEqualsPlainObject(Object.keys(aFields), Object.keys(bFields))
      );
    }

    case "GetFieldExpression": {
      const { id: aId, object: aObject, ...aExceptExcluded } = a;
      const {
        id: bId,
        object: bObject,
        ...bExceptExcluded
      } = b as GetFieldExpression;
      return deepEqualsPlainObject(aExceptExcluded, bExceptExcluded);
    }

    case "UpdateObjectExpression": {
      const {
        id: aId,
        object: aObject,
        updates: aUpdates,
        ...aExceptExcluded
      } = a;
      const {
        id: bId,
        object: bObject,
        updates: bUpdates,
        ...bExceptExcluded
      } = b as UpdateObjectExpression;
      return (
        deepEqualsPlainObject(aExceptExcluded, bExceptExcluded) &&
        deepEqualsPlainObject(Object.keys(aUpdates), Object.keys(bUpdates))
      );
    }

    case "ListExpression": {
      const { id: aId, items: aItems, ...aExceptExcluded } = a;
      const {
        id: bId,
        items: bItems,
        ...bExceptExcluded
      } = b as ListExpression;
      return (
        deepEqualsPlainObject(aExceptExcluded, bExceptExcluded) &&
        aItems.length === bItems.length
      );
    }

    case "SwitchExpression": {
      const {
        id: aId,
        control: aControl,
        cases: aCases,
        default: aDefault,
        ...aExceptExcluded
      } = a;
      const {
        id: bId,
        control: bControl,
        cases: bCases,
        default: bDefault,
        ...bExceptExcluded
      } = b as SwitchExpression;
      return (
        deepEqualsPlainObject(aExceptExcluded, bExceptExcluded) &&
        aCases.length === bCases.length
      );
    }

    case "EnumSwitchExpression": {
      const { id: aId, control: aItems, cases: aCases, ...aExceptExcluded } = a;
      const {
        id: bId,
        control: bItems,
        cases: bCases,
        ...bExceptExcluded
      } = b as EnumSwitchExpression;
      return (
        deepEqualsPlainObject(aExceptExcluded, bExceptExcluded) &&
        deepEqualsPlainObject(Object.keys(aCases), Object.keys(bCases))
      );
    }

    case "ComparisonExpression":
    case "ArithmeticExpression": {
      const { id: aId, a: aA, b: aB, ...aExceptExcluded } = a;
      const {
        id: bId,
        a: bA,
        b: bB,
        ...bExceptExcluded
      } = b as ComparisonExpression | ArithmeticExpression;
      return deepEqualsPlainObject(aExceptExcluded, bExceptExcluded);
    }

    case "RoundNumberExpression":
    case "StringifyNumberExpression": {
      const { id: aId, number: aNumber, ...aExceptExcluded } = a;
      const {
        id: bId,
        number: bNumber,
        ...bExceptExcluded
      } = b as RoundNumberExpression | StringifyNumberExpression;
      return deepEqualsPlainObject(aExceptExcluded, bExceptExcluded);
    }

    case "StringConcatExpression": {
      const { id: aId, strings: aStrings, ...aExceptExcluded } = a;
      const {
        id: bId,
        strings: bStrings,
        ...bExceptExcluded
      } = b as StringConcatExpression;
      return deepEqualsPlainObject(aExceptExcluded, bExceptExcluded);
    }

    case "GetUrlQueryParameterExpression": {
      const {
        id: aId,
        url: aUrl,
        queryParameterName: aQueryParameterName,
        ...aExceptExcluded
      } = a;
      const {
        id: bId,
        url: bUrl,
        queryParameterName: bQueryParameterName,
        ...bExceptExcluded
      } = b as GetUrlQueryParameterExpression;
      return deepEqualsPlainObject(aExceptExcluded, bExceptExcluded);
    }

    case "SplitExpression": {
      const {
        id: aId,
        expose: aExpose,
        unitId: aUnitId,
        dimensionMapping: aDimensionMapping,
        featuresMapping: aFeaturesMapping,
        ...aExceptExcluded
      } = a;
      const {
        id: bId,
        expose: bExpose,
        unitId: bUnitId,
        dimensionMapping: bDimensionMapping,
        featuresMapping: bFeaturesMapping,
        ...bExceptExcluded
      } = b as SplitExpression;
      return (
        deepEqualsPlainObject(aExceptExcluded, bExceptExcluded) &&
        aDimensionMapping.type === bDimensionMapping.type &&
        (aDimensionMapping.type === "discrete" &&
        bDimensionMapping.type === "discrete"
          ? stableStringify(Object.keys(aDimensionMapping.cases)) ===
            stableStringify(Object.keys(bDimensionMapping.cases))
          : true) &&
        stableStringify(Object.keys(aFeaturesMapping)) ===
          stableStringify(Object.keys(bFeaturesMapping))
      );
    }

    case "LogEventExpression": {
      const {
        id: aId,
        unitId: aUnitId,
        featuresMapping: aFeaturesMapping,
        ...aExceptExcluded
      } = a;
      const {
        id: bId,
        unitId: bUnitId,
        featuresMapping: bFeaturesMapping,
        ...bExceptExcluded
      } = b as LogEventExpression;
      return (
        deepEqualsPlainObject(aExceptExcluded, bExceptExcluded) &&
        stableStringify(Object.keys(aFeaturesMapping)) ===
          stableStringify(Object.keys(bFeaturesMapping))
      );
    }

    case "FunctionExpression": {
      const { id: aId, body: aBody, ...aExceptExcluded } = a;
      const {
        id: bId,
        body: bBody,
        ...bExceptExcluded
      } = b as FunctionExpression;
      return deepEqualsPlainObject(aExceptExcluded, bExceptExcluded);
    }

    case "ApplicationExpression": {
      const {
        id: aId,
        function: aFunction,
        arguments: aArguments,
        ...aExceptExcluded
      } = a;
      const {
        id: bId,
        function: bFunction,
        arguments: bArguments,
        ...bExceptExcluded
      } = b as ApplicationExpression;
      return (
        deepEqualsPlainObject(aExceptExcluded, bExceptExcluded) &&
        aArguments.length === bArguments.length
      );
    }

    default: {
      const neverExpression: never = a;
      throw new Error(
        `Unexpected expression: ${JSON.stringify(neverExpression)}`
      );
    }
  }
}
