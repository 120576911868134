let canvas: HTMLCanvasElement | null = null;

export default function getTextWidth(
  font: string,
  fontSize: number,
  text: string
): number {
  if (!canvas) {
    canvas = document.createElement("canvas");
  }
  const context = canvas.getContext("2d");
  if (!context) {
    return 0;
  }
  context.font = `normal ${fontSize}px ${font}`;
  const metrics = context.measureText(text);
  return metrics.width;
}
