import { Expression, FunctionExpression } from "@hypertune/sdk/src/shared";

export default function getSetVariableNameFunction(
  expression: FunctionExpression,
  setExpression: (newExpression: Expression | null) => void,
  parameterIndex: number
): (newVariableName: string) => void {
  return (newVariableName: string) => {
    setExpression({
      ...expression,
      parameters: [
        ...expression.parameters.slice(0, parameterIndex),
        {
          ...expression.parameters[parameterIndex],
          name: newVariableName,
        },
        ...expression.parameters.slice(parameterIndex + 1),
      ],
    });
  };
}
