import {
  GraphQLSchema,
  isEnumType,
  isInputObjectType,
  isObjectType,
  isUnionType,
} from "graphql";
import {
  EnumSchema,
  ObjectFieldSchema,
  ObjectSchema,
  Schema,
  Tag,
  UnionSchema,
} from "@hypertune/sdk/src/shared/types";
import { getFieldArgumentsObjectTypeName } from "./fieldArgumentsObjectTypeName";
import getValueTypeFromGraphqlType from "./getValueTypeFromGraphqlType";
import { isEventType } from "./getGraphqlSchema";

export default function getSchema(graphqlSchema: GraphQLSchema): Schema {
  const enums: { [enumTypeName: string]: EnumSchema } = {};
  const objects: { [objectTypeName: string]: ObjectSchema } = {};
  const unions: { [unionTypeName: string]: UnionSchema } = {};
  const tags: { [name: string]: Tag } = {};

  const typeMap = graphqlSchema.getTypeMap();
  Object.keys(typeMap).forEach((typeName) => {
    const type = typeMap[typeName];
    if (typeName === "__Internal") {
      if (
        type.name === "__Internal" &&
        type.astNode?.directives &&
        type.astNode?.directives?.length > 0 &&
        type.astNode?.directives[0].name.value === "data" &&
        type.astNode?.directives[0]?.arguments &&
        type.astNode?.directives[0]?.arguments[0]?.value &&
        type.astNode?.directives[0]?.arguments[0]?.value?.kind === "ListValue"
      ) {
        type.astNode?.directives[0]?.arguments[0]?.value.values.map((value) => {
          if (
            value?.kind === "ObjectValue" &&
            value.fields?.length === 2 &&
            value.fields[0].value.kind === "StringValue" &&
            value.fields[1].value.kind === "StringValue"
          ) {
            tags[value.fields[0].value.value] = {
              name: value.fields[0].value.value,
              color: value.fields[1].value.value,
            };
          }
          return null;
        });
      }
    }

    if (typeName.startsWith("__")) {
      return;
    }

    if (isEnumType(type)) {
      enums[type.name] = {
        description: type.description || null,
        values: Object.fromEntries(
          type.getValues().map((value) => [
            value.name,
            {
              description: value.description || null,
              deprecationReason:
                value.deprecationReason === null
                  ? undefined
                  : value.deprecationReason,
            },
          ])
        ),
      };
    }

    if (isInputObjectType(type)) {
      const schemaInputObjectFields: {
        [fieldName: string]: ObjectFieldSchema;
      } = {};

      const fieldMap = type.getFields();
      Object.keys(fieldMap).forEach((fieldName) => {
        const field = fieldMap[fieldName];
        schemaInputObjectFields[fieldName] = {
          description: field.description || null,
          deprecationReason:
            field.deprecationReason === null
              ? undefined
              : field.deprecationReason,
          valueType: getValueTypeFromGraphqlType(field.type),
        };
      });

      objects[type.name] = {
        role: isEventType(type) ? "event" : "input",
        description: type.description || null,
        fields: schemaInputObjectFields,
      };
    }

    if (isObjectType(type)) {
      const schemaObjectFields: { [fieldName: string]: ObjectFieldSchema } = {};

      const fieldMap = type.getFields();
      Object.keys(fieldMap).forEach((fieldName) => {
        const field = fieldMap[fieldName];

        const schemaFieldArgumentsObjectFields: {
          [fieldName: string]: ObjectFieldSchema;
        } = {};
        field.args.forEach((arg) => {
          schemaFieldArgumentsObjectFields[arg.name] = {
            description: arg.description || null,
            valueType: getValueTypeFromGraphqlType(arg.type),
          };
        });

        const fieldArgumentsObjectTypeName = getFieldArgumentsObjectTypeName({
          parentObjectTypeName: type.name,
          fieldName,
        });

        objects[fieldArgumentsObjectTypeName] = {
          role: "args",
          description: null,
          fields: schemaFieldArgumentsObjectFields,
        };

        schemaObjectFields[fieldName] = {
          description: field.description || null,
          deprecationReason:
            field.deprecationReason === null
              ? undefined
              : field.deprecationReason,
          valueType: {
            type: "FunctionValueType",
            parameterValueTypes: [
              {
                type: "ObjectValueType",
                objectTypeName: fieldArgumentsObjectTypeName,
              },
            ],
            returnValueType: getValueTypeFromGraphqlType(field.type),
          },
        };
      });

      objects[type.name] = {
        role: "output",
        description: type.description || null,
        fields: schemaObjectFields,
      };
    }

    if (isUnionType(type)) {
      unions[type.name] = {
        description: type.description || null,
        variants: Object.fromEntries(
          type.getTypes().map((objectType) => [objectType.name, true])
        ),
      };
    }
  });

  return { enums, objects, unions, tags };
}
