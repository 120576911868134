import { useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/input/TextInput";
import { useCreateAnalyticsViewMutation } from "../../../generated/graphql";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useSelectedAnalyticsViewId } from "./analyticsHooks";
import { setShowNewViewModal } from "./analyticsSlice";
import { refetchAnalyticsViewsQueries } from "../../../lib/query/refetchQueries";

export default function NewViewModal(): React.ReactElement | null {
  const { selectedProjectId } = useParams();
  const show = useAppSelector(
    (state) => state.projectAnalytics.showNewViewModal
  );

  if (!show || !selectedProjectId) {
    return null;
  }
  return <NewViewModalInner projectId={selectedProjectId} />;
}

function NewViewModalInner({
  projectId,
}: {
  projectId: string;
}): React.ReactElement {
  const dispatch = useAppDispatch();
  const [, setAnalyticsViewId] = useSelectedAnalyticsViewId();

  const [name, setName] = useState("");

  const [createView, { loading }] = useCreateAnalyticsViewMutation({
    refetchQueries: refetchAnalyticsViewsQueries,
    awaitRefetchQueries: true,
  });
  async function onSubmit(): Promise<void> {
    const trimmedName = name.trim();
    if (loading || !trimmedName) {
      return;
    }
    try {
      const result = await createView({
        variables: {
          input: {
            projectId,
            name: trimmedName,
            funnelStepsJson: JSON.stringify([]),
          },
        },
      });
      const newViewId = result.data?.createAnalyticsView;
      if (newViewId) {
        setAnalyticsViewId(newViewId, true);
        dispatch(setShowNewViewModal(false));
      }
    } catch (error) {
      console.error("createAnalyticsView error:", error);
    }
  }

  return (
    <Modal
      title="Create new funnel"
      onClose={() => dispatch(setShowNewViewModal(false))}
      saveText="Create"
      saveLoadingText="Creating..."
      saveLoading={loading}
      saveDisabled={name.trim() === ""}
      onSave={onSubmit}
      closeOnEsc
    >
      <div className="mb-2 text-sm">Funnel name</div>
      <TextInput
        value={name}
        onChange={setName}
        focusOnMount
        trimOnBlur
        readOnly={loading}
        onEnter={onSubmit}
        placeholder="Enter a name for this funnel"
      />
    </Modal>
  );
}
