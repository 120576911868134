import { useEffect, useState } from "react";
import {
  EnvelopeSimple,
  User,
  ClipboardText,
  UserMinus,
} from "@phosphor-icons/react";
import { DateTime } from "luxon";
import { Navigate } from "react-router-dom";

import {
  BusinessManagementQuery,
  BusinessUserRole,
  useBusinessManagementQuery,
  useUpdateBusinessUserRoleMutation,
} from "../../../generated/graphql";
import BusinessPage from "../BusinessPage";
import CardGroup from "../../../components/CardGroup";
import Label from "../../../components/Label";
import {
  lighterGreyHex,
  intentDangerHex,
  whiteHex,
} from "../../../lib/constants";
import matchesSearch from "../../../lib/generic/matchesSearch";
import Tag from "../../../components/Tag";
import Menu from "../../../components/Menu";
import RevokeInviteModal from "./RevokeInviteModal";
import EvictUserModal from "./EvictUserModal";
import UserPicture from "../../../components/UserPicture";
import ErrorMessageCard from "../../../components/ErrorMessageCard";
import SearchInput from "../../../components/SearchInput";
import InviteButton from "./InviteButton";
import InviteLinkButton from "./InviteLinkButton";
import { canEditBusiness } from "../../../lib/query/rolePermissions";

import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";
import RoleDropDown from "./RoleDropDown";
import { useHypertune } from "../../../generated/hypertune.react";
import { Intent } from "../../../components/intent";

export default function TeamPage(): React.ReactElement {
  useEffect(() => {
    document.title = "Team - Hypertune";
  }, []);

  const { error, data } = useBusinessManagementQuery();
  const canEdit = canEditBusiness(data?.primaryBusiness?.role);

  const [searchText, setSearchText] = useState<string>("");

  if (data?.primaryBusiness?.business.type === "Personal") {
    return <Navigate to="/teams" />;
  }

  return (
    <BusinessPage>
      <div className="mb-5 flex h-8 w-full justify-between">
        <div className="flex flex-col">
          <p className="text-lg font-semibold">Team members</p>
        </div>
        {data?.primaryBusiness && (
          <div className="flex flex-row gap-2">
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              style={{ backgroundColor: whiteHex }}
            />
            <InviteButton business={data.primaryBusiness} />
            {data.primaryBusiness.business.inviteToken && (
              <InviteLinkButton
                businessId={data.primaryBusiness.id}
                inviteToken={data.primaryBusiness.business.inviteToken}
              />
            )}
          </div>
        )}
      </div>
      {error ? (
        <ErrorMessageCard error={error} />
      ) : data ? (
        <Team searchText={searchText} data={data} canEdit={canEdit} />
      ) : (
        <CardGroup
          layout="list"
          cardLayout="horizontal-with-icon"
          loadingCount={5}
          cards={[]}
        />
      )}
    </BusinessPage>
  );
}

function Team({
  searchText,
  data,
  canEdit,
}: {
  searchText: string;
  data: BusinessManagementQuery;
  canEdit: boolean;
}): React.ReactElement {
  return (
    <div className="grid gap-5">
      <MembersList data={data} searchText={searchText} canEdit={canEdit} />
      <InvitesList data={data} searchText={searchText} canEdit={canEdit} />
    </div>
  );
}

function MembersList({
  data,
  searchText,
  canEdit,
}: {
  data: BusinessManagementQuery;
  searchText: string;
  canEdit: boolean;
}): React.ReactElement {
  const { primaryBusiness } = data;
  if (!primaryBusiness) {
    return (
      <Label type="small-body" className="text-tx-muted">
        No team selected.
      </Label>
    );
  }

  const users = primaryBusiness.business.businessUsers
    .slice()
    .sort((a, b) => a.user.displayName.localeCompare(b.user.displayName));

  return (
    <CardGroup
      layout="list"
      cardLayout="horizontal-with-icon"
      cards={users
        .filter(({ user }) =>
          matchesSearch(searchText, [user.displayName, user.email])
        )
        .sort(({ user: userA }, { user: userB }) =>
          userA.displayName.localeCompare(userB.displayName)
        )
        .sort(({ user: userA }, { user: userB }) =>
          userA.id.localeCompare(userB.id)
        )
        .map(({ role, user }) => ({
          key: user.id,
          className: "hover:bg-bg-light",
          children: (
            <MemberCardContent
              meId={data.me.id}
              businessId={primaryBusiness.id}
              user={{
                id: user.id,
                email: user.email,
                displayName: user.displayName,
                imageUrl: user.imageUrl,
                isInvite: false,
              }}
              role={role}
              canEdit={canEdit}
              hideDelete={
                !canEdit || primaryBusiness.business.type === "Personal"
              }
            />
          ),
        }))}
    />
  );
}

function InvitesList({
  data,
  searchText,
  canEdit,
}: {
  data: BusinessManagementQuery;
  searchText: string;
  canEdit: boolean;
}): React.ReactElement | null {
  const { primaryBusiness } = data;
  if (!primaryBusiness || primaryBusiness.business.invites.length === 0) {
    return null;
  }

  const invites = primaryBusiness.business.invites
    .slice()
    .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));

  return (
    <>
      <div className="mt-7 flex items-center justify-between">
        <p className="text-base font-semibold text-tx-muted">Pending invites</p>
      </div>
      {invites.length > 0 ? (
        <CardGroup
          layout="list"
          cardLayout="horizontal-with-icon"
          cards={invites
            .filter((invite) => matchesSearch(searchText, [invite.email]))
            .sort((inviteA, inviteB) =>
              inviteA.email.localeCompare(inviteB.email)
            )
            .map((invite) => ({
              key: invite.id,
              children: (
                <MemberCardContent
                  businessId={primaryBusiness.id}
                  user={{
                    id: invite.id,
                    email: invite.email,
                    displayName: "User",
                    isInvite: true,
                    invitedAt: invite.createdAt,
                  }}
                  canEdit={canEdit}
                  hideDelete={
                    (!canEdit && invite.sender.id !== data.me.id) ||
                    primaryBusiness.business.type === "Personal"
                  }
                />
              ),
            }))}
        />
      ) : null}
    </>
  );
}

type User = {
  id: string;
  email: string;
  displayName: string;
  imageUrl?: string;
  isInvite: boolean;
  invitedAt?: string;
};

function MemberCardContent({
  meId,
  businessId,
  user,
  role,
  canEdit,
  hideDelete,
}: {
  meId?: string;
  businessId: string;
  user: User;
  role?: BusinessUserRole;
  canEdit: boolean;
  hideDelete: boolean;
}): React.ReactElement {
  const roleManagementEnabled = useHypertune()
    .features()
    .teamRolesEnabled({ fallback: false });

  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <UserPicture
        size="large"
        outlineStyle={user.isInvite ? "dashed" : "default"}
        href={user.imageUrl}
      />
      <div>
        <div className="flex flex-row gap-2">
          <Label type="title3">{user.displayName}</Label>
          {!user.isInvite && meId === user.id && (
            <Tag intent="primary" text="You" />
          )}
        </div>

        <Label type="small-body" className="text-tx-muted">
          {user.email}
        </Label>
      </div>

      <div className="flex flex-row items-center gap-2">
        {user.isInvite && user.invitedAt && (
          <Tag
            intent="neutral"
            icon={
              <EnvelopeSimple size={12} color={lighterGreyHex} weight="bold" />
            }
            text={DateTime.fromISO(user.invitedAt).toRelative() || ""}
          />
        )}
        {roleManagementEnabled && role && (
          <UserRoleDropDown
            role={role}
            businessId={businessId}
            userId={user.id}
            readOnly={!canEdit}
          />
        )}
        <Menu
          items={[
            {
              icon: <ClipboardText />,
              intent: "neutral",
              title: "Copy email",
              onClick: () => {
                navigator.clipboard
                  .writeText(user.email)
                  .catch((error) =>
                    console.error("failed to copy email to clipboard", error)
                  );
              },
            },
            ...(!hideDelete
              ? [
                  {
                    icon: <UserMinus />,
                    iconActive: <UserMinus color={intentDangerHex} />,
                    intent: "danger" as Intent,
                    title: user.isInvite
                      ? "Delete invite"
                      : user.id === meId
                        ? "Leave"
                        : "Remove",
                    onClick: () => {
                      setModalVisible(true);
                    },
                  },
                ]
              : []),
          ]}
        />
      </div>

      {user.isInvite && modalVisible && (
        <RevokeInviteModal
          inviteId={user.id}
          onClose={() => setModalVisible(false)}
        />
      )}
      {!user.isInvite && modalVisible && (
        <EvictUserModal
          userId={user.id}
          businessId={businessId}
          isLeave={user.id === meId}
          onClose={() => setModalVisible(false)}
        />
      )}
    </>
  );
}

function UserRoleDropDown({
  businessId,
  role,
  userId,
  readOnly,
}: {
  businessId: string;
  userId: string;
  role: BusinessUserRole;
  readOnly: boolean;
}): React.ReactElement | null {
  const [changeRole, { loading }] = useUpdateBusinessUserRoleMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });
  return (
    <RoleDropDown
      muted
      role={role}
      changeRole={(newRole) =>
        changeRole({
          variables: {
            input: {
              userId,
              businessId,
              newRole,
            },
          },
        })
      }
      loading={loading}
      readOnly={readOnly}
    />
  );
}
