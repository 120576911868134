import { DeepPartial } from "@hypertune/sdk";
import { diff } from "deep-object-diff";
import {
  getSchemaWithDescriptions,
  reconcileSchemaAndImplementation,
  splitSchemaCode,
} from "@hypertune/shared-internal";
import {
  Expression,
  Schema,
  SplitMap,
  asError,
} from "@hypertune/sdk/src/shared";
import { getLogicErrorMessage } from "@hypertune/shared-internal/src/expression/getExpressionRecursiveErrorMessages";
import {
  ExpressionMap,
  getExpressionFromMap,
  getExpressionMap,
} from "@hypertune/shared-internal/src/expressionMap";
import { getSplitsErrorMessage } from "@hypertune/shared-internal/src/expression/getSplitErrorMessage";
import { DraftCommit } from "../projectSlice";

export type CommitData = DraftCommit & {
  id: number;
};

type RebaseCommitData = {
  schema: Schema;
  splits: SplitMap;
  expressionMap: ExpressionMap;
};

export default function squashAndRebase({
  commonAncestorCommit,
  intoBranchCommit,
  fromBranchCommit,
}: {
  commonAncestorCommit: CommitData;
  intoBranchCommit: CommitData;
  fromBranchCommit: CommitData;
}): DraftCommit & {
  schemaError: string | null;
  logicError: string | null;
  splitsError: string | null;
} {
  if (intoBranchCommit.id === commonAncestorCommit.id) {
    // When the from branch is up to data with into branch we can just use
    // the latest from branch commit.
    const { id: _, ...data } = fromBranchCommit;
    return {
      ...data,
      schemaError: null,
      logicError: null,
      splitsError: null,
    };
  }
  const commonAncestorData = getRebaseCommitData(commonAncestorCommit);
  const intoBranchData = getRebaseCommitData(intoBranchCommit);
  const fromBranchData = getRebaseCommitData(fromBranchCommit);

  const diffToApply = getCommitDiff(commonAncestorData, fromBranchData);

  const newCommitData = applyDiffToCommit(
    commonAncestorData,
    intoBranchData,
    fromBranchData,
    diffToApply
  );
  const newRawExpression = getExpressionFromMap(
    newCommitData.expressionMap,
    fromBranchData.expressionMap,
    {
      type: "ExpressionMapPointer",
      id: fromBranchCommit.expression!.id,
    }
  );
  console.debug("squashAndRebase", {
    commonAncestorData,
    intoBranchData,
    fromBranchData,
    diffToApply,
    newCommitData,
    newRawExpression,
  });

  const {
    newSchema,
    newSchemaCode,
    newEventTypeMap,
    newSplits,
    newExpression,
  } = reconcileSchemaAndImplementation(
    newCommitData.schema,
    newCommitData.splits,
    { ...intoBranchCommit.eventTypes, ...fromBranchCommit.eventTypes },
    newRawExpression as Expression
  );
  let schemaError: string | null = null;
  let logicError: string | null = null;
  let splitsError: string | null = null;

  try {
    getSchemaWithDescriptions(newSchemaCode);
  } catch (error) {
    schemaError = asError(schemaError).message;
  }
  if (schemaError === null) {
    logicError = getLogicErrorMessage(newSchema, newExpression, newSplits);
    splitsError = getSplitsErrorMessage(newSchema, newSplits);
  }
  const { readOnlySchemaCode, editableSchemaCode } = splitSchemaCode(
    newSchema,
    newSchemaCode
  );

  return {
    schema: newSchema,
    schemaCode: newSchemaCode,
    readOnlySchemaCode,
    editableSchemaCode,
    eventTypes: newEventTypeMap,
    splits: newSplits,
    expression: newExpression,
    schemaError,
    logicError,
    splitsError,
  };
}

function getCommitDiff(
  baseCommit: RebaseCommitData,
  newCommit: RebaseCommitData
): DeepPartial<RebaseCommitData> {
  return diff(baseCommit, newCommit);
}

function applyDiffToCommit(
  sharedAncestorCommit: RebaseCommitData,
  baseCommit: RebaseCommitData,
  newCommit: RebaseCommitData,
  diffData: DeepPartial<RebaseCommitData>
): RebaseCommitData {
  return applyDiffToData({
    sharedAncestorData: sharedAncestorCommit,
    baseData: baseCommit,
    newData: newCommit,
    diffData,
  });
}

function applyDiffToData({
  sharedAncestorData,
  baseData,
  newData,
  diffData,
}: {
  sharedAncestorData: any;
  baseData: any;
  newData: any;
  diffData: any;
}): any {
  if (diffData === undefined) {
    // Undefined means deleted.
    return undefined;
  }
  if (baseData === undefined) {
    // Missing base data means data is only present in the new value.
    return newData;
  }

  if (
    baseData &&
    newData &&
    typeof baseData === "object" &&
    typeof newData === "object" &&
    typeof diffData === "object"
  ) {
    if (
      (isExpressionOrValueType(baseData) || isExpressionOrValueType(newData)) &&
      baseData.type !== newData.type
    ) {
      // When we get expression or value types that don't match
      // fallback to newData
      return newData;
    }

    return Object.fromEntries([
      // Start with base data to include new fields.
      ...Object.entries(baseData),
      // Then add base fields and override them based on the diff.
      ...Object.entries(diffData)
        .map(([fieldName, fieldDiffData]) => {
          return [
            fieldName,
            applyDiffToData({
              sharedAncestorData: sharedAncestorData?.[fieldName],
              baseData: baseData[fieldName],
              newData: newData[fieldName],
              diffData: fieldDiffData,
            }),
          ];
        })
        // Filter to remove deleted fields.
        .filter(([, value]) => value !== undefined),
    ]);
  }

  // Fallback to new data if we have primitive values or arrays.
  // TODO: figure out what to do with arrays
  // (They can only exists in function parameters and continuous split dimensions.)
  return newData;
}

function isExpressionOrValueType(data: object): boolean {
  return (
    data &&
    "type" in data &&
    !!data.type &&
    typeof data.type === "string" &&
    (data.type.endsWith("Expression") || data.type.endsWith("ValueType"))
  );
}

function getRebaseCommitData(commit: CommitData): RebaseCommitData {
  return {
    schema: commit.schema,
    splits: commit.splits,
    expressionMap: getExpressionMap(commit.expression!),
  };
}
