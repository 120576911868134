import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import Tag from "../../../components/Tag";
import { PullRequestStatus } from "../../../generated/graphql";
import { Intent } from "../../../components/intent";

export default function PullRequestStatusTag({
  status,
  className,
}: {
  status: PullRequestStatus;
  className?: string;
}): React.ReactElement | null {
  return (
    <Tag
      text={toStartCase(status)}
      intent={pullRequestStatusToIntent(status)}
      className={className}
    />
  );
}

function pullRequestStatusToIntent(status: PullRequestStatus): Intent {
  switch (status) {
    case PullRequestStatus.Open:
      return "primary";
    case PullRequestStatus.Approved:
      return "success";
    case PullRequestStatus.Closed:
      return "danger";
    case PullRequestStatus.Merged:
      return "success";
    default: {
      const neverStatus: never = status;
      throw new Error(`unexpected pull request status: "${neverStatus}"`);
    }
  }
}
