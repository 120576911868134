export default function formatCount(count: number): string {
  if (count < 1000) {
    return count.toString();
  }
  if (count < 10_000) {
    return `${(count / 1_000).toFixed(2)}K`; // E.g. 3.33K
  }
  if (count < 100_000) {
    return `${(count / 1_000).toFixed(1)}K`; // E.g. 33.3K
  }
  if (count < 1_000_000) {
    return `${Math.floor(count / 1_000)}K`; // E.g. 333K
  }
  if (count < 10_000_000) {
    return `${(count / 1_000_000).toFixed(2)}M`; // E.g. 3.33M
  }
  if (count < 100_000_000) {
    return `${(count / 1_000_000).toFixed(1)}M`; // E.g. 33.3M
  }
  return `${Math.floor(count / 1_000_000)}M`; // E.g. 333M
}
