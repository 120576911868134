import { blueHex, lighterGreyHex } from "../../lib/constants";

export default function Flags3({
  className,
}: {
  className?: string;
}): React.ReactElement {
  return (
    <svg
      width="253"
      height="169"
      viewBox="0 0 253 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.5" filter="url(#filter0_bdd_1834_46429)">
        <rect
          x="29.4119"
          y="70.6328"
          width="60"
          height="60"
          rx="16"
          transform="rotate(-15 29.4119 70.6328)"
          fill="white"
          fillOpacity="0.8"
          shapeRendering="crispEdges"
        />
        <rect
          x="28.7995"
          y="70.2793"
          width="61"
          height="61"
          rx="16.5"
          transform="rotate(-15 28.7995 70.2793)"
          stroke={lighterGreyHex}
          strokeOpacity="0.2"
          shapeRendering="crispEdges"
        />
        <path
          d="M56.6857 86.0073C56.6328 86.0855 56.5972 86.1742 56.5813 86.2673C56.5654 86.3604 56.5695 86.4558 56.5934 86.5472L60.1992 100.004C60.2447 100.174 60.3559 100.319 60.5083 100.407C60.6606 100.495 60.8417 100.519 61.0117 100.473C61.1816 100.427 61.3265 100.316 61.4145 100.164C61.5025 100.012 61.5263 99.8305 61.4808 99.6605L60.5315 96.1177C62.2232 93.8478 64.3168 94.2664 66.7283 94.75C68.216 95.0477 69.8181 95.3687 71.3352 94.9622C72.451 94.6632 73.5193 93.9692 74.4589 92.5799C74.5118 92.5017 74.5474 92.413 74.5633 92.3199C74.5792 92.2269 74.5751 92.1314 74.5512 92.0401L71.975 82.4257C71.9418 82.3028 71.8738 82.192 71.7793 82.1066C71.6849 82.0213 71.5678 81.9649 71.4422 81.9443C71.3165 81.9236 71.1876 81.9396 71.0707 81.9902C70.9539 82.0409 70.8541 82.1241 70.7833 82.2299C69.0605 84.7717 66.9086 84.3404 64.4146 83.8402C61.8303 83.3205 58.9011 82.7343 56.6857 86.0073ZM73.186 92.0813C71.4943 94.3512 69.4005 93.9318 66.9892 93.449C64.7217 92.9963 62.1983 92.4894 60.1166 94.5694L57.9564 86.5073C59.6481 84.2374 61.7417 84.656 64.153 85.1389C66.4205 85.5915 68.9447 86.0982 71.0256 84.0185L73.186 92.0813Z"
          fill={blueHex}
        />
      </g>
      <g opacity="0.5" filter="url(#filter1_bdd_1834_46429)">
        <rect
          x="165.633"
          y="52.4121"
          width="60"
          height="60"
          rx="16"
          transform="rotate(15 165.633 52.4121)"
          fill="white"
          fillOpacity="0.8"
          shapeRendering="crispEdges"
        />
        <rect
          x="165.279"
          y="51.7997"
          width="61"
          height="61"
          rx="16.5"
          transform="rotate(15 165.279 51.7997)"
          stroke={lighterGreyHex}
          strokeOpacity="0.2"
          shapeRendering="crispEdges"
        />
        <path
          d="M180.883 80.4993C180.798 80.5406 180.723 80.5996 180.662 80.6722C180.602 80.7449 180.558 80.8296 180.533 80.9207L176.927 94.3775C176.882 94.5474 176.906 94.7285 176.994 94.8809C177.082 95.0332 177.226 95.1444 177.396 95.19C177.566 95.2355 177.747 95.2117 177.9 95.1237C178.052 95.0357 178.163 94.8908 178.209 94.7209L179.158 91.1781C181.758 90.0581 183.362 91.4674 185.209 93.092C186.348 94.0936 187.575 95.1727 189.092 95.5792C190.208 95.8782 191.48 95.8113 192.989 95.0779C193.074 95.0366 193.149 94.9777 193.209 94.905C193.269 94.8324 193.313 94.7476 193.338 94.6566L195.915 85.0422C195.947 84.9191 195.944 84.7892 195.905 84.6681C195.866 84.5469 195.792 84.4395 195.694 84.3588C195.595 84.2781 195.476 84.2275 195.349 84.2129C195.223 84.1984 195.095 84.2205 194.98 84.2768C192.218 85.6166 190.57 84.1672 188.66 82.487C186.682 80.7447 184.438 78.7725 180.883 80.4993ZM192.136 94.0097C189.536 95.1297 187.932 93.7196 186.085 92.0958C184.348 90.57 182.416 88.8693 179.573 89.6297L181.733 81.5677C184.333 80.4477 185.937 81.857 187.784 83.4808C189.521 85.0066 191.454 86.7075 194.296 85.9469L192.136 94.0097Z"
          fill={blueHex}
        />
      </g>
      <g filter="url(#filter2_bdd_1834_46429)">
        <g clipPath="url(#clip0_1834_46429)">
          <rect
            x="87.4119"
            y="27.4119"
            width="80"
            height="80"
            rx="20"
            fill="white"
            fillOpacity="1"
            shapeRendering="crispEdges"
          />
          <path
            d="M140.625 56.9518V73.3581C140.624 73.5138 140.59 73.6675 140.525 73.809C140.46 73.9504 140.365 74.0764 140.248 74.1784C138.159 75.9872 136.161 76.6393 134.257 76.6393C131.671 76.6393 129.257 75.4444 127.011 74.3356C123.381 72.5377 120.225 70.9805 116.562 73.8735V79.9206C116.562 80.2106 116.447 80.4888 116.242 80.694C116.037 80.8991 115.759 81.0143 115.469 81.0143C115.179 81.0143 114.9 80.8991 114.695 80.694C114.49 80.4888 114.375 80.2106 114.375 79.9206V56.9518C114.376 56.796 114.41 56.6422 114.476 56.5007C114.541 56.3592 114.636 56.2334 114.754 56.1315C119.676 51.8686 124.093 54.052 127.987 55.9784C131.738 57.835 134.991 59.4401 138.815 56.1315C138.973 55.9945 139.167 55.9057 139.374 55.8756C139.581 55.8455 139.792 55.8754 139.983 55.9617C140.173 56.0481 140.335 56.1872 140.449 56.3627C140.563 56.5382 140.624 56.7426 140.625 56.9518Z"
            fill={blueHex}
          />
        </g>
        <rect
          x="86.9119"
          y="26.9119"
          width="81"
          height="81"
          rx="20.5"
          stroke={lighterGreyHex}
          strokeOpacity="0.2"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_bdd_1834_46429"
          x="-2.26814"
          y="33.9835"
          width="136.845"
          height="136.845"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.28" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1834_46429"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.56" />
          <feGaussianBlur stdDeviation="15.84" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_1834_46429"
            result="effect2_dropShadow_1834_46429"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="dilate"
            in="SourceAlpha"
            result="effect3_dropShadow_1834_46429"
          />
          <feOffset />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.478431 0 0 0 0 0.513726 0 0 0 0 0.588235 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1834_46429"
            result="effect3_dropShadow_1834_46429"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1834_46429"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_bdd_1834_46429"
          x="118.423"
          y="31.2921"
          width="136.845"
          height="136.845"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.28" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1834_46429"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.56" />
          <feGaussianBlur stdDeviation="15.84" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_1834_46429"
            result="effect2_dropShadow_1834_46429"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="dilate"
            in="SourceAlpha"
            result="effect3_dropShadow_1834_46429"
          />
          <feOffset />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.478431 0 0 0 0 0.513726 0 0 0 0 0.588235 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1834_46429"
            result="effect3_dropShadow_1834_46429"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1834_46429"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_bdd_1834_46429"
          x="46.8119"
          y="0.0118628"
          width="161.2"
          height="161.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1834_46429"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.2" />
          <feGaussianBlur stdDeviation="19.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_1834_46429"
            result="effect2_dropShadow_1834_46429"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="dilate"
            in="SourceAlpha"
            result="effect3_dropShadow_1834_46429"
          />
          <feOffset />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.478431 0 0 0 0 0.513726 0 0 0 0 0.588235 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1834_46429"
            result="effect3_dropShadow_1834_46429"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1834_46429"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1834_46429">
          <rect
            x="87.4119"
            y="27.4119"
            width="80"
            height="80"
            rx="20"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
