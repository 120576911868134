import twMerge from "../lib/twMerge";
import CodeEditor from "./CodeEditor";

export default function CopyableText({
  text,
  className,
  style,
}: {
  text: string;
  className?: string;
  style?: React.CSSProperties;
}): React.ReactElement {
  return (
    <CodeEditor
      code={text}
      readOnly
      setCode={() => {
        // Dummy
      }}
      language="bash"
      showButtons="on-hover"
      className={twMerge(
        "overflow-hidden rounded-xl border bg-bg-light",
        className
      )}
      style={style}
    />
  );
}
