import CardGroup from "../../../components/CardGroup";
import Label from "../../../components/Label";
import MarkdownView from "../../../components/MarkdownView";
import Container from "./Container";
import { Framework } from "../../../generated/hypertune";

export type FrameworkDefinition = {
  id: Framework;
  label: string;
  requireCodegen: boolean;
};

export default function Step2GenerateClient({
  codeGenerated,
  selectedFrameworkIndex,
  setSelectedFrameworkIndex,
  allFrameworks,
  data,
  onBack,
  onNext,
  formatDocs,
}: {
  codeGenerated: boolean;
  selectedFrameworkIndex: number;
  setSelectedFrameworkIndex: (newIndex: number) => void;
  allFrameworks: FrameworkDefinition[];
  data: Map<number, string>;
  onBack: () => void;
  onNext: () => void;
  formatDocs: (docs: string) => string;
}): React.ReactElement {
  const markdown = formatDocs(data.get(selectedFrameworkIndex) || "");

  return (
    <Container onBack={onBack} onNext={onNext} nextDisabled={!codeGenerated}>
      <CardGroup
        className="select-none grid-cols-4 pb-3"
        layout="grid"
        cardLayout="none"
        cards={allFrameworks.map((framework, index) => ({
          key: framework.label,
          className: "flex flex-col items-center gap-[6px]",
          isSelected: selectedFrameworkIndex === index,
          onClick: () => setSelectedFrameworkIndex(index),
          children: (
            <>
              <FrameworkImage framework={framework.id} />
              <Label
                type="title3"
                className={`whitespace-pre-line text-center ${
                  selectedFrameworkIndex === index
                    ? "text-base-blue"
                    : "text-tx-default"
                }`}
              >
                {framework.label}
              </Label>
            </>
          ),
        }))}
      />

      {markdown && (
        <div className="mt-7 flex flex-col">
          <MarkdownView markdown={markdown} />
        </div>
      )}
    </Container>
  );
}

export function FrameworkImage({
  framework,
  imageStyle,
}: {
  framework: Framework;
  imageStyle?: React.CSSProperties;
}): React.ReactElement | null {
  return (
    <div className="p-[5px]">
      <img
        src={`/${framework as string}.svg`}
        alt={`${framework} Logo`}
        style={{ height: 30, ...(imageStyle || {}) }}
      />
    </div>
  );
}
