import React from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { DotsThree } from "@phosphor-icons/react";
import { twJoin } from "tailwind-merge";
import { blackTextHex, optionsButtonHeight } from "../../../lib/constants";

export type OptionsButtonItem = {
  iconImgSrc?: string;
  icon?: React.ReactNode;
  label: string;
  onClick: React.MouseEventHandler;
};

export default function OptionsButton({
  items,
}: {
  items: OptionsButtonItem[];
}): React.ReactElement {
  return (
    <Popover className="relative whitespace-nowrap text-tx-default">
      {({ open }) => {
        return (
          <>
            <PopoverButton
              className={twJoin(
                "hover:filter-intent-primary -m-2 flex cursor-pointer flex-row items-center bg-transparent p-2 outline-none",
                open && "filter-intent-primary"
              )}
            >
              <DotsThree
                size={optionsButtonHeight}
                weight="bold"
                color={blackTextHex}
              />
            </PopoverButton>
            <PopoverPanel
              anchor="bottom start"
              className="z-200 rounded-lg border border-bd-darker bg-white p-1 shadow-lg"
            >
              {({ close }) => (
                <>
                  {items.map((item) => (
                    <Item
                      key={item.label}
                      item={{
                        ...item,
                        onClick: (event) => {
                          item.onClick(event);
                          close();
                        },
                      }}
                    />
                  ))}
                </>
              )}
            </PopoverPanel>
          </>
        );
      }}
    </Popover>
  );
}

function Item({ item }: { item: OptionsButtonItem }): React.ReactElement {
  const { iconImgSrc, icon, label, onClick } = item;

  return (
    <div
      className="flex cursor-pointer flex-row items-center rounded-lg p-[5px] hover:bg-bg-pressed"
      onClick={onClick}
    >
      <div className="flex w-[14px] items-center justify-center">
        {iconImgSrc && <img src={iconImgSrc} className="h-3" alt="" />}
        {icon || null}
      </div>
      <div className="ml-[6px] mr-1 whitespace-nowrap text-base">{label}</div>
    </div>
  );
}
