import { ValueType } from "@hypertune/sdk/src/shared/types";
import {
  getFieldArgumentsObjectTypeName,
  getFieldArgumentsObjectTypeNameParts,
} from "./fieldArgumentsObjectTypeName";

export function renameObjectInValueType(
  valueType: ValueType,
  oldObjectTypeName: string,
  newObjectTypeName: string
): ValueType {
  return mapValueType(valueType, (currentValueType) => {
    if (
      currentValueType.type === "FunctionValueType" &&
      currentValueType.parameterValueTypes.length === 1 &&
      currentValueType.parameterValueTypes[0].type === "ObjectValueType"
    ) {
      const argsObjectFields = getFieldArgumentsObjectTypeNameParts(
        currentValueType.parameterValueTypes[0].objectTypeName
      );
      if (
        argsObjectFields !== null &&
        argsObjectFields.parentObjectTypeName === oldObjectTypeName
      ) {
        return {
          ...currentValueType,
          parameterValueTypes: [
            {
              ...currentValueType.parameterValueTypes[0],
              objectTypeName: getFieldArgumentsObjectTypeName({
                parentObjectTypeName: newObjectTypeName,
                fieldName: argsObjectFields.fieldName,
              }),
            },
          ],
        };
      }
      return currentValueType;
    }
    if (
      currentValueType.type === "ObjectValueType" &&
      currentValueType.objectTypeName === oldObjectTypeName
    ) {
      return {
        ...currentValueType,
        objectTypeName: newObjectTypeName,
      };
    }
    return currentValueType;
  });
}

export function renameEnumInValueType(
  valueType: ValueType,
  oldEnumTypeName: string,
  newEnumTypeName: string
): ValueType {
  return mapValueType(valueType, (currentValueType) => {
    if (
      currentValueType.type === "EnumValueType" &&
      currentValueType.enumTypeName === oldEnumTypeName
    ) {
      return {
        ...currentValueType,
        enumTypeName: newEnumTypeName,
      };
    }
    return currentValueType;
  });
}

export function mapValueType(
  valueType: ValueType,
  mapFunction: (currentValueType: ValueType) => ValueType
): ValueType {
  switch (valueType.type) {
    case "FunctionValueType": {
      return mapFunction({
        ...valueType,
        parameterValueTypes: valueType.parameterValueTypes.map(
          (parameterValueType) => {
            return mapValueType(parameterValueType, mapFunction);
          }
        ),
        returnValueType: mapValueType(valueType.returnValueType, mapFunction),
      });
    }
    case "ListValueType":
      return mapFunction({
        ...valueType,
        itemValueType: mapValueType(valueType.itemValueType, mapFunction),
      });

    default:
      return mapFunction(valueType);
  }
}
