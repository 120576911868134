import { useState } from "react";
import { defaultBranchName } from "@hypertune/sdk/src/shared";
import { Size, Weight } from "../../../components/buttons/Button";
import {
  ProjectDocument,
  PullRequestDocument,
  useUpdateBranchMutation,
} from "../../../generated/graphql";
import DeleteButton from "../../../components/buttons/DeleteButton";
import ModalWithContent from "../../../components/ModalWithContent";
import { useHypertune } from "../../../generated/hypertune.react";
import {
  skipNavigationBlockerKey,
  useProjectSelectedState,
} from "../projectHooks";
import { useAppDispatch } from "../../../app/hooks";
import { setDraftCommit } from "../projectSlice";

export default function DeleteBranchButton({
  meId,
  branch,
  canEdit,
  canContribute,
  hideIcon,
  text,
  size,
  weight,
}: {
  meId: string;
  branch: {
    id: string;
    name: string;
    authorId: string | null;
    hasOpenPR: boolean;
  };
  canEdit: boolean;
  canContribute: boolean;
  hideIcon?: boolean;
  text?: string;
  size?: Size;
  weight?: Weight;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const { selected, setSelected } = useProjectSelectedState();
  const content = useHypertune().content().settings();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [updateBranch, { loading }] = useUpdateBranchMutation({
    refetchQueries: [ProjectDocument, PullRequestDocument],
    awaitRefetchQueries: true,
  });

  if (
    branch.name === defaultBranchName ||
    branch.hasOpenPR ||
    (!canEdit && !(canContribute && branch.authorId === meId))
  ) {
    return null;
  }

  return (
    <div>
      <DeleteButton
        size={size}
        weight={weight}
        hideIcon={hideIcon}
        text={text}
        loading={loading}
        onClick={() => setShowConfirmModal(true)}
      />
      {showConfirmModal && (
        <ModalWithContent
          onClose={() => setShowConfirmModal(false)}
          content={content.deleteBranchModal().get()}
          saveLoading={loading}
          onSave={() => {
            updateBranch({
              variables: { input: { id: branch.id, archived: true } },
            })
              .then(() => {
                setShowConfirmModal(false);

                if (selected.branchName === branch.name) {
                  dispatch(setDraftCommit(undefined));
                  setSelected({
                    branchName: defaultBranchName,
                    searchParams: new URLSearchParams({
                      [skipNavigationBlockerKey]: "1",
                    }),
                  });
                }
              })
              .catch((error) =>
                console.error("failed to delete branch", { error })
              );
          }}
        />
      )}
    </div>
  );
}
