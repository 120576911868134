export function getSplitGoalEventTypeIdFromRewardSql(
  rewardSql: string
): string | null {
  const regex =
    /LEAST\(COUNT\(\*\) FILTER \(WHERE event_type_id = '([A-Za-z0-9_-]+)'\), 1\)/;
  const result = regex.exec(rewardSql);
  return result ? result[1] || null : null;
}

export function getSplitGoalEventTypeNameFromRewardSql(
  rewardSql: string
): string | null {
  const regex =
    /LEAST\(COUNT\(\*\) FILTER \(WHERE event_object_type_name = '([A-Za-z0-9_-]+)'\), 1\)/;
  const result = regex.exec(rewardSql);
  return result ? result[1] || null : null;
}

export function getSplitRewardSql(goalEventTypeName: string): string {
  return `LEAST(COUNT(*) FILTER (WHERE event_object_type_name = '${goalEventTypeName}'), 1)`;
}
