/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo } from "react";
import { ProjectQuery, PullRequestStatus } from "../../../generated/graphql";
import CenteredContainer from "../../../components/container/CenteredContainer";
import useSearchParamsState from "../../../app/useSearchParamsState";
import PullRequestList from "./PullRequestList";
import PullRequestView from "./PullRequestView";
import { useHypertune } from "../../../generated/hypertune.react";
import { selectedPRIdUrlKey } from "./pullRequestHooks";

const statusFilterValues = ["Open", "Closed", "All"] as const;
export const statusFilterOptions = statusFilterValues.map((value) => ({
  value,
  label: value,
}));
export type StatusFilter = (typeof statusFilterValues)[number];

export type PullRequestEditorProps = {
  meId: string;
  isVisible: boolean;
  projectId: string;
  canEdit: boolean;
  canContribute: boolean;
  branches: ProjectQuery["project"]["branches"];
  pullRequests: ProjectQuery["project"]["pullRequests"];
};

export default function PullRequestEditor(
  props: PullRequestEditorProps
): React.ReactElement | null {
  const hypertune = useHypertune();
  const isEnabled = hypertune.features().branchingEnabled({ fallback: false });

  const { isVisible } = props;

  if (!isVisible) {
    // Don't render the editor unless it's visible to avoid
    // unnecessary expensive diff calculation.
    return null;
  }

  return <PullRequestEditorInner {...props} showUpgradeMessage={!isEnabled} />;
}

function PullRequestEditorInner({
  meId,
  isVisible,
  projectId,
  canEdit,
  canContribute,
  branches,
  pullRequests,
  showUpgradeMessage,
}: PullRequestEditorProps & {
  showUpgradeMessage: boolean;
}): React.ReactElement | null {
  const [selectedPRId, setSelectedPRId] = useSearchParamsState<number>(
    selectedPRIdUrlKey,
    0
  );

  const prIdToFromBranchHasOpenPR = useMemo(() => {
    const branchesWithOpenPR = new Set(
      pullRequests
        .filter(
          (pullRequest) =>
            pullRequest.status === PullRequestStatus.Open ||
            pullRequest.status === PullRequestStatus.Approved
        )
        .map((pullRequest) => pullRequest.fromBranch.id)
    );

    return Object.fromEntries(
      pullRequests.map((pullRequest) => [
        pullRequest.id,
        branchesWithOpenPR.has(pullRequest.fromBranch.id),
      ])
    );
  }, [pullRequests]);

  return (
    <>
      {selectedPRId ? (
        <PullRequestView
          meId={meId}
          projectId={projectId}
          canEdit={canEdit}
          canContribute={canContribute}
          fromBranchHasOpenPR={prIdToFromBranchHasOpenPR[selectedPRId]}
          pullRequestId={selectedPRId}
        />
      ) : (
        <CenteredContainer hidden={!isVisible}>
          <PullRequestList
            projectId={projectId}
            canCreate={canContribute}
            branches={branches}
            pullRequests={pullRequests}
            setSelectedPRId={setSelectedPRId}
            showUpgradeMessage={showUpgradeMessage}
          />
        </CenteredContainer>
      )}
    </>
  );
}

PullRequestEditor.LoadingSkeleton = function (): React.ReactElement | null {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedPRId] = useSearchParamsState<number>(selectedPRIdUrlKey, 0);

  return (
    <>
      {selectedPRId ? (
        <PullRequestView.LoadingSkeleton />
      ) : (
        <CenteredContainer className="bg-dotted">
          <PullRequestList.LoadingSkeleton />
        </CenteredContainer>
      )}
    </>
  );
};
