import {
  Expression,
  Permissions,
  stableStringify,
} from "@hypertune/sdk/src/shared";
import React from "react";
import {
  canWrite,
  getEmptyPermissions,
  isEmptyPermissions,
  resolvePermissions,
  teamGroupId,
} from "@hypertune/shared-internal";
import Modal from "../../../components/Modal";
import { useBusinessesQuery } from "../../../generated/graphql";
import PermissionsList from "./PermissionsList";
import Selector from "../../../components/Selector";

import { ExpressionControlContext } from "../../../lib/types";
import isReadOnly from "../../../lib/expression/isReadOnly";
import { redTextHex, teamGroupDisplayName } from "../../../lib/constants";
import { useHypertune } from "../../../generated/hypertune.react";

type View = "this" | "resolved";

export default function ExpressionPermissionsModal({
  context,
  parentResolvedPermissions,
  expression,
  setExpression,
  onClose,
}: {
  context: ExpressionControlContext;
  parentResolvedPermissions: Permissions;
  expression: Expression;
  setExpression: (newExpression: Expression | null) => void;
  onClose: () => void;
}): React.ReactElement {
  const content = useHypertune().content().logic();
  const readOnly = isReadOnly(context);

  const { data } = useBusinessesQuery();

  const groupIdToDisplayName = { [teamGroupId]: teamGroupDisplayName };
  const userIdToDisplayName = Object.fromEntries(
    data?.primaryBusiness?.business.businessUsers.map(({ user }) => [
      user.id,
      user.displayName,
    ]) ?? []
  );

  const [selectedView, setSelectedView] = React.useState<View>("this");

  const existingPermissions =
    expression.metadata?.permissions ?? getEmptyPermissions();

  const [draftPermissions, setDraftPermissions] =
    React.useState<Permissions>(existingPermissions);

  const isDirty =
    stableStringify(existingPermissions) !== stableStringify(draftPermissions);

  const draftResolvedPermissions = resolvePermissions(
    parentResolvedPermissions,
    draftPermissions
  );

  const meWouldBeAbleToWrite = canWrite(context.meId, draftResolvedPermissions);

  const errorMessage = !meWouldBeAbleToWrite
    ? content.permissionsErrorMessage({ fallback: "" })
    : null;
  const saveDisabled = !isDirty || errorMessage !== null;

  return (
    <Modal
      className="text-tx-default"
      style={{ overflow: "initial" }}
      title={`${
        readOnly
          ? "View"
          : isEmptyPermissions(existingPermissions)
            ? "Set"
            : isEmptyPermissions(draftPermissions)
              ? "Remove"
              : "Edit"
      } permissions`}
      onClose={onClose}
      confirmClose={isDirty}
      saveDisabled={saveDisabled}
      onSave={
        readOnly
          ? undefined
          : () => {
              if (saveDisabled) {
                return;
              }
              const metadata = { ...expression.metadata };
              if (isEmptyPermissions(draftPermissions)) {
                delete metadata.permissions;
              } else {
                metadata.permissions = draftPermissions;
              }
              setExpression({ ...expression, metadata });
              onClose();
            }
      }
    >
      <Selector<View>
        style={{ marginBottom: 12 }}
        options={["On this expression", "View all resolved"]}
        values={["this", "resolved"]}
        selectedValue={selectedView}
        onSelectValue={(view) => {
          setSelectedView(view);
        }}
        buttonStyle={{ flexGrow: 1 }}
      />
      <PermissionsList
        isVisible={selectedView === "this"}
        groupIdToDisplayName={groupIdToDisplayName}
        userIdToDisplayName={userIdToDisplayName}
        permissions={draftPermissions}
        setPermissions={readOnly ? undefined : setDraftPermissions}
      />
      <PermissionsList
        isVisible={selectedView === "resolved"}
        groupIdToDisplayName={groupIdToDisplayName}
        userIdToDisplayName={userIdToDisplayName}
        permissions={draftResolvedPermissions}
      />
      {errorMessage ? (
        <div style={{ marginBottom: 12, color: redTextHex }}>
          {errorMessage}
        </div>
      ) : null}
    </Modal>
  );
}
