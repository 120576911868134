import { useMemo } from "react";
import {
  getEmptyPermissions,
  rootFieldName,
  variablePathSuffix,
} from "@hypertune/shared-internal";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import { Expression, Schema, SplitMap } from "@hypertune/sdk/src/shared";
import { getExpressionMap } from "@hypertune/shared-internal/src/expressionMap";
import { ExpressionDiffNode } from "./ExpressionDiff";
import toTree, {
  ExpressionNode,
  ExpressionNodeMap,
} from "../expression/toTree";
import { DiffCommitData, ExpressionControlContext } from "../../../lib/types";
import getDiffExpressionPathsAndIntentMap from "./getDiffExpressionPathsAndIntentMap";
import { Intent } from "../../../components/intent";
import getSplitDiffsMetaAndIntentMap, {
  SplitDiffMeta,
} from "./getSplitDiffsMetaAndIntentMap";
import { SplitControlIntentMap } from "./SplitControl";
import getSchemaDiffValues, { SchemaDiffValue } from "./getSchemaDiffValues";

export type DiffNode = {
  id: string;
  index: number;
  label: string;
  children: DiffNode[];
} & ExpressionDiffNode;

export default function useGetCommitDiff({
  meId,
  currentCommit,
  newCommit,
}: {
  meId: string;
  currentCommit: DiffCommitData;
  newCommit: DiffCommitData;
}): {
  currentContext: ExpressionControlContext;
  newContext: ExpressionControlContext;
  currentExpressionIntentMap: Record<string, Intent>;
  newExpressionIntentMap: Record<string, Intent>;
  expressionDiffPathTree: DiffNode;
  expressionDiffNodes: DiffNode[];
  splitsDiffsMeta: SplitDiffMeta[];
  newSplitsIntentMap: Record<string, SplitControlIntentMap>;
  schemaDiffValues: SchemaDiffValue[];
  hasExpressionDiff: boolean;
  hasSplitsDiff: boolean;
  hasSchemaDiff: boolean;
  hasDiff: boolean;
} {
  const currentContext = useMemo(
    () => getContext(meId, currentCommit.schema, currentCommit.splits),
    [meId, currentCommit.schema, currentCommit.splits]
  );
  const newContext = useMemo(
    () => getContext(meId, newCommit.schema, newCommit.splits),
    [meId, newCommit.schema, newCommit.splits]
  );

  const currentCommitTree = useMemo<ExpressionNodeMap>(
    () => getTree(currentContext, currentCommit.expression),
    [currentContext, currentCommit.expression]
  );
  const newCommitTree = useMemo<ExpressionNodeMap>(
    () => getTree(newContext, newCommit.expression),
    [newContext, newCommit.expression]
  );
  const currentCommitExpressionMap = useMemo(
    () => getExpressionMap(currentCommit.expression),
    [currentCommit.expression]
  );
  const newCommitExpressionMap = useMemo(
    () => getExpressionMap(newCommit.expression),
    [newCommit.expression]
  );
  const {
    expressionDiffPaths,
    currentExpressionIntentMap,
    newExpressionIntentMap,
  } = useMemo(
    () =>
      getDiffExpressionPathsAndIntentMap({
        currentCommitTree,
        newCommitTree,
        currentCommitExpressionMap,
        newCommitExpressionMap,
      }),
    [
      currentCommitTree,
      newCommitTree,
      currentCommitExpressionMap,
      newCommitExpressionMap,
    ]
  );
  const { expressionDiffPathTree, expressionDiffNodes } = useMemo(() => {
    // We remove the first element from the path arrays, as it's always the root.
    const node = diffPathsToTree(
      expressionDiffPaths.map((path) => path.slice(1)),
      currentCommitTree,
      newCommitTree
    );
    return {
      expressionDiffPathTree: node,
      expressionDiffNodes: flattenTree(
        node,
        /* excludeCurrent */ node.children.length > 0 ||
          expressionDiffPaths.length === 0
      ),
    };
  }, [expressionDiffPaths, currentCommitTree, newCommitTree]);
  console.debug("Expression diff", {
    expressionDiffPaths,
    expressionDiffPathTree,
    expressionDiffNodes,
    currentCommitTree,
    newCommitTree,
    currentCommitExpressionMap,
    newCommitExpressionMap,
    currentExpressionIntentMap,
    newExpressionIntentMap,
  });

  const { splitsDiffsMeta, newSplitsIntentMap } = useMemo(
    () =>
      getSplitDiffsMetaAndIntentMap({
        currentSplits: currentCommit.splits,
        newSplits: newCommit.splits,
      }),
    [currentCommit.splits, newCommit.splits]
  );
  console.debug("SplitsDiff", {
    newSplitsIntentMap,
    currentSplits: currentCommit.splits,
    newSplits: newCommit.splits,
  });

  const schemaDiffValues = useMemo(
    () => getSchemaDiffValues(currentCommit.schema, newCommit.schema),
    [currentCommit.schema, newCommit.schema]
  );
  console.debug("SchemaDiff", {
    schemaDiffValues,
    currentSchema: currentCommit.schema,
    newSchema: newCommit.schema,
  });

  const hasExpressionDiff = expressionDiffPaths.length > 0;
  const hasSplitsDiff = splitsDiffsMeta.length > 0;
  const hasSchemaDiff = schemaDiffValues.length > 0;
  const hasDiff = hasExpressionDiff || hasSplitsDiff || hasSchemaDiff;
  return {
    currentContext,
    newContext,
    currentExpressionIntentMap,
    newExpressionIntentMap,
    expressionDiffPathTree,
    expressionDiffNodes,
    splitsDiffsMeta,
    newSplitsIntentMap,
    schemaDiffValues,
    hasExpressionDiff,
    hasSplitsDiff,
    hasSchemaDiff,
    hasDiff,
  };
}

function getContext(
  meId: string,
  schema: Schema,
  splits: SplitMap
): ExpressionControlContext {
  return {
    meId,
    fullFieldPath: "",
    commitContext: {
      schema,
      splits,
      setSplits: () => {
        // Noop
      },
      eventTypes: {},
    },
    evaluations: {},
    expressionEditorState: {
      selectedItem: null,
      collapsedExpressionIds: {},
    },
    setExpressionEditorState: () => {
      // Noop
    },
    ignoreErrors: false,
    readOnly: true,
    resolvedPermissions: getEmptyPermissions(),
    expandByDefault: true,
  };
}

function diffPathsToTree(
  diffPaths: string[][],
  currentTree: ExpressionNodeMap | null,
  newTree: ExpressionNodeMap | null
): DiffNode {
  const currentRootTree = currentTree?.[rootFieldName] ?? null;
  const newRootTree = newTree?.[rootFieldName] ?? null;

  const result: DiffNode = {
    id: rootFieldName,
    index: 0,
    label: "Logic",
    children: [],
    iconProps: {
      // Use false to force flag instead of a folder.
      hasChildren: false,
      isVariable: false,
      valueTypeConstraint: { type: "BooleanValueTypeConstraint" },
    },
    fullLabel: "Root",
    currentExpressionNode: currentRootTree,
    newExpressionNode: newRootTree,
  };
  diffPaths.forEach((path, index) =>
    addPathToNode(
      index,
      result,
      [],
      path,
      currentRootTree?.childExpressions ?? null,
      newRootTree?.childExpressions ?? null
    )
  );

  return result;
}

// eslint-disable-next-line max-params
function addPathToNode(
  index: number,
  node: DiffNode,
  parentPath: string[],
  path: string[],
  currentTree: ExpressionNodeMap | null,
  newTree: ExpressionNodeMap | null
): void {
  if (path.length === 0) {
    return;
  }
  const step = path[0];
  let childNode = node.children.find((child) => child.id === path[0]);
  if (!childNode) {
    const currentExpressionNode = currentTree?.[step] ?? null;
    const newExpressionNode = newTree?.[step] ?? null;
    const expressionNode = (newExpressionNode ||
      currentExpressionNode) as ExpressionNode;

    childNode = {
      index,
      id: step,
      label: expressionNode.fieldLabel,
      fullLabel: parentPath
        .map((pathStep) =>
          toStartCase(pathStep.replace(variablePathSuffix, ""))
        )
        .concat([expressionNode.fieldLabel])
        .join(" / "),
      children: [],
      currentExpressionNode,
      newExpressionNode,
      iconProps: {
        isVariable: !!expressionNode.variableContext,
        hasChildren: !!expressionNode.childExpressions,
        valueTypeConstraint: expressionNode.valueTypeConstraint,
      },
    };
    node.children.push(childNode);
  }
  addPathToNode(
    index,
    childNode,
    parentPath.concat(step),
    path.slice(1),
    currentTree?.[step]?.childExpressions ?? null,
    newTree?.[step]?.childExpressions ?? null
  );
}

function flattenTree(node: DiffNode, excludeCurrent = false): DiffNode[] {
  const result: DiffNode[] = [];
  if (node.children.length === 0 && !excludeCurrent) {
    result.push(node);
    return result;
  }
  node.children.forEach((child) => {
    result.push(...flattenTree(child));
  });
  return node.children.flatMap((child) => flattenTree(child));
}

function getTree(
  context: ExpressionControlContext,
  expression: Expression | null
): ExpressionNodeMap {
  if (!expression) {
    return {};
  }
  return (
    toTree({
      expression,
      setExpression: noop,
      setAddObjectFieldModalState: noop,
      setExpressionEditorSelectedItem: noop,
      schema: context.commitContext.schema,
      splits: context.commitContext.splits,
    }) || {}
  );
}

function noop(): void {
  // Do nothing
}
