import { arrow, autoUpdate, useFloating, offset } from "@floating-ui/react-dom";
import { useRef } from "react";
import Button from "../buttons/Button";
import TooltipArrow from "../icons/TooltipArrow";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTooltipStep } from "./tooltipsSlice";
import { TooltipStepNode } from "../../generated/hypertune";
import TooltipDot from "./TooltipDot";

export default function Tooltip({
  id,
  step,
  allSteps,
  children,
  placement = "right-start",
  dotPlacement,
  topOffset,
  topArrowOffset = 15,
  leftOffset,
  onNext,
  isVisible = true,
  className,
}: {
  id: string;
  step: number;
  allSteps: TooltipStepNode[];
  children: React.ReactElement;
  placement?: "right-start" | "bottom-end";
  dotPlacement?: "top-end" | "bottom-start";
  topOffset?: number;
  leftOffset?: number;
  topArrowOffset?: number;
  onNext?: () => void;
  isVisible?: boolean;
  className?: string;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const arrowRef = useRef(null);
  const hideTooltips = useAppSelector((state) => state.app.hideTooltips);
  const currentStep = useAppSelector((state) => state.tooltips[id] || 0);

  const message = allSteps.at(step - 1)?.get();

  const floatingMessage = useFloating({
    placement,
    middleware: [
      offset({
        mainAxis: topOffset || 10,
        crossAxis: leftOffset || (placement === "right-start" ? -30 : 30),
      }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  if (!isVisible || hideTooltips || !message || currentStep + 1 !== step) {
    return children;
  }

  return (
    <>
      <div ref={floatingMessage.refs.setReference} className={className}>
        <TooltipDot placement={dotPlacement} className={className}>
          {children}
        </TooltipDot>
      </div>
      <div
        className="z-50 flex flex-row"
        ref={floatingMessage.refs.setFloating}
        style={floatingMessage.floatingStyles}
      >
        <div
          ref={arrowRef}
          style={{
            position: "absolute",
            ...(placement === "right-start"
              ? {
                  left: floatingMessage.middlewareData.arrow?.x,
                  top:
                    (floatingMessage.middlewareData.arrow?.y as number) -
                    topArrowOffset,
                }
              : {
                  right: 26.5,
                  top: topArrowOffset,
                }),
          }}
        >
          <TooltipArrow
            className={placement === "right-start" ? "" : "rotate-90"}
          />
        </div>
        <div className="ml-[7px] flex max-w-[260px] flex-col gap-2 rounded-[10px] border border-bd-darker bg-white px-[18px] py-4">
          <p className="text-md font-semibold text-tx-default">
            {message.title}
          </p>
          <p className="leading-[20px] text-tx-muted">{message.body}</p>
          <div className="flex flex-row items-center justify-end">
            <Button
              text={
                allSteps.length === 1
                  ? "Dismiss"
                  : allSteps.length === step
                    ? "Finish"
                    : "Next"
              }
              intent={allSteps.length === 1 ? "neutral" : "primary"}
              weight={allSteps.length === 1 ? "default" : "filled"}
              onClick={() => {
                dispatch(setTooltipStep({ id, step: currentStep + 1 }));
                if (onNext) {
                  onNext();
                }
              }}
              className="mt-2"
            />
          </div>
        </div>
      </div>
    </>
  );
}
