import { useEffect, useState } from "react";
import ModalWithContent from "../components/ModalWithContent";
import { newAppVersionAvailableEventName } from "../lib/constants";
import useStickyState from "../lib/hooks/useStickyState";
import { useHypertune } from "../generated/hypertune.react";

const oneHourInMs = 3600_000;

export default function NewAppVersionModal(): React.ReactElement | null {
  const content = useHypertune().content();

  const [showNewVersionAvailableModal, setShowNewVersionAvailableModal] =
    useState(false);
  const [newVersionModalClosedAtTs, setNewVersionModalClosedAtTs] =
    useStickyState<number | null>(null, "new-version-modal-closed-at-ts");
  useEffect(() => {
    function listener(): void {
      if (
        !newVersionModalClosedAtTs ||
        Date.now() - newVersionModalClosedAtTs > oneHourInMs
      ) {
        setShowNewVersionAvailableModal(true);
      }
    }
    window.addEventListener(newAppVersionAvailableEventName, listener);

    return () => {
      window.removeEventListener(newAppVersionAvailableEventName, listener);
    };
  }, [newVersionModalClosedAtTs]);

  if (!showNewVersionAvailableModal) {
    return null;
  }

  return (
    <ModalWithContent
      onClose={() => {
        setNewVersionModalClosedAtTs(Date.now());
        setShowNewVersionAvailableModal(false);
      }}
      onSave={() => window.location.reload()}
      content={content.homepage().newAppVersionAvailable().get()}
    />
  );
}
