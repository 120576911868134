import { greyHex, topBarHeight } from "../lib/constants";
import BusinessSelector from "../features/business/BusinessSelector";
import Logo from "./Logo";

export default function TopBar({
  showBusinessSelector,
  disableLogoLink,
  left,
  right,
}: {
  showBusinessSelector: boolean;
  disableLogoLink?: boolean;
  left?: React.ReactElement;
  right?: React.ReactElement;
}): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: topBarHeight,
        minHeight: topBarHeight,
        borderBottom: `1px solid ${greyHex}`,
        overflowX: "clip",
        overflowY: "visible",
        gap: 2,
        paddingRight: 8,
        paddingLeft: 8,
      }}
    >
      <Logo linkTo={!disableLogoLink ? "/" : undefined} />
      {showBusinessSelector && <BusinessSelector />}
      {left || null}
      <div style={{ flexGrow: 1 }} />
      {right || null}
    </div>
  );
}
