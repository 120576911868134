import { prodEdgeCdnBaseUrl } from "@hypertune/sdk/src/shared";
import { Env } from "./type";

const env: Env = {
  NAME: "production",
  FRONTEND_BASE_URL: "https://app.hypertune.com",
  BACKEND_BASE_URL: "https://app.hypertune.com",
  EDGE_CDN_BASE_URL: prodEdgeCdnBaseUrl,
};

export default env;
