import { DiscreteDimension } from "@hypertune/sdk/src/shared";

export default function getArmAllocationsSum(
  dimension: DiscreteDimension
): number {
  return Object.values(dimension.arms).reduce(
    (acc, curr) => acc + curr.allocation,
    0
  );
}
