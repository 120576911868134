import React, { useMemo } from "react";
import { Expression } from "@hypertune/sdk/src/shared";
import SplitsEditorSidebar from "./SplitsEditorSidebar";
import { useSplitEditorSelectedSplitId } from "./splitHooks";
import SplitEditorMain from "./splitEditor/SplitEditorMain";
import { CommitContext } from "../../../lib/types";

export default function SplitsEditor({
  isVisible,
  expression,
  commitContext,
  readOnly,
}: {
  isVisible: boolean;
  expression: Expression;
  commitContext: CommitContext;
  readOnly: boolean;
}): React.ReactElement | null {
  const { splits } = commitContext;

  const [selectedSplitId, setSelectedSplitId] = useSplitEditorSelectedSplitId();

  const selectedSplit = useMemo(
    () => (selectedSplitId ? splits[selectedSplitId] : null),
    [splits, selectedSplitId]
  );

  if (!isVisible) {
    return null;
  }

  return (
    <div className="grid h-full grid-cols-[auto,1fr]">
      <SplitsEditorSidebar
        readOnly={readOnly}
        schema={commitContext.schema}
        splits={splits}
        selectedSplit={selectedSplit}
        setSelectedSplitId={setSelectedSplitId}
      />
      <SplitEditorMain
        readOnly={readOnly}
        expression={expression}
        commitContext={commitContext}
        selectedSplit={selectedSplit}
        setSelectedSplitId={setSelectedSplitId}
      />
    </div>
  );
}
