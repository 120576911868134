import { Value } from "@hypertune/sdk/src/shared/types";
import uniqueId from "@hypertune/sdk/src/shared/helpers/uniqueId";
import getUserAgent from "./getUserAgent";

export default function replaceVariablePlaceholders(
  variableValues: { [variableName: string]: Value },
  userAgent: string,
  referer: string
): { [variableName: string]: Value } {
  const ua = getUserAgent(userAgent);
  return Object.fromEntries(
    Object.entries(variableValues).map(([variableName, variableValue]) => [
      variableName,
      variableValue === "#UA#"
        ? ua
        : typeof variableValue === "string" &&
            variableValue.startsWith("#UA:") &&
            variableValue.endsWith("#")
          ? getUserAgent(variableValue.substring(4, variableValue.length - 1))
          : variableValue === "#UID#"
            ? uniqueId()
            : variableValue === "#REFERER#"
              ? referer
              : variableValue,
    ])
  );
}
