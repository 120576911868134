import { Link } from "react-router-dom";
import TopBar from "../components/TopBar";
import Label from "../components/Label";
import Button from "../components/buttons/Button";
import CenteredContainer from "../components/container/CenteredContainer";
import Card from "../components/Card";

export default function NotFoundPage(): React.ReactElement {
  return (
    <div className="flex h-screen w-full flex-col">
      <TopBar showBusinessSelector />

      <div className="flex h-full flex-row overflow-hidden text-base">
        <CenteredContainer>
          <Card layout="horizontal">
            <div className="flex flex-col">
              <Label type="title1">Page not found</Label>
              <Label type="title4" className="text-tx-muted">
                Sorry, we can't find the page you are looking for.
              </Label>
            </div>

            <Link
              to="/"
              // Button is already tab-able
              tabIndex={-1}
            >
              <Button
                text="Go to projects"
                intent="primary"
                weight="elevated"
              />
            </Link>
          </Card>
        </CenteredContainer>
      </div>
    </div>
  );
}
