import React from "react";
import {
  Arm,
  DiscreteDimension,
  Split,
  SplitType,
} from "@hypertune/sdk/src/shared";
import { getSplitGoalEventTypeNameFromRewardSql } from "@hypertune/shared-internal";
import getArmAllocationsSum from "@hypertune/shared-internal/src/expression/getArmAllocationsSum";
import { twJoin } from "tailwind-merge";
import {
  Intent,
  intentToBorderClassName,
  intentToLightBgClassName,
  intentToTextColorName,
} from "../../../components/intent";
import twMerge from "../../../lib/twMerge";
import { getStartCasePayloadPathString } from "../../../lib/getPayloadPathString";

export type SplitControlDimensionArmIntentMap = {
  name?: Intent;
  allocation?: Intent;
};

export type SplitControlDimensionIntentMap = {
  name?: Intent;
  arms?: {
    [armId: string]: SplitControlDimensionArmIntentMap;
  };
};

export type SplitControlIntentMap = {
  name?: Intent;
  type?: Intent;
  payloadEventType?: Intent;
  goalEventType?: Intent;
  goalEventUnitIdPath?: Intent;
  dimensions?: {
    [dimensionId: string]: SplitControlDimensionIntentMap;
  };
};

export default function SplitControl({
  typeName,
  split,
  intentMap,
}: {
  typeName: string;
  split: Split;
  intentMap?: SplitControlIntentMap;
}): React.ReactElement {
  const sortedDimensions = Object.values(split.dimensions).sort(
    (a, b) => a.index - b.index
  );

  return (
    <div className="flex min-w-[240px] flex-col">
      <Label intent={intentMap?.type} text="Split type" />
      <TextContainer
        intent={intentMap?.type}
        text={typeName === "ml" ? "ML loop" : "Test"}
      />
      <Label intent={intentMap?.name} text="Split name" />
      <TextContainer intent={intentMap?.name} text={split.name} />
      {split.type === "ml" ? (
        <>
          <Label intent={intentMap?.goalEventType} text="Goal event type" />
          <TextContainer
            intent={intentMap?.goalEventType}
            text={getSplitGoalEventTypeNameFromRewardSql(split.rewardSql) || ""}
          />
          <Label
            intent={intentMap?.goalEventUnitIdPath}
            text="Goal event type unit ID path"
          />
          <TextContainer
            intent={intentMap?.goalEventUnitIdPath}
            text={
              split.rewardEvents[0]?.unitIdPayloadPath &&
              split.rewardEvents[0]?.unitIdPayloadPath.length > 0
                ? getStartCasePayloadPathString(
                    split.rewardEvents[0].unitIdPayloadPath
                  )
                : ""
            }
          />
        </>
      ) : null}
      {sortedDimensions.map((dimension) => {
        if (dimension.type !== "discrete") {
          return null;
        }
        return (
          <DiscreteDimensionControl
            key={dimension.id}
            is1dSplit={sortedDimensions.length === 1}
            splitType={split.type}
            dimension={dimension}
            intentMap={intentMap?.dimensions?.[dimension.id]}
          />
        );
      })}
      <Label intent={intentMap?.payloadEventType} text="Payload event type" />
      <TextContainer
        intent={intentMap?.payloadEventType}
        text={split.eventObjectTypeName || "None"}
      />
      {/* TODO: UI for continuous dimensions */}
    </div>
  );
}

export function DiscreteDimensionControl({
  is1dSplit,
  splitType,
  dimension,
  intentMap,
}: {
  is1dSplit: boolean;
  splitType: SplitType;
  dimension: DiscreteDimension;
  intentMap?: SplitControlDimensionIntentMap;
}): React.ReactElement {
  const unallocated = 1 - getArmAllocationsSum(dimension);
  const sortedArms = Object.values(dimension.arms).sort(
    (a, b) => a.index - b.index
  );

  return (
    <>
      {is1dSplit ? null : (
        <>
          <Label intent={intentMap?.name} text="Dimension Name" />
          <TextContainer intent={intentMap?.name} text={dimension.name} />
        </>
      )}
      <Label text="Arms" />
      {splitType === "test" ? (
        <Label
          intent={
            intentMap?.arms
              ? Object.values(intentMap.arms)
                  .map((value) => value.allocation)
                  .find(Boolean)
              : undefined
          }
          text={`Unallocated: ${(Math.max(unallocated, 0) * 100).toFixed(2)}%`}
        />
      ) : null}
      {sortedArms.map((arm) => (
        <ArmControl
          intentMap={intentMap?.arms?.[arm.id]}
          key={arm.id}
          splitType={splitType}
          arm={arm}
        />
      ))}
    </>
  );
}

export function ArmControl({
  splitType,
  arm,
  intentMap,
}: {
  splitType: SplitType;
  arm: Arm;
  intentMap?: SplitControlDimensionArmIntentMap;
}): React.ReactElement {
  return (
    <div className="mb-2 grid auto-cols-auto grid-flow-col grid-cols-[1fr] items-center gap-2">
      <TextContainer intent={intentMap?.name} text={arm.name} margin="none" />
      {splitType === "test" ? (
        <>
          <TextContainer
            intent={intentMap?.allocation}
            text={(arm.allocation * 100).toString()}
            margin="none"
            className="w-[50px] justify-end"
          />
          <div>%</div>
        </>
      ) : null}
    </div>
  );
}

function Label({
  text,
  intent = "neutral",
}: {
  text: string;
  intent?: Intent;
}): React.ReactElement | null {
  return (
    <div className={twJoin("mb-2 text-xs", intentToTextColorName(intent))}>
      {text}
    </div>
  );
}

function TextContainer({
  text,
  intent = "neutral",
  margin = "default",
  className,
}: {
  text: string;
  intent?: Intent;
  margin?: "default" | "none";
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "flex h-[30px] flex-row items-center rounded-lg border p-2",
        margin !== "none" && "mb-3",
        intent !== "neutral" && intentToBorderClassName(intent),
        intentToTextColorName(intent),
        intent === "neutral" ? "bg-bg-light" : intentToLightBgClassName(intent),
        className
      )}
    >
      {text}
    </div>
  );
}
