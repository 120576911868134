import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Fragment } from "react";
import { DotsThreeOutlineVertical } from "@phosphor-icons/react";
import { Intent, intentToActiveClassName as intentToBGColor } from "./intent";
import Label from "./Label";
import { lighterGreyHex } from "../lib/constants";
import twMerge from "../lib/twMerge";

export type MenuItem = {
  icon?: React.ReactNode;
  iconActive?: React.ReactNode;
  title: string;
  onClick: () => void;
  intent: Intent;
};

export default function Menu({
  showOnGroupHover,
  button,
  variant = "right",
  className,
  items,
  itemsClassName,
}: {
  showOnGroupHover?: boolean;
  button?: React.ReactNode;
  variant?: "right" | "left";
  className?: string;
  items: MenuItem[];
  itemsClassName?: string;
}): React.ReactElement {
  return (
    <div className="relative">
      <HeadlessMenu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <MenuButton
              className={twMerge(
                `flex h-[30px] w-[30px] items-center justify-center rounded-lg 
                            hover:cursor-pointer hover:border hover:border-bd-darker hover:drop-shadow-sm 
                            ${open ? "bg-bg-pressed" : "bg-white"}
                            ${
                              showOnGroupHover && !open
                                ? "opacity-0 group-hover:opacity-100"
                                : ""
                            }`,
                className
              )}
              onClick={(event) => event.stopPropagation()}
            >
              {button || (
                <DotsThreeOutlineVertical
                  weight="fill"
                  color={lighterGreyHex}
                />
              )}
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-75"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-75"
            >
              <MenuItems
                className={twMerge(
                  `absolute ${variant === "left" ? "right-0" : "left-0"} z-50 mt-1 w-32 origin-top-left rounded-lg border border-bd-darker bg-white  p-1 focus:outline-none`,
                  itemsClassName
                )}
              >
                {items.map((item) => (
                  <MenuItem>
                    {({ active }) => (
                      <div
                        className={`${
                          active ? intentToBGColor(item.intent) : ""
                        } group flex w-full cursor-pointer items-center gap-[6px] rounded-md p-[7px]`}
                        onClick={(event) => {
                          event.stopPropagation();
                          item.onClick();
                        }}
                      >
                        {active && item.iconActive
                          ? item.iconActive
                          : item.icon || null}
                        <Label type="title4">{item.title}</Label>
                      </div>
                    )}
                  </MenuItem>
                ))}
              </MenuItems>
            </Transition>
          </>
        )}
      </HeadlessMenu>
    </div>
  );
}
