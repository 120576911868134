import { Schema, Value, ValueType } from "@hypertune/sdk/src/shared/types";
import nullThrows from "@hypertune/sdk/src/shared/helpers/nullThrows";

export default function getDefaultValue(
  schema: Schema,
  valueType: ValueType
): Value {
  switch (valueType.type) {
    case "BooleanValueType":
      return false;
    case "IntValueType":
    case "FloatValueType":
      return 0;
    case "StringValueType":
      return "";
    case "EnumValueType": {
      const enumValues = Object.keys(
        nullThrows(
          schema.enums[valueType.enumTypeName].values,
          `No schema enum "${valueType.enumTypeName}".`
        )
      );
      if (enumValues.length === 0) {
        throw new Error(`No enum values for enum "${valueType.enumTypeName}".`);
      }
      return enumValues[0];
    }
    case "ObjectValueType": {
      const schemaObject = nullThrows(
        schema.objects[valueType.objectTypeName],
        `No schema object "${valueType.objectTypeName}"`
      );
      return Object.fromEntries(
        Object.entries(schemaObject.fields).map(([fieldName, field]) => [
          fieldName,
          getDefaultValue(schema, field.valueType),
        ])
      );
    }
    case "ListValueType":
      return [];
    case "UnionValueType": {
      const unionValues = Object.keys(
        nullThrows(
          schema.unions[valueType.unionTypeName],
          `No schema union "${valueType.unionTypeName}".`
        )
      );
      if (unionValues.length === 0) {
        throw new Error(`No union values for "${valueType.unionTypeName}".`);
      }
      return getDefaultValue(schema, {
        type: "ObjectValueType",
        objectTypeName: unionValues[0],
      });
    }
    case "VoidValueType":
    case "RegexValueType":
    case "FunctionValueType":
      throw new Error(`Unexpected value type: ${valueType.type}`);
    default: {
      const neverValueType: never = valueType;
      throw new Error(
        `Unexpected value type: ${JSON.stringify(neverValueType)}`
      );
    }
  }
}
