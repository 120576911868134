import Skeleton from "react-loading-skeleton";
import {
  BusinessesDocument,
  ProjectDocument,
  useUpdateProjectMutation,
} from "../../../generated/graphql";
import MutableText from "../../../components/input/MutableText";
import {
  blackTextHex,
  boldFontWeight,
  interFontFamily,
  lighterGreyHex,
  mediumFontSize,
} from "../../../lib/constants";
import getTextWidth from "../../../lib/generic/getTextWidth";

export default function ProjectNameControl({
  id,
  name,
  muted,
  readOnly,
}: {
  id?: string;
  name?: string;
  muted?: boolean;
  readOnly?: boolean;
}): React.ReactElement | null {
  const [updateProject] = useUpdateProjectMutation({
    refetchQueries: [BusinessesDocument, ProjectDocument],
    awaitRefetchQueries: true,
  });

  if (!id || !name) {
    return (
      <div className="py-[8px] leading-[13px]">
        <Skeleton height={13} width={80} />{" "}
      </div>
    );
  }

  return (
    <div className="py-[5.25px]">
      <MutableText
        text={name}
        setText={async (newName) => {
          try {
            await updateProject({
              variables: { input: { id, name: newName } },
            });
          } catch (error) {
            console.error("updateProject error:", error);
          }
        }}
        showPencil={false}
        minWidth={getTextWidth(interFontFamily, mediumFontSize, name) + 12}
        style={{
          lineHeight: "14px",
          fontWeight: boldFontWeight,
          color: muted ? lighterGreyHex : blackTextHex,
        }}
        readOnly={readOnly}
      />
    </div>
  );
}
