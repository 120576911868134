import { ArrowsClockwise } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import { lighterGreyHex } from "../../lib/constants";

export default function SpinnerArrows({
  size = 14,
}: {
  size?: number;
}): React.ReactElement {
  return (
    <motion.div
      className="rounded-full bg-base-grey-3-light p-[2px]"
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{ duration: 2, repeat: Infinity }}
    >
      <ArrowsClockwise color={lighterGreyHex} size={size} weight="regular" />
    </motion.div>
  );
}
