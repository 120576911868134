import { useState } from "react";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/input/TextInput";
import {
  BusinessesDocument,
  useUpdateProjectMutation,
} from "../../../generated/graphql";

const expectedDeleteMeText = "delete me";

export default function DeleteProjectModal({
  projectId,
  onClose,
}: {
  projectId: string;
  onClose: () => void;
}): React.ReactElement | null {
  const [updateProject, { loading }] = useUpdateProjectMutation({
    refetchQueries: [BusinessesDocument],
    awaitRefetchQueries: true,
  });
  async function deleteProject(): Promise<void> {
    try {
      await updateProject({
        variables: { input: { id: projectId, archived: true } },
      });
      onClose();
    } catch (error) {
      console.error("archiveProject error:", error);
    }
  }
  const [deleteMe, setDeleteMe] = useState("");

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Modal
        title="Delete project"
        onSave={deleteProject}
        onClose={onClose}
        saveLoading={loading}
        saveText="Yes delete this project"
        saveDisabled={deleteMe.trim().toLowerCase() !== expectedDeleteMeText}
        saveIntent="danger"
      >
        <p className="w-[380px] pb-2">
          Are you sure you want to delete this project? Type <b>delete me</b>{" "}
          below to confirm.
        </p>
        <TextInput
          value={deleteMe}
          placeholder={expectedDeleteMeText}
          onChange={setDeleteMe}
          trimOnBlur={false}
          readOnly={false}
          onEnter={deleteProject}
        />
      </Modal>
    </div>
  );
}
