import { Expression } from "@hypertune/sdk/src/shared/types";
import { ExpressionControlContext } from "../types";

export default function setSelectedExpressionId(
  context: ExpressionControlContext,
  expressionId: string | null,
  child: Expression | null
): void {
  let selectedExpressionId = expressionId;
  if (
    child?.type === "ListExpression" &&
    child.items.length > 0 &&
    child.items[0] !== null
  ) {
    selectedExpressionId = child.items[0].id;
  }
  if (selectedExpressionId) {
    context.setExpressionEditorState({
      ...context.expressionEditorState,
      selectedItem: { type: "expression", id: selectedExpressionId },
    });
  }
}
