import React, { useState } from "react";
import {
  BusinessesDocument,
  useCreateProjectMutation,
} from "../../../generated/graphql";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/input/TextInput";
import { useHypertune } from "../../../generated/hypertune.react";
import Dropdown from "../../../components/Dropdown";
import toLabeledOption from "../../../lib/toLabeledOption";

export default function NewProjectModal({
  businessId,
  onCreate,
  onClose,
}: {
  businessId: string;
  onCreate: (newProjectId: string) => void;
  onClose: () => void;
}): React.ReactElement {
  const hypertune = useHypertune();
  const [name, setName] = React.useState<string>("");

  const templateOptions = hypertune
    .app()
    .projectTemplates()
    .map((templateNode) =>
      toLabeledOption(templateNode.name({ fallback: "" }))
    );
  const [selectedTemplateName, setSelectedTemplateName] = useState(
    templateOptions[0]?.value ?? ""
  );

  const [createProject, { loading }] = useCreateProjectMutation({
    refetchQueries: [BusinessesDocument],
    awaitRefetchQueries: true,
  });

  async function create(): Promise<void> {
    const trimmedName = name.trim();
    if (loading || !trimmedName) {
      return;
    }

    try {
      const result = await createProject({
        variables: {
          input: {
            businessId,
            name: trimmedName,
            templateName: selectedTemplateName || null,
          },
        },
      });
      const newProjectId = result.data?.createProject;
      if (!newProjectId) {
        return;
      }
      onCreate(newProjectId);
    } catch (error) {
      console.error("createProject error:", error);
    }
  }

  return (
    <Modal
      title="Create new project"
      onClose={onClose}
      confirmClose={!!name}
      onSave={async () => {
        await create();
      }}
      saveText="Create"
      saveDisabled={!name}
      saveLoading={loading}
      saveLoadingText="Creating..."
    >
      <div className="mb-2 text-sm">Project Name</div>
      <TextInput
        value={name}
        trimOnBlur
        focusOnMount
        placeholder="Enter a name for this project"
        readOnly={false}
        onChange={(newValue: string) => {
          setName(newValue);
        }}
        onEnter={async () => {
          await create();
        }}
      />
      {hypertune.app().showProjectTemplateDropdown({ fallback: false }) && (
        <>
          <div className="mb-2 mt-4 text-sm">Project Template</div>
          <Dropdown
            options={{
              type: "options",
              options: templateOptions,
            }}
            height={30}
            value={toLabeledOption(selectedTemplateName)}
            onChange={(newOption) => {
              if (newOption) {
                setSelectedTemplateName(newOption.value);
              }
            }}
            placeholder="Select a template"
            noOptionsMessage="No templates available"
          />
        </>
      )}
    </Modal>
  );
}
