import {
  FunnelEventStep,
  FunnelStep,
  getAllValuePathsForObject,
  getStringValuePathsForObject,
} from "@hypertune/shared-internal";
import { useState, useMemo, useEffect, useCallback } from "react";
import {
  Schema,
  ValueType,
  fieldPathSeparator,
} from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import CardGroup, { CardGroupItem } from "../../../components/CardGroup";
import Label from "../../../components/Label";
import TypeIcon from "../../../components/icons/TypeIcon";
import { CommitContext } from "../../../lib/types";
import IdSelector from "../IdSelector";
import Modal from "../../../components/Modal";
import UnitIdPathSelector from "../UnitIdPathSelector";
import getPayloadPathString from "../../../lib/getPayloadPathString";
import isNumericValueType from "../../../lib/isNumericValueType";

const funnelStepTypes = ["event", "split"] as const;
type FunnelStepType = (typeof funnelStepTypes)[number];

export default function AddOrEditStepModal({
  commitContext,
  steps,
  setSteps,
  index,
  insert,
  onClose,
}: {
  commitContext: CommitContext;
  steps: FunnelStep[];
  setSteps: (newSteps: FunnelStep[]) => void;
  index: number;
  insert: boolean;
  onClose: () => void;
}): React.ReactElement | null {
  const oldStep: FunnelStep | null = insert ? null : (steps[index] ?? null);

  const [funnelStepType, setFunnelStepType] = useState<FunnelStepType>(
    oldStep?.type === "FunnelExposureStep" ? "split" : "event"
  );
  const [eventTypeName, setEventTypeName] = useState<string | null>(
    oldStep?.type === "FunnelEventStep" ? oldStep.eventObjectTypeName : null
  );
  const [splitId, setSplitId] = useState<string | null>(
    oldStep?.type === "FunnelExposureStep" ? oldStep.splitId : null
  );
  const [unitIdPath, setUnitIdPath] = useState<string | null>(
    oldStep?.type === "FunnelEventStep"
      ? getPayloadPathString(oldStep.unitIdPayloadPath)
      : oldStep?.type === "FunnelExposureStep"
        ? oldStep.unitId.type === "unitId"
          ? "unitId"
          : getPayloadPathString(oldStep.unitId.payloadPath)
        : null
  );

  const getPayloadAllowedPaths = useCallback(
    (filter?: (valueType: ValueType) => boolean) => {
      return new Set(
        funnelStepType === "split"
          ? splitId && commitContext.splits[splitId].eventObjectTypeName
            ? getAllValuePathsForObject(
                commitContext.schema,
                commitContext.splits[splitId].eventObjectTypeName!,
                filter
              ).join(fieldPathSeparator)
            : []
          : eventTypeName
            ? getAllValuePathsForObject(
                commitContext.schema,
                eventTypeName,
                filter
              ).join(fieldPathSeparator)
            : []
      );
    },
    [commitContext, funnelStepType, splitId, eventTypeName]
  );
  const allowedPayloadBreakdownPaths = useMemo(
    () => getPayloadAllowedPaths(),
    [getPayloadAllowedPaths]
  );
  const allowedPayloadAggregationPaths = useMemo(
    () => getPayloadAllowedPaths(isNumericValueType),
    [getPayloadAllowedPaths]
  );

  const unitIdOptions = useMemo(() => {
    return (
      funnelStepType === "event" && eventTypeName
        ? getJoinIdOptionsForObject(commitContext.schema, eventTypeName)
        : funnelStepType === "split" && splitId
          ? commitContext.splits[splitId].eventObjectTypeName
            ? [
                ["unitId"],
                ...getJoinIdOptionsForObject(
                  commitContext.schema,
                  commitContext.splits[splitId].eventObjectTypeName as string
                ),
              ]
            : [["unitId"]]
          : []
    ).map((path) => {
      return {
        value: path.join(fieldPathSeparator),
        label: path.map(toStartCase).join(fieldPathSeparator),
      };
    });
  }, [
    commitContext.schema,
    commitContext.splits,
    funnelStepType,
    eventTypeName,
    splitId,
  ]);
  const eventUnitIdPathsSet = useMemo(() => {
    return new Set(
      steps
        .filter((step) => step.type === "FunnelEventStep")
        .map((step) =>
          getPayloadPathString((step as FunnelEventStep).unitIdPayloadPath)
        )
    );
  }, [steps]);

  useEffect(() => {
    if (!unitIdOptions.some((option) => option.value === unitIdPath)) {
      const defaultOption = unitIdOptions.find(({ value }) =>
        eventUnitIdPathsSet.has(value)
      );
      setUnitIdPath(
        defaultOption?.value ??
          (unitIdOptions.length > 0 ? unitIdOptions[0].value : null)
      );
    }
  }, [eventUnitIdPathsSet, unitIdOptions, unitIdPath]);

  const isValid =
    (funnelStepType === "event" ? eventTypeName !== null : splitId !== null) &&
    unitIdPath !== null;

  function onSubmit(): void {
    if (!isValid) {
      return;
    }
    const stepBase = !oldStep
      ? {}
      : {
          filter: oldStep.filter,
          derivedFields: oldStep.derivedFields,
          // Remove invalid breakdowns and aggregations
          breakdowns: oldStep.breakdowns?.filter(
            (breakdown) =>
              breakdown.type === "derivedField" ||
              allowedPayloadBreakdownPaths.has(
                breakdown.payloadPath.join(fieldPathSeparator)
              )
          ),
          aggregations: oldStep.aggregations?.filter(
            (aggregation) =>
              aggregation.data.type === "derivedField" ||
              allowedPayloadAggregationPaths.has(
                aggregation.data.payloadPath.join(fieldPathSeparator)
              )
          ),
        };
    const newStep: FunnelStep =
      funnelStepType === "event"
        ? {
            ...stepBase,
            type: "FunnelEventStep",
            eventObjectTypeName: eventTypeName as string,
            unitIdPayloadPath: unitIdPath.split(fieldPathSeparator).slice(1),
          }
        : {
            ...stepBase,
            type: "FunnelExposureStep",
            splitId: splitId as string,
            unitId:
              unitIdPath === "unitId"
                ? { type: "unitId" }
                : {
                    type: "payloadPath",
                    payloadPath: unitIdPath.split(fieldPathSeparator).slice(1),
                  },
            dimensionIds:
              Object.keys(commitContext.splits[splitId as string].dimensions)
                .length === 1
                ? {
                    [Object.keys(
                      commitContext.splits[splitId as string].dimensions
                    )[0]]: true,
                  }
                : {},
          };
    setSteps([
      ...steps.slice(0, index),
      newStep,
      ...steps.slice(index + (insert ? 0 : 1)),
    ]);
    onClose();
  }

  return (
    <Modal
      title={`${insert ? "Add" : "Edit"} funnel step`}
      onClose={onClose}
      closeText="Cancel"
      onSave={onSubmit}
      saveDisabled={!isValid}
      saveText={insert ? "Add" : "Save"}
      saveIntent="neutral"
      saveWeight="outlined"
      buttonLayout="end"
    >
      <Label type="title4" className="mb-[9px] mt-4 text-tx-muted">
        Type
      </Label>
      <CardGroup
        layout="grid"
        cardLayout="horizontal-with-icon"
        cards={funnelStepTypes.map(
          (stepType): CardGroupItem => ({
            key: stepType,
            className: "gap-2",
            isSelected: stepType === funnelStepType,
            onClick: () => {
              setUnitIdPath(null);
              setFunnelStepType(stepType);
            },
            children: (
              <>
                <TypeIcon type={stepType} />
                <Label type="title3">{toStartCase(stepType)}</Label>
              </>
            ),
          })
        )}
      />
      <Label type="title4" className="mb-[9px] mt-6 text-tx-muted">
        {funnelStepType === "event" ? "Event type" : "Split"}
      </Label>
      {funnelStepType === "event" ? (
        <IdSelector
          context={commitContext}
          source="eventTypes"
          selectedId={eventTypeName}
          setSelectedId={setEventTypeName}
          readOnly={false}
          hideNewOption
        />
      ) : (
        <IdSelector
          context={commitContext}
          source="splits"
          selectedId={splitId}
          setSelectedId={setSplitId}
          readOnly={false}
          hideNewOption
        />
      )}
      <Label type="title4" className="mb-[9px] mt-4 text-tx-muted">
        Unit ID
      </Label>
      <UnitIdPathSelector
        context={commitContext}
        source={funnelStepType}
        eventTypeName={eventTypeName}
        splitId={splitId}
        unitIdPath={unitIdPath}
        setUnitIdPath={setUnitIdPath}
      />
    </Modal>
  );
}

function getJoinIdOptionsForObject(
  schema: Schema,
  objectTypeName: string
): string[][] {
  return getStringValuePathsForObject(schema, objectTypeName).map((path) => [
    "payload",
    ...path,
  ]);
}
