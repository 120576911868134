import {
  SplitAssignment,
  Split,
  SplitConfig,
} from "@hypertune/sdk/src/shared/types";
import { DbArmReward, DbArmRewardMap } from "./types";

export default function getEpsilonGreedySplitConfig(
  split: Split,
  armRewardMap: DbArmRewardMap
): SplitConfig {
  const bestAssignment: SplitAssignment = {};

  Object.values(split.dimensions).forEach((dimension) => {
    if (dimension.type !== "discrete") {
      // TODO: Support continuous dimensions
      return;
    }

    const arms = Object.values(dimension.arms);
    if (arms.length === 0) {
      return;
    }

    const bestArmId = arms
      .map((arm) => {
        const armId = arm.id;
        const armReward = armRewardMap[armId];
        const expectedValue = armReward ? getExpectedValue(armReward) : 0;
        return { armId, expectedValue };
      })
      .reduce((acc, curr) =>
        curr.expectedValue > acc.expectedValue ? curr : acc
      ).armId;

    bestAssignment[dimension.id] = { type: "discrete", armId: bestArmId };
  });

  return {
    type: "EpsilonGreedyConfig",
    epsilon: 1, // TODO: Make smarter
    bestAssignment,
  };
}

/* eslint-disable camelcase */
function getExpectedValue(armReward: DbArmReward): number {
  const { exposure_count, reward_sum } = armReward;
  return exposure_count > 0 ? reward_sum / exposure_count : 0;
}
