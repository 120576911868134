declare const window: any;

export default function trackAnalyticsEvent(name: string): void {
  window.gtag("event", name, {
    transport_type: "beacon",
    event_callback() {
      console.log(name);
    },
  });
}
