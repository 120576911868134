import {
  AnyAction,
  EmptyObject,
  Reducer,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import { PersistPartial } from "redux-persist/lib/persistReducer";
import appReducer, { AppState } from "./appSlice";
import tabsReducer, { TabsState } from "../components/tabsSlice";
import tooltipsReducer, {
  TooltipsState,
} from "../components/tooltips/tooltipsSlice";
import projectReducer, { ProjectState } from "../features/project/projectSlice";
import analyticsReducer, {
  AnalyticsEditorState,
} from "../features/project/analytics/analyticsSlice";

const persistConfig = {
  key: "redux-state",
  whitelist: ["tabs", "tooltips", "project/showDetails"],
  storage,
  migrate: (state: any) => {
    // Migrate existing simple boolean tooltips to the new format.
    const newState = {
      ...state,
      tooltips: {
        logic:
          window.localStorage.getItem("notification:newFlagButton") === "true"
            ? 1
            : 0,
        ...(state && state.tooltips ? state.tooltips : {}),
      },
    };
    window.localStorage.removeItem("notification:newFlagButton");
    window.localStorage.removeItem("notification:newTypeButton");

    return Promise.resolve(newState);
  },
};

const projectPersistConfig = {
  key: "redux-project-state",
  whitelist: ["showDetails"],
  storage,
};

const persistedReducer: Reducer<
  EmptyObject & {
    app: AppState;
    tabs: TabsState;
    tooltips: TooltipsState;
    project: ProjectState & PersistPartial;
    projectAnalytics: AnalyticsEditorState;
  } & PersistPartial,
  AnyAction
> = persistReducer(
  persistConfig,
  combineReducers({
    app: appReducer,
    tabs: tabsReducer,
    tooltips: tooltipsReducer,
    project: persistReducer(projectPersistConfig, projectReducer),
    projectAnalytics: analyticsReducer,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // We are only persisting serializable part of the state,
      // so we don't need the check for now.
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
