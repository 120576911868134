import {
  ApplicationExpression,
  Expression,
  ValueType,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import dropArgument from "@hypertune/shared-internal/src/expression/dropArgument";
import getApplicationFunctionExpression from "@hypertune/shared-internal/src/expression/getApplicationFunctionExpression";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import getSetVariableNameFunction from "../../../lib/expression/getSetVariableNameFunction";
import getTextWidth from "../../../lib/generic/getTextWidth";
import {
  small,
  interFontFamily,
  mediumFontSize,
  normal,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
} from "../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import LabeledExpressionControlList, {
  LabeledExpressionControlListItemControlType,
} from "./LabeledExpressionControlList";
import VariableNameControl from "./VariableNameControl";
import isReadOnly from "../../../lib/expression/isReadOnly";
import DeleteButton from "../../../components/buttons/DeleteButton";

export default function ApplicationExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  parentExpression,
  lift,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: ApplicationExpression;
  setExpression: (newExpression: Expression | null) => void;
  parentExpression: Expression | null;
  lift: LiftFunction;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const functionWithReturnValueTypeConstraint: ValueTypeConstraint =
    isValueTypeValid(context.commitContext.schema, expression.valueType)
      ? {
          type: "FunctionWithReturnValueTypeConstraint",
          returnValueTypeConstraint: getConstraintFromValueType(
            expression.valueType
          ),
        }
      : { type: "ErrorValueTypeConstraint" };

  const parameterValueTypes: ValueType[] | null =
    expression.function &&
    expression.function.valueType.type === "FunctionValueType" &&
    expression.arguments.length ===
      expression.function.valueType.parameterValueTypes.length
      ? expression.function.valueType.parameterValueTypes
      : null;

  const functionExpression = getApplicationFunctionExpression(expression);

  const readOnly = isReadOnly(context);

  const argumentExpressionControls = expression.arguments.map(
    (argument, index) => (
      <ExpressionControl
        key={`${index.toString()}`} // TODO: Better key
        context={{
          ...context,
          disableVariableLift:
            context.disableVariableLift || parentExpression === null,
        }}
        variables={variables}
        setVariableName={setVariableName}
        valueTypeConstraint={
          parameterValueTypes &&
          isValueTypeValid(
            context.commitContext.schema,
            parameterValueTypes[index]
          )
            ? getConstraintFromValueType(parameterValueTypes[index])
            : { type: "ErrorValueTypeConstraint" }
        }
        expression={argument}
        setExpression={(newExpression: Expression | null): void =>
          setExpression({
            ...expression,
            arguments: [
              ...expression.arguments.slice(0, index),
              newExpression,
              ...expression.arguments.slice(index + 1),
            ],
          })
        }
        lift={getApplicationExpressionArgumentLiftFunction(
          expression,
          lift,
          index
        )}
        parentExpression={expression}
        setParentExpression={setExpression}
        variableContext={
          functionExpression
            ? {
                name: functionExpression.parameters[index].name,
                rename: getSetVariableNameFunction(
                  functionExpression,
                  (newExpression) => {
                    setExpression({ ...expression, function: newExpression });
                  },
                  index
                ),
                drop: () => {
                  const newExpression = dropArgument(expression, index);
                  if (!newExpression) {
                    return;
                  }
                  setExpression(newExpression);
                },
              }
            : undefined
        }
        includeExpressionOption={includeExpressionOption}
        disablePanelOnSelect
      />
    )
  );
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: small }}>
      {functionExpression ? null : <div>Arguments</div>}
      {functionExpression ? (
        <LabeledExpressionControlList
          useArrow
          items={argumentExpressionControls.map((expressionControl, index) => ({
            type: LabeledExpressionControlListItemControlType,
            label: {
              component: (
                <VariableNameControl
                  schema={context.commitContext.schema}
                  variable={{
                    ...functionExpression.parameters[index],
                    valueType:
                      functionExpression.valueType.parameterValueTypes[index],
                  }}
                  setVariableName={getSetVariableNameFunction(
                    functionExpression,
                    (newExpression) => {
                      setExpression({ ...expression, function: newExpression });
                    },
                    index
                  )}
                  readOnly={readOnly}
                />
              ),
              width: getTextWidth(
                interFontFamily,
                mediumFontSize,
                functionExpression.parameters[index].name
              ),
            },
            intent:
              context.expressionIdToIntent?.[expression.id] ??
              (expression.function?.id
                ? context.expressionIdToIntent?.[expression.function?.id]
                : "neutral") ??
              "neutral",
            options:
              readOnly || expression.arguments[index] === null
                ? []
                : [
                    <DeleteButton
                      key="drop"
                      size="x-small"
                      disabled={expression.arguments[index] === null}
                      onClick={(): void => {
                        const newExpression = dropArgument(expression, index);
                        if (!newExpression) {
                          return;
                        }
                        setExpression(newExpression);
                      }}
                    />,
                  ],
            expressionControl,
          }))}
          context={context}
          expressionIds={Object.values(expression.arguments).map(
            (_expression) => _expression?.id ?? ""
          )}
        />
      ) : (
        argumentExpressionControls
      )}
      {functionExpression ? null : (
        <div style={{ marginTop: normal }}>Function</div>
      )}
      <ExpressionControl
        context={context}
        variables={variables}
        setVariableName={setVariableName}
        valueTypeConstraint={functionWithReturnValueTypeConstraint}
        expression={expression.function}
        setExpression={(newExpression: Expression | null): void =>
          setExpression({
            ...expression,
            function: newExpression,
          })
        }
        lift={getApplicationExpressionFunctionLiftFunction(expression, lift)}
        parentExpression={expression}
        setParentExpression={setExpression}
        includeExpressionOption={includeExpressionOption}
      />
    </div>
  );
}

export function getApplicationExpressionArgumentLiftFunction(
  expression: ApplicationExpression,
  lift: LiftFunction,
  index: number
): LiftFunction {
  return (child) => {
    function replaceArgument(
      variable: VariableExpression | ApplicationExpression
    ): ApplicationExpression {
      const newExpression: ApplicationExpression = {
        ...expression,
        arguments: [
          ...expression.arguments.slice(0, index),
          child.replaceArgument(variable),
          ...expression.arguments.slice(index + 1),
        ],
      };
      return newExpression;
    }
    // We never lift directly in an ApplicationExpression; we let the
    // parent lift
    lift({
      argument: child.argument,
      replacedVariableIdToNewVariable: child.replacedVariableIdToNewVariable,
      replaceArgument,
      newVariableName: child.newVariableName,
      isNew: child.isNew,
      keepInObjectField: child.keepInObjectField,
    });
  };
}

export function getApplicationExpressionFunctionLiftFunction(
  expression: ApplicationExpression,
  lift: LiftFunction
): LiftFunction {
  return (child) => {
    function replaceArgument(
      variable: VariableExpression | ApplicationExpression
    ): ApplicationExpression {
      const newExpression: ApplicationExpression = {
        ...expression,
        function: child.replaceArgument(variable),
      };
      return newExpression;
    }
    // We never lift directly in an ApplicationExpression; we let the
    // parent lift
    lift({
      argument: child.argument,
      replacedVariableIdToNewVariable: child.replacedVariableIdToNewVariable,
      replaceArgument,
      newVariableName: child.newVariableName,
      isNew: child.isNew,
      keepInObjectField: child.keepInObjectField,
    });
  };
}
