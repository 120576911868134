import { intentWarningHex, whiteHex } from "../../lib/constants";

export default function Folder(): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="6" fill={intentWarningHex} />
      <rect x="3" y="7" width="10" height="5" rx="1" fill={whiteHex} />
      <rect x="3" y="4" width="6" height="2" rx="1" fill={whiteHex} />
    </svg>
  );
}
