import { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import getTextWidth from "../../lib/generic/getTextWidth";
import {
  interFontFamily,
  mediumFontSize,
  newLineRegex,
  optionsButtonRight,
  optionsButtonTop,
} from "../../lib/constants";
import {
  Intent,
  intentToBorderClassName,
  intentToLightBgClassName,
  intentToPlaceholderTextColorName,
  intentToTextColorName,
} from "../intent";
import ModalWithContent from "../ModalWithContent";
import { useHypertune } from "../../generated/hypertune.react";
import removeNonBreakingSpaces from "../../lib/expression/removeNonBreakingSpaces";
import twMerge from "../../lib/twMerge";

export default function TextArea({
  readOnly,
  focusOnMount,
  placeholder,
  value,
  setValue,
  shouldStack,
  optionsButton,
  style,
  className,
  minWidth: defaultMinWidth = 450,
  minRows,
  intent = "neutral",
  shiftForNewLine,
  onBlur,
  onEsc,
  onSplit,
  onClick,
}: {
  readOnly: boolean;
  focusOnMount?: boolean;
  placeholder: string;
  value: string;
  setValue: (newValue: string) => void;
  shouldStack?: boolean;
  optionsButton?: React.ReactNode;
  style?: Omit<React.CSSProperties, "minHeight" | "maxHeight" | "height">;
  className?: string;
  minWidth?: number;
  minRows?: number;
  intent?: Intent;
  shiftForNewLine?: boolean;
  onBlur?: () => void;
  onEsc?: () => void;
  onSplit?: (lines: string[]) => void;
  onClick?: (event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
}): React.ReactElement {
  const paragraph = useRef<HTMLParagraphElement>(null);
  const textarea = useRef<HTMLTextAreaElement>(null);

  const [toSplit, setToSplit] = useState<string | null>(null);

  const content = useHypertune().content();

  function select(): void {
    const el = textarea.current;
    if (!el) {
      return;
    }
    el.select();
  }

  function focus(): void {
    const el = textarea.current;
    if (!el || document.activeElement === el) {
      return;
    }
    el.focus();
    el.selectionStart = el.value.length;
  }
  useEffect(() => {
    if (focusOnMount) {
      focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const minWidth = Math.min(
    defaultMinWidth,
    getTextWidth(interFontFamily, mediumFontSize, value || placeholder) + 45
  );
  const fullClassName = twMerge(
    "m-0 flex-grow resize-none border py-1.5 pl-2 pr-8 leading-normal outline-none",
    !readOnly &&
      "hover:border-intent-primary focus:border-intent-primary  focus:shadow-inputs",
    shouldStack ? "rounded-b-md" : "rounded-md",
    intent !== "neutral"
      ? [
          intentToTextColorName(intent),
          intentToPlaceholderTextColorName(intent),
          intentToBorderClassName(intent),
          intentToLightBgClassName(intent),
        ]
      : ["border-bd-darker", readOnly ? "bg-bg-light" : "bg-white", className]
  );

  return (
    <div className="relative flex flex-row items-stretch">
      {readOnly ? (
        <p
          ref={paragraph}
          className={`${fullClassName} whitespace-pre-wrap`}
          style={{
            minWidth,
            minHeight: 19.5 * (minRows ?? 1) + 14,
            ...(minWidth === defaultMinWidth ? { maxWidth: minWidth } : {}),
            ...(style || {}),
          }}
        >
          {value}
        </p>
      ) : (
        <TextareaAutosize
          ref={textarea}
          disabled={readOnly || !!toSplit}
          className={fullClassName}
          style={{
            minWidth,
            ...(style || {}),
          }}
          minRows={minRows}
          placeholder={readOnly ? "" : placeholder}
          value={value}
          readOnly={readOnly}
          onChange={(event) => {
            setValue(removeNonBreakingSpaces(event.target.value));
          }}
          onClick={(event) => {
            if (readOnly) {
              select();
              return;
            }
            onClick?.(event);
          }}
          onBlur={onBlur}
          onKeyDown={(event) => {
            if (
              shiftForNewLine &&
              onBlur &&
              event.key === "Enter" &&
              !event.shiftKey
            ) {
              event.preventDefault();
              onBlur();
              return;
            }
            if (onEsc && event.key === "Escape") {
              event.preventDefault();
              onEsc();
            }
          }}
          onPaste={(event) => {
            if (event.currentTarget.value || !onSplit) {
              return;
            }
            const text = event.clipboardData.getData("text/plain");
            if (getLines(text).length < 2) {
              return;
            }
            event.preventDefault();
            setToSplit(text);
          }}
        />
      )}

      {optionsButton ? (
        <div
          style={{
            position: "absolute",
            top: optionsButtonTop + 2,
            right: optionsButtonRight,
          }}
        >
          {optionsButton}
        </div>
      ) : null}

      {onSplit && toSplit ? (
        <ModalWithContent
          content={content.logic().splitPasteValueModal().get()}
          closeWeight="minimal"
          saveWeight="filled"
          buttonLayout="end"
          onSave={() => {
            const lines = getLines(toSplit);
            onSplit(lines);
            setToSplit(null);
          }}
          onClose={() => {
            setValue(toSplit);
            setToSplit(null);
          }}
          disableAwayClickClose
        >
          <div className="grid grid-cols-2 items-start gap-2">
            <ul>
              <li className="whitespace-pre-line rounded-lg border border-base-grey-1-medium px-2 py-1">
                {`1
                  2
                  3`}
              </li>
            </ul>
            <ul className="grid gap-1">
              <li className="rounded-lg border border-base-grey-1-medium px-2 py-1">
                1
              </li>
              <li className="rounded-lg border border-base-grey-1-medium px-2 py-1">
                2
              </li>
              <li className="rounded-lg border border-base-grey-1-medium px-2 py-1">
                3
              </li>
            </ul>
          </div>
        </ModalWithContent>
      ) : null}
    </div>
  );
}

function cacheScrollTops(start: HTMLElement | null): () => void {
  let el: HTMLElement | null = start;
  const cache: [HTMLElement, number][] = [];

  while (el && el.parentNode && el.parentNode instanceof HTMLElement) {
    if (el.parentNode.scrollTop) {
      cache.push([el.parentNode, el.parentNode.scrollTop]);
    }
    el = el.parentNode;
  }

  return () =>
    cache.forEach(([node, scrollTop]) => {
      node.style.scrollBehavior = "auto";
      node.scrollTop = scrollTop;
      node.style.scrollBehavior = "";
    });
}

function getLines(text: string): string[] {
  return removeNonBreakingSpaces(text)
    .split(newLineRegex)
    .map((line) => line.trim())
    .filter((line) => !!line);
}
