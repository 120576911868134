import React, { useState } from "react";
import AutosizeInput from "react-input-autosize";
import { Pencil } from "@phosphor-icons/react";
import {
  baseDarkGreyHex,
  blackTextHex,
  interFontFamily,
  mediumFontSize,
} from "../../lib/constants";
import getTextWidth from "../../lib/generic/getTextWidth";
import ErrorTooltip from "./ErrorTooltip";
import { ModalContent } from "../../generated/hypertune";
import ModalWithContent from "../ModalWithContent";
import twMerge from "../../lib/twMerge";

export default function MutableText<ErrorT>({
  className,
  readOnly,
  text,
  setText,
  showPencil,
  minWidth: customMinWidth,
  style,
  confirmModalContent,
  confirmModalVariables,
  hasError,
  showError,
  stopClickPropagation = true,
}: {
  className?: string;
  readOnly?: boolean;
  text: string;
  setText: (newText: string) => Promise<void>;
  showPencil: boolean;
  minWidth?: number;
  style?: React.CSSProperties;
  confirmModalContent?: ModalContent;
  confirmModalVariables?: { [variableName: string]: string };
  hasError?: (newText: string) => ErrorT;
  showError?: (error: ErrorT) => React.ReactElement | null;
  stopClickPropagation?: boolean;
}): React.ReactElement {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [draft, setDraft] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const minWidth =
    customMinWidth ?? getTextWidth(interFontFamily, mediumFontSize, text) + 2;
  const wrapperMinWidth = !readOnly && showPencil ? minWidth + 26 : minWidth;

  const styleObject: React.CSSProperties = {
    fontFamily: interFontFamily,
    fontSize: mediumFontSize,
    whiteSpace: "nowrap",
    ...(style || {}),
  };

  const textError = hasError ? hasError(draft || text) : null;
  const useConfirmDialog = !!confirmModalContent;

  async function onSave(): Promise<void> {
    if (!draft || textError !== null) {
      return;
    }
    const newText = draft.trim();
    if (newText) {
      setIsLoading(true);
      try {
        await setText(newText);
      } catch (error) {
        console.error("onSave: setText error: ", error);
      }
      setIsLoading(false);
    }
    setShowConfirmModal(false);
    setDraft(null);
  }
  async function onConfirmChange(): Promise<void> {
    if (!draft || textError !== null) {
      return;
    }
    if (draft === text) {
      setDraft(null);
      return;
    }
    if (useConfirmDialog) {
      setShowConfirmModal(true);
      return;
    }

    await onSave();
  }

  if (draft !== null) {
    return (
      <>
        {showConfirmModal && useConfirmDialog && (
          <ModalWithContent
            content={confirmModalContent}
            variables={confirmModalVariables}
            onClose={() => {
              setDraft(null);
              setShowConfirmModal(false);
            }}
            onSave={onSave}
          />
        )}
        <Wrapper
          focused
          minWidth={wrapperMinWidth}
          style={styleObject}
          hasError={textError !== null}
        >
          <div className="flex flex-row gap-2">
            <AutosizeInput
              autoFocus
              className={twMerge(className, "text-clip")}
              disabled={isLoading}
              onFocus={(event) => {
                event.target.select();
              }}
              onBlur={async () => {
                if (!draft || textError !== null) {
                  setDraft(null);
                  return;
                }
                await onConfirmChange();
              }}
              inputStyle={{
                color: baseDarkGreyHex,
                outline: `0px solid`,
                lineHeight: 1,
              }}
              value={draft}
              onChange={(event) => {
                setDraft(event.target.value);
              }}
              onKeyDown={async (event) => {
                if (event.key === "Escape") {
                  setDraft(null);
                  return;
                }
                if (event.key === "Enter") {
                  await onConfirmChange();
                }
              }}
            />
            {showError && textError && (
              <ErrorTooltip error={showError(textError)} />
            )}
          </div>
        </Wrapper>
      </>
    );
  }

  return (
    <Wrapper
      readOnly={readOnly}
      minWidth={wrapperMinWidth}
      onClick={!readOnly ? () => setDraft(text) : undefined}
      stopClickPropagation={stopClickPropagation}
      style={styleObject}
    >
      <div className="flex flex-row items-center gap-2">
        <span className={className}>{text}</span>
        {!readOnly && showPencil && (
          <Pencil color={blackTextHex} weight="regular" size={14} />
        )}
      </div>
    </Wrapper>
  );
}

function Wrapper({
  readOnly,
  focused,
  hasError,
  minWidth,
  onClick,
  children,
  style,
  stopClickPropagation,
}: {
  readOnly?: boolean;
  focused?: boolean;
  hasError?: boolean;
  minWidth: number;
  onClick?: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  stopClickPropagation?: boolean;
}): React.ReactElement | null {
  return (
    <div
      className={`inline-block rounded-sm border p-[2px]
                  ${
                    !focused
                      ? "border-transparent shadow-inputs-sm shadow-transparent"
                      : hasError
                        ? "border-intent-danger shadow-inputs-sm-error"
                        : "cursor-text border-base-blue shadow-inputs-sm"
                  }
                  ${onClick ? "cursor-pointer" : ""}
                 ${!readOnly && !hasError ? "hover:border-base-blue" : ""} `}
      style={{ ...(style || {}), minWidth }}
      onClick={
        onClick
          ? (event) => {
              if (stopClickPropagation) {
                event.stopPropagation();
              }
              onClick();
            }
          : undefined
      }
    >
      {children}
    </div>
  );
}
