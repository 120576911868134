import { getAuth } from "firebase/auth";
import { Navigate, useLocation } from "react-router-dom";
import { locationStateWithPrevious } from "../lib/query/locationState";

export default function RequireAuth({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const auth = getAuth();
  const location = useLocation();

  if (!auth.currentUser) {
    const to = "/register";
    console.log(`RequireAuth redirecting to ${to} from:`, location);
    return (
      <Navigate to={to} state={locationStateWithPrevious(location)} replace />
    );
  }

  return children;
}
