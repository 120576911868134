import { defaultBranchName } from "@hypertune/sdk/src/shared";

export default function getVercelEdgeConfigItemKey(
  projectId: number,
  branchName: string
): string {
  if (branchName === defaultBranchName) {
    return `hypertune_${projectId}`;
  }
  return `hypertune_${projectId}_${branchName}`;
}
