import React from "react";
import { CommitConfig, Expression, Schema } from "@hypertune/sdk/src/shared";
import {
  ProjectTokenMapWithMeta,
  getFullSchemaCode,
} from "@hypertune/shared-internal";
import {
  CommitMetadata,
  DiffCommitData,
  EditorState,
  ImplementationContext,
} from "../../lib/types";
import AnalyticsEditor from "./analytics/AnalyticsEditor";
import DebugEditor from "./DebugEditor";
import LogicEditor from "./LogicEditor";
import LogsEditor from "./LogsEditor";
import SchemaEditor from "./schema/SchemaEditor";
import SettingsEditor from "./settings/SettingsEditor";
import { BusinessType, ProjectQuery } from "../../generated/graphql";
import { ProjectView } from "./projectHooks";
import DiffEditor from "./diff/DiffEditor";
import PullRequestEditor from "./pull-request/PullRequestEditor";
import SplitsEditor from "./split/SplitsEditor";
import { ExpressionNodeMap } from "./expression/toTree";
import BranchesEditor from "./BranchesEditor";

export default function CommitInner({
  selectedView,
  meId,
  project,
  commitId,
  schema,
  logicError,
  schemaCodeError,
  readOnlySchemaCode,
  editableSchemaCode,
  implementationContext,
  expressionTree,
  expression,
  setExpression,
  commitConfig,
  editorState,
  setEditorState,
  setLogicSelectedFieldPath,
  canEdit,
  canContribute,
  readOnly,
  baseCommit,
  defaultFieldPath,
  hasChanges,
  hasError,
  meta,
}: {
  selectedView: ProjectView;
  meId: string;
  project: ProjectQuery["project"];
  commitId: string | null;
  schema: Schema;
  logicError?: string;
  schemaCodeError?: string;
  readOnlySchemaCode: string;
  editableSchemaCode: string;
  implementationContext: ImplementationContext;
  expressionTree: ExpressionNodeMap;
  expression: Expression;
  setExpression: (newExpression: Expression) => void;
  commitConfig: CommitConfig;
  editorState: EditorState;
  setEditorState: (newEditorState: EditorState) => void;
  setLogicSelectedFieldPath: (newSelectedFieldPath: string) => void;
  canEdit: boolean;
  canContribute: boolean;
  readOnly: boolean;
  baseCommit: DiffCommitData;
  defaultFieldPath: string[];
  hasChanges: boolean;
  hasError: boolean;
  meta?: CommitMetadata;
}): React.ReactElement {
  const projectTokens: ProjectTokenMapWithMeta = JSON.parse(project.tokensJson);

  console.debug("Expression:", expression);
  console.debug("Splits:", implementationContext.splits);
  console.debug("Commit Config:", commitConfig);
  console.debug("Editor State:", editorState);

  return (
    <>
      <SplitsEditor
        isVisible={selectedView === "splits"}
        expression={expression}
        commitContext={{ ...implementationContext, schema }}
        // errorMessage={schemaCodeError}
        readOnly={readOnly}
      />
      <DiffEditor
        meId={meId}
        isCurrent={!readOnly}
        isVisible={selectedView === "diff"}
        currentCommit={baseCommit}
        newCommit={{
          schema,
          expression,
          splits: implementationContext.splits,
        }}
        newCommitHasChanges={hasChanges}
        newCommitHasError={hasError}
        meta={meta}
      />
      <PullRequestEditor
        meId={meId}
        isVisible={selectedView === "pull-requests"}
        projectId={project.id}
        canEdit={canEdit}
        canContribute={canContribute}
        branches={project.branches}
        pullRequests={project.pullRequests}
      />
      <SchemaEditor
        isVisible={selectedView === "schema"}
        schema={schema}
        splits={implementationContext.splits}
        eventTypeMap={implementationContext.eventTypes}
        expression={expression}
        readOnlySchemaCode={readOnlySchemaCode}
        editableSchemaCode={editableSchemaCode}
        errorMessage={schemaCodeError}
        readOnly={readOnly}
      />
      <LogicEditor
        isVisible={selectedView === "logic"}
        hasError={!!logicError}
        meId={meId}
        projectId={project.id}
        commitId={commitId}
        schema={schema}
        implementationContext={implementationContext}
        expressionTree={expressionTree}
        expression={expression}
        setExpression={setExpression}
        setSelectedFieldPath={setLogicSelectedFieldPath}
        readOnly={readOnly}
        defaultFieldPath={defaultFieldPath}
      />
      <DebugEditor
        isVisible={selectedView === "preview"}
        meId={meId}
        projectTokens={projectTokens}
        schema={schema}
        schemaCode={getFullSchemaCode(readOnlySchemaCode, editableSchemaCode)}
        implementationContext={implementationContext}
        expression={expression}
        commitConfig={commitConfig}
        debugEditorState={editorState.debug}
        setDebugEditorState={(newDebugEditorState) => {
          setEditorState({ ...editorState, debug: newDebugEditorState });
        }}
      />
      <AnalyticsEditor
        meId={meId}
        canEdit={canContribute}
        isVisible={selectedView === "analytics"}
        projectId={project.id}
        views={project.analyticsViews}
        schema={schema}
        implementationContext={implementationContext}
      />
      <LogsEditor
        isVisible={selectedView === "logs"}
        projectId={project.id}
        commitId={commitId}
        logsEditorState={editorState.logs}
        setLogsEditorState={(newLogsEditorState) => {
          setEditorState({
            ...editorState,
            logs: newLogsEditorState,
          });
        }}
      />
      <SettingsEditor
        isVisible={selectedView === "settings"}
        isPersonal={project.business.type === BusinessType.Personal}
        projectId={project.id}
        projectName={project.name}
        projectTokens={projectTokens}
        canEdit={canEdit}
      />
      <BranchesEditor
        meId={meId}
        isVisible={selectedView === "branches"}
        projectId={project.id}
        canEdit={canEdit}
        canContribute={canContribute}
        branches={project.branches}
        pullRequests={project.pullRequests}
      />
    </>
  );
}
