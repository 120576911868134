import { Expression, SplitMap, Schema } from "@hypertune/sdk/src/shared";
import { queryObjectTypeName } from "../constants";
import getComparisonBValueTypeConstraint from "./constraint/getComparisonBValueTypeConstraint";
import getConstraintFromValueType from "./constraint/getConstraintFromValueType";
import getExpressionErrorMessage from "./getExpressionErrorMessage";
import getNewVariables from "./getNewVariables";
import { ValueTypeConstraint, VariableMap } from "./types";
import isValueTypeValid from "./isValueTypeValid";

// TODO: Refactor using fold with pass down?
// Expensive
// eslint-disable-next-line max-params
export default function getExpressionRecursiveErrorMessages(
  schema: Schema,
  splits: SplitMap,
  rootVariables: VariableMap,
  rootValueTypeConstraint: ValueTypeConstraint,
  rootParentExpression: Expression | null,
  rootExpression: Expression | null
): string[] {
  function inner(
    variables: VariableMap,
    valueTypeConstraint: ValueTypeConstraint,
    parentExpression: Expression | null,
    expression: Expression | null
  ): string[] {
    const messages = new Array<string>();

    if (!expression) {
      return ["Missing expression"];
    }

    const message = getExpressionErrorMessage(
      schema,
      splits,
      variables,
      valueTypeConstraint,
      parentExpression,
      expression
    );
    if (message) {
      messages.push(message);
    }

    switch (expression.type) {
      case "NoOpExpression":
      case "BooleanExpression":
      case "IntExpression":
      case "FloatExpression":
      case "StringExpression":
      case "RegexExpression":
      case "EnumExpression":
      case "VariableExpression":
        return messages;

      case "ObjectExpression":
        {
          if (!schema.objects[expression.objectTypeName]) {
            messages.push(`Unknown object type: ${expression.objectTypeName}`);
          }
          const schemaObjectFields =
            schema.objects[expression.objectTypeName]?.fields || {};

          Object.keys(expression.fields).forEach((fieldName) => {
            const schemaFieldValueType =
              schemaObjectFields[fieldName]?.valueType;

            messages.push(
              ...inner(
                variables,
                schemaFieldValueType
                  ? getConstraintFromValueType(schemaFieldValueType)
                  : { type: "ErrorValueTypeConstraint" },
                expression,
                expression.fields[fieldName]
              )
            );
          });
        }
        break;

      case "GetFieldExpression":
        messages.push(
          ...inner(
            variables,
            { type: "AnyObjectValueTypeConstraint" },
            expression,
            expression.object
          )
        );
        break;

      case "UpdateObjectExpression":
        {
          const childValueTypeConstraint: ValueTypeConstraint =
            isValueTypeValid(schema, expression.valueType)
              ? getConstraintFromValueType(expression.valueType)
              : { type: "ErrorValueTypeConstraint" };

          messages.push(
            ...inner(
              variables,
              childValueTypeConstraint,
              expression,
              expression.object
            )
          );

          const schemaObjectFields =
            schema.objects[expression.valueType.objectTypeName]?.fields || {};

          Object.keys(expression.updates).forEach((fieldName) => {
            const fieldValueType = schemaObjectFields[fieldName]?.valueType;
            messages.push(
              ...inner(
                variables,
                fieldValueType
                  ? getConstraintFromValueType(fieldValueType)
                  : { type: "ErrorValueTypeConstraint" },
                expression,
                expression.updates[fieldName]
              )
            );
          });
        }
        break;

      case "ListExpression":
        {
          const childValueTypeConstraint: ValueTypeConstraint =
            isValueTypeValid(schema, expression.valueType)
              ? getConstraintFromValueType(expression.valueType.itemValueType)
              : { type: "ErrorValueTypeConstraint" };

          expression.items.forEach((item) => {
            messages.push(
              ...inner(variables, childValueTypeConstraint, expression, item)
            );
          });
        }
        break;

      case "SwitchExpression":
        {
          const childValueTypeConstraint: ValueTypeConstraint =
            isValueTypeValid(schema, expression.valueType)
              ? getConstraintFromValueType(expression.valueType)
              : { type: "ErrorValueTypeConstraint" };

          const whenValueTypeConstraint: ValueTypeConstraint =
            expression.control &&
            isValueTypeValid(schema, expression.control.valueType)
              ? getConstraintFromValueType(expression.control.valueType)
              : { type: "ErrorValueTypeConstraint" };

          messages.push(
            ...inner(
              variables,
              { type: "AnyValueTypeConstraint" },
              expression,
              expression.control
            )
          );

          expression.cases.forEach((item) => {
            messages.push(
              ...inner(
                variables,
                whenValueTypeConstraint,
                expression,
                item.when
              ),
              ...inner(
                variables,
                childValueTypeConstraint,
                expression,
                item.then
              )
            );
          });

          messages.push(
            ...inner(
              variables,
              childValueTypeConstraint,
              expression,
              expression.default
            )
          );
        }
        break;

      case "ComparisonExpression":
        {
          const aValueType =
            expression.a && isValueTypeValid(schema, expression.a.valueType)
              ? expression.a.valueType
              : null;

          messages.push(
            ...inner(
              variables,
              { type: "AnyValueTypeConstraint" },
              expression,
              expression.a
            ),
            ...inner(
              variables,
              getComparisonBValueTypeConstraint(
                aValueType,
                expression.operator
              ),
              expression,
              expression.b
            )
          );
        }
        break;

      case "ArithmeticExpression":
        {
          const childValueTypeConstraint: ValueTypeConstraint =
            expression.valueType.type === "IntValueType"
              ? { type: "IntValueTypeConstraint" }
              : { type: "FloatValueTypeConstraint" };

          messages.push(
            ...inner(
              variables,
              childValueTypeConstraint,
              expression,
              expression.a
            ),
            ...inner(
              variables,
              childValueTypeConstraint,
              expression,
              expression.b
            )
          );
        }
        break;

      case "RoundNumberExpression":
      case "StringifyNumberExpression":
        messages.push(
          ...inner(
            variables,
            { type: "FloatValueTypeConstraint" },
            expression,
            expression.number
          )
        );
        break;

      case "StringConcatExpression":
        messages.push(
          ...inner(
            variables,
            {
              type: "ListValueTypeConstraint",
              itemValueTypeConstraint: { type: "StringValueTypeConstraint" },
            },
            expression,
            expression.strings
          )
        );
        break;

      case "GetUrlQueryParameterExpression":
        messages.push(
          ...inner(
            variables,
            { type: "StringValueTypeConstraint" },
            expression,
            expression.url
          ),
          ...inner(
            variables,
            { type: "StringValueTypeConstraint" },
            expression,
            expression.queryParameterName
          )
        );
        break;

      case "EnumSwitchExpression":
        {
          const childValueTypeConstraint: ValueTypeConstraint =
            isValueTypeValid(schema, expression.valueType)
              ? getConstraintFromValueType(expression.valueType)
              : { type: "ErrorValueTypeConstraint" };

          messages.push(
            ...inner(
              variables,
              { type: "AnyEnumValueTypeConstraint" },
              expression,
              expression.control
            )
          );

          Object.keys(expression.cases).forEach((enumValue) => {
            messages.push(
              ...inner(
                variables,
                childValueTypeConstraint,
                expression,
                expression.cases[enumValue]
              )
            );
          });
        }
        break;

      case "SplitExpression":
        {
          const childValueTypeConstraint: ValueTypeConstraint =
            isValueTypeValid(schema, expression.valueType)
              ? getConstraintFromValueType(expression.valueType)
              : { type: "ErrorValueTypeConstraint" };

          messages.push(
            ...inner(
              variables,
              { type: "StringValueTypeConstraint" },
              expression,
              expression.unitId
            )
          );

          const { dimensionMapping } = expression;

          if (dimensionMapping.type === "discrete") {
            Object.keys(dimensionMapping.cases).forEach((armId) => {
              messages.push(
                ...inner(
                  variables,
                  childValueTypeConstraint,
                  expression,
                  dimensionMapping.cases[armId]
                )
              );
            });
          } else {
            messages.push(
              ...inner(
                variables,
                childValueTypeConstraint.type === "ErrorValueTypeConstraint"
                  ? childValueTypeConstraint
                  : {
                      type: "FunctionValueTypeConstraint",
                      parameterValueTypeConstraints: [
                        { type: "FloatValueTypeConstraint" },
                      ],
                      returnValueTypeConstraint: childValueTypeConstraint,
                    },
                expression,
                dimensionMapping.function
              )
            );
          }
          if (
            expression.splitId &&
            splits[expression.splitId]?.eventObjectTypeName
          ) {
            messages.push(
              ...inner(
                variables,
                {
                  type: "ObjectValueTypeConstraint",
                  objectTypeName: splits[expression.splitId]
                    .eventObjectTypeName as string,
                },
                expression,
                expression.eventPayload
              )
            );
          }
          if (!expression.eventObjectTypeName && expression.eventPayload) {
            messages.push(
              "Payload must be empty when no event type is selected."
            );
          }
        }
        break;

      case "LogEventExpression":
        messages.push(
          ...inner(
            variables,
            { type: "StringValueTypeConstraint" },
            expression,
            expression.unitId
          ),
          ...inner(
            variables,
            expression.eventObjectTypeName
              ? {
                  type: "ObjectValueTypeConstraint",
                  objectTypeName: expression.eventObjectTypeName,
                }
              : { type: "ErrorValueTypeConstraint" },
            expression,
            expression.eventPayload
          )
        );
        break;

      case "FunctionExpression":
        {
          const bodyValueTypeConstraint: ValueTypeConstraint = isValueTypeValid(
            schema,
            expression.valueType
          )
            ? getConstraintFromValueType(expression.valueType.returnValueType)
            : { type: "ErrorValueTypeConstraint" };

          messages.push(
            ...inner(
              {
                ...variables,
                ...getNewVariables(
                  expression.parameters,
                  expression.valueType.parameterValueTypes
                ),
              },
              bodyValueTypeConstraint,
              expression,
              expression.body
            )
          );
        }
        break;

      case "ApplicationExpression":
        {
          const functionWithReturnValueTypeConstraint: ValueTypeConstraint =
            isValueTypeValid(schema, expression.valueType)
              ? {
                  type: "FunctionWithReturnValueTypeConstraint",
                  returnValueTypeConstraint: getConstraintFromValueType(
                    expression.valueType
                  ),
                }
              : { type: "ErrorValueTypeConstraint" };

          messages.push(
            ...inner(
              variables,
              functionWithReturnValueTypeConstraint,
              expression,
              expression.function
            )
          );

          if (
            expression.function &&
            expression.function.valueType.type === "FunctionValueType" &&
            expression.arguments.length ===
              expression.function.valueType.parameterValueTypes.length
          ) {
            const { parameterValueTypes } = expression.function.valueType;

            expression.arguments.forEach((argument, index) => {
              const argumentValueTypeConstraint: ValueTypeConstraint =
                isValueTypeValid(schema, parameterValueTypes[index])
                  ? getConstraintFromValueType(parameterValueTypes[index])
                  : { type: "ErrorValueTypeConstraint" };

              messages.push(
                ...inner(
                  variables,
                  argumentValueTypeConstraint,
                  expression,
                  argument
                )
              );
            });
          }
        }
        break;

      default: {
        const neverExpression: never = expression;
        throw new Error(
          `Expression with unexpected type: ${JSON.stringify(neverExpression)}`
        );
      }
    }

    return messages;
  }
  return inner(
    rootVariables,
    rootValueTypeConstraint,
    rootParentExpression,
    rootExpression
  );
}

// eslint-disable-next-line max-params
export function getLogicErrorMessage(
  schema: Schema,
  expression: Expression | null,
  splits: SplitMap
): string | null {
  const errorMessages = getExpressionRecursiveErrorMessages(
    schema,
    splits,
    {},
    {
      type: "ObjectValueTypeConstraint",
      objectTypeName: queryObjectTypeName,
    },
    null,
    expression
  );
  if (errorMessages.length === 0) {
    return null;
  }

  console.debug(`Logic errors: ${errorMessages.join("\n")}`);

  return `${errorMessages} logic error${errorMessages.length === 1 ? "" : "s"}.`;
}
