import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { ProjectView, getProjectUrl } from "./projectHooks";

const viewSearchParamKey = "view";

export default function ProjectRedirect(): React.ReactElement | null {
  const [searchParams] = useSearchParams();
  const { selectedProjectId, selectedBranchName, selectedCommitId } =
    useParams();

  return (
    <Navigate
      to={getProjectUrl({
        searchParams,
        projectId: selectedProjectId!,
        selectedBranchName,
        selectedCommitId,
        selectedView:
          (searchParams.get(viewSearchParamKey) as ProjectView) ?? "logic",
      })}
      replace
    />
  );
}
