import React from "react";
import { FunnelStep } from "@hypertune/shared-internal";
import { Plus } from "@phosphor-icons/react";

import { defaultStepHeight } from "../../../lib/constants";
import { CommitContext } from "../../../lib/types";
import Button from "../../../components/buttons/Button";
import AddOrEditStepModal from "./AddOrEditStepModal";

const width = 75;

export default function StepSeparator({
  canEdit,
  commitContext,
  steps,
  setSteps,
  index,
  isSingleSeparator,
  focusModeEnabled,
}: {
  canEdit: boolean;
  commitContext: CommitContext;
  steps: FunnelStep[];
  setSteps: (newSteps: FunnelStep[]) => void;
  index: number;
  isSingleSeparator?: boolean;
  focusModeEnabled: boolean;
}): React.ReactElement | null {
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  if (focusModeEnabled) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...(isSingleSeparator
          ? {}
          : {
              minWidth: width,
              maxWidth: width,
            }),
      }}
    >
      {isSingleSeparator ? null : (
        <div style={{ minHeight: defaultStepHeight }} />
      )}
      {canEdit && (
        <div className="bg-white">
          <Button
            intent="neutral"
            weight="outlined"
            size="large"
            icon={<Plus weight="regular" />}
            text={isSingleSeparator ? " Add funnel step" : ""}
            onClick={() => {
              setIsModalVisible(true);
            }}
          />
        </div>
      )}

      {isModalVisible ? (
        <AddOrEditStepModal
          commitContext={commitContext}
          steps={steps}
          setSteps={setSteps}
          index={index}
          onClose={() => setIsModalVisible(false)}
          insert
        />
      ) : null}
    </div>
  );
}
