import { ReactElement, useState } from "react";
import { GitPullRequest, Plus } from "@phosphor-icons/react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import toInt from "@hypertune/shared-internal/src/toInt";
import { ProjectQuery, PullRequestStatus } from "../../../generated/graphql";
import { leftArrowSymbol, whiteHex } from "../../../lib/constants";
import Label from "../../../components/Label";
import { CenteredContainerHeader } from "../../../components/container/CenteredContainer";
import Button from "../../../components/buttons/Button";
import CardGroup from "../../../components/CardGroup";
import NewPullRequestModal from "./NewPullRequestModal";
import SearchInput from "../../../components/SearchInput";
import matchesSearch from "../../../lib/generic/matchesSearch";
import TopBarDropdown from "../../../components/TopBarDropdown";
import { StatusFilter, statusFilterOptions } from "./PullRequestEditor";
import PullRequestStatusTag from "./PullRequestStatusTag";
import Card from "../../../components/Card";
import EmptyStateContainer from "../../../components/EmptyStateContainer";
import { useHypertune } from "../../../generated/hypertune.react";
import useSearchParamsState from "../../../app/useSearchParamsState";

const searchTextQueryParamKey = "prs_search";
const statusFilterParamKey = "prs_status_filter";

export default function PullRequestList({
  projectId,
  canCreate,
  branches,
  pullRequests,
  setSelectedPRId,
  showUpgradeMessage,
}: {
  projectId: string;
  canCreate: boolean;
  branches: ProjectQuery["project"]["branches"];
  pullRequests: ProjectQuery["project"]["pullRequests"];
  setSelectedPRId: (selectedPRId: number) => void;
  showUpgradeMessage: boolean;
}): React.ReactElement | null {
  const navigate = useNavigate();
  const content = useHypertune().content();
  const emptyState = showUpgradeMessage
    ? content.plans().pullRequestsUpgradeEmptyStateMessage().get()
    : content.pullRequests().emptyState().get();

  const [statusFilter, setStatusFilter] = useSearchParamsState<StatusFilter>(
    statusFilterParamKey,
    "Open"
  );
  const [searchText, setSearchText] = useSearchParamsState(
    searchTextQueryParamKey,
    ""
  );
  const [showCreatePRModal, setShowCreatePRModal] = useState(false);

  const filteredAndSortedPRs = pullRequests
    .filter(
      (pullRequest) =>
        (statusFilter === "All" ||
          (statusFilter === "Open" &&
            (pullRequest.status === PullRequestStatus.Open ||
              pullRequest.status === PullRequestStatus.Approved)) ||
          (statusFilter === "Closed" &&
            (pullRequest.status === PullRequestStatus.Closed ||
              pullRequest.status === PullRequestStatus.Merged))) &&
        matchesSearch(searchText, [
          pullRequest.intoBranch.name,
          pullRequest.fromBranch.name,
          pullRequest.author.displayName,
        ])
    )
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt));

  return (
    <>
      <CenteredContainerHeader title="Pull requests" className="h-[30px]">
        {!showUpgradeMessage && (
          <div className="flex flex-row gap-2">
            <TopBarDropdown<StatusFilter>
              value={
                statusFilterOptions.find(
                  (option) => option.value === statusFilter
                ) || null
              }
              placeholder=""
              options={{
                type: "options",
                options: statusFilterOptions,
              }}
              onChange={(newOption) => {
                if (newOption) {
                  setStatusFilter(newOption.value);
                }
              }}
              dropdownStyle={{
                hideSearch: true,
                caret: "down",
                buttonClassName:
                  "py-[8.5px] px-[7px] leading-none bg-white border",
                panelClassName: "-mt-4 data-top:mt-4 data-top:-mb-4",
                buttonPrefix: (
                  <Label type="title3" className="text-tx-muted">
                    Status:{" "}
                  </Label>
                ),
              }}
            />
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              style={{ backgroundColor: whiteHex }}
            />
            {canCreate && (
              <Button
                icon={<Plus color={whiteHex} weight="bold" />}
                text="New pull request"
                intent="primary"
                weight="filled"
                onClick={() => setShowCreatePRModal(true)}
              />
            )}
          </div>
        )}
      </CenteredContainerHeader>
      {filteredAndSortedPRs.length === 0 && (
        <Card layout="none">
          <EmptyStateContainer
            icon={<GitPullRequest />}
            content={emptyState}
            buttonHideIcon={showUpgradeMessage}
            buttonOnClick={
              showUpgradeMessage ? () => navigate("/plans") : undefined
            }
          />
        </Card>
      )}
      <CardGroup
        layout="list"
        cardLayout="horizontal-with-icon"
        cards={filteredAndSortedPRs.map((pullRequest) => ({
          key: pullRequest.id,
          onClick: () => setSelectedPRId(toInt(pullRequest.id)),
          children: (
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center gap-2">
                <GitPullRequest />
                <Label type="title3">
                  {pullRequest.intoBranch.name} {leftArrowSymbol}{" "}
                  {pullRequest.fromBranch.name}
                </Label>
                <PullRequestStatusTag status={pullRequest.status} />
              </div>
              <div className="ml-6 flex flex-row items-center gap-2">
                <Label type="title4" className="text-tx-muted">
                  {`Opened ${DateTime.fromISO(pullRequest.createdAt).toRelative()} by ${pullRequest.author.displayName}`}
                </Label>
              </div>
            </div>
          ),
        }))}
      />
      {showCreatePRModal && (
        <NewPullRequestModal
          projectId={projectId}
          branches={branches}
          pullRequests={pullRequests}
          onClose={(newPullRequestId) => {
            if (newPullRequestId) {
              setSelectedPRId(newPullRequestId);
            }
            setShowCreatePRModal(false);
          }}
        />
      )}
    </>
  );
}

PullRequestList.LoadingSkeleton =
  function LoadingSkeleton(): ReactElement | null {
    return (
      <>
        <CenteredContainerHeader title="Pull requests" />
        <CardGroup
          layout="list"
          cardLayout="horizontal-with-icon"
          loadingCount={2}
          cards={[]}
        />
      </>
    );
  };
