import React from "react";

export default function HeaderSteps({
  selectedStep,
  setSelectedStep,
  steps,
}: {
  selectedStep: number;
  setSelectedStep: (step: number) => void;
  steps: {
    text: string;
    isCompleted: boolean;
  }[];
}): React.ReactElement {
  const firstNotCompletedStepIndex = steps.findIndex(
    (step) => !step.isCompleted
  );
  const lastCompletedStepNumber =
    firstNotCompletedStepIndex === -1
      ? steps.length // All steps are completed
      : firstNotCompletedStepIndex;

  return (
    <div className="mr-20 flex flex-row gap-2 font-semibold text-tx-muted">
      {steps.map((step, index) => {
        const stepNumber = index + 1;
        const isAvailable =
          index === 0 || stepNumber - 1 <= lastCompletedStepNumber;

        return (
          <HeaderStep
            number={stepNumber}
            text={step.text}
            isCompleted={isAvailable && step.isCompleted}
            isAvailable={isAvailable}
            isSelected={selectedStep === stepNumber}
            onClick={() => setSelectedStep(stepNumber)}
          />
        );
      })}
    </div>
  );
}

function HeaderStep({
  number,
  text,
  isCompleted,
  isAvailable,
  isSelected,
  onClick,
}: {
  number: number;
  text: string;
  isCompleted: boolean;
  isAvailable: boolean;
  isSelected: boolean;
  onClick: () => void;
}): React.ReactElement {
  return (
    <div
      className={`flex flex-row items-center gap-2 rounded-lg py-3 pl-3 pr-4
      ${isSelected || isCompleted ? " text-base-blue" : ""}
      ${isSelected ? "bg-bg-primary" : ""} 
      ${
        !isSelected && isAvailable
          ? isCompleted
            ? "cursor-pointer hover:bg-bg-primary"
            : "cursor-pointer hover:bg-bg-hover"
          : ""
      }
      `}
      onClick={isAvailable ? onClick : undefined}
    >
      <div
        className={`flex h-6 w-6 flex-col justify-center rounded-full border text-center text-md ${
          isSelected || isCompleted
            ? "border-base-blue bg-base-blue text-white"
            : ""
        }`}
      >
        {number}
      </div>
      {text}
    </div>
  );
}
