import { useState } from "react";
import { Plus } from "@phosphor-icons/react";
import Modal from "../../../components/Modal";
import {
  BusinessesDocument,
  useBusinessesQuery,
  useCloneProjectMutation,
} from "../../../generated/graphql";
import TopBarDropdown, {
  LabeledOption,
} from "../../../components/TopBarDropdown";
import { lighterGreyHex } from "../../../lib/constants";
import BusinessIcon from "../BusinessIcon";
import NewBusinessModal from "../team/NewBusinessModal";

export default function CloneProjectModal({
  projectId,
  onClose,
}: {
  projectId: string;
  onClose: () => void;
}): React.ReactElement | null {
  const { data } = useBusinessesQuery({});

  const [newBusinessId, setNewBusinessId] = useState<string | null>(
    data?.primaryBusiness?.id || null
  );
  const [isNewTeamModalVisible, setIsNewTeamModalVisible] = useState(false);

  const [cloneProjectQuery, { loading }] = useCloneProjectMutation({
    refetchQueries: [BusinessesDocument],
    awaitRefetchQueries: true,
  });
  async function cloneProject(): Promise<void> {
    if (!newBusinessId) {
      return;
    }
    try {
      await cloneProjectQuery({
        variables: {
          input: {
            sourceProjectId: projectId,
            businessId: newBusinessId,
          },
        },
      });
      onClose();
    } catch (error) {
      console.error("cloneProject error:", error);
    }
  }

  const newTeamValue = "new";
  const options: LabeledOption<string>[] = (
    data
      ? data.businesses.map<LabeledOption<string>>((business) => {
          return {
            label: business.name,
            value: business.id,
            icon: (
              <BusinessIcon
                businessType={business.type}
                isSelected={false}
                personalImageURL={data.me.imageUrl}
              />
            ),
            selectedIcon: (
              <BusinessIcon
                businessType={business.type}
                isSelected
                personalImageURL={data.me.imageUrl}
              />
            ),
          };
        })
      : []
  ).concat([
    {
      label: "New team",
      value: newTeamValue,
      icon: <Plus color={lighterGreyHex} weight="bold" />,
    },
  ]);
  const selectedOption =
    options.find((option) => option.value === newBusinessId) || null;

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Modal
        title="Clone project"
        onSave={cloneProject}
        onClose={onClose}
        saveDisabled={newBusinessId === null}
        saveLoading={loading}
        saveText="Clone"
        saveIntent="neutral"
      >
        <p className="mb-2 w-[374px]">
          Create a copy of this project in this or another Hypertune team you
          are a member of.
        </p>
        <TopBarDropdown<string>
          value={selectedOption}
          placeholder="Select a team"
          options={{
            type: "options",
            options,
          }}
          onChange={(option) => {
            if (!option) {
              return;
            }
            if (option.value === newTeamValue) {
              setIsNewTeamModalVisible(true);
              return;
            }
            setNewBusinessId(option.value);
          }}
          dropdownStyle={{
            minWidth: 374,
            sectionMaxHeight: 151,
            buttonClassName: "border",
          }}
        />
      </Modal>
      {isNewTeamModalVisible ? (
        <NewBusinessModal
          skipSwitch
          onClose={(newTeamId) => {
            if (newTeamId) {
              setNewBusinessId(newTeamId);
            }
            setIsNewTeamModalVisible(false);
          }}
        />
      ) : null}
    </div>
  );
}
