import {
  fieldPathSeparator,
  ObjectValueType,
  ValueType,
  Schema,
} from "@hypertune/sdk/src/shared";

export default function getFieldPathToValueType(
  schema: Schema,
  objectValueType: ObjectValueType,
  visitedObjectTypeNames: { [objectTypeName: string]: boolean }
): { [fieldPath: string]: ValueType } {
  if (visitedObjectTypeNames[objectValueType.objectTypeName]) {
    return {};
  }

  const schemaObjectFields =
    schema.objects[objectValueType.objectTypeName]?.fields;
  if (!schemaObjectFields) {
    return {};
  }

  const fieldPathToValueType: { [fieldPath: string]: ValueType } = {};

  Object.keys(schemaObjectFields).forEach((fieldName) => {
    const fieldValueType = schemaObjectFields[fieldName].valueType;
    fieldPathToValueType[fieldName] = fieldValueType;

    if (fieldValueType.type === "ObjectValueType") {
      const childFieldPathToValueType = getFieldPathToValueType(
        schema,
        fieldValueType,
        { ...visitedObjectTypeNames, [objectValueType.objectTypeName]: true }
      );
      Object.keys(childFieldPathToValueType).forEach((childFieldPath) => {
        const childFieldValueType = childFieldPathToValueType[childFieldPath];
        fieldPathToValueType[
          `${fieldName}${fieldPathSeparator}${childFieldPath}`
        ] = childFieldValueType;
      });
    }
  });

  return fieldPathToValueType;
}
