import {
  Faro,
  LogLevel,
  getWebInstrumentations,
  initializeFaro,
} from "@grafana/faro-web-sdk";
import { appVersion } from "./constants";
import env from "../environment";

let faro: Faro | null = null;

export default function getFaro(): Faro | null {
  if (!faro && env.NAME === "production") {
    faro = initializeFaro({
      url: "https://faro-collector-prod-us-east-0.grafana.net/collect/3e6ae125fee4168af6790d5a1cc66431",
      app: {
        name: "Hypertune",
        version: appVersion,
        environment: env.NAME,
      },

      instrumentations: [
        // Mandatory, overwriting the instrumentations array
        // would cause the default instrumentations to be omitted
        ...getWebInstrumentations({
          captureConsoleDisabledLevels: [LogLevel.DEBUG],
        }),
      ],
    });
  }
  return faro;
}
