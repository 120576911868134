import { Dimension, Split } from "@hypertune/sdk/src/shared";
import getArmAllocationsSum from "./getArmAllocationsSum";

export default function isDefaultArmNeeded(
  split: Split | null,
  dimension: Dimension | null
): boolean {
  return (
    !!split &&
    split.type === "test" &&
    !!dimension &&
    dimension.type === "discrete" &&
    getArmAllocationsSum(dimension) < 1
  );
}
