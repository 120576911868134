import {
  mapExpression,
  ApplicationExpression,
  FunctionExpression,
} from "@hypertune/sdk/src/shared";
import copyExpression from "./copyExpression";
import getApplicationFunctionExpression from "./getApplicationFunctionExpression";

// Expensive
export default function dropArgument(
  expression: ApplicationExpression,
  index: number
): ApplicationExpression | null {
  const argument = expression.arguments[index];
  const functionExpression = getApplicationFunctionExpression(expression);
  if (!argument || !functionExpression) {
    return null;
  }

  const parameter = functionExpression.parameters[index];
  const { body } = functionExpression;
  if (!parameter || !body) {
    return null;
  }

  const newBody = mapExpression((expr) => {
    if (
      expr &&
      expr.type === "VariableExpression" &&
      expr.variableId === parameter.id
    ) {
      return copyExpression(argument);
    }

    return expr;
  }, body);

  const newFunctionExpression: FunctionExpression = {
    ...functionExpression,
    valueType: {
      ...functionExpression.valueType,
      parameterValueTypes: [
        ...functionExpression.valueType.parameterValueTypes.slice(0, index),
        ...functionExpression.valueType.parameterValueTypes.slice(index + 1),
      ],
    },
    parameters: [
      ...functionExpression.parameters.slice(0, index),
      ...functionExpression.parameters.slice(index + 1),
    ],
    body: newBody,
  };

  const newExpression: ApplicationExpression = {
    ...expression,
    function: newFunctionExpression,
    arguments: [
      ...expression.arguments.slice(0, index),
      ...expression.arguments.slice(index + 1),
    ],
  };

  return newExpression;
}
