import {
  stableStringify,
  SplitAssignmentWithNullableEntries,
} from "@hypertune/sdk/src/shared";
import { DbFeaturePathValues } from "@hypertune/shared-internal";

export default function getFunnelSegmentDataKey(
  featurePathValuesList: DbFeaturePathValues[],
  assignmentList: SplitAssignmentWithNullableEntries[]
): string {
  return stableStringify({ featurePathValuesList, assignmentList });
}
