import { Split, Schema, SplitMap } from "@hypertune/sdk/src/shared";
import getDimensionErrorMessage from "./getDimensionErrorMessage";

export default function getSplitErrorMessage(
  schema: Schema,
  split: Split
): string | null {
  const typeName = split.type === "ml" ? "ML loop" : "test";
  if (!split.name.trim()) {
    return `The ${typeName} must have a name.`;
  }

  if (
    split.type === "ml" &&
    (!split.rewardEvents ||
      split.rewardEvents.length === 0 ||
      split.rewardEvents.some(
        (rewardEvent) => !rewardEvent.eventObjectTypeName
      ))
  ) {
    return `The ${typeName} must have a goal event type.`;
  }
  if (
    split.type === "ml" &&
    split.rewardEvents.some(
      (rewardEvent) =>
        !rewardEvent.unitIdPayloadPath ||
        rewardEvent.unitIdPayloadPath.length === 0
    )
  ) {
    return `The ${typeName} must have a goal event unit ID path.`;
  }

  const dimensions = Object.values(split.dimensions);

  if (dimensions.length === 0) {
    return `The ${typeName} must have at least one dimension.`;
  }

  if (
    new Set(dimensions.map((dimension) => dimension.index)).size !==
    dimensions.length
  ) {
    return "Two dimensions have the same index.";
  }

  if (
    new Set(dimensions.map((dimension) => dimension.name)).size !==
    dimensions.length
  ) {
    return "Two dimensions have the same name.";
  }

  for (let i = 0; i < dimensions.length; i += 1) {
    const dimension = dimensions[i];
    const label = `Dimension ${dimension.name ? `"${dimension.name}"` : i + 1}`;

    if (split.type === "test" && dimension.type !== "discrete") {
      return `All dimensions in a test must be discrete but ${label} isn't.`;
    }

    const dimensionErrorMessage = getDimensionErrorMessage(dimension);
    if (dimensionErrorMessage) {
      return dimensions.length > 1
        ? `${label} Error: ${dimensionErrorMessage}`
        : dimensionErrorMessage;
    }
  }

  return null;
}

export function getSplitsErrorMessage(
  schema: Schema,
  splits: SplitMap
): string | null {
  for (const split of Object.values(splits)) {
    const splitError = getSplitErrorMessage(schema, split);
    if (splitError) {
      return splitError;
    }
  }
  return null;
}

export function getSplitWithErrorId(
  schema: Schema,
  splits: SplitMap
): string | null {
  for (const split of Object.values(splits)) {
    const splitError = getSplitErrorMessage(schema, split);
    if (splitError) {
      return split.id;
    }
  }
  return null;
}
