import { ProjectTokenMap, ProjectTokenScope } from "@hypertune/shared-internal";

export function getQueryToken(tokenMap: ProjectTokenMap): string | null {
  const queryToken = getToken(tokenMap, (scopes) => scopes.includes("query"));
  if (queryToken) {
    // Return query token if it exists.
    return queryToken;
  }
  // Default to all token.
  return getAllToken(tokenMap);
}

export function getAllToken(tokenMap: ProjectTokenMap): string | null {
  return getToken(tokenMap, (scopes) => scopes.includes("all"));
}

export function getToken(
  tokenMap: ProjectTokenMap,
  hasScopes: (scopes: ProjectTokenScope[]) => boolean
): string | null {
  return (
    Object.entries(tokenMap)
      .map(([token, { scopes }]) => (hasScopes(scopes) ? token : null))
      .find(Boolean) ?? null
  );
}
