import { Expression } from "@hypertune/sdk/src/shared";

export default function isIfElseExpression(expression: Expression): boolean {
  return (
    expression.type === "SwitchExpression" &&
    !!expression.control &&
    expression.control.type === "BooleanExpression" &&
    expression.control.value
  );
}
