import env from "../../environment";
import {
  darkGreyHex,
  intentPrimaryHex,
  intentSuccessHex,
} from "../../lib/constants";

export const logoColor =
  env.NAME === "development"
    ? intentSuccessHex
    : env.NAME === "staging"
      ? intentPrimaryHex
      : darkGreyHex;

export default function Logo({
  size = 16,
  style,
  onClick,
}: {
  size?: number;
  style?: React.CSSProperties;
  onClick?: () => void;
}): React.ReactElement {
  return (
    <svg
      width={size}
      height={size}
      onClick={onClick}
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="6" fill={logoColor} />
      <rect
        x="4.58057"
        y="15.4192"
        width="15.7583"
        height="0.9375"
        rx="0.46875"
        transform="rotate(-45 4.58057 15.4192)"
        fill="white"
      />
      <rect
        x="11.0205"
        y="15.5327"
        width="6.71895"
        height="0.9375"
        rx="0.46875"
        transform="rotate(-45 11.0205 15.5327)"
        fill="white"
      />
      <rect
        x="4.375"
        y="6.875"
        width="2.5"
        height="2.5"
        rx="1.25"
        transform="rotate(-90 4.375 6.875)"
        fill="white"
      />
    </svg>
  );
}
