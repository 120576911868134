import {
  Expression,
  Schema,
  fieldPathSeparator,
} from "@hypertune/sdk/src/shared";
import { rootFieldName } from "@hypertune/shared-internal";
import { TopLevelEnum } from "../types";

export default function getDefaultFieldPathAndTopLevelEnumTypeName(
  schema: Schema,
  expression: Expression,
  selectedFieldPath?: string
): {
  defaultFieldPath: string[];
  topLevelEnum: TopLevelEnum;
} {
  if (
    expression.type === "ObjectExpression" &&
    expression.fields[rootFieldName] &&
    expression.fields[rootFieldName].type === "FunctionExpression" &&
    expression.fields[rootFieldName].body?.type === "EnumSwitchExpression" &&
    expression.fields[rootFieldName].body.control?.valueType.type ===
      "EnumValueType"
  ) {
    const enumValues = Object.keys(
      schema.enums[
        expression.fields[rootFieldName].body.control.valueType.enumTypeName
      ].values || {}
    );
    if (enumValues.length > 0) {
      const selectedFieldPathSteps =
        selectedFieldPath?.split(fieldPathSeparator) ?? [];
      return {
        defaultFieldPath: [
          rootFieldName,
          // Set default path to the first part of the selected field path when set.
          // This ensures we don't accidentally switch environment when switching
          // the default path on deletes.
          selectedFieldPathSteps.length >= 2 &&
          !selectedFieldPathSteps[1].includes("-") // This deals with view all case.
            ? selectedFieldPathSteps[1]
            : enumValues[0],
        ],
        topLevelEnum: {
          typeName:
            expression.fields[rootFieldName].body.control.valueType
              .enumTypeName,
        },
      };
    }
  }
  return {
    defaultFieldPath: [rootFieldName],
    topLevelEnum: null,
  };
}
