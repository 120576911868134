import { ValueType } from "@hypertune/sdk/src/shared";
import filterNulls from "../../filterNulls";

import { InnerValueTypeConstraint } from "../types";

export default function getValueTypeFromConstraint(
  valueTypeConstraint: InnerValueTypeConstraint
): ValueType | null {
  switch (valueTypeConstraint.type) {
    case "VoidValueTypeConstraint":
      return { type: "VoidValueType" };

    case "BooleanValueTypeConstraint":
      return { type: "BooleanValueType" };

    case "IntValueTypeConstraint":
      return { type: "IntValueType" };

    case "FloatValueTypeConstraint":
      return { type: "FloatValueType" };

    case "StringValueTypeConstraint":
      return { type: "StringValueType" };

    case "RegexValueTypeConstraint":
      return { type: "RegexValueType" };

    case "EnumValueTypeConstraint":
      return {
        type: "EnumValueType",
        enumTypeName: valueTypeConstraint.enumTypeName,
      };

    case "AnyEnumValueTypeConstraint":
      return null;

    case "ObjectValueTypeConstraint":
      return {
        type: "ObjectValueType",
        objectTypeName: valueTypeConstraint.objectTypeName,
      };

    case "AnyObjectValueTypeConstraint":
      return null;

    case "UnionValueTypeConstraint":
      return {
        type: "UnionValueType",
        unionTypeName: valueTypeConstraint.unionTypeName,
      };

    case "ListValueTypeConstraint": {
      const listItemValueType = getValueTypeFromConstraint(
        valueTypeConstraint.itemValueTypeConstraint
      );
      if (!listItemValueType) {
        return null;
      }
      return {
        type: "ListValueType",
        itemValueType: listItemValueType,
      };
    }

    case "FunctionValueTypeConstraint": {
      const parameterValueTypes =
        valueTypeConstraint.parameterValueTypeConstraints.map((constraint) =>
          getValueTypeFromConstraint(constraint)
        );
      const filteredParameterValueTypes = filterNulls(parameterValueTypes);
      const returnValueType = getValueTypeFromConstraint(
        valueTypeConstraint.returnValueTypeConstraint
      );
      if (
        parameterValueTypes.length !== filteredParameterValueTypes.length ||
        !returnValueType
      ) {
        return null;
      }
      return {
        type: "FunctionValueType",
        parameterValueTypes: filteredParameterValueTypes,
        returnValueType,
      };
    }

    case "FunctionWithReturnValueTypeConstraint":
    case "AnyNonFunctionValueTypeConstraint":
    case "AnyValueTypeConstraint":
      return null;

    default: {
      const neverValueTypeConstraint: never = valueTypeConstraint;
      throw new Error(
        `Unexpected inner value type constraint: ${JSON.stringify(
          neverValueTypeConstraint
        )}`
      );
    }
  }
}
