import { useState } from "react";
import {
  addFieldToObject,
  getSchemaCodeFromSchema,
  rootObjectTypeNameFromSchema,
} from "@hypertune/shared-internal";
import fixAndSimplify from "@hypertune/shared-internal/src/expression/fixAndSimplify";
import { getLogicErrorMessage } from "@hypertune/shared-internal/src/expression/getExpressionRecursiveErrorMessages";
import {
  CreateCommitInput,
  ProjectBranchQuery,
  ProjectQuery,
  useCreateCommitMutation,
} from "../../../generated/graphql";
import TextInput from "../../../components/input/TextInput";
import { useAppDispatch } from "../../../app/hooks";
import getErrorMessage from "../../../lib/query/getErrorMessage";
import { DraftCommit, setDraftCommit } from "../projectSlice";
import ErrorMessage from "../../../components/ErrorMessage";
import Container from "./Container";
import Toggle from "../../../components/Toggle";
import TextArea from "../../../components/input/TextArea";
import SchemaNameError, {
  objectFieldNameError,
} from "../schema/typeEditor/SchemaNameError";
import { newCommitRefetchQueries } from "../../../lib/query/refetchQueries";

export default function Step1CreateFlag({
  commitData,
  project,
  branch,
  flagCreated,
  flagName,
  flagSchemaName,
  setFlagName,
  flagValue,
  setFlagValue,
  flagDescription,
  setFlagDescription,
  onNext,
}: {
  commitData: DraftCommit;
  project: ProjectQuery["project"];
  branch: ProjectBranchQuery["projectBranch"];
  flagCreated: boolean;
  flagName: string;
  flagSchemaName: string;
  setFlagName: (newName: string) => void;
  flagValue: boolean;
  setFlagValue: (newValue: boolean) => void;
  flagDescription: string;
  setFlagDescription: (newDescription: string) => void;
  onNext: () => void;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [createCommit, { loading }] = useCreateCommitMutation({
    refetchQueries: newCommitRefetchQueries,
    awaitRefetchQueries: true,
  });

  const rootObjectTypeName = rootObjectTypeNameFromSchema(commitData.schema);
  const flagNameError = objectFieldNameError(
    commitData.schema,
    "flag",
    rootObjectTypeName,
    flagSchemaName
  );

  async function onComplete(): Promise<void> {
    if (flagCreated) {
      onNext();
      return;
    }
    const newSchema = addFieldToObject(
      commitData.schema,
      rootObjectTypeNameFromSchema(commitData.schema),
      flagSchemaName,
      { type: "BooleanValueType" },
      flagDescription || null,
      "first"
    );
    const newSchemaCode = getSchemaCodeFromSchema(newSchema);
    const { newExpression } = fixAndSimplify(
      newSchema,
      commitData.splits,
      commitData.eventTypes,
      { boolean: flagValue },
      commitData.expression
    );
    const logicError = getLogicErrorMessage(
      newSchema,
      newExpression,
      commitData.splits
    );
    if (logicError) {
      setErrorMessage(logicError);
      return;
    }

    const nextCommitVersion = branch.commits.length + 1;
    const message = `v${nextCommitVersion}`;
    const input: CreateCommitInput = {
      projectId: project.id,
      parentId: branch.activeCommit.id,
      schemaCode: newSchemaCode,
      expressionJson: JSON.stringify(newExpression),
      splitsJson: JSON.stringify(commitData.splits),
      eventTypesJson: JSON.stringify(commitData.eventTypes),
      message,
    };

    try {
      await createCommit({ variables: { input } });
      dispatch(
        setDraftCommit({
          ...commitData,
          schema: newSchema,
          schemaCode: newSchemaCode,
          expression: newExpression,
        })
      );
      onNext();
    } catch (error) {
      let newErrorMessage = `Create commit error: ${getErrorMessage(error)}`;
      if (newErrorMessage.toLowerCase().includes("failed to fetch")) {
        newErrorMessage =
          "Encountered an error. Please check your internet connection and try again.";
      }
      setErrorMessage(newErrorMessage);
    }
  }

  return (
    <Container
      onNext={onComplete}
      nextDisabled={!flagCreated && (flagName === "" || flagNameError !== null)}
      nextLoading={loading}
    >
      <div className="flex flex-col items-stretch">
        <TextInput
          value={flagName}
          label="Feature flag name"
          labelVariant="large"
          placeholder="Enter a name for this flag"
          onChange={setFlagName}
          textTransform="capitalize"
          trimOnBlur={false}
          focusOnMount
          readOnly={flagCreated}
          error={
            !loading &&
            !flagCreated &&
            flagNameError && (
              <SchemaNameError schemaCheckOrError={flagNameError} />
            )
          }
        />
        {flagSchemaName && (
          <TextInput
            value={flagSchemaName}
            trimOnBlur={false}
            readOnly
            onChange={() => {
              // Dummy
            }}
            style={{ marginTop: 10 }}
          />
        )}
        <div className="mb-4 mt-6 text-md font-semibold text-base-dark-grey">
          Description
        </div>
        <TextArea
          placeholder="Optionally enter a description for this flag"
          value={flagDescription}
          setValue={setFlagDescription}
          readOnly={flagCreated}
          minRows={2}
          className={
            !flagCreated
              ? "focus-within:border-base-blue focus-within:shadow-inputs hover:border-base-blue"
              : undefined
          }
        />
        <div className="mb-4 mt-6 text-md font-semibold text-base-dark-grey">
          Default state
        </div>
        <div className="flex flex-row justify-between rounded-xl border border-base-grey-1-medium px-6 py-[14px]">
          <p className="w-5 font-[520] text-base-dark-grey">
            {flagValue ? "On" : "Off"}
          </p>
          <Toggle
            size="large"
            disabled={flagCreated}
            value={flagValue}
            setValue={setFlagValue}
          />
        </div>
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    </Container>
  );
}
