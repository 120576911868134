import { useState } from "react";
import { isValidEmail } from "@hypertune/shared-internal";
import { UserPlus } from "@phosphor-icons/react";
import { asError } from "@hypertune/sdk/src/shared";
import { useNavigate } from "react-router-dom";
import {
  BusinessManagementDocument,
  BusinessManagementQuery,
  BusinessesDocument,
  Plan,
  useCreateInvitesMutation,
} from "../../../generated/graphql";
import Modal from "../../../components/Modal";
import Button from "../../../components/buttons/Button";
import { useHypertune } from "../../../generated/hypertune.react";
import EmailListInput from "../../../components/input/EmailListInput";
import getErrorMessage from "../../../lib/query/getErrorMessage";
import ModalWithContent from "../../../components/ModalWithContent";
import UpgradeErrorMessage from "./UpgradeErrorMessage";

export default function InviteButton({
  business,
}: {
  business: NonNullable<BusinessManagementQuery["primaryBusiness"]>;
}): React.ReactElement {
  const navigate = useNavigate();
  const hypertune = useHypertune();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const planLimit = hypertune
    .features()
    .planLimits()
    .hardTeamMemberCount({ fallback: -1 });
  const showUpgradeModal =
    business.business.plan === Plan.Free &&
    planLimit > 0 &&
    business.business.businessUsers.length >= planLimit;

  return (
    <>
      {isModalVisible ? (
        showUpgradeModal ? (
          <ModalWithContent
            content={hypertune.content().plans().teamFullInviteModal().get()}
            onSave={() => {
              navigate("/plans");
              setIsModalVisible(false);
            }}
            saveWeight="filled"
            onClose={() => setIsModalVisible(false)}
          />
        ) : (
          <InviteModal
            businessId={business.id}
            plan={business.business.plan}
            onClose={() => {
              setIsModalVisible(false);
            }}
          />
        )
      ) : null}
      <Button
        onClick={() => {
          setIsModalVisible(true);
        }}
        icon={<UserPlus weight="regular" color="white" />}
        text="Invite"
        intent="primary"
        weight="filled"
        size="large"
      />
    </>
  );
}

function InviteModal({
  businessId,
  plan,
  onClose,
}: {
  businessId: string;
  plan: Plan;
  onClose: () => void;
}): React.ReactElement {
  const content = useHypertune().content();
  const message = content.team().inviteModalMessage({ fallback: "" });

  const [errorMessage, setErrorMessage] = useState("");
  const [emails, _setEmails] = useState<string[]>([""]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function setEmails(newEmails: string[]): void {
    _setEmails(newEmails);
    setIsDirty(true);
  }

  const [createInvites] = useCreateInvitesMutation({
    refetchQueries: [BusinessesDocument, BusinessManagementDocument],
    awaitRefetchQueries: true,
  });

  const isValid = emails.length > 0 && emails.every(isValidEmail);

  function onCreateButtonClick(): void {
    if (isLoading || !isValid) {
      return;
    }
    setErrorMessage("");
    setIsLoading(true);
    createInvites({ variables: { input: { businessId, emails } } })
      .then(() => {
        setIsLoading(false);
        onClose();
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(getErrorMessage(error));
        console.error("createInvites error:", asError(error));
      });
  }

  return (
    <Modal
      title="Invite new members"
      onClose={onClose}
      confirmClose={isDirty}
      saveText="Invite"
      saveLoadingText="Inviting..."
      saveIntent="primary"
      saveWeight="filled"
      saveLoading={isLoading}
      saveDisabled={!isValid}
      onSave={onCreateButtonClick}
      childrenStyle={{
        marginTop: 8,
        marginBottom: 8,
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: 500,
        overflowY: "auto",
      }}
    >
      {message && (
        <p className="mb-4 w-[374px] whitespace-pre-wrap text-base text-tx-muted">
          {message}
        </p>
      )}
      <EmailListInput
        emails={emails}
        setEmails={setEmails}
        onEnter={onCreateButtonClick}
        isLoading={isLoading}
        focusFirstOnMount
      />
      <UpgradeErrorMessage
        errorMessage={errorMessage}
        plan={plan}
        className="max-w-[374px]"
      />
    </Modal>
  );
}
