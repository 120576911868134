import React from "react";
import { Users } from "@phosphor-icons/react";
import { BusinessType } from "../../generated/graphql";
import BorderedIcon from "../../components/BorderedIcon";
import { blueHex, lighterGreyHex } from "../../lib/constants";
import UserPicture from "../../components/UserPicture";

export default function BusinessIcon({
  businessType,
  isSelected,
  personalImageURL,
}: {
  businessType: BusinessType;
  isSelected: boolean;
  personalImageURL?: string;
}): React.ReactElement {
  return businessType === "Personal" ? (
    <UserPicture
      size="small"
      outlineStyle={isSelected ? "default" : "muted"}
      isSelected={isSelected}
      href={personalImageURL}
    />
  ) : (
    <BorderedIcon
      size={20}
      isSelected={isSelected}
      icon={<Users color={isSelected ? blueHex : lighterGreyHex} size={12} />}
    />
  );
}
