import { ValueType } from "@hypertune/sdk/src/shared";
import toCamelCase from "../toCamelCase";
import { getFieldArgumentsObjectTypeNameParts } from "../schema/fieldArgumentsObjectTypeName";
import getNextName from "../getNextName";

export default function getNewVariableName(
  existingNames: { [name: string]: boolean },
  valueType: ValueType
): string {
  return getNextName(existingNames, getBaseName(valueType));
}

function getBaseName(valueType: ValueType): string {
  switch (valueType.type) {
    case "VoidValueType":
      return "voidVar";
    case "BooleanValueType":
      return "booleanVar";
    case "IntValueType":
      return "intVar";
    case "FloatValueType":
      return "floatVar";
    case "StringValueType":
      return "stringVar";
    case "RegexValueType":
      return "regexVar";
    case "EnumValueType":
      return `${toCamelCase(valueType.enumTypeName)}Var`;
    case "ObjectValueType": {
      const fieldArgumentsObjectTypeNameParts =
        getFieldArgumentsObjectTypeNameParts(valueType.objectTypeName);
      return toCamelCase(
        fieldArgumentsObjectTypeNameParts
          ? `${fieldArgumentsObjectTypeNameParts.fieldName}Args`
          : `${valueType.objectTypeName}Var`
      );
    }
    case "UnionValueType":
      return `${toCamelCase(valueType.unionTypeName)}Var`;
    case "ListValueType":
      return "listVar";
    case "FunctionValueType":
      return "functionVar";
    default: {
      const neverParameterValueType: never = valueType;
      throw new Error(
        `Unexpected parameter value type: ${neverParameterValueType}`
      );
    }
  }
}
