import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import { variablePathSuffix } from "../constants";

export function getObjectFieldPath(
  parentPath: string,
  fieldName: string
): string {
  return parentPath
    ? `${parentPath}${fieldPathSeparator}${fieldName}`
    : fieldName;
}

export function getEnumSwitchFieldPath(
  parentPath: string,
  enumValueName: string
): string {
  return parentPath
    ? `${parentPath}${fieldPathSeparator}${enumValueName}`
    : enumValueName;
}

export function getListItemFieldPath(
  parentPath: string,
  index: number
): string {
  return parentPath
    ? `${parentPath}${fieldPathSeparator}${index + 1}`
    : `${index + 1}`;
}

export function getVariableFieldPath(
  parentPath: string,
  parameterName: string
): string {
  return parentPath
    ? `${parentPath}${fieldPathSeparator}${parameterName}${variablePathSuffix}`
    : `${parameterName}${variablePathSuffix}`;
}

export function getFieldNameFromPath(fieldPath: string): string {
  const fieldName = fieldPath.split(fieldPathSeparator).at(-1);
  if (!fieldName) {
    throw new Error(`can't extract fieldName from path: "${fieldPath}"`);
  }
  return fieldName;
}
