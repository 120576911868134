import React from "react";

import { smallFontSize } from "../lib/constants";
import Modal from "./Modal";
import TextInput from "./input/TextInput";
import Button from "./buttons/Button";

export default function TextInputModal({
  title,
  textInputLabel,
  textInputPlaceholder,
  buttonLabel,
  onEnter,
  onClose,
  sanitizeText,
}: {
  title: string;
  textInputLabel?: string;
  textInputPlaceholder: string;
  buttonLabel: string;
  sanitizeText?: (text: string) => string;
  onEnter: (text: string) => void;
  onClose: () => void;
}): React.ReactElement {
  const [text, setText] = React.useState<string>("");

  function onClick(): void {
    const trimmedText = text.trim();
    if (!trimmedText) {
      return;
    }
    onEnter(trimmedText);
  }

  return (
    <Modal title={title} onClose={onClose} confirmClose={!!text}>
      {textInputLabel && (
        <div style={{ marginBottom: 8, fontSize: smallFontSize }}>
          {textInputLabel}
        </div>
      )}
      <TextInput
        style={{ marginBottom: 12 }}
        value={text}
        trimOnBlur
        focusOnMount
        placeholder={textInputPlaceholder}
        readOnly={false}
        onChange={(newValue: string) => {
          setText(sanitizeText ? sanitizeText(newValue) : newValue);
        }}
        onEnter={onClick}
      />
      <Button
        intent="primary"
        weight="minimal"
        text={buttonLabel}
        onClick={onClick}
      />
    </Modal>
  );
}
