// The `| undefined` forces us to check it's safe to use getWebEnvironment
// in environments without a definition for window
declare const window: { location?: { hostname?: string } } | undefined;

/**
 * For a web application (that we expect to be hosted on a Hypertune domain
 * in prod or staging), tells us which environment we are in.
 *
 * @example `When we're on app.hypertune.com, return "prod"`
 * @example `When we're on app.staging.hypertune.com, return "staging"`
 * @example `When we're on localhost:3000, return "local"`
 */
export default function getWebEnvironment(): "local" | "staging" | "prod" {
  return getWebEnvironmentInternal(
    typeof window === "undefined" ? undefined : window?.location?.hostname,
    process.env.NODE_ENV
  );
}

// @VisibleForTesting
export function getWebEnvironmentInternal(
  hostname?: string,
  nodeEnv?: string
): "local" | "staging" | "prod" {
  // SSR
  if (hostname === undefined) {
    return nodeEnv === "development" ? "local" : "prod";
  }

  if (
    hostname.endsWith("prod.hypertune.com") ||
    // Regex matches *.hypertune.com, where * does not include a dot
    /^([^.]+\.)?hypertune\.com$/.test(hostname)
  ) {
    return "prod";
  }
  if (hostname.endsWith("staging.hypertune.com")) {
    return "staging";
  }

  return "local";
}
