import { Schema } from "@hypertune/sdk/src/shared/types";
import { getDefaultQueryCode as getDefaultQueryCodeWithException } from "@hypertune/shared-internal";

export default function getDefaultQueryCode(args: {
  schema: Schema;
  includeDeprecated: boolean;
  includeComments: boolean;
  includeArguments: boolean;
  useSharedFragments: boolean;
}): string | null {
  try {
    return getDefaultQueryCodeWithException(args);
  } catch (error) {
    return `Error generating default query, this usually indicates an issue with your schema: "${error}"`;
  }
}
