export default function ConfigurationContainer({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <div className="flex flex-col items-stretch">
      <p className="mb-2 underline">{title}</p>
      {children}
    </div>
  );
}
