import { ValueType } from "@hypertune/sdk/src/shared/types";
import { rightDoubleArrowSymbol } from "../constants";

export default function valueTypeToString(valueType: ValueType): string {
  switch (valueType.type) {
    case "VoidValueType":
      return "Void";
    case "BooleanValueType":
      return "Boolean";
    case "IntValueType":
      return "Int";
    case "FloatValueType":
      return "Float";
    case "StringValueType":
      return "String";
    case "RegexValueType":
      return "Regex";
    case "EnumValueType":
      return valueType.enumTypeName;
    case "ObjectValueType":
      return valueType.objectTypeName;
    case "UnionValueType":
      return valueType.unionTypeName;
    case "ListValueType":
      return `List[${valueTypeToString(valueType.itemValueType)}]`;
    case "FunctionValueType":
      return `(${valueType.parameterValueTypes
        .map((parameterValueType) => valueTypeToString(parameterValueType))
        .join(", ")}) ${rightDoubleArrowSymbol} ${valueTypeToString(
        valueType.returnValueType
      )}`;
    default: {
      const neverValueType: never = valueType;
      throw new Error(
        `valueTypeToStringInner unexpected value type: ${JSON.stringify(
          neverValueType
        )}`
      );
    }
  }
}
