import { getWebEnvironment } from "@hypertune/shared-internal";
import prod from "./env.prod";
import staging from "./env.staging";
import local from "./env.local";
import { Env } from "./type";

function getEnv(): Env {
  const baseEnv = { local, staging, prod }[getWebEnvironment()];

  if (baseEnv.NAME !== "development") {
    baseEnv.BACKEND_BASE_URL = `https://${window.location.hostname}`;
  }

  return baseEnv;
}

const env: Env = getEnv();

export default env;
