import { useState } from "react";
import { Plus } from "@phosphor-icons/react";
import Modal from "../../../components/Modal";
import {
  BusinessesDocument,
  useBusinessesQuery,
  useUpdateProjectMutation,
} from "../../../generated/graphql";
import TopBarDropdown, {
  LabeledOption,
} from "../../../components/TopBarDropdown";
import { lighterGreyHex } from "../../../lib/constants";
import BusinessIcon from "../BusinessIcon";
import NewBusinessModal from "../team/NewBusinessModal";

export default function TransferProjectModal({
  projectId,
  projectName,
  onClose,
}: {
  projectId: string;
  projectName: string;
  onClose: () => void;
}): React.ReactElement | null {
  const { data } = useBusinessesQuery({});
  const [newBusinessId, setNewBusinessId] = useState<string | null>(null);
  const [isNewTeamModalVisible, setIsNewTeamModalVisible] = useState(false);

  const [updateProject, { loading }] = useUpdateProjectMutation({
    refetchQueries: [BusinessesDocument],
    awaitRefetchQueries: true,
  });
  async function transferProject(): Promise<void> {
    try {
      await updateProject({
        variables: { input: { id: projectId, businessId: newBusinessId } },
      });
      onClose();
    } catch (error) {
      console.error("archiveProject error:", error);
    }
  }

  const newTeamValue = "new";
  const options: LabeledOption<string>[] = (
    data
      ? data.businesses
          .filter((business) => business.id !== data.primaryBusiness?.id)
          .map<LabeledOption<string>>((business) => {
            return {
              label: business.name,
              value: business.id,
              icon: (
                <BusinessIcon
                  businessType={business.type}
                  isSelected={false}
                  personalImageURL={data.me.imageUrl}
                />
              ),
              selectedIcon: (
                <BusinessIcon
                  businessType={business.type}
                  isSelected
                  personalImageURL={data.me.imageUrl}
                />
              ),
            };
          })
      : []
  ).concat([
    {
      label: "New team",
      value: newTeamValue,
      icon: <Plus color={lighterGreyHex} weight="bold" />,
    },
  ]);
  const selectedOption =
    options.find((option) => option.value === newBusinessId) || null;

  if (isNewTeamModalVisible) {
    return (
      <NewBusinessModal
        initialTransferProjects={[{ id: projectId, name: projectName }]}
        onClose={() => {
          setIsNewTeamModalVisible(false);
          onClose();
        }}
      />
    );
  }

  return (
    <Modal
      title="Transfer project"
      onSave={transferProject}
      onClose={onClose}
      saveDisabled={newBusinessId === null}
      saveLoading={loading}
      saveText="Transfer"
      saveIntent="neutral"
    >
      <p className="mb-2 w-[374px]">
        Transfer your project from{" "}
        <b>{data?.primaryBusiness?.business.name} </b>
        to another Hypertune team you are a member of.
      </p>
      <TopBarDropdown<string>
        value={selectedOption}
        placeholder="Select a team"
        options={{
          type: "options",
          options,
        }}
        onChange={(option) => {
          if (!option) {
            return;
          }
          if (option.value === newTeamValue) {
            setIsNewTeamModalVisible(true);
            return;
          }
          setNewBusinessId(option.value);
        }}
        dropdownStyle={{
          minWidth: 374,
          sectionMaxHeight: 151,
          buttonClassName: "border",
        }}
      />
    </Modal>
  );
}
