import { defaultArmKey, SplitExpression } from "@hypertune/sdk/src/shared";
import getExpressionHash from "./getExpressionHash";

export default function getSplitArmNonUniqueExpressionIds(
  expression: SplitExpression
): Set<string> {
  const splitArmNonUniqueExpressionIds: Set<string> = new Set();
  const expressionHashToId: { [key: number]: string } = {};

  const { dimensionMapping } = expression;
  if (dimensionMapping.type === "discrete") {
    Object.entries(dimensionMapping.cases).forEach(([armId, armExpression]) => {
      if (!armExpression || armId === defaultArmKey) {
        return;
      }
      const armExpressionHash = getExpressionHash(armExpression);
      if (armExpressionHash in expressionHashToId) {
        splitArmNonUniqueExpressionIds.add(armExpression.id);
        splitArmNonUniqueExpressionIds.add(
          expressionHashToId[armExpressionHash]
        );
      }

      expressionHashToId[armExpressionHash] = armExpression.id;
    });
  }

  return splitArmNonUniqueExpressionIds;
}
