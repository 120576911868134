import React from "react";

export type LabelType =
  | "title1"
  | "title2"
  | "title3"
  | "title4"
  | "small-body";

export default function Label({
  type,
  children,
  className,
}: {
  type: LabelType;
  children: React.ReactNode;
  className?: string;
}): React.ReactElement {
  switch (type) {
    case "title1": {
      return (
        <p className={`text-lg font-semibold ${className || ""}`}>{children}</p>
      );
    }
    case "title2": {
      return (
        <p className={`text-md font-semibold ${className || ""}`}>{children}</p>
      );
    }
    case "title3": {
      return (
        <p className={`text-base font-semibold ${className || ""}`}>
          {children}
        </p>
      );
    }
    case "title4": {
      return (
        <p className={`text-base font-medium ${className || ""}`}>{children}</p>
      );
    }
    case "small-body": {
      return (
        <p className={`text-sm font-light ${className || ""}`}>{children}</p>
      );
    }
    default: {
      const neverType: never = type;
      throw new Error(`Text with unexpected type ${neverType}`);
    }
  }
}
