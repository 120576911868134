import { iconPink, whiteHex } from "../../lib/constants";

export default function Variable({
  className,
}: {
  className?: string;
}): React.ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="16" height="16" rx="6" fill={iconPink} />
      <path
        d="M11.6663 8.78337H9.88301V7.21671H11.6663C11.8122 7.21671 11.9521 7.15876 12.0553 7.05562C12.1584 6.95247 12.2163 6.81258 12.2163 6.66671C12.2163 6.52084 12.1584 6.38094 12.0553 6.2778C11.9521 6.17465 11.8122 6.11671 11.6663 6.11671H9.88301V4.33337C9.88301 4.1875 9.82506 4.04761 9.72192 3.94447C9.61877 3.84132 9.47888 3.78337 9.33301 3.78337C9.18714 3.78337 9.04725 3.84132 8.9441 3.94447C8.84095 4.04761 8.78301 4.1875 8.78301 4.33337V6.11671H7.21634V4.33337C7.21634 4.18751 7.1584 4.04761 7.05525 3.94447C6.95211 3.84132 6.81221 3.78337 6.66634 3.78337C6.52047 3.78337 6.38058 3.84132 6.27743 3.94447C6.17429 4.04761 6.11634 4.1875 6.11634 4.33337V6.11671H4.33301C4.18714 6.11671 4.04724 6.17465 3.9441 6.2778C3.84095 6.38094 3.78301 6.52084 3.78301 6.66671C3.78301 6.81258 3.84095 6.95247 3.9441 7.05562C4.04724 7.15876 4.18714 7.21671 4.33301 7.21671H6.11634V8.78337H4.33301C4.18714 8.78337 4.04724 8.84132 3.9441 8.94447C3.84095 9.04761 3.78301 9.18751 3.78301 9.33337C3.78301 9.47924 3.84095 9.61914 3.9441 9.72228C4.04724 9.82543 4.18714 9.88338 4.33301 9.88338H6.11634V11.6667C6.11634 11.8126 6.17429 11.9525 6.27743 12.0556C6.38058 12.1588 6.52047 12.2167 6.66634 12.2167C6.81221 12.2167 6.95211 12.1588 7.05525 12.0556C7.1584 11.9525 7.21634 11.8126 7.21634 11.6667V9.88338H8.78301V11.6667C8.78301 11.8126 8.84095 11.9525 8.9441 12.0556C9.04725 12.1588 9.18714 12.2167 9.33301 12.2167C9.47888 12.2167 9.61877 12.1588 9.72192 12.0556C9.82506 11.9525 9.88301 11.8126 9.88301 11.6667V9.88338H11.6663C11.8122 9.88338 11.9521 9.82543 12.0553 9.72228C12.1584 9.61914 12.2163 9.47924 12.2163 9.33337C12.2163 9.18751 12.1584 9.04761 12.0553 8.94447C11.9521 8.84132 11.8122 8.78337 11.6663 8.78337ZM7.21634 8.78337V7.21671H8.78301V8.78337H7.21634Z"
        fill={whiteHex}
        stroke={whiteHex}
        strokeWidth="0.1"
      />
    </svg>
  );
}
