import React from "react";

import Input, { InputProps } from "./Input";

export default function FloatInput({
  initialValue,
  onChange,
  min,
  max,
  ...props
}: Omit<InputProps, "type" | "onChange" | "trimOnBlur" | "value"> & {
  initialValue: number;
  onChange: (newValue: number) => void;
  min?: number;
  max?: number;
}): React.ReactElement {
  const [value, setValue] = React.useState<number>(initialValue);

  return (
    <Input
      type="number"
      value={Number.isNaN(value) ? "" : value.toString()}
      onChange={(rawNewValue) => {
        let newValue = parseFloat(rawNewValue);

        if (typeof min !== "undefined" && newValue < min) {
          newValue = min;
        }
        if (typeof max !== "undefined" && newValue > max) {
          newValue = max;
        }
        setValue(newValue);
        if (!Number.isNaN(newValue)) {
          onChange(newValue);
        }
      }}
      onBlur={() => {
        if (Number.isNaN(value)) {
          setValue(initialValue);
          onChange(initialValue);
        }
      }}
      {...props}
    />
  );
}
