import { useCallback, useEffect, useState } from "react";
import { defaultBranchName } from "@hypertune/sdk/src/shared";
import { ArrowLeft } from "@phosphor-icons/react";
import toInt from "@hypertune/shared-internal/src/toInt";
import Modal from "../../../components/Modal";
import {
  ProjectDocument,
  ProjectQuery,
  PullRequestStatus,
  useCreatePullRequestMutation,
} from "../../../generated/graphql";
import Label from "../../../components/Label";
import Dropdown from "../../../components/Dropdown";
import getTextWidth from "../../../lib/generic/getTextWidth";
import { interFontFamily, mediumFontSize } from "../../../lib/constants";

const dropdownPlaceholderText = "Select a branch";

export default function NewPullRequestModal({
  projectId,
  branches,
  pullRequests,
  onClose,
}: {
  projectId: string;
  branches: ProjectQuery["project"]["branches"];
  pullRequests: ProjectQuery["project"]["pullRequests"];
  onClose: (newPullRequestId?: number) => void;
}): React.ReactElement | null {
  const [createPullRequest, { loading }] = useCreatePullRequestMutation();
  const [intoBranchId, setIntoBranchId] = useState<string | null>(
    branches.find((branch) => branch.name === defaultBranchName)?.id ?? null
  );
  const [fromBranchId, setFromBranchId] = useState<string | null>(null);

  useEffect(() => {
    if (intoBranchId && fromBranchId && intoBranchId === fromBranchId) {
      setFromBranchId(null);
    }
  }, [intoBranchId, fromBranchId]);

  const branchToActiveCommitId = Object.fromEntries(
    branches.map((branch) => [branch.id, branch.activeCommit.id])
  );
  const activePullRequests = new Set(
    pullRequests
      .filter(
        (pullRequest) =>
          pullRequest.status === PullRequestStatus.Open ||
          pullRequest.status === PullRequestStatus.Approved
      )
      .map(
        (pullRequest) =>
          `${pullRequest.intoBranch.id}/${pullRequest.fromBranch.id}`
      )
  );
  const intoOptions = branches.map((branch) => ({
    value: branch.id,
    label: branch.name,
  }));
  const fromOptions = intoOptions.filter(
    (option) =>
      option.value !== intoBranchId &&
      (!intoBranchId ||
        branchToActiveCommitId[option.value] !==
          branchToActiveCommitId[intoBranchId]) &&
      !activePullRequests.has(`${intoBranchId}/${option.value}`)
  );
  const dropDownMinWidth =
    Math.max(
      getTextWidth(interFontFamily, mediumFontSize, dropdownPlaceholderText),
      ...intoOptions.map(({ label }) =>
        getTextWidth(interFontFamily, mediumFontSize, label)
      )
    ) + 60;

  const onSave = useCallback(async () => {
    if (!fromBranchId || !intoBranchId || loading) {
      return;
    }
    const resp = await createPullRequest({
      variables: {
        input: {
          projectId,
          fromBranchId,
          intoBranchId,
        },
      },
      refetchQueries: [ProjectDocument],
      awaitRefetchQueries: true,
    });
    onClose(toInt(resp.data?.createPullRequest ?? "0"));
  }, [
    createPullRequest,
    loading,
    onClose,
    projectId,
    fromBranchId,
    intoBranchId,
  ]);

  return (
    <Modal
      closeOnEsc
      onClose={() => onClose()}
      title="Create new pull request"
      saveIntent="primary"
      saveWeight="filled"
      saveText="Create"
      saveLoading={loading}
      saveDisabled={!fromBranchId || !intoBranchId}
      onSave={onSave}
    >
      <div className="flex flex-row items-center justify-between gap-8 px-4 pb-3 pt-2">
        <BranchSelector
          label="Into branch"
          selectedValue={intoBranchId}
          setSelectedValue={setIntoBranchId}
          options={intoOptions}
          minWidth={dropDownMinWidth}
        />
        <ArrowLeft weight="bold" size={20} />
        <BranchSelector
          label="From branch"
          selectedValue={fromBranchId}
          setSelectedValue={setFromBranchId}
          options={fromOptions}
          minWidth={dropDownMinWidth}
        />
      </div>
    </Modal>
  );
}

function BranchSelector({
  label,
  selectedValue,
  setSelectedValue,
  options,
  minWidth,
}: {
  label: string;
  selectedValue: string | null;
  setSelectedValue: (newValue: string | null) => void;
  options: { value: string; label: string }[];
  minWidth: number;
}): React.ReactElement | null {
  return (
    <div className="flex flex-col gap-2">
      <Label type="title3">{label}</Label>
      <Dropdown
        height={30}
        minWidth={minWidth}
        options={{
          type: "options",
          options,
        }}
        value={options.find((option) => option.value === selectedValue) ?? null}
        onChange={(newOption) => {
          setSelectedValue(newOption?.value ?? null);
        }}
        placeholder={dropdownPlaceholderText}
        noOptionsMessage="No branches found"
      />
    </div>
  );
}
