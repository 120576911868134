import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useApolloClient } from "@apollo/client";
import { Outlet, useLocation } from "react-router-dom";
import { interFontFamily, mediumFontSize } from "../lib/constants";
import { onUpdateProfile } from "../lib/query/auth";
import useIntercom from "./useIntercom";
import NewAppVersionModal from "./NewAppVersionModal";
import { useHypertune } from "../generated/hypertune.react";
import CookieConsentBanner from "./CookieConsentBanner";
import Maintenance from "./Maintenance";

export default function App(): React.ReactElement | null {
  // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app#tell-intercom-when-your-data-or-url-changes
  const location = useLocation();
  const hypertune = useHypertune();
  const hypertuneIsReady = hypertune.isReady();
  const { updateIntercom, shutdownIntercom } = useIntercom();
  useEffect(() => {
    updateIntercom();
  }, [updateIntercom, location]);

  useEffect(() => {
    const href = `${window.location.origin}${location.pathname}`;

    if (hypertuneIsReady && href) {
      hypertune.events().pageViewed({ args: { href } });
      console.debug("App logged pageViewed:", { href });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hypertuneIsReady, location.pathname]);

  const [authReady, setAuthReady] = useState(false);
  const apolloClient = useApolloClient();
  useEffect(() => {
    return getAuth().onAuthStateChanged(async (firebaseUser) => {
      setAuthReady(true);
      await apolloClient.resetStore();

      if (!firebaseUser) {
        shutdownIntercom();
        return;
      }

      // TODO: Remove this when we implement our own auth solution.
      // There's a race condition on registration where displayName is not set
      // for a short period of time, but the backend requires it to be set
      // before we make more requests. This is because onAuthStateChanged is
      // called when the user is created, but before we update them with a
      // displayName. Unfortunately, Firebase does not allow us to do this in
      // a single call so instead we wait for the displayName to be set on the
      // user.
      await new Promise<void>((resolve) => {
        const c = setInterval(() => {
          if (firebaseUser.displayName) {
            clearInterval(c);
            resolve();
          }
        }, 100);
      });
      await onUpdateProfile();
    });
  }, [apolloClient, shutdownIntercom]);

  if (!authReady) {
    return null;
  }
  const maintenanceModeEnabled = hypertune
    .maintenanceMode()
    .enabled({ fallback: false });

  return (
    <div
      style={{
        fontFamily: interFontFamily,
        fontSize: mediumFontSize,
        minHeight: "100vh",
      }}
    >
      {maintenanceModeEnabled ? (
        <Maintenance />
      ) : (
        <>
          <Outlet />
          <NewAppVersionModal />
        </>
      )}
      <CookieConsentBanner />
    </div>
  );
}
