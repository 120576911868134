import { Value } from "@hypertune/sdk/src/shared";
import { getEdgeRequestUrl } from "@hypertune/sdk/src/lib/edge";
import trimQueryCode from "@hypertune/shared-internal/src/trimQueryCode";
import env from "../environment";

type SnippetArgs = {
  projectToken: string;
  queryCode: string;
  variableValues: { [variableName: string]: Value };
};

export default function formatSnippet(
  snippet: string,
  args: SnippetArgs
): string {
  const { projectToken, queryCode, variableValues } = args;

  return snippet
    .replaceAll(
      "{edgeRequestJsUrl}",
      getEdgeRequestUrl({
        baseUrl: env.EDGE_CDN_BASE_URL,
        requestType: "js",
        token: projectToken,
        branchName: null,
        body: {
          query: trimQueryCode(queryCode),
          variables: variableValues,
        },
      })
    )
    .replaceAll(
      "{edgeRequestGraphqlUrl}",
      getEdgeRequestUrl({
        baseUrl: env.EDGE_CDN_BASE_URL,
        requestType: "graphql",
        token: projectToken,
        branchName: null,
        body: {
          query: trimQueryCode(queryCode),
          variables: variableValues,
        },
      })
    )
    .replaceAll("{queryVariables}", JSON.stringify(variableValues, null, 2))
    .replaceAll("{queryCode}", trimQueryCode(queryCode))
    .replaceAll("{edgeBaseUrl}", env.EDGE_CDN_BASE_URL)
    .replaceAll("{urlProjectToken}", encodeURIComponent(projectToken));
}
