import { DraftCommit } from "../../features/project/projectSlice";
import { CreateCommitInput } from "../../generated/graphql";

export default function getCreateCommitInput({
  projectId,
  parentId,
  branchName,
  message,
  draftCommit,
}: {
  projectId: string;
  parentId: string;
  branchName: string;
  message: string;
  draftCommit: DraftCommit;
}): CreateCommitInput {
  return {
    projectId,
    parentId,
    branchName,
    message,
    schemaCode: draftCommit.schemaCode,
    expressionJson: JSON.stringify(draftCommit.expression),
    splitsJson: JSON.stringify(draftCommit.splits),
    eventTypesJson: JSON.stringify(draftCommit.eventTypes),
  };
}
