import { ReactElement, useEffect, useState } from "react";
import { Split, fieldPathSeparator } from "@hypertune/sdk/src/shared";
import {
  ArrowsSplit,
  Copy,
  NavigationArrow,
  NotePencil,
  SealCheck,
  Shuffle,
  Target,
} from "@phosphor-icons/react";
import {
  getSplitGoalEventTypeNameFromRewardSql,
  getSplitRewardSql,
} from "@hypertune/shared-internal";

import { copySplit } from "@hypertune/shared-internal/src/copyCommitData";
import {
  Description,
  DetailContainer,
  DetailsContainer,
  Status,
} from "../../../../components/Details";
import IdSelector from "../../IdSelector";
import { CommitContext } from "../../../../lib/types";
import UnitIdPathSelector, {
  getJoinIdOptionsForObject,
} from "../../UnitIdPathSelector";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import Button from "../../../../components/buttons/Button";
import { intentSuccessHex, intentWarningHex } from "../../../../lib/constants";
import ModalWithContent from "../../../../components/ModalWithContent";
import { useHypertune } from "../../../../generated/hypertune.react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { toggleShowDetails } from "../../projectSlice";
import getPayloadPathString from "../../../../lib/getPayloadPathString";

export default function SelectedSplitDetails({
  readOnly,
  hasError,
  commitContext,
  split,
  setSplit,
  setSelectedSplitId,
}: {
  readOnly: boolean;
  hasError: boolean;
  commitContext: CommitContext;
  split: Split;
  setSplit: (newSelectedType: Split) => void;
  setSelectedSplitId: (newSelectedSplitId: string) => void;
}): ReactElement {
  const dispatch = useAppDispatch();
  const showDetails = useAppSelector((state) => state.project.showDetails);

  const content = useHypertune().content().splits();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (hasError && !showDetails) {
      dispatch(toggleShowDetails());
    }
    // We don't want changes to showDetails to trigger this hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasError]);

  return (
    <DetailsContainer>
      <Description
        readOnly={readOnly}
        text={split.description || ""}
        setText={(newDescription) =>
          setSplit({
            ...split,
            description: newDescription,
          })
        }
      />
      <Status
        currentStatus={split.archived ? "Completed" : "Active"}
        intent={split.archived ? "neutral" : "success"}
      />
      {split.type === "ml" && (
        <>
          <DetailContainer icon={<Target />} title="Goal event type">
            <IdSelector
              context={commitContext}
              source="eventTypes"
              selectedId={getSplitGoalEventTypeNameFromRewardSql(
                split.rewardSql
              )}
              setSelectedId={(newGoalEventTypeName: string | null) => {
                if (readOnly || !newGoalEventTypeName) {
                  return;
                }
                const newRewardSql = getSplitRewardSql(newGoalEventTypeName);
                const unitIdPaths = getJoinIdOptionsForObject(
                  commitContext.schema,
                  newGoalEventTypeName
                );
                setSplit({
                  ...split,
                  rewardEvents: [
                    {
                      eventObjectTypeName: newGoalEventTypeName,
                      unitIdPayloadPath:
                        unitIdPaths.length > 0 ? unitIdPaths[0].slice(1) : [],
                    },
                  ],
                  rewardSql: newRewardSql,
                });
              }}
              readOnly={readOnly}
              intent={split.rewardEvents.length === 0 ? "danger" : undefined}
            />
          </DetailContainer>
          <DetailContainer
            icon={<Target />}
            title="Goal event type unit ID path"
          >
            <UnitIdPathSelector
              context={commitContext}
              source="event"
              eventTypeName={split.rewardEvents[0]?.eventObjectTypeName}
              unitIdPath={
                split.rewardEvents[0]?.unitIdPayloadPath &&
                split.rewardEvents[0]?.unitIdPayloadPath.length > 0
                  ? getPayloadPathString(
                      split.rewardEvents[0].unitIdPayloadPath
                    )
                  : ""
              }
              setUnitIdPath={(newUnitIdPath: string | null) => {
                if (readOnly || !newUnitIdPath) {
                  return;
                }
                setSplit({
                  ...split,
                  rewardEvents: [
                    {
                      eventObjectTypeName:
                        split.rewardEvents[0]?.eventObjectTypeName || "",
                      unitIdPayloadPath: newUnitIdPath
                        .split(fieldPathSeparator)
                        .slice(1),
                    },
                  ],
                });
              }}
              readOnly={readOnly}
              intent={
                split.rewardEvents.length > 0 &&
                split.rewardEvents[0].unitIdPayloadPath.length === 0
                  ? "danger"
                  : undefined
              }
            />
          </DetailContainer>
        </>
      )}
      <DetailContainer icon={<NavigationArrow />} title="Payload event type">
        <IdSelector
          context={commitContext}
          source="eventTypes"
          allowEmpty
          selectedId={split.eventObjectTypeName}
          setSelectedId={(newPayloadEventObjectTypeName: string | null) => {
            if (readOnly) {
              return;
            }
            setSplit({
              ...split,
              eventObjectTypeName: newPayloadEventObjectTypeName,
            });
          }}
          readOnly={readOnly}
        />
      </DetailContainer>
      {!readOnly && (
        <DetailContainer icon={<NotePencil />} title="Actions">
          <div className="flex flex-row flex-wrap gap-2">
            <DeleteButton
              text="Delete"
              weight="elevated"
              onClick={() => setShowDeleteModal(true)}
            />
            <Button
              icon={<Copy weight="regular" />}
              weight="elevated"
              text="Clone"
              onClick={() => {
                const newSplit = copySplit(split, "Copy of ", {}, {});
                commitContext.setSplits({
                  ...commitContext.splits,
                  [newSplit.id]: newSplit,
                });
                setSelectedSplitId(newSplit.id);
              }}
            />
            <Button
              icon={<SealCheck weight="regular" color={intentSuccessHex} />}
              weight="elevated"
              intent="success"
              text={split.archived ? "Reactivate" : "Mark as completed"}
              onClick={() => {
                const { archived: completed, ...newSplit } = split;
                setSplit({
                  ...newSplit,
                  ...(!completed ? { archived: true } : {}),
                });
              }}
            />
            {!split.archived && (
              <Button
                icon={
                  split.type === "ml" ? (
                    <ArrowsSplit weight="regular" color={intentWarningHex} />
                  ) : (
                    <Shuffle weight="regular" color={intentWarningHex} />
                  )
                }
                weight="elevated"
                intent="warning"
                text={`Convert to ${split.type === "ml" ? "Test" : "ML loop"}`}
                onClick={() => {
                  if (split.type === "ml") {
                    setSplit({
                      type: "test",
                      id: split.id,
                      name: split.name,
                      description: split.description,
                      archived: split.archived,
                      eventObjectTypeName: split.eventObjectTypeName,
                      dimensions: split.dimensions,
                      featureIds: split.featureIds,
                    });
                    return;
                  }
                  setSplit({
                    type: "ml",
                    id: split.id,
                    name: split.name,
                    description: split.description,
                    archived: split.archived,
                    eventObjectTypeName: split.eventObjectTypeName,
                    dimensions: split.dimensions,
                    featureIds: split.featureIds,

                    rewardEvents: [],
                    rewardSql: "",
                  });
                }}
              />
            )}
          </div>
        </DetailContainer>
      )}
      {showDeleteModal && (
        <ModalWithContent
          content={content
            .deleteSplitModal({
              args: { splitType: split.type === "ml" ? "ML loop" : "test" },
            })
            .get()}
          onClose={() => setShowDeleteModal(false)}
          onSave={() => {
            const { [split.id]: _, ...newSplits } = commitContext.splits;
            commitContext.setSplits(newSplits);
            setShowDeleteModal(false);
          }}
        />
      )}
    </DetailsContainer>
  );
}
