import { getProjectUrl, useProjectSelectedState } from "../projectHooks";
import env from "../../../environment";

export const selectedPRIdUrlKey = "selected_pr_id";
export const showSharePRModalUrlKey = "pr_share";

export function getPullRequestURL(
  projectId: string,
  pullRequestId: string
): string {
  const params = getPullRequestParams(pullRequestId);
  return `${env.FRONTEND_BASE_URL}${getProjectUrl({
    projectId,
    selectedView: "pull-requests",
    searchParams: params,
  })}`;
}

export function getPullRequestParams(
  pullRequestId: string,
  showShareModal?: boolean
): URLSearchParams {
  return new URLSearchParams({
    [selectedPRIdUrlKey]: pullRequestId,
    ...(showShareModal ? { [showSharePRModalUrlKey]: "1" } : {}),
  });
}

export function useNavigateToPR(): (pullRequestId: string) => void {
  const { setSelected } = useProjectSelectedState();

  return (pullRequestId: string) =>
    setSelected({
      view: "pull-requests",
      searchParams: new URLSearchParams({
        [selectedPRIdUrlKey]: pullRequestId,
      }),
    });
}
