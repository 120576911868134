import { ReactElement, useState } from "react";
import { Pencil, Plus } from "@phosphor-icons/react";
import {
  useProjectQuery,
  useCreateWebhookMutation,
  ProjectDocument,
  WebhookEventType,
  ProjectQuery,
  useUpdateWebhookMutation,
} from "../../../generated/graphql";
import { redTextHex, smallFontSize, whiteHex } from "../../../lib/constants";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/input/TextInput";
import Toggle from "../../../components/Toggle";
import getErrorMessage from "../../../lib/query/getErrorMessage";
import ErrorCircle from "../../../components/icons/ErrorCircle";
import SuccessCircle from "../../../components/icons/SuccessCircle";
import Button from "../../../components/buttons/Button";
import CardGroup from "../../../components/CardGroup";
import Label from "../../../components/Label";
import { CenteredContainerHeader } from "../../../components/container/CenteredContainer";
import ErrorMessageCard from "../../../components/ErrorMessageCard";

export default function WebhookSettingsEditor({
  projectId,
  canEdit,
}: {
  projectId: string;
  canEdit: boolean;
}): ReactElement {
  // Maybe make this a different query? Refetching the entire project when we
  // update the webhooks seems wasteful - but then again doing one query to get
  // the project with all its settings seems nice. Not sure what the right
  // pattern with GraphQL is here (is partial refetching possible?).
  const {
    loading,
    error,
    data: projectQueryData,
  } = useProjectQuery({
    variables: { projectId },
  });
  if (loading || !projectQueryData) {
    return <WebhookSettingsEditor.LoadingSkeleton />;
  }

  if (error) {
    return <ErrorMessageCard error={error} />;
  }

  const { webhooks } = projectQueryData.project;

  return (
    <div className="my-8">
      <CenteredContainerHeader title="Webhooks" className="mb-4">
        {canEdit && <NewWebhookButton projectId={projectId} />}
      </CenteredContainerHeader>

      {webhooks.length > 0 ? (
        <CardGroup
          layout="list"
          cardLayout="horizontal"
          cards={webhooks.map((webhook) => ({
            key: webhook.id,
            className: "items-center",
            children: (
              <>
                <div className="flex flex-row items-center">
                  <div className="flex w-24 flex-row items-center">
                    {webhook.active && (
                      <SuccessCircle
                        style={{
                          marginRight: 8,
                          display: "inline",
                        }}
                      />
                    )}
                    {!webhook.active && (
                      <ErrorCircle
                        style={{
                          marginRight: 8,
                          display: "inline",
                        }}
                      />
                    )}
                    <span>{webhook.active ? "Active" : "Inactive"}</span>
                  </div>
                  <Label type="title3" className="text-tx-default">
                    {webhook.name}
                  </Label>
                </div>
                {canEdit && <EditWebhookButton webhook={webhook} />}
              </>
            ),
          }))}
        />
      ) : null}
    </div>
  );
}

WebhookSettingsEditor.LoadingSkeleton =
  function LoadingSkeleton(): React.ReactElement {
    return (
      <div className="my-8">
        <CenteredContainerHeader title="Webhooks" className="mb-4" />
        <CardGroup
          layout="list"
          cardLayout="horizontal"
          loadingCount={2}
          cards={[]}
        />
      </div>
    );
  };

function NewWebhookButton({
  projectId,
}: {
  projectId: string;
}): React.ReactElement {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <>
      {isModalVisible ? (
        <NewWebhookModal
          projectId={projectId}
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      ) : null}
      <Button
        text="New webhook"
        icon={<Plus weight="bold" color={whiteHex} />}
        weight="filled"
        intent="primary"
        onClick={() => {
          setIsModalVisible(true);
        }}
      />
    </>
  );
}

function NewWebhookModal({
  projectId,
  onClose,
}: {
  projectId: string;
  onClose: () => void;
}): React.ReactElement {
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [secret, setSecret] = useState<string>(generateSecret());
  const [active, setActive] = useState<boolean>(true);

  const [createWebhook, { loading, error }] = useCreateWebhookMutation({
    refetchQueries: [ProjectDocument],
  });

  const submitDisabledMessage: string | null = loading
    ? "Loading..."
    : !name
      ? "Missing name"
      : !url
        ? "Missing URL"
        : !secret
          ? "Missing secret"
          : null;

  function onSubmit(): void {
    if (submitDisabledMessage !== null) {
      return;
    }
    createWebhook({
      variables: {
        input: {
          projectId,
          name,
          url,
          eventTypes: [WebhookEventType.NewCommit],
          secret,
          active,
        },
      },
      awaitRefetchQueries: true,
    })
      .then(() => {
        onClose();
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        console.error("createWebhook error:", error);
      });
  }

  return (
    <Modal
      title="Create new webhook"
      confirmClose // TODO: confirmClose only when dirty
      onClose={loading ? null : onClose}
      style={{ width: 420 }}
    >
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>
        Webhook Name
      </div>
      <TextInput
        style={{ marginBottom: 12 }}
        trimOnBlur
        focusOnMount
        placeholder="Enter a name for this webhook"
        readOnly={loading}
        value={name}
        onChange={(newValue: string) => {
          setName(newValue);
        }}
      />
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>
        Payload URL
      </div>
      <TextInput
        style={{ marginBottom: 12 }}
        trimOnBlur
        placeholder="Enter a HTTPS URL where payloads should be sent"
        readOnly={loading}
        value={url}
        onChange={(newValue: string) => {
          setUrl(newValue);
        }}
      />
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>Secret</div>
      <TextInput
        style={{ marginBottom: 12 }}
        trimOnBlur
        placeholder="Enter a secret for us to sign payloads with"
        readOnly={loading}
        value={secret}
        onChange={(newValue: string) => {
          setSecret(newValue);
        }}
      />
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>Active</div>
      <Toggle
        className="mb-[12px]"
        disabled={loading}
        value={active}
        setValue={(newValue: boolean) => {
          setActive(newValue);
        }}
      />
      <div style={{ marginBottom: 12 }}>
        {active
          ? "We will send POST requests to your endpoint on new commits."
          : "This webhook won't receive events yet because it's not active."}
      </div>
      {error && (
        <div style={{ marginBottom: 12, color: redTextHex }}>
          {getErrorMessage(error)}
        </div>
      )}
      <Button
        disabled={submitDisabledMessage !== null}
        title={submitDisabledMessage ?? undefined}
        onClick={onSubmit}
        loading={loading}
        text="Create"
        loadingText="Creating..."
        intent="primary"
        weight="minimal"
      />
    </Modal>
  );
}

export function EditWebhookButton({
  webhook,
}: {
  webhook: ProjectQuery["project"]["webhooks"][number];
}): React.ReactElement {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <>
      {isModalVisible ? (
        <EditWebhookModal
          webhook={webhook}
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      ) : null}
      <Button
        text="Edit"
        weight="minimal"
        onClick={() => {
          setIsModalVisible(true);
        }}
        iconEnd={<Pencil size={14} />}
      />
    </>
  );
}

function EditWebhookModal({
  webhook,
  onClose,
}: {
  webhook: ProjectQuery["project"]["webhooks"][number];
  onClose: () => void;
}): React.ReactElement {
  const [name, setName] = useState<string>(webhook.name);
  const [url, setUrl] = useState<string>(webhook.url);
  const [secret, setSecret] = useState<string>(webhook.secret);
  const [active, setActive] = useState<boolean>(webhook.active);

  const [updateWebhook, { loading, error }] = useUpdateWebhookMutation({
    refetchQueries: [ProjectDocument],
  });

  const submitDisabledMessage: string | null = loading
    ? "Loading..."
    : !name
      ? "Missing name"
      : !url
        ? "Missing URL"
        : !secret
          ? "Missing secret"
          : null;

  function onSubmit(): void {
    if (submitDisabledMessage !== null) {
      return;
    }
    updateWebhook({
      variables: {
        input: {
          id: webhook.id,
          name,
          url,
          eventTypes: [WebhookEventType.NewCommit],
          secret,
          active,
        },
      },
      awaitRefetchQueries: true,
    })
      .then(() => {
        onClose();
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        console.error("updateWebhook error:", error);
      });
  }

  return (
    <Modal
      title="Edit Webhook"
      confirmClose // TODO: confirmClose only when dirty
      onClose={loading ? null : onClose}
      style={{ width: 420 }}
    >
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>
        Webhook Name
      </div>
      <TextInput
        style={{ marginBottom: 12 }}
        trimOnBlur
        focusOnMount
        placeholder="Enter a name for this webhook"
        readOnly={loading}
        value={name}
        onChange={(newValue: string) => {
          setName(newValue);
        }}
      />
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>
        Payload URL
      </div>
      <TextInput
        style={{ marginBottom: 12 }}
        trimOnBlur
        placeholder="Enter a HTTPS URL where payloads should be sent"
        readOnly={loading}
        value={url}
        onChange={(newValue: string) => {
          setUrl(newValue);
        }}
      />
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>Secret</div>
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <TextInput
          style={{
            marginBottom: 12,
            flexGrow: 1,
          }}
          trimOnBlur
          placeholder="Enter a secret for us to sign payloads with"
          readOnly={loading}
          value={secret}
          onChange={(newValue: string) => {
            setSecret(newValue);
          }}
        />
        <Button
          text="Regenerate"
          weight="outlined"
          onClick={() => {
            setSecret(generateSecret());
          }}
        />
      </div>
      <div style={{ marginBottom: 8, fontSize: smallFontSize }}>Active</div>
      <Toggle
        className="mb-[12px]"
        disabled={loading}
        value={active}
        setValue={(newValue: boolean) => {
          setActive(newValue);
        }}
      />
      <div style={{ marginBottom: 12 }}>
        {active
          ? "We will send POST requests to your endpoint on new commits."
          : "This webhook won't receive events because it's not active."}
      </div>
      {error && (
        <div style={{ marginBottom: 12, color: redTextHex }}>
          {getErrorMessage(error)}
        </div>
      )}
      <Button
        disabled={submitDisabledMessage !== null}
        title={submitDisabledMessage ?? undefined}
        onClick={onSubmit}
        loading={loading}
        text="Update"
        loadingText="Updating..."
        intent="primary"
        weight="minimal"
      />
    </Modal>
  );
}

function generateSecret(): string {
  const randomValues = new Uint8Array(16);
  // Desktop 99%: https://caniuse.com/getrandomvalues
  crypto.getRandomValues(randomValues);
  let result = "";
  for (const value of randomValues) {
    result += value.toString(16).padStart(2, "0");
  }
  return result;
}
