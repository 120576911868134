import { Trash } from "@phosphor-icons/react";
import { useState } from "react";
import Button, { Size, Weight } from "../../../../components/buttons/Button";
import { intentDangerHex } from "../../../../lib/constants";
import ModalWithContent from "../../../../components/ModalWithContent";
import { useHypertune } from "../../../../generated/hypertune.react";

export default function DeleteWithConfirmationButton({
  entityName,
  onClick,
  className,
  size,
  weight = "elevated",
  text = "Delete",
}: {
  entityName: string;
  onClick: () => void;
  className?: string;
  size?: Size;
  weight?: Weight;
  text?: string;
}): React.ReactElement | null {
  const content = useHypertune().content();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  return (
    <>
      {showConfirmModal && (
        <ModalWithContent
          content={content.schema().deleteConfirmation().get()}
          variables={{ entityName }}
          onClose={() => setShowConfirmModal(false)}
          onSave={() => {
            onClick();
            setShowConfirmModal(false);
          }}
        />
      )}
      <Button
        icon={<Trash weight="regular" color={intentDangerHex} />}
        text={text}
        intent="danger"
        weight={weight}
        size={size}
        onClick={() => setShowConfirmModal(true)}
        className={className}
      />
    </>
  );
}
