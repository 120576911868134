import { useNavigate } from "react-router-dom";
import Button, { Weight } from "../../../components/buttons/Button";
import { Intent } from "../../../components/intent";

export default function UpgradeToProButton({
  weight = "filled",
  intent = "primary",
  className,
}: {
  weight?: Weight;
  intent?: Intent;
  className?: string;
}): React.ReactElement {
  const navigate = useNavigate();

  return (
    <Button
      text="Upgrade"
      intent={intent}
      weight={weight}
      onClick={() => navigate("/plans")}
      className={className}
    />
  );
}
