import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  FieldPolicy,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  anonymousUserToken,
  appVersionHeaderName,
  sessionIdHeaderName,
} from "@hypertune/shared-internal";
import { uniqueId } from "@hypertune/sdk/src/shared";
import { LogsQuery } from "../../generated/graphql";
import env from "../../environment";
import { getAuthToken } from "./auth";
import {
  appVersion,
  newAppVersionAvailableEventName,
  sessionIdKey,
} from "../constants";

let apolloClient: ApolloClient<unknown> | undefined;

export default function getApolloClient(): ApolloClient<unknown> {
  if (apolloClient) {
    return apolloClient;
  }

  const sessionId = window.sessionStorage.getItem(sessionIdKey) ?? uniqueId();
  window.sessionStorage.setItem(sessionIdKey, sessionId);

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAuthToken();
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token || anonymousUserToken}`,
        [sessionIdHeaderName]: sessionId,
      },
    };
  });
  const httpLink = createHttpLink({
    uri: `${env.BACKEND_BASE_URL}/graphql`,
  });
  const versionLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;

      const version = headers.get(appVersionHeaderName);

      if (version && appVersion < version) {
        console.log(
          `New backend version detected ${version} that is newer than app version ${appVersion}`
        );
        window.dispatchEvent(new Event(newAppVersionAvailableEventName));
      }
      return response;
    });
  });

  const fieldPolicy: FieldPolicy<LogsQuery["logs"][]> = {
    keyArgs: ["input", ["commitId"]],
    merge(existing = [], incoming = []) {
      return [...incoming, ...existing];
    },
  };

  apolloClient = new ApolloClient({
    link: versionLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            logs: fieldPolicy,
          },
        },
      },
    }),
  });

  return apolloClient;
}
