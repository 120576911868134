import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ErrorCircle from "./icons/ErrorCircle";
import CollapseButton from "./buttons/CollapseButton";
import CollapseDiv from "./animations/CollapseDiv";
import Spinner from "./icons/Spinner";
import TooltipDot from "./tooltips/TooltipDot";
import {
  Intent,
  intentToBorderClassName,
  intentToHoverDarkerBgClassName,
  intentToHoverLightBgClassName,
  intentToLightBgClassName,
  intentToTextColorName,
} from "./intent";
import twMerge from "../lib/twMerge";

export type SidebarItemProps = {
  isCollapsed?: boolean;
  icon: React.ReactElement;
  title: string;
  subtitle?: string;
  intent?: Intent;
  variant?: "default" | "outlined" | "minimal";
  link?: {
    href: string;
    isExternal?: boolean;
  };
  onClick?: () => void;
  hasError?: boolean;
  hasWarning?: boolean;
  isSelected?: boolean;
  className?: string;
  children?:
    | React.ReactElement<SidebarItemProps>
    | React.ReactElement<SidebarItemProps>[]
    | null;
  actions?: React.ReactNode[];
  loading?: boolean;
  showCollapseToggle?: boolean;
  collapseOnClickWhenNotSelected?: boolean;
};

export default function SidebarItem({
  isCollapsed,
  icon,
  title,
  subtitle,
  intent: baseIntent,
  variant = "default",
  onClick,
  link,
  hasError,
  hasWarning,
  isSelected,
  className,
  children,
  actions,
  loading,
  showCollapseToggle,
  collapseOnClickWhenNotSelected,
}: SidebarItemProps): React.ReactElement {
  const div = useRef<HTMLDivElement>(null);
  const intent = baseIntent ?? (isSelected ? "primary" : "neutral");

  useEffect(() => {
    if (!isSelected) {
      return;
    }
    const el = div.current;
    if (el) {
      el.scrollIntoView({ block: "nearest" });
    }
  }, [div, isSelected]);

  const [isOpen, setOpen] = React.useState<boolean>(true);

  const hasChildren =
    children && (!Array.isArray(children) || children.length > 0);

  let item = (
    <div ref={div} className="flex w-full min-w-0 select-none flex-col">
      <div
        title={isCollapsed ? title : undefined}
        className={twMerge(
          `group flex min-w-0 cursor-pointer flex-col items-stretch
           rounded-lg border p-2`,
          isSelected
            ? intentToLightBgClassName(intent)
            : `bg-transparent ${variant === "minimal" ? intentToHoverDarkerBgClassName(intent) : intentToHoverLightBgClassName(intent)}`,
          hasChildren && "mb-[2px]",
          variant === "outlined"
            ? intent === "neutral"
              ? "border-bd-darker"
              : intentToBorderClassName(intent)
            : "border-transparent",
          variant === "minimal" ? intentToLightBgClassName(intent) : null,
          className
        )}
        onClick={
          !link
            ? () => {
                if (
                  hasChildren &&
                  (isSelected || collapseOnClickWhenNotSelected)
                ) {
                  setOpen(!isOpen);
                  return;
                }
                if (onClick) {
                  onClick();
                }
              }
            : undefined
        }
      >
        <div className="flex flex-row items-center gap-2">
          {loading ? <Spinner intent={intent} size={16} /> : icon}
          {!isCollapsed && (
            <>
              <div
                className={`min-w-0 flex-shrink-2 overflow-hidden text-ellipsis 
                          whitespace-nowrap font-medium leading-4
                          ${intentToTextColorName(intent)}`}
              >
                {title}
              </div>
              <div className="flex-grow" />
              {hasError ? (
                <ErrorCircle />
              ) : hasWarning ? (
                <ErrorCircle intent="warning" />
              ) : null}
              {actions && actions.length > 0 ? (
                <div className="flex gap-1">{actions}</div>
              ) : null}
              {(showCollapseToggle || hasChildren) && (
                <CollapseButton
                  isOpen={isOpen}
                  setOpen={setOpen}
                  isSelected={isSelected}
                  disabled={!children}
                />
              )}
            </>
          )}
        </div>
        {subtitle && !isCollapsed && (
          <div
            className={`ml-6 mt-[6px] min-w-0 flex-shrink-2 overflow-hidden 
                        text-ellipsis whitespace-nowrap text-sm font-medium leading-[15px]
                          ${intent === "neutral" ? "text-tx-muted" : intentToTextColorName(intent)}`}
          >
            {subtitle}
          </div>
        )}
      </div>
      {hasChildren && (
        <CollapseDiv isOpen={isOpen} className="overflow-hidden">
          <div className="mb-[18px] ml-[19px] mr-[10px] min-w-0 border-l border-bd-default" />
          <div className="flex w-full min-w-0 flex-col gap-[2px]">
            {children}
          </div>
        </CollapseDiv>
      )}
    </div>
  );
  if (isCollapsed && (hasError || hasWarning)) {
    item = (
      <TooltipDot
        intent={hasError ? "danger" : "warning"}
        pulse={false}
        className="w-full"
      >
        {item}
      </TooltipDot>
    );
  }
  if (link) {
    return (
      <Link
        className="w-full"
        to={link.href}
        target={link.isExternal ? "_blank" : undefined}
      >
        {item}
      </Link>
    );
  }
  return item;
}

SidebarItem.LoadingSkeleton = function LoadingSkeleton({
  className,
}: {
  className?: string;
}): React.ReactElement {
  return (
    <div
      className={twMerge(
        "flex flex-row items-center gap-2 p-2 leading-4",
        className
      )}
    >
      <div className="w-4">
        <Skeleton height={16} />
      </div>
      <div className="w-full">
        <Skeleton height={13} />
      </div>
    </div>
  );
};
