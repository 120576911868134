import {
  BooleanExpression,
  Expression,
  IntExpression,
  StringExpression,
  FloatExpression,
  RegexExpression,
  EnumExpression,
  VariableExpression,
  ObjectExpression,
  GetFieldExpression,
  UpdateObjectExpression,
  ListExpression,
  SwitchExpression,
  EnumSwitchExpression,
  ArithmeticExpression,
  ComparisonExpression,
  RoundNumberExpression,
  StringifyNumberExpression,
  StringConcatExpression,
  GetUrlQueryParameterExpression,
  SplitExpression,
  DiscreteDimensionMapping,
  ContinuousDimensionMapping,
  LogEventExpression,
  FunctionExpression,
  ApplicationExpression,
} from "@hypertune/sdk/src/shared/types";
import valueTypesAreEqual from "../schema/valueTypesAreEqual";

export default function expressionsAreEqual(
  a: Expression | null,
  b: Expression | null
): boolean {
  if (a === null) {
    return b === null;
  }
  if (b === null) {
    return a === null;
  }
  if (a.type !== b.type || !valueTypesAreEqual(a.valueType, b.valueType)) {
    return false;
  }
  switch (a.type) {
    case "NoOpExpression":
      return true;
    case "BooleanExpression":
    case "StringExpression":
    case "IntExpression":
    case "FloatExpression":
    case "RegexExpression":
    case "EnumExpression":
      return (
        a.value ===
        (
          b as
            | BooleanExpression
            | StringExpression
            | IntExpression
            | FloatExpression
            | RegexExpression
            | EnumExpression
        ).value
      );
    case "VariableExpression":
      return a.variableId === (b as VariableExpression).variableId;

    case "ObjectExpression": {
      const bExpr = b as ObjectExpression;
      return (
        a.objectTypeName === bExpr.objectTypeName &&
        objectsOfExpressionsAreEqual(a.fields, bExpr.fields)
      );
    }
    case "GetFieldExpression": {
      const bExpr = b as GetFieldExpression;
      return (
        a.fieldPath === bExpr.fieldPath &&
        expressionsAreEqual(a.object, bExpr.object)
      );
    }
    case "UpdateObjectExpression": {
      const bExpr = b as UpdateObjectExpression;
      return (
        objectsOfExpressionsAreEqual(a.updates, bExpr.updates) &&
        expressionsAreEqual(a.object, bExpr.object)
      );
    }

    case "ListExpression": {
      const bExpr = b as ListExpression;
      return (
        a.items.length === bExpr.items.length &&
        !a.items.some(
          (aItem, index) =>
            !expressionsAreEqual(aItem, bExpr.items[index] ?? null)
        )
      );
    }

    case "SwitchExpression": {
      const bExpr = b as SwitchExpression;
      return (
        expressionsAreEqual(a.control, bExpr.control) &&
        expressionsAreEqual(a.default, bExpr.default) &&
        a.cases.length === bExpr.cases.length &&
        !a.cases.some(
          ({ when: aWhen, then: aThen }, index) =>
            !expressionsAreEqual(aWhen, bExpr.cases[index]?.when ?? null) ||
            !expressionsAreEqual(aThen, bExpr.cases[index]?.then ?? null)
        )
      );
    }

    case "EnumSwitchExpression": {
      const bExpr = b as EnumSwitchExpression;
      return (
        expressionsAreEqual(a.control, bExpr.control) &&
        objectsOfExpressionsAreEqual(a.cases, bExpr.cases)
      );
    }
    case "ArithmeticExpression":
    case "ComparisonExpression": {
      const bExpr = b as ArithmeticExpression | ComparisonExpression;
      return (
        a.operator === bExpr.operator &&
        expressionsAreEqual(a.a, bExpr.a) &&
        expressionsAreEqual(a.b, bExpr.b)
      );
    }

    case "RoundNumberExpression":
    case "StringifyNumberExpression": {
      const bExpr = b as RoundNumberExpression | StringifyNumberExpression;
      return expressionsAreEqual(a.number, bExpr.number);
    }

    case "StringConcatExpression": {
      const bExpr = b as StringConcatExpression;
      return expressionsAreEqual(a.strings, bExpr.strings);
    }

    case "GetUrlQueryParameterExpression": {
      const bExpr = b as GetUrlQueryParameterExpression;
      return (
        expressionsAreEqual(a.queryParameterName, bExpr.queryParameterName) &&
        expressionsAreEqual(a.url, bExpr.url)
      );
    }

    case "SplitExpression": {
      const bExpr = b as SplitExpression;
      return (
        a.splitId === bExpr.splitId &&
        a.dimensionId === bExpr.dimensionId &&
        a.eventObjectTypeName === bExpr.eventObjectTypeName &&
        a.dimensionMapping.type === bExpr.dimensionMapping.type &&
        (a.dimensionMapping.type === "discrete"
          ? objectsOfExpressionsAreEqual(
              a.dimensionMapping.cases,
              (bExpr.dimensionMapping as DiscreteDimensionMapping).cases
            )
          : expressionsAreEqual(
              a.dimensionMapping.function,
              (bExpr.dimensionMapping as ContinuousDimensionMapping).function
            )) &&
        expressionsAreEqual(a.expose, bExpr.expose) &&
        expressionsAreEqual(a.unitId, bExpr.unitId) &&
        expressionsAreEqual(a.eventPayload, bExpr.eventPayload) &&
        objectsOfExpressionsAreEqual(a.featuresMapping, bExpr.featuresMapping)
      );
    }
    case "LogEventExpression": {
      const bExpr = b as LogEventExpression;
      return (
        a.eventObjectTypeName === bExpr.eventObjectTypeName &&
        a.eventTypeId === bExpr.eventTypeId &&
        expressionsAreEqual(a.unitId, bExpr.unitId) &&
        expressionsAreEqual(a.eventPayload, bExpr.eventPayload) &&
        objectsOfExpressionsAreEqual(a.featuresMapping, bExpr.featuresMapping)
      );
    }
    case "FunctionExpression": {
      const bExpr = b as FunctionExpression;
      return (
        a.parameters.length === bExpr.parameters.length &&
        !a.parameters.some(
          (aParam, index) =>
            aParam.id !== bExpr.parameters[index].id ||
            aParam.name !== bExpr.parameters[index].name
        ) &&
        expressionsAreEqual(a.body, bExpr.body)
      );
    }

    case "ApplicationExpression": {
      const bExpr = b as ApplicationExpression;
      return (
        a.arguments.length === bExpr.arguments.length &&
        !a.arguments.some(
          (aArg, index) => !expressionsAreEqual(aArg, bExpr.arguments[index])
        ) &&
        expressionsAreEqual(a.function, bExpr.function)
      );
    }
    default: {
      const neverExpression: never = a;
      throw new Error(`unexpected expression: ${neverExpression}`);
    }
  }
}

function objectsOfExpressionsAreEqual(
  a: { [fieldName: string]: Expression | null },
  b: { [fieldName: string]: Expression | null }
): boolean {
  return (
    // Fields in a match fields in b
    !Object.entries(a).some(
      ([fieldName, aField]) =>
        !expressionsAreEqual(aField, b[fieldName] ?? null)
    ) &&
    // No extra fields in b
    !Object.keys(b).some((fieldName) => !(fieldName in a))
  );
}
