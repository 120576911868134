import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setFunnelSteps } from "./analyticsSlice";
import { useAppSelector } from "../../../app/hooks";
import { useProjectSelectedState } from "../projectHooks";
import {
  ProjectAnalyticsViewsQuery,
  useProjectAnalyticsViewsQuery,
} from "../../../generated/graphql";

export const analyticsFromParamKey = "analytics_from";
export const analyticsToParamKey = "analytics_to";
export const analyticsViewIdKey = "analytics_view_id";

export function useActiveSelectedAnalyticsView():
  | ProjectAnalyticsViewsQuery["project"]["analyticsViews"][number]
  | null {
  const {
    selected: { projectId: selectedProjectId },
    searchParams,
  } = useProjectSelectedState();

  const selectedViewId = searchParams.get(analyticsViewIdKey);

  const { data } = useProjectAnalyticsViewsQuery({
    variables: { projectId: selectedProjectId as string },
  });
  const currentView = useMemo(
    () =>
      data?.project.analyticsViews?.find(
        (view) => view.id === selectedViewId
      ) ?? null,
    [selectedViewId, data]
  );

  return currentView;
}

export function useSelectedAnalyticsViewId(): [
  string | null,
  (newSelectedViewId: string | null, skipNavigationCheck?: boolean) => void,
] {
  const { setSelected, searchParams } = useProjectSelectedState();

  const stopNavigation = useStopAnalyticsViewNavigation();

  const setSelectedViewId = useCallback(
    (newSelectedViewId: string | null, skipNavigationCheck?: boolean) => {
      if (!skipNavigationCheck && stopNavigation()) {
        return;
      }
      setSelected({
        view: "analytics",
        searchParams: new URLSearchParams({
          [analyticsViewIdKey]: newSelectedViewId || "",
        }),
      });
    },
    [setSelected, stopNavigation]
  );
  const selectedViewId = useMemo(
    () => searchParams.get(analyticsViewIdKey) || null,
    [searchParams]
  );

  return [selectedViewId, setSelectedViewId];
}

export function useStopAnalyticsViewNavigation(): () => boolean {
  const dispatch = useDispatch();
  const editorState = useAppSelector((state) => state.projectAnalytics);
  const currentView = useActiveSelectedAnalyticsView();
  const {
    selected: { view: selectedProjectView },
  } = useProjectSelectedState();

  const isAnalyticsViewVisible = selectedProjectView === "analytics";

  const stopNavigation = useCallback(() => {
    // No need to block if the view is not visible or no changes were made to saved views
    if (!isAnalyticsViewVisible || !editorState.hasChanges) {
      return false;
    }

    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm("Leave site? Changes that you made may not be saved.")) {
      return true;
    }
    // Discard changes
    dispatch(setFunnelSteps(JSON.parse(currentView?.funnelStepsJson || "[]")));

    return false;
  }, [dispatch, currentView, isAnalyticsViewVisible, editorState.hasChanges]);
  return stopNavigation;
}
