import { FunnelSegmentData } from "../types";

export default function currentFunnelStepHasBreakdowns(
  segmentData: FunnelSegmentData
): boolean {
  return (
    Object.keys(
      segmentData.breakdownPathValuesList[
        segmentData.breakdownPathValuesList.length - 1
      ]
    ).length > 0 ||
    Object.keys(
      segmentData.assignmentList[segmentData.assignmentList.length - 1]
    ).length > 0
  );
}
