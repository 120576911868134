import { Link } from "react-router-dom";
import { ArrowSquareOut } from "@phosphor-icons/react";
import Button, { Size, Weight } from "./Button";
import { Intent } from "../intent";
import {
  blueHex,
  intentDangerHex,
  intentNeutralHex,
  intentSuccessHex,
  intentWarningHex,
  whiteHex,
} from "../../lib/constants";

export default function ButtonLink({
  text,
  href,
  noTarget,
  intent = "neutral",
  weight = "default",
  size = "default",
  className,
  icon,
}: {
  text: string;
  href: string;
  noTarget?: boolean;
  intent?: Intent;
  weight?: Weight;
  size?: Size;
  className?: string;
  icon?: React.ReactNode;
}): React.ReactElement {
  return (
    <Link
      to={href}
      target={noTarget ? undefined : "_blank"}
      rel="noreferrer"
      className={className}
    >
      <Button
        text={text}
        icon={icon}
        intent={intent}
        weight={weight}
        size={size}
        iconEnd={
          !icon && (
            <ArrowSquareOut
              weight="bold"
              size={buttonSizeToIconSize(size)}
              color={intentAndWeightToIconColor(intent, weight)}
            />
          )
        }
      />
    </Link>
  );
}

function buttonSizeToIconSize(size: Size): number {
  switch (size) {
    case "small":
      return 10;
    case "large":
      return 14;
    default:
      return 12;
  }
}

function intentAndWeightToIconColor(intent: Intent, weight: Weight): string {
  if (weight === "filled") {
    return whiteHex;
  }
  switch (intent) {
    case "primary":
      return blueHex;
    case "warning":
      return intentWarningHex;
    case "danger":
      return intentDangerHex;
    case "success":
      return intentSuccessHex;
    default:
      return intentNeutralHex;
  }
}
