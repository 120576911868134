import { Plus } from "@phosphor-icons/react";
import Button from "./Button";

export default function PlusButton({
  onClick,
}: {
  onClick: () => void;
}): React.ReactElement | null {
  return (
    <Button
      intent="neutral"
      weight="outlined"
      size="small"
      icon={<Plus weight="bold" size={12} />}
      onClick={onClick}
      className="min-w-[26px]"
    />
  );
}
