export default function trimQueryCode(queryCode: string): string {
  return queryCode
    .trim()
    .split("\n")
    .map((line) => (line.includes("#") ? line.split("#")[0] : line))
    .map((line) => line.trim())
    .filter(Boolean)
    .join(" ")
    .split(/\s*{\s*/)
    .join("{")
    .split(/\s*}\s*/)
    .join("}")
    .split(/\s*\(\s*/)
    .join("(")
    .split(/\s*\)\s*/)
    .join(")");
}
