import { EnumValueSchema, Schema, ValueType } from "@hypertune/sdk/src/shared";
import isSchemaNameValid, {
  alreadyExistsMessage,
  schemaNameErrorMessage,
} from "./isSchemaNameValid";
import {
  RemovedFields,
  formatTypeSchemaName,
  removeFieldsFromObjects,
} from "./schemaOperationsObject";
import { renameEnumInValueType } from "./valueTypeOperations";
import { getFieldArgumentsObjectTypeNameParts } from "./fieldArgumentsObjectTypeName";

export type EnumValuePosition = "first" | "last";

export function addEmptyEnum(
  schema: Schema,
  enumTypeName: string,
  description: string | null
): Schema {
  if (!isSchemaNameValid(enumTypeName)) {
    throw new Error(schemaNameErrorMessage("Enum", enumTypeName));
  }
  if (
    schema.objects[enumTypeName] ||
    schema.unions[enumTypeName] ||
    schema.enums[enumTypeName]
  ) {
    throw new Error(alreadyExistsMessage("Type", enumTypeName));
  }
  return {
    ...schema,
    enums: {
      ...schema.enums,
      [enumTypeName]: {
        description,
        values: {
          [enumTypeName + 1]: { description: null },
        },
      },
    },
  };
}

export function renameEnum(
  schema: Schema,
  oldEnumTypeName: string,
  rawNewEnumTypeName: string
): Schema {
  const newEnumTypeName = formatTypeSchemaName(rawNewEnumTypeName);
  if (oldEnumTypeName === newEnumTypeName) {
    return schema;
  }

  if (!isSchemaNameValid(newEnumTypeName)) {
    throw new Error(schemaNameErrorMessage("Enum", newEnumTypeName));
  }
  if (!schema.enums[oldEnumTypeName]) {
    throw new Error(`oldEnumTypeName does not exist: ${oldEnumTypeName}`);
  }
  if (
    schema.objects[newEnumTypeName] ||
    schema.unions[newEnumTypeName] ||
    schema.enums[newEnumTypeName]
  ) {
    throw new Error(alreadyExistsMessage("Type", newEnumTypeName));
  }

  return {
    ...schema,
    enums: Object.fromEntries(
      Object.entries(schema.enums).map(([enumName, enumSchema]) => [
        enumName === oldEnumTypeName ? newEnumTypeName : enumName,
        enumSchema,
      ])
    ),
    objects: Object.fromEntries(
      Object.entries(schema.objects).map(([objectName, objectSchema]) => [
        objectName,
        {
          ...objectSchema,
          fields: Object.fromEntries(
            Object.entries(objectSchema.fields).map(
              ([fieldName, fieldSchema]) => [
                fieldName,
                {
                  ...fieldSchema,
                  valueType: renameEnumInValueType(
                    fieldSchema.valueType,
                    oldEnumTypeName,
                    newEnumTypeName
                  ),
                },
              ]
            )
          ),
        },
      ])
    ),
  };
}

export function removeEnum(
  schema: Schema,
  enumTypeName: string
): { schema: Schema; removedFields: RemovedFields } {
  if (!schema.enums[enumTypeName]) {
    throw new Error(`enumTypeName does not exist: ${enumTypeName}`);
  }

  const fieldsToRemove: RemovedFields = {};
  Object.entries(schema.objects).forEach(([objectName, objectType]) => {
    const objectFields = Object.keys(objectType.fields);

    const objectFieldsToRemove = objectFields.filter((fieldName) =>
      valueTypeReturnsEnum(objectType.fields[fieldName].valueType, enumTypeName)
    );

    if (
      getFieldArgumentsObjectTypeNameParts(objectName) === null &&
      objectFieldsToRemove.length > 0 &&
      objectFields.length - objectFieldsToRemove.length === 0
    ) {
      throw new Error(
        `Removing enum "${enumTypeName}" would result in object "${objectName}" being empty. Delete it or add other fields to it first.`
      );
    }
    if (objectFieldsToRemove.length > 0) {
      fieldsToRemove[objectName] = objectFieldsToRemove;
    }
  });

  return {
    schema: {
      ...schema,
      enums: Object.fromEntries(
        Object.entries(schema.enums).filter(
          ([enumName]) => enumName !== enumTypeName
        )
      ),
      objects: removeFieldsFromObjects(schema, fieldsToRemove).objects,
    },
    removedFields: fieldsToRemove,
  };
}

export function setEnumDescription(
  schema: Schema,
  enumTypeName: string,
  description: string | null
): Schema {
  return {
    ...schema,
    enums: Object.fromEntries(
      Object.entries(schema.enums).map(([enumName, objectSchema]) => [
        enumName,
        enumName === enumTypeName
          ? { ...objectSchema, description }
          : objectSchema,
      ])
    ),
  };
}

export function setEnumValueDescription(
  schema: Schema,
  enumTypeName: string,
  valueName: string,
  description: string | null
): Schema {
  return setEnumValueSchema(schema, enumTypeName, valueName, (valueSchema) => ({
    ...valueSchema,
    description,
  }));
}

export function setEnumValueDeprecationReason(
  schema: Schema,
  enumTypeName: string,
  valueName: string,
  deprecationReason: string | undefined
): Schema {
  return setEnumValueSchema(schema, enumTypeName, valueName, (valueSchema) => {
    const { deprecationReason: _, ...baseValueSchema } = valueSchema;
    return {
      ...baseValueSchema,
      ...(deprecationReason === undefined ? {} : { deprecationReason }),
    };
  });
}

function setEnumValueSchema(
  schema: Schema,
  enumTypeName: string,
  enumValueName: string,
  newValueSchema: (valueSchema: EnumValueSchema) => EnumValueSchema
): Schema {
  if (!schema.enums[enumTypeName]) {
    throw new Error(`enumTypeName does not exist: ${enumTypeName}`);
  }
  if (!schema.enums[enumTypeName].values[enumValueName]) {
    throw new Error(`valueName does not exist: ${enumValueName}`);
  }
  return {
    ...schema,
    enums: Object.fromEntries(
      Object.entries(schema.enums).map(([enumName, enumSchema]) => [
        enumName,
        enumName !== enumTypeName
          ? enumSchema
          : {
              ...enumSchema,
              values: Object.fromEntries(
                Object.entries(enumSchema.values).map(
                  ([valueName, valueSchema]) => [
                    valueName,
                    enumValueName === valueName
                      ? newValueSchema(valueSchema)
                      : valueSchema,
                  ]
                )
              ),
            },
      ])
    ),
  };
}

// eslint-disable-next-line max-params
export function addEnumValue(
  schema: Schema,
  enumTypeName: string,
  rawNewValueName: string,
  description: string | null,
  valuePosition: EnumValuePosition = "last"
): Schema {
  const newValueName = formatEnumValueSchemaName(rawNewValueName);
  if (!isSchemaNameValid(newValueName)) {
    throw new Error(schemaNameErrorMessage("Enum value", newValueName));
  }
  if (!schema.enums[enumTypeName]) {
    throw new Error(`enumTypeName doesn't exist: ${enumTypeName}`);
  }
  if (schema.enums[enumTypeName].values[newValueName]) {
    throw new Error(alreadyExistsMessage("Enum value", newValueName));
  }
  const newValue = { [newValueName]: { description } };
  return {
    ...schema,
    enums: {
      ...schema.enums,
      [enumTypeName]: {
        ...schema.enums[enumTypeName],
        values: {
          ...(valuePosition === "first" ? newValue : {}),
          ...schema.enums[enumTypeName].values,
          ...(valuePosition === "last" ? newValue : {}),
        },
      },
    },
  };
}

export function renameEnumValue(
  schema: Schema,
  enumTypeName: string,
  oldValueName: string,
  rawNewValueName: string
): Schema {
  const newValueName = formatEnumValueSchemaName(rawNewValueName);
  if (oldValueName === newValueName) {
    return schema;
  }
  if (!isSchemaNameValid(newValueName)) {
    throw new Error(schemaNameErrorMessage("Enum value", newValueName));
  }
  if (!schema.enums[enumTypeName].values[oldValueName]) {
    throw new Error(`oldValueName does not exist: ${oldValueName}`);
  }
  if (schema.enums[enumTypeName].values[newValueName]) {
    throw new Error(alreadyExistsMessage("Enum value", newValueName));
  }

  return {
    ...schema,
    enums: Object.fromEntries(
      Object.entries(schema.enums).map(([enumName, enumSchema]) => [
        enumName,
        enumTypeName === enumName
          ? {
              ...enumSchema,
              values: Object.fromEntries(
                Object.entries(enumSchema.values).map(([key, value]) => [
                  key === oldValueName ? newValueName : key,
                  value,
                ])
              ),
            }
          : enumSchema,
      ])
    ),
  };
}

export function removeEnumValue(
  schema: Schema,
  enumTypeName: string,
  valueName: string
): Schema {
  if (!schema.enums[enumTypeName]) {
    throw new Error(`enumTypeName does not exist: ${enumTypeName}`);
  }
  if (!schema.enums[enumTypeName].values[valueName]) {
    throw new Error(`valueName does not exist: ${valueName}`);
  }
  if (Object.keys(schema.enums[enumTypeName].values).length === 1) {
    throw new Error(`enum must have at least one value: ${enumTypeName}`);
  }

  return {
    ...schema,
    enums: Object.fromEntries(
      Object.entries(schema.enums).map(([enumName, enumSchema]) => [
        enumName,
        enumTypeName === enumName
          ? {
              ...enumSchema,
              values: Object.fromEntries(
                Object.entries(enumSchema.values).filter(
                  ([name]) => valueName !== name
                )
              ),
            }
          : enumSchema,
      ])
    ),
  };
}

export function formatEnumValueSchemaName(name: string): string {
  return name.trim().split(/\s/).filter(Boolean).join("_");
}

function valueTypeReturnsEnum(
  valueType: ValueType,
  enumTypeName: string
): boolean {
  switch (valueType.type) {
    case "EnumValueType":
      return valueType.enumTypeName === enumTypeName;

    case "FunctionValueType":
      return valueTypeReturnsEnum(valueType.returnValueType, enumTypeName);

    case "ListValueType":
      return valueTypeReturnsEnum(valueType.itemValueType, enumTypeName);

    default:
      return false;
  }
}
