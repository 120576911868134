import { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { leftArrowSymbol } from "../../../lib/constants";
import Button from "../../../components/buttons/Button";
import {
  ProjectBranchDocument,
  ProjectDocument,
  PullRequestDocument,
  PullRequestQuery,
  PullRequestStatus,
  useClosePullRequestMutation,
  useCreateCommitMutation,
  useMergePullRequestMutation,
  usePullRequestQuery,
} from "../../../generated/graphql";
import DiffEditor, { DiffEditorContent } from "../diff/DiffEditor";
import getCommitData from "../../../lib/query/getCommitData";
import LoadingOrErrorView from "../LoadingOrErrorView";
import ErrorMessage from "../../../components/ErrorMessage";
import squashAndRebase from "./squashAndRebase";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setDraftCommit, setRebaseState } from "../projectSlice";
import getCreateCommitInput from "../../../lib/query/getCreateCommitInput";
import { ProjectView, useProjectSelectedState } from "../projectHooks";
import PullRequestStatusTag from "./PullRequestStatusTag";
import DeleteBranchButton from "./DeleteBranchButton";
import useSearchParamsState from "../../../app/useSearchParamsState";
import ModalWithContent from "../../../components/ModalWithContent";
import CopyableText from "../../../components/CopyableText";
import PullRequestComments from "./PullRequestComments";
import { useHypertune } from "../../../generated/hypertune.react";
import { newCommitRefetchQueries } from "../../../lib/query/refetchQueries";
import { getPullRequestURL, showSharePRModalUrlKey } from "./pullRequestHooks";

export default function PullRequestView({
  meId,
  projectId,
  canEdit,
  canContribute,
  fromBranchHasOpenPR,
  pullRequestId,
}: {
  meId: string;
  projectId: string;
  canEdit: boolean;
  canContribute: boolean;
  fromBranchHasOpenPR: boolean;
  pullRequestId: number;
}): React.ReactElement | null {
  const { data, loading, error } = usePullRequestQuery({
    variables: { pullRequestId: pullRequestId.toString() },
  });
  if (!data || loading || error) {
    return (
      <LoadingOrErrorView
        selectedView="pull-requests"
        projectId={projectId}
        error={error}
      />
    );
  }
  return (
    <PullRequestViewInner
      meId={meId}
      projectId={projectId}
      canEdit={canEdit}
      canContribute={canContribute}
      fromBranchHasOpenPR={fromBranchHasOpenPR}
      pullRequest={data.pullRequest}
    />
  );
}

function PullRequestViewInner({
  meId,
  projectId,
  canEdit,
  canContribute,
  fromBranchHasOpenPR,
  pullRequest,
}: {
  meId: string;
  projectId: string;
  canEdit: boolean;
  canContribute: boolean;
  fromBranchHasOpenPR: boolean;
  pullRequest: PullRequestQuery["pullRequest"];
}): React.ReactElement | null {
  const canClose = canEdit || (canContribute && pullRequest.author.id === meId);
  const { selectedBranchName } = useParams();
  const hasDraftChanges = useAppSelector(
    (state) => state.project.draftCommitDerived.hasChanges
  );

  const content = useHypertune().content().pullRequests();
  const [showShareModal, setShowShareModal] = useSearchParamsState(
    showSharePRModalUrlKey,
    false
  );
  const { setSelected } = useProjectSelectedState();
  const [createCommit, { loading: createCommitLoading }] =
    useCreateCommitMutation({
      refetchQueries: newCommitRefetchQueries,
      awaitRefetchQueries: true,
    });
  const [mergePullRequest, { loading: mergeLoading }] =
    useMergePullRequestMutation({
      refetchQueries: [
        ProjectBranchDocument,
        ProjectDocument,
        PullRequestDocument,
      ],
      awaitRefetchQueries: true,
    });
  const dispatch = useAppDispatch();
  const intoCommit = getCommitData(
    pullRequest.intoBranchCommit ?? pullRequest.intoBranch.activeCommit
  );
  const fromCommit = getCommitData(
    pullRequest.fromBranchCommit ?? pullRequest.fromBranch.activeCommit
  );

  const navigateToBranchDraft = useCallback(
    (branchName: string, view: ProjectView) =>
      setSelected({
        view,
        branchName,
      }),
    [setSelected]
  );

  const readyToMerge =
    pullRequest.intoBranch.activeCommit.id ===
    pullRequest.fromBranch.activeCommit.parent?.id;

  const onSquashAndRebase = useCallback(async () => {
    const newDraftCommit = squashAndRebase({
      commonAncestorCommit: getCommitData(pullRequest.latestCommonCommit),
      intoBranchCommit: intoCommit,
      fromBranchCommit: fromCommit,
    });
    const commitMessage = `v${pullRequest.intoBranch.commits.length + 1} (squash and rebase on ${pullRequest.intoBranch.name})`;

    if (
      !!newDraftCommit.schemaError ||
      !!newDraftCommit.logicError ||
      !!newDraftCommit.splitsError
    ) {
      navigateToBranchDraft(
        pullRequest.fromBranch.name,
        newDraftCommit.schemaError !== null
          ? "schema"
          : newDraftCommit.splitsError !== null
            ? "splits"
            : "logic"
      );
      dispatch(setDraftCommit(newDraftCommit));
      dispatch(
        setRebaseState({
          branchName: pullRequest.intoBranch.name,
          commitMessage,
        })
      );
      return;
    }
    if (
      selectedBranchName === pullRequest.fromBranch.name &&
      !hasDraftChanges
    ) {
      // Set draft state to the new commit if from branch is selected
      // and there are no changes to prevent navigation blocker.
      dispatch(setDraftCommit(newDraftCommit));
    }

    const input = getCreateCommitInput({
      projectId,
      parentId: pullRequest.intoBranch.activeCommit.id,
      message: commitMessage,
      branchName: pullRequest.fromBranch.name,
      draftCommit: newDraftCommit,
    });

    await createCommit({
      variables: {
        input: {
          ...input,
          rebaseBranchName: pullRequest.intoBranch.name,
        },
      },
    });
  }, [
    createCommit,
    dispatch,
    fromCommit,
    hasDraftChanges,
    intoCommit,
    navigateToBranchDraft,
    projectId,
    pullRequest.fromBranch.name,
    pullRequest.intoBranch.activeCommit.id,
    pullRequest.intoBranch.commits.length,
    pullRequest.intoBranch.name,
    pullRequest.latestCommonCommit,
    selectedBranchName,
  ]);

  return (
    <>
      <DiffEditorContent
        isVisible
        newCommitHasChanges
        meId={meId}
        currentCommit={intoCommit}
        newCommit={fromCommit}
        customTitle={
          <>
            {pullRequest.intoBranch.name} {leftArrowSymbol}{" "}
            {pullRequest.fromBranch.name}
            <PullRequestStatusTag
              status={pullRequest.status}
              className="ml-2 inline-block"
            />
          </>
        }
        meta={{
          createdAt: pullRequest.createdAt,
          author: pullRequest.author,
        }}
        timeFormat="relative"
        rightSidebar={
          <PullRequestComments
            meId={meId}
            pullRequestId={pullRequest.id}
            comments={pullRequest.comments}
          />
        }
        topBarAction={
          pullRequest.status !== PullRequestStatus.Open ? (
            pullRequest.fromBranch.archived ? null : (
              <DeleteBranchButton
                hideIcon
                weight="elevated"
                text="Delete branch"
                branch={{
                  id: pullRequest.fromBranch.id,
                  name: pullRequest.fromBranch.name,
                  authorId: pullRequest.fromBranch.author?.id ?? null,
                  hasOpenPR: fromBranchHasOpenPR,
                }}
                meId={meId}
                canEdit={canEdit}
                canContribute={canContribute}
              />
            )
          ) : (
            <div className="flex flex-row gap-3">
              {!readyToMerge && (
                <ErrorMessage
                  errorMessage={content.squashAndRebaseMessage({
                    fallback: "",
                  })}
                />
              )}
              <Button
                text="Share"
                weight="elevated"
                onClick={() => setShowShareModal(true)}
              />

              {!readyToMerge && (
                <Button
                  text="Squash and rebase"
                  weight="elevated"
                  onClick={onSquashAndRebase}
                  loading={createCommitLoading}
                />
              )}
              <Button
                text="Edit"
                weight="elevated"
                onClick={() =>
                  navigateToBranchDraft(pullRequest.fromBranch.name, "logic")
                }
              />
              {canClose && <CloseButton pullRequestId={pullRequest.id} />}
              {readyToMerge && canEdit && (
                <Button
                  text="Merge"
                  intent="success"
                  weight="elevated"
                  onClick={async () => {
                    await mergePullRequest({
                      variables: {
                        input: { pullRequestId: pullRequest.id },
                      },
                    });
                    dispatch(setDraftCommit(fromCommit));
                    navigateToBranchDraft(pullRequest.intoBranch.name, "logic");
                  }}
                  loading={mergeLoading}
                />
              )}
            </div>
          )
        }
      />
      {showShareModal && (
        <ModalWithContent
          content={{
            title: content.contributorNewPRModal().heading({ fallback: "" }),
            message: content.contributorNewPRModal().text({ fallback: "" }),
            intent: "NEUTRAL",
            buttonText: "",
            closeText: "",
          }}
          onClose={() => setShowShareModal(false)}
        >
          <CopyableText
            text={getPullRequestURL(projectId, pullRequest.id)}
            className="max-w-[380px]"
          />
        </ModalWithContent>
      )}
    </>
  );
}

function CloseButton({
  pullRequestId,
}: {
  pullRequestId: string;
}): React.ReactElement | null {
  const [closePullRequest, { loading }] = useClosePullRequestMutation({
    refetchQueries: [ProjectBranchDocument, PullRequestDocument],
    awaitRefetchQueries: true,
  });

  return (
    <Button
      text="Close"
      intent="danger"
      weight="elevated"
      onClick={async () => {
        await closePullRequest({
          variables: {
            input: { pullRequestId },
          },
        });
      }}
      loading={loading}
    />
  );
}

PullRequestView.LoadingSkeleton = function (): React.ReactElement | null {
  return (
    <DiffEditor.LoadingSkeleton
      customTitle={<Skeleton className="rounded-lg" height={13} width={150} />}
      rightSidebar={<PullRequestComments.LoadingSkeleton />}
    />
  );
};
