import { Link } from "react-router-dom";
import LogoWithText from "./icons/LogoWithText";

export default function Logo({
  size = "normal",
  linkTo,
  className,
}: {
  size?: "normal" | "large";
  linkTo?: string;
  className?: string;
}): React.ReactElement {
  const logo = (
    <div
      className={`flex flex-row items-center gap-2 rounded-md px-2 py-[7.5px] 
                  ${linkTo ? "cursor-pointer hover:bg-bg-hover/50" : ""} 
                  ${className || ""}`}
    >
      <LogoWithText size={size === "large" ? 29 : undefined} />
    </div>
  );
  if (linkTo) {
    return <Link to={linkTo}>{logo}</Link>;
  }
  return logo;
}
