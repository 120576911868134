import { dotSymbol } from "../lib/constants";
import twMerge from "../lib/twMerge";
import Label from "./Label";
import UserPicture from "./UserPicture";

export default function UserWithTime({
  user,
  time,
  muted,
  className,
}: {
  user: {
    imageUrl: string;
    displayName: string;
  };
  time?: string;
  muted?: boolean;
  className?: string;
}): React.ReactElement | null {
  return (
    <div className={twMerge("flex items-center", className)}>
      <UserPicture size="small" href={user.imageUrl} />
      <div className="pl-[13px]">
        <div className="flex flex-row gap-2">
          <Label
            type="title3"
            className={muted ? "text-tx-muted" : "text-tx-default"}
          >
            {user.displayName}
          </Label>
          {time && (
            <>
              <Label
                type="title4"
                className={muted ? "text-tx-muted" : "text-tx-default"}
              >
                {dotSymbol}
              </Label>
              <Label
                type="title4"
                className={muted ? "text-tx-muted" : "text-tx-default"}
              >
                {time}
              </Label>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
