import { rightDoubleArrowSymbol } from "../../constants";

import { ValueTypeConstraint } from "../types";

export default function valueTypeConstraintToString(
  valueTypeConstraint: ValueTypeConstraint
): string {
  switch (valueTypeConstraint.type) {
    case "VoidValueTypeConstraint":
      return "Void";

    case "BooleanValueTypeConstraint":
      return "Boolean";

    case "IntValueTypeConstraint":
      return "Int";

    case "FloatValueTypeConstraint":
      return "Float";

    case "StringValueTypeConstraint":
      return "String";

    case "RegexValueTypeConstraint":
      return "Regex";

    case "EnumValueTypeConstraint":
      return valueTypeConstraint.enumTypeName;

    case "AnyEnumValueTypeConstraint":
      return "AnyEnum";

    case "ObjectValueTypeConstraint":
      return valueTypeConstraint.objectTypeName;

    case "AnyObjectValueTypeConstraint":
      return "AnyObject";

    case "UnionValueTypeConstraint":
      return valueTypeConstraint.unionTypeName;

    case "ListValueTypeConstraint":
      return `List[${valueTypeConstraintToString(
        valueTypeConstraint.itemValueTypeConstraint
      )}]`;

    case "FunctionValueTypeConstraint":
      return `(${valueTypeConstraint.parameterValueTypeConstraints
        .map((constraint) => valueTypeConstraintToString(constraint))
        .join(", ")}) ${rightDoubleArrowSymbol} ${valueTypeConstraintToString(
        valueTypeConstraint.returnValueTypeConstraint
      )}`;

    case "FunctionWithReturnValueTypeConstraint":
      return `(AnyParameters) ${rightDoubleArrowSymbol} ${valueTypeConstraintToString(
        valueTypeConstraint.returnValueTypeConstraint
      )}`;

    case "AnyNonFunctionValueTypeConstraint":
      return "AnyNonFunction";
    case "AnyValueTypeConstraint":
      return "*";

    case "ErrorValueTypeConstraint":
      return "TypeError";

    default: {
      const neverValueTypeConstraint: never = valueTypeConstraint;
      throw new Error(
        `Unexpected value type constraint: ${JSON.stringify(
          neverValueTypeConstraint
        )}`
      );
    }
  }
}
