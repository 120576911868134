import { FunnelStep } from "@hypertune/shared-internal";
import { CommitContext } from "./types";

export default function getFunnelStepPayloadObjectTypeName(
  commitContext: CommitContext,
  step: FunnelStep
): string | null {
  return step.type === "FunnelExposureStep"
    ? (commitContext.splits[step.splitId]?.eventObjectTypeName ?? null)
    : step.eventObjectTypeName;
}
