import { Schema, ValueType } from "@hypertune/sdk/src/shared/types";
import { TypeDefinition, TypeOption, TypeReferencesMap } from "./schemaHooks";

export default function getSchemaTypeReferences(
  schema: Schema
): TypeReferencesMap {
  const result: TypeReferencesMap = {};
  function addToResult(typeName: string, def: TypeDefinition): void {
    if (!(typeName in result)) {
      result[typeName] = new Set();
    }
    result[typeName].add(def);
  }
  function addValueTypeReferencesToResult(
    def: TypeDefinition,
    valueType: ValueType
  ): void {
    switch (valueType.type) {
      case "ObjectValueType":
        addToResult(valueType.objectTypeName, def);
        break;
      case "EnumValueType":
        addToResult(valueType.enumTypeName, def);
        break;
      case "UnionValueType":
        addToResult(valueType.unionTypeName, def);
        break;
      case "ListValueType":
        addValueTypeReferencesToResult(def, valueType.itemValueType);
        break;
      case "FunctionValueType":
        valueType.parameterValueTypes.forEach((paramValueType) =>
          addValueTypeReferencesToResult(def, paramValueType)
        );
        addValueTypeReferencesToResult(def, valueType.returnValueType);
        break;
      default:
      // Skip other basic value types
    }
  }
  Object.entries(schema.objects).forEach(([typeName, objectSchema]) => {
    const typeOption: TypeOption =
      objectSchema.role === "output" || objectSchema.role === "args"
        ? "object"
        : objectSchema.role;
    Object.values(objectSchema.fields).forEach((fieldSchema) => {
      addValueTypeReferencesToResult(
        { type: typeOption, name: typeName },
        fieldSchema.valueType
      );
    });
  });
  Object.entries(schema.unions).forEach(([typeName, unionSchema]) => {
    Object.keys(unionSchema.variants).forEach((objectTypeName) => {
      addToResult(objectTypeName, { type: "union", name: typeName });
    });
  });
  return result;
}
