import { ArrowRight, GitBranch, Plus } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { defaultBranchName } from "@hypertune/sdk/src/shared";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import CardGroup from "../../components/CardGroup";
import Label from "../../components/Label";
import Button from "../../components/buttons/Button";
import CenteredContainer, {
  CenteredContainerHeader,
} from "../../components/container/CenteredContainer";
import { whiteHex } from "../../lib/constants";
import { ProjectQuery, PullRequestStatus } from "../../generated/graphql";
import DeleteBranchButton from "./pull-request/DeleteBranchButton";
import NewBranchModal from "./controls/NewBranchModal";
import Tag from "../../components/Tag";
import { useNavigateToPR } from "./pull-request/pullRequestHooks";
import { useHypertune } from "../../generated/hypertune.react";
import Card from "../../components/Card";
import EmptyStateContainer from "../../components/EmptyStateContainer";

export default function BranchesEditor({
  isVisible,
  meId,
  canEdit,
  canContribute,
  projectId,
  branches,
  pullRequests,
}: {
  isVisible: boolean;
  meId: string;
  canEdit: boolean;
  canContribute: boolean;
  projectId: string;
  branches: ProjectQuery["project"]["branches"];
  pullRequests: ProjectQuery["project"]["pullRequests"];
}): React.ReactElement | null {
  const navigate = useNavigate();
  const hypertune = useHypertune();
  const branchingEnabled = hypertune
    .features()
    .branchingEnabled({ fallback: false });
  const content = hypertune
    .content()
    .plans()
    .branchesUpgradeEmptyStateMessage()
    .get();

  const navigateToPR = useNavigateToPR();
  const [showCreateBranchModal, setShowCreateBranchModal] = useState(false);

  const branchIdToPRId = useMemo(
    () =>
      Object.fromEntries(
        pullRequests
          .filter(
            (pullRequest) =>
              pullRequest.status === PullRequestStatus.Open ||
              pullRequest.status === PullRequestStatus.Approved
          )
          .map((pullRequest) => [pullRequest.fromBranch.id, pullRequest.id])
      ),
    [pullRequests]
  );

  if (!branchingEnabled) {
    return (
      <CenteredContainer hidden={!isVisible}>
        <CenteredContainerHeader title="Branches" className="h-[30px]" />
        <Card layout="none">
          <EmptyStateContainer
            icon={<GitBranch />}
            content={content}
            buttonOnClick={() => navigate("/plans")}
            buttonHideIcon
          />
        </Card>
      </CenteredContainer>
    );
  }

  return (
    <CenteredContainer hidden={!isVisible}>
      <CenteredContainerHeader title="Branches" className="h-[30px]">
        {canContribute && branchingEnabled && (
          <Button
            text="New branch"
            intent="primary"
            weight="filled"
            icon={<Plus color={whiteHex} weight="bold" />}
            onClick={() => setShowCreateBranchModal(true)}
          />
        )}
      </CenteredContainerHeader>
      <CardGroup
        layout="list"
        cardLayout="horizontal"
        cards={[...branches]
          .sort(({ name: nameA }, { name: nameB }) =>
            nameA === defaultBranchName
              ? -1
              : nameB === defaultBranchName
                ? 1
                : nameB.localeCompare(nameA)
          )
          .map((branch) => ({
            key: branch.id,
            className: "min-h-[26px]",
            children: (
              <>
                <div className="flex flex-col gap-1">
                  <Label type="title3">{branch.name}</Label>
                  <Label type="title4" className="text-tx-muted">
                    Created {DateTime.fromISO(branch.createdAt).toRelative()}
                  </Label>
                </div>

                {branch.name === defaultBranchName && (
                  <Tag text="Default" intent="primary" />
                )}
                <DeleteBranchButton
                  meId={meId}
                  branch={{
                    id: branch.id,
                    name: branch.name,
                    authorId: branch.author?.id ?? null,
                    hasOpenPR: !!branchIdToPRId[branch.id],
                  }}
                  canEdit={canEdit}
                  canContribute={canContribute}
                  text=""
                  size="small"
                />
                {branchIdToPRId[branch.id] && (
                  <Button
                    text="Go to pull request"
                    size="small"
                    iconEnd={<ArrowRight weight="regular" />}
                    onClick={() => navigateToPR(branchIdToPRId[branch.id])}
                  />
                )}
              </>
            ),
          }))}
      />
      {showCreateBranchModal && (
        <NewBranchModal
          projectId={projectId}
          baseBranchName={defaultBranchName}
          branches={branches}
          onClose={() => setShowCreateBranchModal(false)}
        />
      )}
    </CenteredContainer>
  );
}

BranchesEditor.LoadingSkeleton = function (): React.ReactElement {
  return (
    <CenteredContainer>
      <CenteredContainerHeader title="Branches" className="h-[30px]" />
      <CardGroup
        layout="list"
        cardLayout="horizontal"
        loadingCount={2}
        cards={[]}
      />
    </CenteredContainer>
  );
};
