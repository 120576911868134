import { Parameter, ValueType, uniqueId } from "@hypertune/sdk/src/shared";
import getNewVariableName from "./getNewVariableName";
import getVariableNames from "./getVariableNames";

import { VariableMap } from "./types";

export default function getParameters(
  variables: VariableMap,
  parameterValueTypes: ValueType[]
): Parameter[] {
  const parameters: Parameter[] = [];

  const variableNames = getVariableNames(variables);

  parameterValueTypes.forEach((parameterValueType) => {
    const name = getNewVariableName(variableNames, parameterValueType);
    const parameter: Parameter = {
      id: uniqueId(),
      name,
    };
    parameters.push(parameter);
    variableNames[name] = true;
  });

  return parameters;
}
