import {
  Schema,
  SplitMap,
  fieldPathSeparator,
} from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import React, { useMemo } from "react";
import { getStringValuePathsForObject } from "@hypertune/shared-internal";
import Dropdown from "../../components/Dropdown";
import { Intent } from "../../components/intent";

export default function UnitIdPathSelector({
  context,
  source,
  eventTypeName,
  splitId,
  unitIdPath,
  setUnitIdPath,
  readOnly,
  style,
  intent = "neutral",
  dropDownHeight = 30,
}: {
  context: { splits: SplitMap; schema: Schema };
  source: "split" | "event";
  eventTypeName?: string | null;
  splitId?: string | null;
  unitIdPath: string | null;
  setUnitIdPath: (newUnitIdPath: string) => void;
  readOnly?: boolean;
  style?: React.CSSProperties;
  intent?: Intent;
  dropDownHeight?: number;
}): React.ReactElement {
  const unitIdOptions = useMemo(() => {
    return (
      source === "event" && eventTypeName
        ? getJoinIdOptionsForObject(context.schema, eventTypeName)
        : source === "split" && splitId
          ? context.splits[splitId].eventObjectTypeName
            ? [
                ["unitId"],
                ...getJoinIdOptionsForObject(
                  context.schema,
                  context.splits[splitId].eventObjectTypeName as string
                ),
              ]
            : [["unitId"]]
          : []
    ).map((path) => {
      return {
        value: path.join(fieldPathSeparator),
        label: path.map(toStartCase).join(fieldPathSeparator),
      };
    });
  }, [context.schema, context.splits, source, eventTypeName, splitId]);

  return (
    <Dropdown
      intent={intent}
      style={style}
      options={{
        type: "options",
        options: unitIdOptions,
      }}
      disabled={readOnly || unitIdOptions.length === 0 || source === "split"}
      height={dropDownHeight}
      value={
        unitIdPath
          ? { value: unitIdPath, label: toStartCase(unitIdPath) }
          : null
      }
      onChange={(newJoinIdPath) => setUnitIdPath(newJoinIdPath?.value || "")}
      placeholder={
        unitIdOptions.length === 0
          ? `Select ${source === "event" ? "event type" : "split"} first`
          : "Select unit ID..."
      }
      noOptionsMessage="No unit IDs"
    />
  );
}

export function getJoinIdOptionsForObject(
  schema: Schema,
  objectTypeName: string
): string[][] {
  return getStringValuePathsForObject(schema, objectTypeName).map((path) => [
    "payload",
    ...path,
  ]);
}
